<nb-stepper disableStepNavigation #stepper>
  <nb-step [stepControl]="nuevoExpedienteForm" [label]="stepOne" class="step-container">
    <ng-template #stepOne>Expediente</ng-template>
    <form [formGroup]="nuevoExpedienteForm" (ngSubmit)="onNuevoExpedienteSubmit()" class="step-container">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="contrato">Contrato*</label>
          <input nbInput type="text" placeholder="Contrato" class="form-control" formControlName="contrato" />
        </div>
        <div class="form-group col-sm-6">
          <label for="contrato">Descripcion*</label>
          <textarea nbInput type="text" placeholder="Descripción" class="form-control" formControlName="descripcion"
            [ngClass]="{
              'form-control-danger':
                nuevoExpedienteForm.invalid &&
                (nuevoExpedienteForm.dirty || nuevoExpedienteForm.touched)
            }"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="entrega">Quien entrega*</label>
          <input nbInput type="text" placeholder="Entrega" class="form-control" formControlName="entrega" [ngClass]="{
              'form-control-danger':
                nuevoExpedienteForm.invalid &&
                (nuevoExpedienteForm.dirty || nuevoExpedienteForm.touched)
            }" />
        </div>
        <div class="form-group col-sm-6">
          <label for="recibe">Quien recibe*</label>
          <input nbInput type="text" placeholder="Recibe" class="form-control" formControlName="recibe" [ngClass]="{
              'form-control-danger':
                nuevoExpedienteForm.invalid &&
                (nuevoExpedienteForm.dirty || nuevoExpedienteForm.touched)
            }" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="cargo_entrega">Cargo de quien entrega*</label>
          <input nbInput type="text" placeholder="Cargo de quien entrega" class="form-control"
            formControlName="cargo_entrega" [ngClass]="{
              'form-control-danger':
                nuevoExpedienteForm.invalid &&
                (nuevoExpedienteForm.dirty || nuevoExpedienteForm.touched)
            }" />
        </div>
        <div class="form-group col-sm-6">
          <label for="cargo_recibe">Cargo de quien recibe *</label>
          <input nbInput type="text" placeholder="Cargo de quien recibe" class="form-control"
            formControlName="cargo_recibe" [status]="
              nuevoExpedienteForm.invalid &&
              (nuevoExpedienteForm.dirty || nuevoExpedienteForm.touched)
                ? 'danger'
                : 'basic'
            " />
        </div>
      </div>
      <button type="submit" nbButton>Guardar</button>
    </form>
  </nb-step>
  <nb-step [label]="stepTwo">
    <ng-template #stepTwo>Documentos</ng-template>
    <ngx-expediente-documentos-form [docsExpediente]="docsExpediente"></ngx-expediente-documentos-form>
  </nb-step>
</nb-stepper>
