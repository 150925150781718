import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiguracionesComponent } from './configuraciones.component';
import { NbButtonModule, NbCardModule, NbSpinnerModule, NbTabsetModule } from '@nebular/theme';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { EmpresaFiniquitoService } from '../../@core/services/empresa-finiquito.service';



@NgModule({
  declarations: [
    ConfiguracionesComponent
  ],
  imports: [
    CommonModule,
    NbTabsetModule,
    NbCardModule,
    FormBuilderModule,
    MatTableBuilderModule,
    NbButtonModule,
    NbSpinnerModule
  ],
  providers: [
    EmpresaFiniquitoService
  ]
})
export class ConfiguracionesModule { }
