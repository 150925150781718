<div class="row">
  <div class="col-xxxl-9 col-xl-12">
    <nb-card>
      <nb-card-header class="d-flex justify-content-between">
        <div>Roles
          <button nbButton size="small" status="success" nbTooltip="Nuevo rol" (click)="onNewDataClick()">
          <nb-icon  icon="plus"></nb-icon>
        </button>
      </div>
        <div>
          <!-- <input nbInput type="text" (keyup)="filter($event.target.value)" placeholder="Buscar licencia"> -->
        </div>
      </nb-card-header>
      <nb-card-body>
        <ngx-mat-table-builder [columnsDef]="columns" [data]="filteredData" [actions]="actions"></ngx-mat-table-builder>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<ng-template class="modal" #newForm let-data>
  <div class="responsive-div-lg modal-body' ">
  <ngx-form-builder [config]="data.config" (formOuput)="onCreated($event)"></ngx-form-builder>
  </div>
</ng-template>

