import { Injectable } from '@angular/core';
import { Banco } from '../data/gastos-data';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class BancoService extends BaseService<Banco>{

  protected getPath(): string {
    return 'bancos';
  }
}
