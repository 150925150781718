<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Nomina semanales
    </div>
    <div>
      <button nbButton status="primary" nbTooltip="Generar nomina" (click)="generarNomina()">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
    </div>
    <!-- <ngx-filter-value-date [items]="gastos" [filterField]="'fecha_compra'" (filterChange)="onFilterChange($event)"></ngx-filter-value-date> -->
  </nb-card-header>
  <nb-card-body class="no-padding">
    <ngx-mat-table-builder [columnsDef]="nominaConfig.tbDef" [data]="nominaConfig.data" [actions]="nominaConfig.tbActions"></ngx-mat-table-builder>
    <!-- <div class="total-container">
      <label class="total-label">Total de Importes: {{ getTotalImportes() | currency:"MXN":"symbol":"1.2-2" }}</label>
      <label class="total-label">Total de Montos: {{ getTotalMontos() | currency:"MXN":"symbol":"1.2-2" }}</label>
    </div> -->
  </nb-card-body>
</nb-card>

<ng-template let-data #nuevaNominaWindow>
  <div class="responsive-div">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onNominaCreated($event)">
    </ngx-form-builder>
  </div>
</ng-template>
