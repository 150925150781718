
<table mat-table #table [dataSource]="cotizaciones">
  <ng-container matColumnDef="maquinaria_numero">
    <th mat-header-cell *matHeaderCellDef>Número</th>
    <td mat-cell *matCellDef="let cotizacion">{{cotizacion.maquinaria_numero}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="maquinaria_nombre">
    <th mat-header-cell *matHeaderCellDef>Maquinaria</th>
    <td mat-cell *matCellDef="let cotizacion">
      <nb-select (selectedChange)="onSelectMaquinaria($event,cotizacion)" [(ngModel)]="cotizacion.maquinaria_id" fullWidth placeholder="Selecciona una maquinaria">
        <nb-option *ngFor="let maquinaria of maquinarias" [value]="maquinaria.id">{{maquinaria.nombre}}</nb-option>
      </nb-select>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="precio_renta_maquinaria">
    <th mat-header-cell *matHeaderCellDef>Precio renta</th>
    <td mat-cell *matCellDef="let cotizacion">
      <input nbInput [value]="cotizacion.precio_renta_maquinaria" (change)="onPrecioChange($event,cotizacion)" type="number">
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="cantidad_horas">
    <th mat-header-cell *matHeaderCellDef>Cantidad de horas</th>
    <td mat-cell *matCellDef="let cotizacion">
      <input nbInput [value]="cotizacion.cantidad_horas" (change)="onHorasChage($event,cotizacion)" type="number">
    </td>
    <td mat-footer-cell *matFooterCellDef>Total</td>
  </ng-container>
  <ng-container matColumnDef="subtotal">
    <th mat-header-cell *matHeaderCellDef>Subtotal</th>
    <td mat-cell *matCellDef="let cotizacion">
      {{cotizacion.subtotal | currency:"MXN":"symbol"}}
    </td>
    <td mat-footer-cell *matFooterCellDef> {{getTotalCost() | currency:"MXN":"symbol":"1.2-2"}} </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let cotizacion">
      <button nbButton nbTooltip="Eliminar" size="small" status="primary"   nbButton (click)="removeCotizacionVehiculo(cotizacion)">
        <nb-icon icon="trash-2-outline"></nb-icon>
      </button>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: allColumns;">
  <tr mat-footer-row *matFooterRowDef="allColumns"></tr>
</table>


