import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GCMarqTablesModule } from '../../tables/gcmarq-tables.module';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { NbButton, NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbListModule } from '@nebular/theme';
import { ContratosRHComponent } from './contratos-rh.component';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { ContratosRhService } from '../../@core/services/contratos-rh.service';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { ManageDocumentsComponent } from './manage-documents/manage-documents.component';



@NgModule({
  declarations: [
    ContratosRHComponent,
    ManageDocumentsComponent
  ],
  imports: [
    CommonModule,
    GCMarqTablesModule,
    FormBuilderModule,
    MatTableBuilderModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    NbListModule,
  ],
  providers: [
    ContratosRhService,
    EmpleadoService
  ]
})
export class ContratosRHModule { }
