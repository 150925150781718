import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CorteNoFiscalComponent } from "./corte-no-fiscal.component";
import { NbCardModule, NbIconModule, NbButtonModule, NbInputModule, NbSelectModule, NbDatepickerModule, NbTableModule, NbSpinnerModule } from "@nebular/theme";
import { FilterValueDateModule } from "../../forms/filter-value-date/filter-value-date.module";
import { MatTableBuilderModule } from "../../mat-table-builder/mat-table-builder.module";

@NgModule({
  declarations: [CorteNoFiscalComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    NbSelectModule,
    NbDatepickerModule,
    FilterValueDateModule,
    MatTableBuilderModule,
    NbDatepickerModule,
    NbSpinnerModule,
  ],
})
export class CorteNoFiscalModule {}
