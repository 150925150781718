<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Entradas Fiscales
      <button nbButton (click)="onNuevoClick()" size="small"  nbTooltip="Nuevo Gasto" >
        <nb-icon icon="plus"></nb-icon>
      </button>
      <button nbButton (click)="downloadCsv()" size="small" nbTooltip="Descargar Reporte" >
        <nb-icon icon="download"></nb-icon>
      </button>
    </div>

    <ngx-filter-value-date [items]="filteredData" [filterFields]="filterFields" filterField="fecha_entrada" (filterChange)="onFilterChange($event)"></ngx-filter-value-date>
  </nb-card-header>
  <nb-card-body class="no-padding">
    <ngx-mat-table-builder [columnsDef]="columns" [data]="filteredData" [actions]="acciones"></ngx-mat-table-builder>
    <div class="total-container">
      <label class="total-label">Total de Importes: {{ getTotalImportes() | currency:"MXN":"symbol":"1.2-2" }}</label>
    </div>
  </nb-card-body>
</nb-card>

<ng-template class="modal" let-data #nuevaEntradaWindow>
  <div class="modal-body responsive-div-md">
    <ngx-form-builder class="modal-body responsive-div-md" [config]="data.config" (formOuput)="onEntradaCreated($event)">
    </ngx-form-builder>
  </div>
</ng-template>
