import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableBuilderComponent } from './mat-table-builder.component';
import { MatTableModule } from '@angular/material/table';
import { NbBadgeModule, NbButton, NbButtonModule, NbIconModule, NbTagModule, NbTooltipModule } from '@nebular/theme';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { GCMARQFormsModule } from '../forms/gcmarqforms.module';
import { FormBuilderModule } from "../form-builder/form-builder.module";



@NgModule({
  declarations: [
    MatTableBuilderComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    NbIconModule,
    NbButtonModule,
    NbTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    GCMARQFormsModule,
    FormBuilderModule,
    NbBadgeModule,
    NbTagModule,
],
  exports: [
    MatTableBuilderComponent
  ]
})
export class MatTableBuilderModule { }
