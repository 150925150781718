import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GastosComponent } from './gastos.component';
import { NbButtonModule, NbCardModule, NbDatepickerModule, NbFormFieldModule, NbIconModule, NbInputModule, NbTooltipModule } from '@nebular/theme';
import { GCMARQFormsModule } from '../../forms/gcmarqforms.module';
import { GastoService } from '../../@core/services/gastos.service';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { FormsModule } from '@angular/forms';
import { FilterValueDateModule } from '../../forms/filter-value-date/filter-value-date.module';

@NgModule({
  declarations: [
    GastosComponent,
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    GCMARQFormsModule,
    MatTableBuilderModule,
    FormBuilderModule,
    NbDatepickerModule,
    NbFormFieldModule,
    FormsModule,
    NbTooltipModule,
    FilterValueDateModule
  ],
  providers: [
    GastoService
  ]
})
export class GastosModule { }
