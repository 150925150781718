<table
  mat-table
  [dataSource]="dataSource"
  matSort
>
  <ng-container
    matColumnDef="{{ column }}"
    *ngFor="let column of columnsToDisplay"
  >
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ column.charAt(0).toUpperCase() + column.slice(1) }}</th>
    <td mat-cell *matCellDef="let vehiculo">
      {{ vehiculo[column] }}
      <div class="shortcode-col col-xs-12"></div>
    </td>
  </ng-container>
  <ng-container matColumnDef="seguro_vigente">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Seguro vigente</th>
    <td mat-cell *matCellDef="let vehiculo">
      <nb-icon
        [icon]="vehiculo.seguro_vigente ? 'checkmark' : 'close'"
        [status]="vehiculo.seguro_vigente ? 'success' : 'danger'"
      ></nb-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="vendido">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendido</th>
    <td mat-cell *matCellDef="let vehiculo">
      <nb-icon
        [icon]="vehiculo.vendido ? 'checkmark' : 'close'"
        [status]="vehiculo.vendido ? 'success' : 'danger'"
      ></nb-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="ref_pagado">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Referendo pagado</th>
    <td mat-cell *matCellDef="let vehiculo">
      <nb-icon
        [icon]="vehiculo.referendo_pagado ? 'checkmark' : 'close'"
        [status]="vehiculo.referendo_pagado ? 'success' : 'danger'"
      ></nb-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="ref_comentario">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Comentario refrendo</th>
    <td mat-cell *matCellDef="let vehiculo">
      {{ vehiculo.referendo_comentario }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dias_vig_seguro">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Dias Restantes Seguro</th>
    <td mat-cell  *matCellDef="let vehiculo">
      <div *ngIf="vehiculo.fecha_fin_vigencia_seguro!==null">
        {{vehiculo.dias_vigencia_seguro}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="fech_fin_vig_seguro">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha fin seguro</th>
    <td mat-cell *matCellDef="let vehiculo">
      <div *ngIf="vehiculo.fecha_fin_vigencia_seguro!==null">
        {{ vehiculo.fecha_fin_vigencia_seguro | date: 'dd/MM/yyyy' }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="chofer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Chofer</th>
    <td mat-cell *matCellDef="let vehiculo" [nBTooltip]="vehiculo.chofer">
      {{ vehiculo.chofer?.substring(0, 10) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef >Acciones</th>
    <td mat-cell *matCellDef="let vehiculo">
    <button
      nbButton
      size="small"
      status="primary"
      nbTooltip="Editar vehiculo"
      (click)="onClickOpenEditarVehiculoForm(vehiculo)"
    >
      <nb-icon icon="edit-2-outline"></nb-icon>
    </button>
    <button
      nbButton
      size="small"
      status="danger"
      nbTooltip="Eliminar vehiculo"
      (click)="onClickOpenEliminarVehiculoForm(vehiculo)"
    >
      <nb-icon icon="trash-2-outline"></nb-icon>
    </button>
    </td>
  </ng-container>
  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let vehiculo"
      [attr.colspan]="columnsToDisplayWithExpand.length"
    >
      <div
        class="example-element-detail"
        [@detailExpand]="vehiculo === expandedVehiculo ? 'expanded' : 'collapsed'"
      >
        <div class="example-element-description">

        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand">
  </tr>
  <tr
    mat-row
    *matRowDef="let element; columns: columnsToDisplayWithExpand"
    class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element"
  >
</tr>
<tr class="mat-row" *matNoDataRow>
  <td class="mat-cell" colspan="4">Sin informacion que mostrar</td>
</tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 21]" color="dark" ariaLabel="Selecciona una pagina" showFirstLastButtons></mat-paginator>

<ng-template  #vehiculoTemplate let-data>
  <div>
    <div>¿Está seguro de eliminar este vehiculo?</div>
    <div>
      <button nbButton status="danger" (click)="onClickEliminarVehiculo(data.idVehiculo)">Eliminar</button>
      <button nbButton status="primary" (click)="onClickCancelarEliminarVehiculo()">Cancelar</button>
    </div>
  </div>
</ng-template>

<ng-template  #editarVehiculoTemplate let-data>
  <div style="min-width:200px; max-width: 500px; max-height: 400px;">
    <ngx-nuevo-vehiculo-form [vehiculoIdToEdit]="data.vehiculo.id" (vehiculoSaved)="onSavedVehiculo()"></ngx-nuevo-vehiculo-form>
  </div>
</ng-template>
