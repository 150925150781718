<table mat-table [dataSource]="dataSource"  class="mat-elevation-z8"  matSort>

  <!-- Dynamic Column Definitions -->
  <ng-container *ngFor="let column of columns" [matColumnDef]="column" style="font-size: 2em;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
       {{ getColumnName(column) }}
    </th>
    <td mat-cell *matCellDef="let row" [align]="getAlign(column,row)" [style.background-color]="getBackgroundColor(column,row)">

      <div [ngSwitch]="getTypo(column)">
        <ng-container *ngSwitchCase="'template'" >
          {{ row.template }}
        </ng-container>
        <ng-container *ngSwitchCase="'boolean'">
          <nb-icon [icon]="getValue(column, row) ? 'checkmark' : 'close'"></nb-icon>
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
            {{ getValue(column, row) === null ? '-' : (getValue(column, row) | date:'dd/MM/yyyy':'UTC') }}
        </ng-container>
        <ng-container *ngSwitchCase="'money'">
          {{ getValue(column, row)===null ? '-' : getValue(column, row) | currency:'MXN':'symbol':'1.2-2' }}
        </ng-container>
        <ng-container *ngSwitchCase="'number'">
          {{ getValue(column, row)===null ? '-' : getValue(column, row) | number:'1.2-2' }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ getValue(column, row)===null ? '-' : getValue(column, row) }}
        </ng-container>
        <ng-container *ngSwitchCase="'image'">
            <a *ngIf="getValue(column, row)" [href]="getValue(column, row)" target="_blank">
            <img [src]="getValue(column, row)" alt="Image" style="width: 50px; height: 50px; border-radius: 50%;">
            </a>
        </ng-container>

        <ng-container *ngSwitchCase="'tag'">
          <nb-tag-list>
            <nb-tag *ngFor="let value of getValue(column, row)" appearance="filled" [text]="value" status="primary" style="font-size: 0.8em;"></nb-tag>
          </nb-tag-list>
        </ng-container>

        <ng-container *ngSwitchCase="'image-url'">
          <img *ngIf="getValue(column, row)" [src]="getValue(column, row)" alt="Image URL" style="max-width: 100px; max-height: 100px; border-radius: 50%;">
        </ng-container>
        <ng-container *ngSwitchCase="'button'">
          <ng-container *ngIf="getValue(column, row) as buttonConfig">
            <button nbButton
              *ngIf="buttonConfig.show !== undefined ? buttonConfig.show(row) : true"
              [size]="buttonConfig.size || 'tiny'"
              [nbTooltip]="buttonConfig.tooltip"
              (click)="buttonConfig.action && buttonConfig.action(row)">
              {{ buttonConfig.value || '' }}
              <nb-icon *ngIf="buttonConfig.icon" [icon]="buttonConfig.icon"></nb-icon>
            </button>
          </ng-container>
        </ng-container>
      </div>
    </td>



  </ng-container>

  <ng-container matColumnDef="actions" *ngIf="showActions">
    <th mat-header-cell *matHeaderCellDef> Acciones </th>
    <td mat-cell *matCellDef="let row">
      <nb-button-group>
        <div *ngFor="let action of actions; let i = index" [ngSwitch]="action.name">
          <ngx-delete-button *ngSwitchCase="'delete'" [title]="action.title" [message]="getMessage(action,row)" (delete)="action.action(row)"></ngx-delete-button>
          <button *ngSwitchCase="'edit'" nbButton [size]="action.size?action.color:'tiny'" [nbTooltip]="action.label" (click)="onClickEdit(row,action)" [status]="action.color?action.color:'warning'">
            <nb-icon [icon]="action.icon"></nb-icon>
          </button>
          <button *ngSwitchDefault nbButton [size]="action.size?action.color:'tiny'" [nbTooltip]="action.label" (click)="action.action(row)"  [status]="action.color?action.color:'basic'">
            <nb-icon [icon]="action.icon"></nb-icon>
          </button>
        </div>
      </nb-button-group>
    </td>
  </ng-container>

  <ng-container *ngIf="hasFooter">
    <td  mat-footer-cell *matFooterCellDef>
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </td>
  </ng-container>

  <!-- Header Row -->
  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
  <!-- Data Rows -->
  <tr mat-row *matRowDef="let row; columns: allColumns;"></tr>
  <!-- <ng-container *ngIf="hasFooter">
    <tr mat-footer-row *matFooterRowDef="allColumns"></tr>
  </ng-container> -->

</table>
<mat-paginator [pageSizeOptions]="[5, 10, 50,100]" aria-label="Selecciona una pagina" showFirstLastButtons></mat-paginator>

<ng-template class="modal" #editWindow let-data>
  <div class="responsive-div-md modal-body">
    <ngx-form-builder class="responsive-div-md modal-body" [config]="data.config" (formOuput)="data.button.action($event,data.data,editFormRef)"></ngx-form-builder>
  </div>
</ng-template>
