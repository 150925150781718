import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Obra } from '../data/maquinaria-data';

@Injectable({
  providedIn: 'root'
})
export class ObraService extends BaseService<Obra>{
  protected getPath(): string {
    return 'obras';
  }
}
