import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmpresaCuenta } from '../../@core/data/empresa-finiquito-data';
import { EmpresaCuentaService } from '../../@core/services/empresa-finiquito.service';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'ngx-empresa-cuenta-group-select',
  templateUrl: './empresa-cuenta-group-select.component.html',
  styleUrls: ['./empresa-cuenta-group-select.component.scss']
})
export class EmpresaCuentaGroupSelectComponent {
  groups: Group[];
  filteredGroups$: Observable<Group[]>;
  empresaCuentas: EmpresaCuenta[];
  @Input() inputFormControl: FormControl;
  @Output() selected: EventEmitter<EmpresaCuenta> = new EventEmitter();
  constructor(private empresaCuentaService: EmpresaCuentaService) {
    empresaCuentaService.getAll().subscribe((empresas: EmpresaCuenta[]) => {
      this.groups = empresas.map(empresa => ({
        name: empresa.empresa_nombre,
        children: empresas
        .filter(cuenta => cuenta.empresa_nombre === empresa.empresa_nombre)
      }));
      this.filteredGroups$ = of(this.groups);
      this.inputFormControl = new FormControl();

      this.filteredGroups$ = this.inputFormControl.valueChanges
        .pipe(
          startWith(''),
          map(filterString => this.filter(filterString)),
        );
    });

    this.inputFormControl.valueChanges.subscribe((cuenta: string) => {
      this.selected.emit(this.empresaCuentas.find(c => c.cuenta === cuenta));
    }
    );
   }


  private filterChildren(children: EmpresaCuenta[], filterValue: string) {
    return children.filter(cuenta => cuenta.banco_nombre.toLowerCase().includes(filterValue)||
    cuenta.cuenta.toLowerCase().includes(filterValue)||cuenta.empresa_nombre.toLowerCase().includes(filterValue));
  }

  private filter(value: string): Group[] {
    const filterValue = value.toLowerCase();
    return this.groups
      .map(group => {
        return {
          name: group.name,
          children: this.filterChildren(group.children, filterValue),
        }
      })
      .filter(group => group.children.length);
  }

  trackByFn(index, item) {
    return item.name;
  }
  viewHandle(empresa: EmpresaCuenta) {
    return empresa.empresa_nombre + ' - ' + empresa.banco_nombre + ' - ' + empresa.cuenta;
  }
}
export interface Group {
  name: string;
  children: EmpresaCuenta[];
}

