  <div class="container">
    <div class="image-container">
      <img src="assets/fondo.jpg" alt="Imagen de Grupo Costa Marq. S.A de C.V." class="login-image">  </div>
    <div class="login-container">
      <div class="login-form">
        <div class="login-form-header">
          <h2 class="login-form-header-title">Grupo CostaMarq. S.A de C.V.</h2>
        </div>
        <div class="login-form-body">
          <form (submit)="submit()" [formGroup]="form">
            <div class="form-group">
              <label for="username">Correo</label>
              <input nbInput type="text" fullWidth id="username" name="username" formControlName="username" class="form-control"
              [status]="loginFormControls().username.invalid && loginFormControls().username.touched ? 'danger' : 'basic'"
              required>
              <span class="text-danger"
                                  *ngIf="(loginFormControls().username.touched) && loginFormControls().username.errors?.required">
                                  Correo es requerido
                              </span>
            </div>
            <div class="form-group">
              <label for="password">Contraseña</label>
              <input nbInput type="password" fullWidth id="password" name="password" formControlName="password" class="form-control"
              [status]="loginFormControls().password.invalid && loginFormControls().password.touched ? 'danger' : 'basic'"
              required>
              <span class="text-danger"
                                  *ngIf="(loginFormControls().password.touched) && loginFormControls().password.errors?.required">
                                  Contraseña es requerida
                              </span>
            </div>
            <div>
              <span class="text-danger" *ngIf="loginError">
                Ha ocurrido un error, por favor intente de nuevo
              </span>
            </div>
            <div class="form-group">
              <button nbButton type="submit" status="success" fullWidth class="btn btn-primary">Iniciar Sesión</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <style>
    .container {
      display: flex;
    }
    .image-container {
      flex: 1;
    }
    .login-container {
      flex: 1;
    }
    .login-image {
      width: 100%;
      height: auto;
    }
  </style>
