import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import { MaquinariaService } from "../../@core/services/maquinaria.service";
import { Maquinaria, Obra } from "../../@core/data/maquinaria-data";
import {
  FormBuilderConfig,
  FormFieldType,
  FormRowConfig,
  OptionType,
} from "../../form-builder/form-builder.component";
import { NbToastrService, NbWindowRef, NbWindowService } from "@nebular/theme";
import { FormGroup } from "@angular/forms";
import { ObraService } from "../../@core/services/obra.service";
import { EmpleadoService } from "../../@core/services/empleado.service";
import { Empleado } from "../../@core/data/cotizacion-data";
import { CSVGenerator } from "../../@core/utils/csv-generator";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "ngx-maquinaria-pesada",
  templateUrl: "./maquinaria-pesada.component.html",
  styleUrls: ["./maquinaria-pesada.component.scss"],
})
export class MaquinariaPesadaComponent implements OnInit {
  tableDef: MatTableColumnConfig[] = [
    { name: "numero", label: "No. Económico", type: "text" },
    { name: "nombre", label: "Nombre", type: "text" },
    { name: "placas", label: "Placas", type: "text" },
    { name: "serie", label: "Serie", type: "text" },
    { name: "modelo", label: "Modelo", type: "text" },
    { name: "motor", label: "Motor", type: "text" },
    { name: "diferencial", label: "Diferencial", type: "text" },
    { name: "caja_velocidad", label: "Caja de velocidad", type: "text" },
    {
      name: "ubicacion",
      label: "Ubicación",
      type: "text",
      value: (m: Maquinaria) => {
        return m.obra;
      },
    },
    { name: "lts", label: "Lts", type: "text" },
    { name: "toneladas", label: "Toneladas", type: "text" },
    { name: "operador", label: "Encargado", type: "text" },
    { name: "precio", label: "Precio", type: "text" },
    { name: "mantenimiento", label: "Mantenimiento", type: "text" },
    { name: "horometro", label: "Kilometraje", type: "text" },
    {
      name: "ult_mantenimiento",
      label: "Ult. mtto.",
      type: "text",
      value: (m: Maquinaria) => {
        if (m.mantenimiento_horas_restantes == 0) {
          return "No hay un mantenimiento programado";
        }
        return (
          m.horometro_original_matenimiento + " km."
        );
      },
    },
    {
      name: "manetenimiento_horas",
      label: "Sig. mtto. en",
      type: "text",
      value: (m: Maquinaria) => {
        if (m.mantenimiento_horas_restantes == 0) {
          return "No hay un mantenimiento programado";
        }
        return (
          m.horometro_original_matenimiento +
          m.mantenimiento_horas_restantes -
          m.horometro +
          " km."
        );
      },
      backgroundColor: (m: Maquinaria) => {
        if (
          m.horometro_original_matenimiento +
            m.mantenimiento_horas_restantes -
            m.horometro <=
          0
        ) {
          return "red";
        } else if (
          m.horometro_original_matenimiento +
            m.mantenimiento_horas_restantes -
            m.horometro <=
          500
        ) {
          return "yellow";
        }
      },
    },
    {
      name: "mantenimiento_horas_restantes",
      label: "Mantenimiento cada/km",
      type: "text",
    },
  ];
  data: Maquinaria[];
  fiteredData: Maquinaria[];
  searchText: any;
  nuevaMaquinariaWindows: NbWindowRef;
  mantenimientosRef: NbWindowRef;
  obras: Obra[];
  empleados: Empleado[];
  @ViewChild("nuevaMaquinaria", { read: TemplateRef })
  nuevaMaquinariaTemplate: TemplateRef<HTMLElement>;
  @ViewChild("mantenimientos", { read: TemplateRef })
  mantenimientosTemplate: TemplateRef<HTMLElement>;
  loading: boolean = true;
  actions: MatTableActionConfig[] = [
    {
      name: "mantenimientos",
      label: "Ver historial de mantenimiento",
      icon: "eye",
      action: (m: Maquinaria) => {
        this.mantenimientosRef = this.windowsService.open(
          this.mantenimientosTemplate,
          { title: "Historial de mantenimiento", context: { maquinaria: m } }
        );
      },
    },
    {
      name: "edit",
      label: "Agregar kilometros recorridos",
      icon: "edit",
      action: (form: FormGroup, m: Maquinaria, ref) => {

        this.maquinariaService.update(form, m.id).subscribe(
          () => {
            this.maquinariaService.getTractocamiones().subscribe((data) => {
              this.data = data;
              this.fiteredData = data;
            });
            this.toastrService.success(
              "Los kilometros han sido agregadas correctamente",
              "Kilometro agregadas"
            );
          },
          (error) => {
            this.toastrService.danger(
              error.error.message,
              "Error al agregar los kilometros",
              {
                duration: 6000,
              }
            );
          }
        );
        ref.close();
      },
      formConfig: (): FormBuilderConfig => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              horas_trabajadas: {
                type: FormFieldType.Number,
                label: "Kilometro recorridos",
                formControlName: "horas_trabajadas",
                placeholder: "0",
                value: 0,
                col: 12,
                validations: ["required"],
              },
            },
          },
        ];
      },
    },
    {
      name: "view",
      label: "Ver pedimento",
      icon: "eye",
      action: (m: Maquinaria) => {
        this.maquinariaService.downloadPedimento(m.id).subscribe(
          (data) => {
            const blob = new Blob([data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          },
          (error) => {
            if (error.status == 404) {
              this.toastrService.danger(
                "La maquinaria no tiene pedimento",
                "Error"
              );
            } else {
              this.toastrService.danger(
                "Hubo un error al descargar el pedimento",
                "Error"
              );
            }
          }
        );
      },
    },
    {
      name: "view",
      label: "Ver poliza",
      icon: "eye",
      action: (m: Maquinaria) => {
        this.maquinariaService.downloadPoliza(m.id).subscribe(
          (data) => {
            const blob = new Blob([data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          },
          (error) => {
            if (error.status == 404) {
              this.toastrService.danger(
                "La maquinaria no tiene poliza",
                "Error"
              );
            } else {
              this.toastrService.danger(
                "Hubo un error al descargar la poliza",
                "Error"
              );
            }
          }
        );
      },
    },
    {
      name: "edit",
      label: "Editar",
      icon: "edit",
      formSize: "medium",
      formConfig: (m: Maquinaria): FormBuilderConfig => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              nombre: {
                type: FormFieldType.Input,
                label: "Nombre*",
                formControlName: "nombre",
                value: m.nombre,
                col: 6,
                validations: ["required"],
              },
              numero: {
                type: FormFieldType.Input,
                label: "No. Economico*",
                formControlName: "numero",
                value: m.numero,
                col: 6,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              serie: {
                type: FormFieldType.Input,
                label: "Serie*",
                formControlName: "serie",
                col: 6,
                value: m.serie,
                validations: ["required"],
              },
              modelo: {
                type: FormFieldType.Input,
                label: "Modelo*",
                formControlName: "modelo",
                col: 6,
                value: m.modelo,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              motor: {
                type: FormFieldType.Input,
                label: "Motor",
                formControlName: "motor",
                col: 4,
                value: m.motor,
                validations: ["required"],
              },
              diferencial: {
                type: FormFieldType.Input,
                label: "Diferencial",
                formControlName: "diferencial",
                col: 4,
                value: m.diferencial,
                validations: ["required"],
              },
              caja_velocidad: {
                type: FormFieldType.Input,
                label: "Caja de velocidad",
                formControlName: "caja_velocidad",
                col: 4,
                value: m.caja_velocidad,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              obra_id: {
                type: FormFieldType.Select,
                label: "Ubicacion",
                formControlName: "obra_id",
                value: m.obra_id,
                placeholder: "Seleccionar ubicación",
                col: 12,
                validations: ["required"],
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: this.obras?.map((obra) => {
                      return { key: obra.id, value: obra.nombre };
                    }),
                  },
                ],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              precio: {
                type: FormFieldType.Number,
                label: "Precio de renta",
                formControlName: "precio",
                col: 6,
                value: m.precio,
                validations: ["required"],
              },
              mantenimiento: {
                type: FormFieldType.Number,
                label: "Mantenimiento (Precio)",
                formControlName: "mantenimiento",
                placeholder: "0",
                col: 6,
                value: m.mantenimiento,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              kilometraje: {
                type: FormFieldType.Number,
                label: "Kilometraje",
                formControlName: "horometro",
                placeholder: "0",
                value: m.horometro,
                col: 6,
                validations: ["required"],
              },
              mantenimiento_horas_restantes: {
                type: FormFieldType.Number,
                label: "Kilometros restantes para el sig. mtto.",
                formControlName: "mantenimiento_horas_restantes",
                placeholder: "0",
                value: m.mantenimiento_horas_restantes,
                col: 6,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              operador_id: {
                type: FormFieldType.Select,
                label: "Operador",
                formControlName: "operador_id",
                col: 6,
                value: m.operador_id,
                placeholder: "Seleccionar operador",
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: this.empleados?.map((empleado) => {
                      return {
                        key: empleado.id,
                        value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}`,
                      };
                    }),
                  },
                ],
              },
              rentable:{
                type: FormFieldType.Radio,
                label: "¿El tractocamión se puede rentar?",
                formControlName: "rentable",
                col: 6,
                value: m.rentable,
                options:[
                  {key:OptionType.RadioOptions,value:[
                    {value:true,label:"Si"},
                    {value:false,label:"No"}
                  ]},
                ]
              }
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              pedimento: {
                type: FormFieldType.File,
                label: "Pedimento",
                formControlName: "pedimento",
                col: 12,
                value: null,
                options: [
                  { key: OptionType.fileSelectTypes, value: "application/pdf" },
                ],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              poliza_file: {
                type: FormFieldType.File,
                label: "Póliza",
                formControlName: "poliza_file",
                placeholder: "Seleccionar Archivo",
                col: 12,
                options: [
                  { key: OptionType.fileSelectTypes, value: "application/pdf" },
                ],
              },
            },
          },
        ];
      },
      action: (form: FormGroup, m: Maquinaria, windowRef) => {
        this.maquinariaService.update(form, m.id).subscribe(
          () => {
            this.toastrService.success(
              "La maquinaria ha sido editada correctamente",
              "Maquinaria Editada"
            );
            let pedimento = form.get("pedimento");
            if (
              pedimento.value !== null
            ) {
              this.maquinariaService
                .updatePedimento(m.id, pedimento.value)
                .subscribe(
                  () => {
                    this.toastrService.success(
                      "El pedimento ha sido actualizado correctamente",
                      "Pedimento Actualizado"
                    );
                  },
                  (error) => {
                    this.toastrService.danger(
                      "Hubo un error al actualizar el pedimento",
                      "Error al actualizar el pedimento"
                    );
                  }
                );
            }
            let poliza = form.get("poliza_file");
            if (
              poliza.value !== null
            ) {
              this.maquinariaService
                .updatePoliza(m.id, form.get("poliza_file").value)
                .subscribe(
                  () => {
                    this.toastrService.success(
                      "La poliza ha sido actualizada correctamente",
                      "Poliza Actualizada"
                    );
                  },
                  (error) => {
                    this.toastrService.danger(
                      "Hubo un error al actualizar la poliza",
                      "Error al actualizar la poliza"
                    );
                  }
                );
            }
            this.maquinariaService.getTractocamiones().subscribe((data) => {
              this.data = data;
              this.fiteredData = data;
            });
          },
          (error) => {
            this.toastrService.danger(
              "Hubo un error al editar la maquinaria",
              "Error al editar la maquinaria"
            );
          }
        );

        windowRef.close();
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      icon: "trash",
      message: `¿Estas seguro que deseas eliminar la maquinaria?`,
      title: "Eliminar Maquinaria",
      action: (m: Maquinaria) => {
        this.maquinariaService.delete(m.id).subscribe(
          () => {
            this.toastrService.success(
              "La maquinaria ha sido eliminada correctamente",
              "Maquinaria Eliminada"
            );
            this.maquinariaService.getTractocamiones().subscribe((data) => {
              this.data = data;
              this.fiteredData = data;
            });
            this.onMaquinariaSearch();
          },
          (error) => {
            this.toastrService.danger(
              "Hubo un error al eliminar la maquinaria",
              "Error al eliminar la maquinaria"
            );
          }
        );
      },
    },
  ];

  constructor(
    private maquinariaService: MaquinariaService,
    private windowsService: NbWindowService,
    private toastrService: NbToastrService,
    private obrasService: ObraService,
    private empleadosService: EmpleadoService
  ) {
    this.maquinariaService
      .getTractocamiones()
      .subscribe((data: Maquinaria[]) => {
        this.data = data;
        this.fiteredData = data;
      });
  }

  ngOnInit(): void {
    forkJoin([
      this.obrasService.getAll(),
      this.empleadosService.getAll(),
    ]).subscribe(([obras, empleados]) => {
      this.obras = obras;
      this.empleados = empleados;
      this.loading = false;
    });
  }

  onMaquinariaCreated(form: FormGroup) {
    this.maquinariaService.create(form).subscribe(
      (data:Maquinaria) => {
        let pedimento = form.get("pedimento");
        if (
          pedimento.value !== null
        ) {
          this.maquinariaService
            .updatePedimento(data.id, pedimento.value)
            .subscribe(
              () => {
                this.toastrService.success(
                  "El pedimento ha sido actualizado correctamente",
                  "Pedimento Actualizado"
                );
              },
              (error) => {
                this.toastrService.danger(
                  "Hubo un error al actualizar el pedimento",
                  "Error al actualizar el pedimento"
                );
              }
            );
        }
        let poliza = form.get("poliza_file");
        if (
          poliza.value !== null
        ) {
          this.maquinariaService
            .updatePoliza(data.id, poliza.value)
            .subscribe(
              () => {
                this.toastrService.success(
                  "La poliza ha sido actualizada correctamente",
                  "Poliza Actualizada"
                );
              },
              (error) => {
                this.toastrService.danger(
                  "Hubo un error al actualizar la poliza",
                  "Error al actualizar la poliza"
                );
              }
            );
        }
        this.toastrService.success(
          "La maquinaria ha sido creada correctamente",
          "Maquinaria Creada"
        );
        this.maquinariaService.getTractocamiones().subscribe((data) => {
          this.data = data;
          this.fiteredData = data;

        });
        this.nuevaMaquinariaWindows.close();
        this.onMaquinariaSearch();
      },
      (error) => {
        this.toastrService.danger(
          error.error.message,
          "Error al crear la maquinaria"
        );
      }
    );
  }
  config: any;
  onMaquinariaSearch() {
    this.fiteredData = this.data.filter((maquinaria) => {
      return (
        maquinaria.numero
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.nombre
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.serie
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.modelo
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.ubicacion
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.lts
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.toneladas
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.encargado
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.precio
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.mantenimiento
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.horometro
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.mantenimiento_horas_restantes
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.motor
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.diferencial
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.caja_velocidad
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.empleado
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.obra?.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }
  onClickOpenNuevaMaquinaria() {
    let formConfig: FormRowConfig[] = [
      {
        type: FormFieldType.Row,
        fields: {
          nombre: {
            type: FormFieldType.Input,
            label: "Nombre",
            formControlName: "nombre",
            col: 6,
            validations: ["required"],
          },
          numero: {
            type: FormFieldType.Input,
            label: "No. Economico",
            formControlName: "numero",
            col: 6,
            validations: [
              "required",
              { key: "min", value: 0 },
              { key: "maxLength", value: 10 },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          serie: {
            type: FormFieldType.Input,
            label: "Serie",
            formControlName: "serie",
            col: 6,

            validations: ["required"],
          },
          modelo: {
            type: FormFieldType.Input,
            label: "Modelo",
            formControlName: "modelo",
            col: 6,
            validations: ["required"],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          motor: {
            type: FormFieldType.Input,
            label: "Motor",
            formControlName: "motor",
            col: 4,
            validations: ["required"],
          },
          diferencial: {
            type: FormFieldType.Input,
            label: "Diferencial",
            formControlName: "diferencial",
            col: 4,
            validations: ["required"],
          },
          caja_velocidad: {
            type: FormFieldType.Input,
            label: "Caja de velocidad",
            placeholder: "0",
            formControlName: "caja_velocidad",
            col: 4,
            validations: ["required"],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          placas: {
            type: FormFieldType.Input,
            label: "Placas",
            formControlName: "placas",
            col: 6,
            validations: ["required"],
          },
          obra_id: {
            type: FormFieldType.Select,
            label: "Ubicacion",
            formControlName: "obra_id",
            placeholder: "Seleccionar ubicación",
            col: 6,
            validations: ["required"],
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.obras?.map((obra) => {
                  return { key: obra.id, value: obra.nombre };
                }),
              },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          precio: {
            type: FormFieldType.Number,
            label: "Precio de renta",
            formControlName: "precio",
            col: 6,
            validations: ["required"],
            value: 0,
          },
          mantenimiento: {
            type: FormFieldType.Number,
            label: "Mantenimiento (Precio)",
            formControlName: "mantenimiento",
            placeholder: "0",
            col: 6,
            validations: ["required"],
            value: 0,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          kilometraje: {
            type: FormFieldType.Number,
            label: "Kilometraje",
            formControlName: "horometro",
            placeholder: "0",
            col: 6,
            validations: ["required"],
            value: 0,
          },
          mantenimiento_horas_restantes: {
            type: FormFieldType.Number,
            label: "Kilometros restantes para el sig. mtto.",
            formControlName: "mantenimiento_horas_restantes",
            placeholder: "0",
            col: 6,
            validations: ["required"],
            value: 0,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          operador_id: {
            type: FormFieldType.Select,
            label: "Operador",
            formControlName: "operador_id",
            col: 6,
            placeholder: "Seleccionar operador",
            validations: ["required"],
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.empleados?.map((empleado) => {
                  return {
                    key: empleado.id,
                    value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}`,
                  };
                }),
              },
            ],
          },
          rentable:{
            type: FormFieldType.Radio,
            label: "¿El tractocamión se puede rentar?",
            formControlName: "rentable",
            col: 6,
            value: false,
            options:[
              {key:OptionType.RadioOptions,value:[
                {value:true,label:"Si"},
                {value:false,label:"No"}
              ]},
            ]
          }
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          pedimento: {
            type: FormFieldType.File,
            label: "Pedimento",
            formControlName: "pedimento",
            col: 12,
            options: [
              { key: OptionType.fileSelectTypes, value: "application/pdf" },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          poliza_file: {
            type: FormFieldType.File,
            label: "Póliza",
            formControlName: "poliza_file",
            placeholder: "Seleccionar Archivo",
            col: 12,
            value: "",
            options: [
              { key: OptionType.fileSelectTypes, value: "application/pdf" },
            ],
          },
        },
      },
    ];
    this.nuevaMaquinariaWindows = this.windowsService.open(
      this.nuevaMaquinariaTemplate,
      { title: "Nuevo tractocamión", context: { formConfig: formConfig } }
    );
  }

  onClickGenerateReport() {
    CSVGenerator.generateExcel(
      this.tableDef,
      this.fiteredData,
      "tractocamiones"
    );
  }
}
