import { IncidenciasService } from './../../../@core/services/incidencias-service';
import { Component, Input, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Carpeta, Documento, Incidencia } from '../../../@core/data/incidencias-data';
import { NbToastrService, NbTreeGridDataSource, NbTreeGridDataSourceBuilder, NbWindowRef, NbWindowService } from '@nebular/theme';
import { CarpetaService } from '../../../@core/services/incidencias-service';
import { FormBuilderConfig, FormFieldType, OptionType } from '../../../form-builder/form-builder.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent {
  @Input() incidencia: Incidencia;
  customColumn = 'name';
  defaultColumns = ['kind'];
  allColumns = [this.customColumn, ...this.defaultColumns,'actions'];
  @ViewChild('newFolderTemplate') newFolderTemplate: TemplateRef<HTMLElement>;
  @ViewChild('renameFolderTemplate') renameFolderTemplate: TemplateRef<HTMLElement>;
  @ViewChild('addDocumentTemplate') addDocumentTemplate: TemplateRef<HTMLElement>;
  @ViewChild('editDocumentTemplate') editDocumentTemplate: TemplateRef<HTMLElement>;
  newFolderRef: NbWindowRef;
  renameFolderRef: NbWindowRef;
  addDocumentRef: NbWindowRef;
  editDocumentRef: NbWindowRef;
  dataSource: NbTreeGridDataSource<FileNode>;
  carpets: Carpeta[] = [];
  newFolderDef: FormBuilderConfig = [
    {
      type: FormFieldType.Row,
      fields: {
        path:{
          formControlName: 'path',
          label: 'Path',
          type: FormFieldType.Input,
          validations:['required']
        }
      }
    }
  ];
  renameFolderDef: FormBuilderConfig;
  addDocumentDef: FormBuilderConfig;
  editDocumentDef: FormBuilderConfig;

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<FileNode>,private carpetaService:CarpetaService,
    private windowService:NbWindowService, private toastrService:NbToastrService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.incidencia) {
      this.carpetaService.getAllBy([{'incidencia':this.incidencia.id}]).subscribe((carpetas) => {
        this.carpets = carpetas;
        const data: TreeNode<FileNode>[] = this.buildFileTree(carpetas, 0);
        this.dataSource = this.dataSourceBuilder.create(data);
      });
    }
  }

  openNewFolder() {
    this.newFolderRef = this.windowService.open(this.newFolderTemplate, { title: 'Nueva Carpeta', context: {formDef:this.newFolderDef} });
  }

  renameFolder(carpeta: Carpeta) {
    this.renameFolderDef = [
      {
        type: FormFieldType.Row,
        fields: {
          path: {
            formControlName: 'path',
            label: 'Nuevo Nombre',
            type: FormFieldType.Input,
            validations: ['required'],
            value: carpeta.path
          }
        }
      }
    ];
    this.renameFolderRef = this.windowService.open(this.renameFolderTemplate, { title: 'Renombrar Carpeta', context: { formDef: this.renameFolderDef, carpeta } });
  }

  addDocument(carpeta: Carpeta) {
    this.addDocumentDef = [
      {
        type: FormFieldType.Row,
        fields: {
          nombre: {
            formControlName: 'nombre',
            label: 'Nombre del Documento',
            type: FormFieldType.Input,
            validations: ['required']
          },
          file: {
            formControlName: 'file',
            label: 'Archivo',
            type: FormFieldType.File,
            validations: ['required'],
            options:[{key:OptionType.fileSelectTypes,value:'image/*,application/pdf'}]
          }
        }
      }
    ];
    this.addDocumentRef = this.windowService.open(this.addDocumentTemplate, { title: 'Agregar Documento', context: { formDef: this.addDocumentDef, carpeta } });
  }

  editDocument(documento: Documento) {
    this.editDocumentDef = [
      {
        type: FormFieldType.Row,
        fields: {
          nombre: {
            formControlName: 'nombre',
            label: 'Nombre del Documento',
            type: FormFieldType.Input,
            validations: ['required'],
            value: documento.nombre
          },
          file: {
            formControlName: 'file',
            label: 'Archivo',
            type: FormFieldType.File,
            validations: ['required'],
            options:[{key:OptionType.fileSelectTypes,value:'image/*,application/pdf'}]
          }
        }
      }
    ];
    this.editDocumentRef = this.windowService.open(this.editDocumentTemplate, { title: 'Editar Documento', context: { formDef: this.editDocumentDef, documento } });
  }

  deleteDocument(documento: Documento) {
    // Implementar la lógica para eliminar el documento
  }

  buildFileTree(carpetas: Carpeta[], level: number): TreeNode<FileNode>[] {
    return carpetas.map(carpeta => {
      const node: TreeNode<FileNode> = {
        data: { name: carpeta.path, kind: 'dir', id: carpeta.id },
        children: [
          ...carpeta.documentos.map(doc => ({
            data: { name: doc.nombre, kind: 'file', path: doc.path, id: doc.id },
          })),
        ]
      };
      return node;
    });
  }

  deleteFolder(carpeta:Carpeta){
    this.carpetaService.delete(carpeta.id).subscribe((res) => {
      this.carpets = this.carpets.filter((c) => c.id !== carpeta.id);
      const data: TreeNode<FileNode>[] = this.buildFileTree(this.carpets, 0);
      this.dataSource = this.dataSourceBuilder.create(data);
      this.toastrService.show('Carpeta eliminada correctamente', 'Carpeta Eliminada', {
        status: 'success',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    },(err) => {
      this.toastrService.show('Error al eliminar la carpeta', 'Error', {
        status: 'danger',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    });
  }

  onSubmitNewFolder(form:FormGroup){
    form.addControl('incidencia_id', new FormControl(this.incidencia.id));
    this.carpetaService.create(form).subscribe((res) => {
      res.documentos = [];
      this.carpets.push(res);
      const data: TreeNode<FileNode>[] = this.buildFileTree(this.carpets, 0);
      this.dataSource = this.dataSourceBuilder.create(data);
      this.toastrService.show('Carpeta creada correctamente', 'Carpeta Creada', {
        status: 'success',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
      this.newFolderRef.close();
    },(err) => {
      this.toastrService.show('Error al crear la carpeta', 'Error', {
        status: 'danger',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    }
    );
  }

  onSubmitRenameFolder(form: FormGroup, carpeta: Carpeta) {
    carpeta.path = form.value.path;
    this.carpetaService.update(form,carpeta.id).subscribe((res) => {
      const index = this.carpets.findIndex(c => c.id === carpeta.id);
      this.carpets[index] = carpeta;
      const data: TreeNode<FileNode>[] = this.buildFileTree(this.carpets, 0);
      this.dataSource = this.dataSourceBuilder.create(data);
      this.toastrService.show('Carpeta renombrada correctamente', 'Carpeta Renombrada', {
        status: 'success',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
      this.renameFolderRef.close();
    }, (err) => {
      this.toastrService.show('Error al renombrar la carpeta', 'Error', {
        status: 'danger',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    });
  }

  onSubmitAddDocument(form: FormGroup, carpeta: Carpeta) {
    const file: File = form.get('file').value;
    const nombre: string = form.get('nombre').value;
    this.carpetaService.addDocument(carpeta.id, file,nombre).subscribe((res) => {
      const documento: Documento = { id: res.id, nombre, path: res.path, carpeta_id: carpeta.id };
      const index = this.carpets.findIndex(c => c.id === carpeta.id);
      this.carpets[index].documentos.push(documento);
      const data: TreeNode<FileNode>[] = this.buildFileTree(this.carpets, 0);
      this.dataSource = this.dataSourceBuilder.create(data);
      this.toastrService.show('Documento agregado correctamente', 'Documento Agregado', {
        status: 'success',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
      this.addDocumentRef.close();
    }, (err) => {
      this.toastrService.show('Error al agregar el documento', 'Error', {
        status: 'danger',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    });
  }

  onSubmitEditDocument(form: FormGroup, documento: Documento) {
    const file: File = form.get('file').value;
  
    this.carpetaService.updateDocument(form, file,documento.id).subscribe((res) => {
      const carpeta = this.carpets.find(c => c.documentos.some(d => d.id === documento.id));
      const docIndex = carpeta.documentos.findIndex(d => d.id === documento.id);
      carpeta.documentos[docIndex] = documento;
      const data: TreeNode<FileNode>[] = this.buildFileTree(this.carpets, 0);
      this.dataSource = this.dataSourceBuilder.create(data);
      this.toastrService.show('Documento editado correctamente', 'Documento Editado', {
        status: 'success',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
      this.editDocumentRef.close();
    }, (err) => {
      this.toastrService.show('Error al editar el documento', 'Error', {
        status: 'danger',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    });
  }
  downloadDocument(documento:FileNode){
    this.carpetaService.downloadDoc(documento.id).subscribe((res) => {
      const url = window.URL.createObjectURL(res);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = documento.name;
      a.click();
      window.URL.revokeObjectURL(url);
      
    });
  }
}

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}
interface FileNode {
  id: number;
  name: string;
  kind: string;
  [key: string]: any;
}
