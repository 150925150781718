import { FormBuilderConfig, FormFieldType, OptionType } from './../../form-builder/form-builder.component';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Entrevista } from '../../@core/data/rh-data';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { FormGroup } from '@angular/forms';
import { EntrevistasService } from '../../@core/services/entrevistas.service';
import { MatTableActionConfig, MatTableColumnConfig } from '../../mat-table-builder/mat-table-builder.component';
import * as moment from 'moment-timezone';
@Component({
  selector: 'ngx-entrevistas-mat-table',
  templateUrl: './entrevistas-mat-table.component.html',
  styleUrls: ['./entrevistas-mat-table.component.scss']
})
export class EntrevistasMatTableComponent {
  columns: MatTableColumnConfig[] = [
    {name:'fecha_entrevista',label:'Fecha Entrevista',type:'date'},
    {name:'id',label:'ID',type:'number'},
    {name:'nombre',label:'Nombre',type:'text'},
    {name:'apellido_paterno',label:'Apellido Paterno',type:'text'},
    {name:'apellido_materno',label:'Apellido Materno',type:'text'},
    {name:'telefono',label:'Telefono',type:'text'},
    {name:'puesto',label:'Puesto',type:'text'},
    {name:'contratable',label:'Contratable',type:'boolean'},
    {name:'comentarios',label:'Comentarios',type:'text'},
    {name:'created_at',label:'Fecha de Creacion',type:'date',
    },
    {name:'fecha_nacimiento',label:'Fecha de Nacimiento',type:'date',
    },
    {name:'direccion',label:'Direccion',type:'text'},
    {name:'sueldo_solicitado',label:'Sueldo Solicitado',type:'number'},
    {name:'grado_estudios',label:'Grado de Estudios',type:'text'},
    {name:'referencia_interna',label:'Referencia Interna',type:'text'},
    {name:'contacto_referencia',label:'Contacto de Referencia',type:'text'},
    {name:'apariencia',label:'Apariencia',type:'text'},
    {name:'facilidad_palabra',label:'Facilidad de Palabra',type:'text'},
    {name:'tipo_licencia',label:'Tipo de Licencia',type:'text'},
    {name:'fecha_inicio_lic',label:'Fecha de Inicio de Licencia',type:'date',
    },
    {name:'fecha_fin_lic',label:'Fecha de Fin de Licencia',type:'date',
    },
  ];
  @Input() dataSource: Entrevista[] = [];
  acciones: MatTableActionConfig[] = [
    {
      name: 'ver_documento',
      label: 'Ver Documento',
      icon: 'file-text',
      action: (data: Entrevista) => {
        if (data.estudios_path!==null) {
          this.entrevistasService.downloadDocumento(data.id).subscribe(data=>{
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
            window.URL.revokeObjectURL(url);

          },error=>{
            this.toastrService.danger("Ha ocurrido un error al descargar el archivo","Error");
          })
        } else {
          this.toastrService.warning('No se ha cargado ningún documento de estudios para esta entrevista.', 'Información');
        }
      }
    },
    {
      name: 'edit',
      label: 'Editar',
      icon: 'edit',
      formSize: 'large',
      formConfig:(data:Entrevista)=>{
        return [
          {
            type: FormFieldType.Row,
            fields: {
              nombre: {
                type: FormFieldType.Input,
                col: 4,
                label: 'Nombre',
                placeholder: 'Ejemplo: Juan',
                formControlName: 'nombre',
                value: data.nombre
              },
              apellido_paterno: {
                type: FormFieldType.Input,
                col: 4,
                label: 'Apellido Paterno',
                placeholder: 'Ejemplo: Perez',
                formControlName: 'apellido_paterno',
                value: data.apellido_paterno
              },apellido_materno: {
                type: FormFieldType.Input,
                col: 4,
                label: 'Apellido Materno',
                placeholder: 'Ejemplo: Lopez',
                formControlName: 'apellido_materno',
                value: data.apellido_materno
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              fecha_nacimiento: {
                type: FormFieldType.Date,
                col: 6,
                label: 'Fecha de Nacimiento',
                formControlName: 'fecha_nacimiento',
                value: data.fecha_nacimiento
              },
              telefono: {
                type: FormFieldType.Input,
                col: 6,
                label: 'Telefono',
                placeholder: 'Telefono del candidato',
                formControlName: 'telefono',
                value: data.telefono
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              puesto: {
                type: FormFieldType.Input,
                col: 6,
                label: 'Puesto',
                placeholder: 'Ejemplo: Gerente',
                formControlName: 'puesto',
                value: data.puesto
              },
              sueldo_solicitado: {
                type: FormFieldType.Number,
                col: 6,
                label: 'Sueldo Solicitado',
                placeholder: 'Sueldo que solicita el candidato',
                formControlName: 'sueldo_solicitado',
                value: data.sueldo_solicitado
              }
            }
          },
          {
            type: FormFieldType.Row,
            fields:{
              grado_estudios: {
                type: FormFieldType.Select,
                col: 6,
                label: 'Grado de Estudios',
                placeholder: 'Ejemplo: Licenciatura',
                formControlName: 'grado_estudios',
                value: data.grado_estudios,
                options: [
                  {key:OptionType.SelectOptions,value:[
                    {value:'Prepa',key:'PREPA'},
                    {value:'Tecnica',key:'TECNICA'},
                    {value:'Licenciatura',key:'LICENCIATURA'},
                    {value:'Maestria',key:'MAESTRIA'},
                  ]}
                ]
              },
              estudios_file: {
                type: FormFieldType.File,
                col: 6,
                label: 'Documento',
                formControlName: 'estudios_file',
                options: [
                  {key:OptionType.fileSelectTypes,value:'application/pdf' }
                ]
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              referencia_interna: {
                type: FormFieldType.Input,
                col: 6,
                label: 'Referencia Interna',
                placeholder: 'Referencia interna del candidato',
                formControlName: 'referencia_interna',
                value: data.referencia_interna
              },
              contacto_referencia: {
                type: FormFieldType.Input,
                col: 6,
                label: 'Contacto de Referencia',
                placeholder: 'Contacto',
                formControlName: 'contacto_referencia',
                value: data.contacto_referencia
              }
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              fecha_entrevista: {
                type: FormFieldType.DateTime,
                col: 12,
                label: 'Fecha de Entrevista',
                formControlName: 'fecha_entrevista',
                value: data.fecha_entrevista
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              apariencia:{
                type: FormFieldType.Radio,
                col: 4,
                label: 'Apariencia',
                formControlName: 'apariencia',
                value: data.apariencia,
                options: [
                  {key:OptionType.RadioOptions,value:[
                    {label:'Excelente',value:'EXCELENTE'},
                    {label:'Buena',value:'BUENA'},
                    {label:'Regular',value:'REGULAR'},
                    {label:'Mala',value:'MALA'},
                  ]}
                ]
              },
              facilidad_palabra:{
                type: FormFieldType.Radio,
                validations: [ 'required' ],
                col: 4,
                label: 'Facilidad de Palabra',
                formControlName: 'facilidad_palabra',
                value: data.facilidad_palabra,
                options: [
                  {key:OptionType.RadioOptions,value:[
                    {label:'Excelente',value:'EXCELENTE'},
                    {label:'Buena',value:'BUENA'},
                    {label:'Regular',value:'REGULAR'},
                    {label:'Mala',value:'MALA'},
                  ]}
                ]
              },
              contratable: {
                type: FormFieldType.Radio,
                col: 4,
                label: 'Contratable',
                formControlName: 'contratable',
                value: data.contratable,
                options: [
                  {key:OptionType.RadioOptions,value:[
                    {label:'Si',value:true},
                    {label:'No',value:false}
                  ]}
                ]
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields:{
              fecha_inicio_lic:{
                type: FormFieldType.Date,
                col: 4,
                label: 'Inicio de licencia',
                placeholder: 'Vigencia',
                formControlName: 'fecha_inicio_lic',
                value: data.fecha_inicio_lic
              },
              fecha_fin_lic:{
                type: FormFieldType.Date,
                col: 4,
                label: 'Fin de licencia',
                placeholder: 'Vigencia',
                formControlName: 'fecha_fin_lic',
                value: data.fecha_fin_lic
              },
              tipo_licencia: {
                type: FormFieldType.Select,
                col: 4,
                label: 'Tipo de Licencia',
                placeholder: 'Tipo de Licencia',
                formControlName: 'tipo_licencia',
                value: data.tipo_licencia,
                options: [
                  {key:OptionType.SelectOptions,value:[
                    {key:'A',value:'A'},
                    {key:'B',value:'B'},
                    {key:'C',value:'C'},
                    {key:'D',value:'D'},
                    {key:'E',value:'E'},
                    {key:'AUTOMOVILISTA',value:'Automovilista'},
                    {key:'CHOFER',value:'Chofer'},
                    {key:null,value:'Ninguna'}
                  ]}
                ]
              }
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              direccion: {
                type: FormFieldType.TextArea,
                col: 12,
                label: 'Direccion',
                placeholder: 'Direccion del entrevistado',
                formControlName: 'direccion',
                value: data.direccion
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              comentarios: {
                type: FormFieldType.TextArea,
                col: 12,
                label: 'Comentarios',
                placeholder: 'Comentarios de la entrevista',
                formControlName: 'comentarios',
                value: data.comentarios
              },
            }
          }
        ]
      },
      action: (form:FormGroup,data:Entrevista,ref:NbWindowRef) => {
        this.entrevistasService.update(form,data.id).subscribe(()=>{
          this.toastrService.success("Entrevista actualizada correctamente","Correcto");
          if(form.get('estudios_file').value){
            this.entrevistasService.updateDocumento(data.id,form.get('estudios_file').value).subscribe(()=>{
              this.toastrService.success("Documento actualizado correctamente","Correcto");
            },error=>{
              this.toastrService.danger(error.error.message,"Error");
            })
          }
          ref.close();
          this.entrevistasService.getAll().subscribe(data=>{
            this.dataSource= data
          })
        },error=>{
          this.toastrService.danger(error.error.message,"Error");
        })
      }
    },
    {
      name: 'delete',
      label: 'Eliminar',
      icon: 'trash',
      message: '¿Estás seguro de que deseas eliminar esta entrevista?',
      action: (data: Entrevista) => {
        this.entrevistasService.delete(data.id).subscribe(()=>{
          this.dataSource = this.dataSource.filter(e => e.id !== data.id);
          this.toastrService.success("Entrevista eliminada correctamente","Correcto");
        },error=>{
          this.toastrService.danger(error.error.message,"Error");
        })
      }

    }
  ];

  constructor(private windowService:NbWindowService, private entrevistasService:EntrevistasService,
    private toastrService:NbToastrService,
  ) {
    moment.locale('es');
    moment.tz.setDefault('Etc/GMT+5');
   }

}


