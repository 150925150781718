import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpleadoSegurosComponent } from './empleado-seguros.component';
import { EmpleadoSeguroService } from '../../@core/services/empleado-seguro.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NbCardModule, NbInputModule, NbButtonModule, NbIconModule, NbTooltipModule, NbSpinnerModule } from '@nebular/theme';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';



@NgModule({
  declarations: [
    EmpleadoSegurosComponent
  ],
  imports: [
    CommonModule,
    MatTableBuilderModule,
    FormBuilderModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    NbSpinnerModule
  ],
  providers:[
    EmpleadoSeguroService
  ]
})
export class EmpleadoSegurosModule { }
