import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmpleadoService } from '../../../../@core/services/empleado.service';
import { Empleado } from '../../../../@core/data/cotizacion-data';

@Component({
  selector: 'ngx-nuevo-empleado-form',
  templateUrl: './nuevo-empleado-form.component.html',
  styleUrls: ['./nuevo-empleado-form.component.scss']
})
export class NuevoEmpleadoFormComponent implements OnInit {
  @Input() empleado: Empleado;
  form:FormGroup;
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private empleadoSerivce:EmpleadoService) { 
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      apellido_paterno: new FormControl('', [Validators.required]),
      apellido_materno: new FormControl('', [Validators.required]),
      cargo: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      titulo: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [Validators.required]),
      firma: new FormControl(false),
    });
    if(this.empleado!==undefined&&this.empleado!==null) {
      this.form.patchValue({
        nombre: this.empleado.nombre,
        apellido_paterno: this.empleado.apellido_paterno,
        apellido_materno: this.empleado.apellido_materno,
        cargo: this.empleado.cargo,
        email: this.empleado.email,
        titulo: this.empleado.titulo,
        telefono: this.empleado.telefono,
        firma: this.empleado.firma
      });
    }
  }

  onSubmit() {
    if(this.empleado!==undefined&&this.empleado!==null) {
      this.empleadoSerivce.update(this.form, this.empleado.id)
      .subscribe(() => {
        this.saved.emit(true);
      },
      error => {
        console.error(error);
      });
    }else{
      this.empleadoSerivce.create(this.form)
      .subscribe(() => {
        this.saved.emit(true);
      },
      error => {
        console.error(error);
      });
    }
  }

}
