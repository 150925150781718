import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorteFiscalComponent } from './corte-fiscal.component';
import { NbCardModule, NbIconModule, NbButtonModule, NbInputModule, NbSelectModule, NbDatepickerModule, NbAutocompleteModule } from '@nebular/theme';
import { FilterValueDateModule } from '../../forms/filter-value-date/filter-value-date.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    CorteFiscalComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    NbSelectModule,
    NbDatepickerModule,
    FilterValueDateModule,
    MatTableBuilderModule,
    NbDatepickerModule,
    FormsModule,
    NbAutocompleteModule,
    ReactiveFormsModule
  ]
})
export class CorteFiscalModule { }
