import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Banco, Gasto, Proveedor, TipoGasto } from "../../@core/data/gastos-data";
import { GastoService } from "../../@core/services/gastos.service";
import { NbCalendarRange, NbToastrService, NbWindowRef, NbWindowService } from "@nebular/theme";
import {
  FormBuilderConfig,
  FormFieldType,
  OptionType,
} from "../../form-builder/form-builder.component";
import { FormControl, FormGroup } from "@angular/forms";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import { ObraService } from "../../@core/services/obra.service";
import { ProveedorService } from "../../@core/services/proveedor.service";
import { Obra } from "../../@core/data/maquinaria-data";
import { filterItems } from "../../@core/utils/filter-utils";
import { CSVGenerator } from "../../@core/utils/csv-generator";
import { EmpresaFiniquito } from "../../@core/data/empresa-finiquito-data";
import { EmpresaFiniquitoService } from "../../@core/services/empresa-finiquito.service";
import { BancoService } from "../../@core/services/banco.service";
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ngx-gastos-fiscales',
  templateUrl: './gastos-fiscales.component.html',
  styleUrls: ['./gastos-fiscales.component.scss']
})
export class GastosFiscalesComponent implements OnInit {
  filterTxt: string = "";
  filterDateTxt: any = "";
  eventDateFilter: any = null;
  public timeRange: NbCalendarRange<Date> | undefined;
  loading: boolean = true;
  gastos: Gasto[] = [];
  gastosFiltrados: Gasto[] = [];
  filterFields: string[] = ["proveedor", "concepto", "importe", "banco", "empresa_finiquito", "comentario", "obra"];
  @ViewChild("nuevoGastoWindow", { read: TemplateRef })
  nuevoGastoWindow: TemplateRef<HTMLElement>;
  nuevoGastoWindowRef: NbWindowRef;
  columns: MatTableColumnConfig[] = [
    {
      name: "fecha",
      label: "Fecha de gasto",
      type: "date",
      value: (gasto: Gasto) => gasto.fecha_compra,
    },
     {
      name: "proveedor",
      label: "Proveedor",
      value: (gasto: Gasto) => gasto.proveedor,
      type: "text",
    },
    {
      name: "concepto",
      label: "Concepto",
      value: (gasto: Gasto) => gasto.concepto,
      type: "text",
    },
    {
      name: "obra",
      label: "Obra",
      value: (gasto: Gasto) => gasto.obra,
      type: "text",
    },
    {
      name: "importe",
      label: "Importe",
      type: "number",
      value: (gasto: Gasto) => gasto.importe,
    },
    {
      name: "banco",
      label: "Banco",
      value: (gasto: Gasto) => gasto.banco,
      type: "text",
    },
    {
      name: "empresaFiniquito",
      label: "Empresa",
      value: (gasto: Gasto) => gasto.empresa_finiquito,
      type: "text",
    },
    {
      name: "comentario",
      label: "Comentario",
      value: (gasto: Gasto) => gasto.comentario,
      type: "text",
    },

  ];

  acciones = [
    {
      name: "edit",
      label: "Editar",
      icon: "edit",
      action: (form: FormGroup,row:Gasto,ref) => {
        this.gastosService.update(form,row.id, ).subscribe(() => {
          ref.close();
          this.toastrService.show("Gasto editado", "Editar Gasto", {
            status: "success",
          });
          this.gastosService
            .getAllByAttributes([{ tipo: "FISCAL" }])
            .subscribe((gastos) => {
              this.gastos = gastos;
              this.gastosFiltrados = gastos;
            });
      },error=>{
        this.toastrService.show(error.error.message, "Editar Gasto", {
          status: "danger",
        });
      });


      },
      formConfig: (row: Gasto) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              fecha_compra: {
                type: FormFieldType.Date,
                validations: ["required"],
                col: 6,
                label: "Fecha de Compra",
                formControlName: "fecha_compra",
                value: row.fecha_compra,
              },
              proveedor_id: {
                type: FormFieldType.Select,
                validations: ["required"],
                col: 6,
                label: "Proveedor",
                options: [{key: OptionType.SelectOptions, value: this.proveedores.map((proveedor) => ({
                  key: proveedor.id,
                  value: proveedor.nombre,
                }))}
                ],
                formControlName: "proveedor_id",
                value: row.proveedor_id,
              },
              importe: {
                type: FormFieldType.Number,
                validations: ["required", "number"],
                col: 6,
                label: "Importe",
                placeholder: "Importe del gasto",
                formControlName: "importe",
                value: row.importe,
              },
              obra_id: {
                type: FormFieldType.Select,
                validations: ["required"],
                col: 6,
                label: "Obra",
                options: [{key: OptionType.SelectOptions, value: this.obras.map((obra) => ({
                  key: obra.id,
                  value: obra.nombre,
                }))}
                ],
                formControlName: "obra_id",
                value: row.obra_id,
              },
              concepto: {
                type: FormFieldType.Input,
                validations: ["required"],
                col: 6,
                label: "Concepto",
                placeholder: "Concepto del gasto",
                formControlName: "concepto",
                value: row.concepto,
              },
              comentario: {
                type: FormFieldType.Input,
                validations: ["required"],
                col: 12,
                label: "Comentario",
                placeholder: "Comentario del gasto",
                formControlName: "comentario",
                value: row.comentario,
              },
            },
          },
        ] as FormBuilderConfig;
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      icon: "trash",
      action: (gasto: Gasto) => this.onDeleteGasto(gasto),
      title: "Eliminar Gasto",
      message: "¿Estás seguro de que deseas eliminar este gasto?",
    },
  ] as MatTableActionConfig[];
  proveedores: Proveedor[];
  obras: Obra[];
  empresasFiniquito: EmpresaFiniquito[];
  bancos: Banco[];

  constructor(
    private gastosService: GastoService,
    private windowsService: NbWindowService,
    private toastrService: NbToastrService,
    private proveedoresService: ProveedorService,
    private obrasService: ObraService,
    private bancosService: BancoService,
    private empresasFiniquitoService: EmpresaFiniquitoService
  ) {

  }

  ngOnInit(): void {
    this.gastosService.getAllByAttributes([{tipo: TipoGasto.FISCAL}]).subscribe((gastos) => {
      this.gastos = gastos;
      this.gastosFiltrados = gastos;
    });
    forkJoin({
      proveedores: this.proveedoresService.getAll(),
      obras: this.obrasService.getAll(),
      bancos: this.bancosService.getAll(),
      empresasFiniquito: this.empresasFiniquitoService.getAll(),
    }).subscribe(({ proveedores, obras, bancos, empresasFiniquito }) => {
      this.proveedores = proveedores;
      this.obras = obras;
      this.bancos = bancos;
      this.empresasFiniquito = empresasFiniquito;
      this.loading = false;
    });
  }
  onFilterChange(filteredItems: any) {
    this.gastosFiltrados = filteredItems;
  }
  downloadCsv() {
    CSVGenerator.generateExcel(this.columns, this.gastosFiltrados, "gastos-fiscales");
  }
  getTotalImportes(): any {
    return this.gastosFiltrados.reduce((total, gasto) => total + parseFloat(gasto.importe.toString()), 0);
  }
  onNuevoClick() {
    const formConfig = [
      {
        type: FormFieldType.Row,
        fields: {
          fecha_compra: {
            type: FormFieldType.Date,
            validations: ["required"],
            col: 6,
            label: "Fecha de Compra",
            formControlName: "fecha_compra",
            value: new Date(),
          },
          proveedor_id: {
            type: FormFieldType.Select,
            validations: ["required"],
            col: 6,
            label: "Proveedor",
            placeholder: "Seleccione un proveedor",
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.proveedores.map((proveedor) => ({
                  key: proveedor.id,
                  value: proveedor.nombre,
                })),
              },
            ],
            formControlName: "proveedor_id",
            value: null,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          importe: {
            type: FormFieldType.Input,
            validations: ["required", "number"],
            col: 6,
            label: "Importe",
            placeholder: "Importe del gasto",
            formControlName: "importe",
            value: 0,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          obra_id: {
            type: FormFieldType.Select,
            validations: ["required"],
            col: 6,
            label: "Obra",
            placeholder: "Seleccione una obra",
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.obras.map((obra) => ({
                  key: obra.id,
                  value: obra.nombre,
                })),
              },
            ],
            formControlName: "obra_id",
            value: 0,
          },
          concepto: {
            type: FormFieldType.Input,
            validations: ["required"],
            col: 6,
            label: "Concepto",
            placeholder: "Concepto del gasto",
            formControlName: "concepto",
            value: "",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          comentario: {
            type: FormFieldType.Input,
            validations: ["required"],
            col: 12,
            label: "Comentario",
            placeholder: "Comentario del gasto",
            formControlName: "comentario",
            value: "",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          empresas_finiquito_id: {
            type: FormFieldType.Select,
            validations: ["required"],
            col: 12,
            label: "Empresa",
            placeholder: "Seleccione una empresa",
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.empresasFiniquito.map((empresa) => ({
                  key: empresa.id,
                  value: empresa.nombre,
                })),
              },
            ],
            formControlName: "empresas_finiquito_id",
            value: 0,
          },
          banco_id: {
            type: FormFieldType.Select,
            validations: ["required"],
            col: 12,
            label: "Banco",
            placeholder: "Seleccione un banco",
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.bancos.map((banco) => ({
                  key: banco.id,
                  value: banco.nombre,
                })),
              },
            ],
            formControlName: "banco_id",
            value: 0,
          },

        },
      },
    ] as FormBuilderConfig;
    this.nuevoGastoWindowRef = this.windowsService.open(this.nuevoGastoWindow, {
      title: "Nuevo Gasto",
      context: { formConfig: formConfig },
    });
  }

  onGastoCreated(form: FormGroup) {
    form.value.fecha_compra = new Date(form.value.fecha_compra).toISOString();
    form.addControl('tipo', new FormControl(TipoGasto.FISCAL));
    this.gastosService.create(form,true).subscribe(() => {
      this.nuevoGastoWindowRef.close();
      this.toastrService.show("Gasto creado", "Nuevo Gasto", {
        status: "success",
      });
      this.gastosService.getAllByAttributes([{tipo: TipoGasto.FISCAL}]).subscribe((gastos) => {
        this.gastos = gastos;
        this.gastosFiltrados = gastos;
      });
    },res=>{
      this.toastrService.show(res.error.message, "Nuevo Gasto", {
        status: "danger",
      });
    });
  }

  onEditGasto(gasto: Gasto) {
    // Lógica para editar el gasto
    this.nuevoGastoWindowRef = this.windowsService.open(this.nuevoGastoWindow, {
      title: "Editar Gasto",
    });
    // Cargar los datos del gasto en el formulario
  }

  onDeleteGasto(gasto: Gasto) {
    // Lógica para eliminar el gasto
    this.gastosService.delete(gasto.id).subscribe(() => {
      this.toastrService.show("Gasto eliminado", "Eliminar Gasto", {
        status: "success",
      });
      this.gastosService.getAllByAttributes([{tipo: TipoGasto.FISCAL}]).subscribe((gastos) => {
        this.gastos = gastos;
        this.gastosFiltrados = gastos;
      });
    });
  }
}
