import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpleadosComponent } from './empleados.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbTooltipModule } from '@nebular/theme';
import { EmpleadosTablasModule } from './empleados-tablas/empleados-tablas.module';
import { EmpleadosFormsModule } from './empleados-forms/empleados-forms.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { FormBuilderModule } from '../../form-builder/form-builder.module';



@NgModule({
  declarations: [
    EmpleadosComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbInputModule,
    EmpleadosTablasModule,
    NbIconModule,
    NbTooltipModule,
    NbButtonModule,
    EmpleadosFormsModule,
    MatTableBuilderModule,
    FormBuilderModule
  ]
})
export class EmpleadosModule { }
