import { NgModule } from "@angular/core";
import { UserService } from "./services/users.service";
import { LayoutService } from "./utils";
import { NbAccessChecker, NbRoleProvider } from "@nebular/security";
import { MomentPipe } from './pipes/moment.pipe';

@NgModule({
    imports: [],
    declarations: [
  
    MomentPipe
  ],
    providers: [
        UserService,
        LayoutService,
        NbAccessChecker,
    ]
})

export class CoreModule {
}
