import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Version } from '../data/version-data';

@Injectable()
export class VersionService extends BaseService<Version>{

  protected getPath(): string {
    return 'versiones';
  }
}
