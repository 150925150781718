import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbToastrService, NbWindowService } from '@nebular/theme';
import { Permission, Role } from '../../../@core/data/usuarios-data';
import { MatTableActionConfig, MatTableColumnConfig } from '../../../mat-table-builder/mat-table-builder.component';
import { FormFieldType, FormRowConfig, OptionType } from '../../../form-builder/form-builder.component';
import { PermisionsService, RoleService } from '../../../@core/services/users.service';


@Component({
  selector: 'ngx-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent {
  @ViewChild("newForm") modal: TemplateRef<any>;
  newRef: any;
  roles: Role[] = [];
  permissions: Permission[] = [];
  columns: MatTableColumnConfig[] = [
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: 'permissions',
      label: 'Permisos',
      type: 'tag',
      value: (row: Role) => row.permissions.map((permission: { name: string }) => permission.name.replace('_', ' '))
    }
  ];
  data: Role[] = [];
  filteredData: Role[] = [];
  actions: MatTableActionConfig[] = [
    {
      name: 'edit',
      label: 'Editar',
      icon: 'edit',
      color: 'accent',
      formSize: 'small',
      title: 'Editar rol',
      action: (form: FormGroup, row: Role, ref) => {
        let newform = new FormGroup({});
        newform.addControl('name', new FormControl(form.get('name').value));
        const selectedPermissions = Object.keys(form.controls)
          .filter(key => key.endsWith('_role') && form.get(key)?.value)
          .map(key => key.replace('_role', ''));

        newform.addControl('permissions', new FormControl(selectedPermissions));
        this.roleService.update(newform, row.id).subscribe(() => {
          ref.close();
          this.roleService.getAll().subscribe((roles: Role[]) => {
            this.data = roles;
            this.filteredData = roles;
          });
        });
      },
      formConfig: (row: Role) => {
        let permission= [
          {
            type: FormFieldType.Row,
            fields: {
              name: {
                type: FormFieldType.Input,
                label: 'Nombre',
                formControlName: 'name',
                value: row.name,
                validations: ['required'],
                col: 12,
              },
            }
          }
        ] as FormRowConfig[];
        const permisionDef = {
          type: FormFieldType.Row,
          fields: ()=>{
            let fields = {};
            this.permissions.forEach((perm: Permission) => {
              fields[perm.name+'_role'] = {
                type: FormFieldType.Checkbox,
                label: perm.name.replace('_', ' ').replace('_',''),
                formControlName: perm.name+'_role',
                value: row.permissions.some((p: Permission) => p.id === perm.id),
                col: 3,
                options: [
                  {key: OptionType.labelSi, value: ''},
                  {key: OptionType.labelNo, value: ''},
                ],
              };
            });
            return fields;
          }
        }
        permission.push(permisionDef);
        return permission;

      }
    },
    {
      name: 'delete',
      label: 'Eliminar',
      icon: 'delete',
      color: 'warn',
      title: 'Eliminar rol',
      message: (row: Role) => `¿Estás seguro de eliminar el rol ${row.name}?`,
      action: (row: Role) => {
        this.roleService.delete(row.id).subscribe(() => {
          this.toastrService.success('Rol eliminado', 'Éxito');
          this.roleService.getAll().subscribe((roles: Role[]) => {
            this.data = roles;
            this.filteredData = roles;
          });
        });
      }
    }
  ];
  editFormRef: any;
  constructor(private roleService: RoleService, private toastrService: NbToastrService, private permissionService: PermisionsService,
    private windowService: NbWindowService
  ) {
    this.roleService.getAll().subscribe((roles: Role[]) => {
      this.data = roles;
      this.filteredData = roles;
    });
    this.permissionService.getAll().subscribe((permissions: Permission[]) => {
      this.permissions = permissions;
    });
  }

  onNewDataClick() {
    let permission= [
      {
        type: FormFieldType.Row,
        fields: {
          name: {
            type: FormFieldType.Input,
            label: 'Nombre',
            formControlName: 'name',
            value: '',
            validations: ['required'],
            col: 12,
          },
        }
      }
    ] as FormRowConfig[];
    const permisionDef = {
      type: FormFieldType.Row,
      fields: ()=>{
        let fields = {};
        this.permissions.forEach((perm: Permission) => {
          fields[perm.name+'_role'] = {
            type: FormFieldType.Checkbox,
            label: perm.name.replace('_', ' ').replace('_',''),
            formControlName: perm.name+'_role',
            value: null,
            col: 3,
            options: [
              {key: OptionType.labelSi, value: ''},
              {key: OptionType.labelNo, value: ''},
            ],
          };
        });
        return fields;
      }
    }
    permission.push(permisionDef);

    this.editFormRef = this.windowService.open(this.modal,
      { title: 'Nuevo rol', context: {  config:permission} });

  }
    filter(arg0: any) {
    throw new Error('Method not implemented.');
    }

    onCreated(form: FormGroup) {
      let newform = new FormGroup({});
      newform.addControl('name', new FormControl(form.get('name').value));
      const selectedPermissions = Object.keys(form.controls)
        .filter(key => key.endsWith('_role') && form.get(key)?.value)
        .map(key => key.replace('_role', ''));

      newform.addControl('permissions', new FormControl(selectedPermissions));
      this.roleService.create(newform).subscribe(() => {
        this.editFormRef.close();
        this.roleService.getAll().subscribe((roles: Role[]) => {
          this.data = roles;
          this.filteredData = roles;
        });
      });
    }
}
