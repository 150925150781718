import { EmpresaFiniquitoService } from './../../@core/services/empresa-finiquito.service';
import { EmpresaCuenta, EmpresaFiniquito } from './../../@core/data/empresa-finiquito-data';
import { Component, TemplateRef, ViewChild } from "@angular/core";
import { MatTableColumnConfig } from "../../mat-table-builder/mat-table-builder.component";
import {
  Corte,
  EntradaNoFiscal,
  Gasto,
  TipoEntrada,
  TipoGasto,
} from "../../@core/data/gastos-data";
import { GastoService } from "../../@core/services/gastos.service";
import { EntradasService } from "../../@core/services/entradas.service";
import { forkJoin, Observable, of } from "rxjs";
import { NbToastrService, NbWindowService } from "@nebular/theme";
import { CortesService } from "../../@core/services/cortes.service";
import { FormControl, FormGroup } from "@angular/forms";
import { map, startWith } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { EmpresaCuentaService } from '../../@core/services/empresa-finiquito.service';
import { CSVGenerator } from '../../@core/utils/csv-generator';

@Component({
  selector: "ngx-corte-fiscal",
  templateUrl: "./corte-fiscal.component.html",
  styleUrls: ["./corte-fiscal.component.scss"],
})
export class CorteFiscalComponent {
  corteLoading: boolean = false;
  loadingData: boolean = false;
  fecha: Date = new Date();
  fechaSeleccionada: any = null;
  fechaSeleccionadaFull: any = null;
  corte: Corte=null;
  ultimoCorte: Corte=null;

  filtereSalidasdData: Gasto[] = [];
  salidasData: Gasto[] = [];
  salidasAnteriorData: Gasto[] = [];
  entradasData: EntradaNoFiscal[] = [];
  entradasAnteriorData: EntradaNoFiscal[] = [];
  filteredEntradasData: EntradaNoFiscal[] = [];
  empresas: EmpresaFiniquito[] = [];
  empresaSeleccionada: EmpresaFiniquito = null;
  @ViewChild("corteConfirm") corteConfirm: TemplateRef<HTMLElement>;
  confirmRef: any;


  value: FormControl;
  salidasColums: MatTableColumnConfig[] = [
    {
      name: "fecha_compra",
      label: "Fecha",
      type: "date",
      value: (row: Gasto) => row.fecha_compra,
    },
    {
      name: "proveedor",
      label: "Proveedor",
      value: (row: Gasto) => row.proveedor,
    },
    {
      name: "obra",
      label: "Obra",
      value: (row: Gasto) => row.obra,
    },
    {
      name: "concepto",
      label: "Concepto",
      value: (row: Gasto) => row.concepto,
    },
    {
      name: "importe",
      label: "Importe",
      value: (row: Gasto) => row.importe,
    },
    {
      name: "empresa",
      label: "Empresa",
      value: (row: Gasto) => row.empresa_finiquito,
    },
    {
      name: "banco",
      label: "Banco",
      value: (row: Gasto) => row.banco,
    },
    {
      name: "comentario",
      label: "Comentario",
      value: (row: Gasto) => row.comentario,
    },
  ];
  entradasColumns: MatTableColumnConfig[] = [
    {
      name: "fecha_entrada",
      label: "Fecha",
      type: "date",
      value: (row: EntradaNoFiscal) => row.fecha_entrada,
    },
    {
      name: "cliente",
      label: "Cliente",
      value: (row: EntradaNoFiscal) => row.cliente_nombre,
    },
    {
      name: "concepto",
      label: "Concepto",
      value: (row: EntradaNoFiscal) => row.concepto,
    },
    {
      name: "importe",
      label: "Importe",
      value: (row: EntradaNoFiscal) => row.importe,
    },
    {
      name: "empresa",
      label: "Empresa",
      value: (row: EntradaNoFiscal) => row.empresa_finiquita_nombre,
    },
    {
      name: "banco",
      label: "Banco",
      value: (row: EntradaNoFiscal) => row.banco_nombre,
    },
    {
      name: "tipo_entrada",
      label: "Tipo",
      value: (row: EntradaNoFiscal) => row.tipo_pago,
    },
  ];
  constructor(
    private gastoService: GastoService,
    private entradasService: EntradasService,
    private toastrService: NbToastrService,
    private cortesService: CortesService,
    private windowService: NbWindowService,
    private empresasService: EmpresaFiniquitoService,

  ) {

    this.fechaSeleccionadaFull = new Date();
    this.fechaSeleccionadaFull.setHours(0, 0, 0, 0);
    this.empresasService.getAll().subscribe((empresas) => {
      this.empresas = empresas;
    });
  }
  downloadEntradas() {
    CSVGenerator.generateExcel(this.entradasColumns, this.filteredEntradasData, "entradas_"+this.fechaSeleccionada);
  }
  downloadSalidas() {
    CSVGenerator.generateExcel(this.salidasColums, this.filtereSalidasdData, "salidas_"+this.fechaSeleccionada);
  }

  // Calcula el total del 'importe' para los datos de salidas
  getTotalImportesSalidas(): number {
    return this.calculateTotal(this.salidasData, "importe");
  }

  // Calcula el total del 'importe' para los datos de entradas
  getTotalImportesEntradas(): number {
    return this.calculateTotal(this.entradasData, "importe");
  }

  // Calcula el total neto del 'importe' (entradas - salidas)
  getTotalImportes(): number {
    return this.getTotalImportesEntradas() - this.getTotalImportesSalidas();
  }

  // Calcula el total del 'importe' para los datos de salidas anteriores
  getTotalImportesSalidasAnterior(): number {
    return this.ultimoCorte?.total_importes_gastos?parseFloat(this.ultimoCorte.total_importes_gastos.toString()):0;
  }

  // Calcula el total del 'importe' para los datos de entradas anteriores
  getTotalImportesEntradasAnterior(): number {
    return this.ultimoCorte?.total_importes_entradas?parseFloat(this.ultimoCorte.total_importes_entradas.toString()):0;
  }

  // Calcula el total neto del 'importe' para los datos anteriores (entradas - salidas)
  getTotalImportesAnterior(): number {
    return (
      this.getTotalImportesEntradasAnterior() - this.getTotalImportesSalidasAnterior()
    );
  }

  // Función auxiliar para calcular el total de una clave específica en el array de datos
  private calculateTotal(data: any[], key: string): number {
    return data.reduce(
      (total, item) => total + parseFloat(item[key].toString()),
      0
    );
  }
  onDateChange($event: any) {
    this.loadingData = true;
    // Reiniciar corte y ultimoCorte a null
    this.corte = null;
    this.ultimoCorte = null;
    this.fecha = $event;
    this.entradasData = [];
    this.filteredEntradasData = [];
    this.salidasData = [];
    this.filtereSalidasdData = [];
    // Formatear la fecha seleccionada
    const fecha = new Date($event);
    this.fechaSeleccionada = `${fecha.getFullYear()}-${(fecha.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${fecha.getDate().toString().padStart(2, "0")}`;

    // Obtener el último corte del tipo 'NO FISCAL'
    this.cortesService.getBy([{ ultimo: true }, { tipo: 'FISCAL' },{empresa_id: this.empresaSeleccionada?this.empresaSeleccionada.id:null}]).subscribe((corte: Corte) => {
      this.ultimoCorte = corte;
    });

    // Obtener el corte para la fecha seleccionada y tipo 'NO FISCAL'
    this.cortesService.getBy([{ fecha: this.fechaSeleccionada }, { ultimo: true }, { tipo: 'FISCAL' },
      {empresa_id: this.empresaSeleccionada?this.empresaSeleccionada.id:null}
    ]).subscribe((corte: Corte) => {
      if (corte !== null) {
        this.corte = corte;
      }
    },
    // Si no se encuentra un corte, obtener datos de los servicios
    (error) => {

    });
    this.fetchDataFromServices(this.fechaSeleccionada).subscribe(([salidas, entradas]) => {
      this.salidasData = salidas;
      this.filtereSalidasdData = salidas;
      this.entradasData = entradas;
      this.filteredEntradasData = entradas;
      this.loadingData = false;
    });
    this.loadingData = false;
  }
  private fetchDataFromServices(fechaFormateada: string) {
    return forkJoin([
      this.gastoService.getAllByAttributes([
        { fecha_compra: fechaFormateada },
        { tipo: 'FISCAL'},
        {empresa: this.empresaSeleccionada?this.empresaSeleccionada.id:null}
      ]),
      this.entradasService.getAllByAttributes([
        { fecha_entrada: fechaFormateada },
        { tipo: 'FISCAL' },
        {empresa: this.empresaSeleccionada?this.empresaSeleccionada.id:null}
      ]),
    ]);
  }

  generarCorte() {
    // Verifica si no hay un corte
    if (this.corte === null) {
      // Crea un nuevo FormGroup para el corte
      const formGroup = new FormGroup({});
      // Agrega el control de fecha al FormGroup
      formGroup.addControl("fecha", new FormControl(this.fechaSeleccionada));
      // Agrega el control de tipo al FormGroup
      formGroup.addControl("tipo", new FormControl('FISCAL'));

      formGroup.addControl("empresa_id", new FormControl(this.empresaSeleccionada?this.empresaSeleccionada.id:null));
      // Llama al servicio para crear el corte
      this.cortesService.create(formGroup).subscribe((corte) => {
        // Asigna el nuevo corte al corte actual
        this.corte = corte;
        // Muestra un mensaje de éxito
        this.toastrService.show("Corte generado correctamente", "Corte", {
          status: "success",
        });
        this.confirmRef.close();
        this.fetchDataFromServices(this.fechaSeleccionada).subscribe(([salidas, entradas]) => {
          this.salidasData = salidas;
          this.filtereSalidasdData = salidas;
          this.entradasData = entradas;
          this.filteredEntradasData = entradas;
          this.loadingData = false;
          this.corteLoading = false;
        });

      },error=>{
        this.toastrService.show("Error al generar corte", "Corte", {  status: "danger",});
      });
    }
  }
  disableCorteButton(): boolean {
    return (this.corte !== null || this.fechaSeleccionada === null)||this.corteLoading||this.loadingData;
  }


  generarCorteConfirm() {
    this.confirmRef = this.windowService.open(this.corteConfirm,{title:'Confirmar corte'});
  }

  cancelarCorte() {
    this.confirmRef.close();
  }

  generarReporte(){
    CSVGenerator.generateCorteExcel(this.entradasData, this.salidasData, this.corte, 'Corte_Fiscal_'+this.fechaSeleccionada);
  }
}
