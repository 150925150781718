import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Licencia } from '../data/rh-data';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LicenciasService extends BaseService<Licencia> {
  getPath(){
    return 'licencias';
  }

  public create(formGroup: FormGroup): Observable<Licencia> {
    const formData = new FormData();
    Object.keys(formGroup.controls).forEach(key => {
      if(formGroup.get(key).value instanceof File){
        const file = formGroup.get(key).value;
        formData.append(key, file, file.name);
      }else if(formGroup.get(key).value instanceof Date){
        formData.append(key, formGroup.get(key).value.toISOString());

      }else{
        formData.append(key, formGroup.get(key).value);
      }

    });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<Licencia>(`${this.baseUrl}${this.getPath()}`, formData,{headers: headers});
  }

  downloadDoc(id: any):Observable<Blob>{
    return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?download=true`, { responseType: 'blob' });
  }

  uploadDoc(id: any, file: File):Observable<Licencia>{
    const formData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<Licencia>(`${this.baseUrl}${this.getPath()}/${id}/update-document`, formData,{headers: headers});
  }
}
