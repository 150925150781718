import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { EmpresaCuenta, EmpresaFiniquito } from '../data/empresa-finiquito-data';

@Injectable({
  providedIn: 'root'
})
export class EmpresaFiniquitoService extends BaseService<EmpresaFiniquito> {
  protected getPath(): string {
    return 'empresas-finiquito';
  }
}

@Injectable({
  providedIn: 'root'
})
export class EmpresaCuentaService extends BaseService<EmpresaCuenta> {
  protected getPath(): string {
    return 'empresas/cuentas';
  }
}
