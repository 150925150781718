import { Component, Input, OnChanges, SimpleChange, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Cotizacion, Empleado } from '../../@core/data/cotizacion-data';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { CotizacionService } from '../../@core/services/cotizacion.service';
import { EmpleadoService } from '../../@core/services/empleado.service';

@Component({
  selector: 'ngx-cotizaciones-mat-table',
  templateUrl: './cotizaciones-mat-table.component.html',
  styleUrls: ['./cotizaciones-mat-table.component.scss']
})
export class CotizacionesMatTableComponent implements OnChanges{

  @Input() conceptos: Cotizacion[];
  dataSource: MatTableDataSource<Cotizacion>;
  defaultColumns: string[] = ['folio','cliente', 'empleado'];
  customColumns: string[] = [ 'fecha','total','acciones'];
  allColumns = [...this.defaultColumns, ...this.customColumns];
  @ViewChild('maquinariasView', { read: TemplateRef }) maquinariasView: TemplateRef<HTMLElement>;
  @ViewChild('selectEmpleadoFirmaView', { read: TemplateRef }) selectEmpleadoFirmaView: TemplateRef<HTMLElement>;
  @ViewChild('openEdit', { read: TemplateRef }) openEdit: TemplateRef<HTMLElement>;
  @ViewChild('openDelete', { read: TemplateRef }) openDelete: TemplateRef<HTMLElement>;
  @ViewChild('openGenerarContrato', { read: TemplateRef }) openGenerarContrato: TemplateRef<HTMLElement>;
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:false}) sort: MatSort;
  editWindows: NbWindowRef;
  fileUploadWindows: NbWindowRef;
  deleteWindows: NbWindowRef;
  selectEmpleadoFirmaWindows: NbWindowRef;
  generarContratoWindows: NbWindowRef;


  empleadosFirma:Empleado[]=[];
  empleadoFirmaSelected:number|null;

  constructor(private windowService: NbWindowService,
    private cotizacionesService: CotizacionService, private empleadosSerivce:EmpleadoService,
    private toastrService: NbToastrService
  ) {
    this.empleadosSerivce.getEmpleadosFirma().subscribe(empleados => {
      this.empleadosFirma = empleados;
    });
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cotizaciones) {
      this.dataSource = new MatTableDataSource(changes.cotizaciones.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  onOpenDelete(data: Cotizacion) {
    this.deleteWindows = this.windowService.open(
      this.openDelete,
      { title: 'Eliminar cotizacion', context: { cotizacion:data }, hasBackdrop: true, closeOnEsc: true },
    );
  }

  onConfirmDelete(cotizacion: Cotizacion) {
    this.cotizacionesService.delete(cotizacion.id).subscribe(()=>{
      this.conceptos = this.conceptos.filter((data) => data.id !== cotizacion.id);
      this.dataSource = new MatTableDataSource(this.conceptos);
      this.deleteWindows.close();
      this.toastrService.show('Cotizacion eliminada con éxito','Eliminar cotizacion', { status: 'success' });
    });
  }

  onGenerarReporteClickBtn(cotizacion: Cotizacion){
    this.selectEmpleadoFirmaWindows =this.windowService.open(
      this.selectEmpleadoFirmaView,
      { title: 'Seleccionar empleado para firma', hasBackdrop: true, closeOnBackdropClick: false,
         closeOnEsc: true, context: {cotizacion: cotizacion}},
    );
  }

  generarReporte(cotizacion: Cotizacion) {
    this.cotizacionesService.generarReporte(cotizacion.id,this.empleadoFirmaSelected).subscribe(()=>{
      this.selectEmpleadoFirmaWindows.close();
    });
  }

  onOpenGenerarContrato(cotizacion: Cotizacion) {
    this.generarContratoWindows = this.windowService.open(
      this.openGenerarContrato,
      { title: 'Generar contrato', context: { cotizacion: cotizacion }, hasBackdrop: true, closeOnEsc: true
      },
    );
  }

  onOpenEditCotizacion(cotizacion: Cotizacion) {
    this.editWindows = this.windowService.open(
      this.openEdit,
      { title: 'Editar cotizacion', context: { cotizacion: cotizacion }, hasBackdrop: true, closeOnEsc: true },
    );
  }
  onConfirmEditCotizacion() {
    this.cotizacionesService.getAll().subscribe(cotizaciones => {
      this.conceptos = cotizaciones;
      this.dataSource = new MatTableDataSource(this.conceptos);
      this.editWindows.close();
      this.toastrService.show('Cotizacion editada con éxito','Editar cotizacion', { status: 'success' });
    });
  }
}
