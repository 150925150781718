<table mat-table [dataSource]="datasource" equalColumnsWidth multiTemplateDataRows>
  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: allColumns;">
  </tr>
  <ng-container
  matColumnDef="{{ column }}"
  *ngFor="let column of colums"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.replace('_', ' ').charAt(0).toUpperCase() + column.replace('_', ' ').slice(1) }}</th>
    <td mat-cell *matCellDef="let expediente">
      {{ expediente[column] }}
    </td>
  </ng-container>
  <ng-container matColumnDef="opciones">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let expediente">
      <button nbTooltip="Editar expediente" nbButton size="small" (click)="onClickEditExpediente(expediente)">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </button>
      <button nbTooltip="Modificar documentos" nbButton size="small" (click)="openDialogDocumentos(expediente)">
        <nb-icon icon="file-text-outline"></nb-icon>
      </button>
      <button nbButton nbTooltip="Eliminar" size="small" status="primary"   nbButton (click)="onOpenDeleteExpediente(expediente)">
        <nb-icon icon="trash-2-outline"></nb-icon>
      </button>
    </td>
  </ng-container>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 21]" aria-label="Selecciona una pagina" showFirstLastButtons></mat-paginator>
<ng-template #openEdit let-data>
  <div style="width: 800;">
    <ngx-editar-expediente-form (expedienteEdited)="onConfirmEditExpediente()" [expediente]="data.expediente"></ngx-editar-expediente-form>
  </div>
</ng-template>
<ng-template #documentosView let-data>
  <div  style="min-width: 800px; max-height: 400px; overflow-y: auto;">
    <ngx-expediente-documentos-form [docsExpediente]="data.docs"></ngx-expediente-documentos-form>

  </div>
</ng-template>
<ng-template #openDelete let-data>
  <div>¿Desea eliminar el expediente {{data.expediente.contrato}}?</div>
  <div><button status="danger" (click)="onConfirmDeleteExpediente(data.expediente)" nbButton>Eliminar</button>
    <button nbButton>
      Cancelar
    </button>
  </div>
</ng-template>
