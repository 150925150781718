import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuService } from './pages-menu';



@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent {
  menu = [];
  constructor(private menuService: MenuService) {
    this.menuService.getMenu().then((menu) => {
      this.menu= menu;
    })
  }
}
