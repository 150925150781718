import { Injectable } from "@angular/core";
import { BaseService } from "./base-service";
import { Maquinaria } from "../data/maquinaria-data";
import { Observable } from "rxjs";
import { FormGroup } from "@angular/forms";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class MaquinariaService extends BaseService<Maquinaria> {
  public downloadPedimento(id: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?download=pedimento`, { responseType: 'blob' });

  }

  public downloadPoliza(id: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?download=poliza`, { responseType: 'blob' });

  }
  protected getPath(): string {
    return 'maquinarias';
  }

  public getMaquinarias(): Observable<Maquinaria[]> {
    return this.http.get<Maquinaria[]>(`${this.baseUrl}${this.getPath()}?maquinarias=true`);
  }

  public getTractocamiones(): Observable<Maquinaria[]> {
    return this.http.get<Maquinaria[]>(`${this.baseUrl}${this.getPath()}?tractocamiones=true`);
  }

  public updatePedimento(id: number, file:File): Observable<Maquinaria>{
    const formData = new FormData();
    formData.append('pedimento', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<Maquinaria>(`${this.baseUrl}${this.getPath()}/${id}/update-pedimento`, formData,{headers: headers});
  }

  public updatePoliza(id: number, file:File): Observable<Maquinaria>{
    const formData = new FormData();
    formData.append('poliza_file', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<Maquinaria>(`${this.baseUrl}${this.getPath()}/${id}/update-poliza`, formData,{headers: headers});
  }
}

