import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';
import { UsersComponent } from './users/users.component';
import { VehiculosComponent } from './vehiculos/vehiculos.component';
import { MaquinariasComponent } from './maquinarias/maquinarias.component';
import { ExpedientesUnicosComponent } from './expedientes-unicos/expedientes-unicos.component';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { ClientesComponent } from './clientes/clientes.component';
import { EmpleadosComponent } from './empleados/empleados.component';
import { EntrevistasComponent } from './entrevistas/entrevistas.component';
import { ContratosRHComponent } from './contratos-rh/contratos-rh.component';
import { LicenciasComponent } from './licencias/licencias.component';
import { MaquinariaPesadaComponent } from './tractocamiones/maquinaria-pesada.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FiniquitosComponent } from './finiquitos/finiquitos.component';
import { EquiposComponent } from './equipos/equipos.component';
import { EmpleadoSegurosComponent } from './empleado-seguros/empleado-seguros.component';
import { GastosComponent } from './gastos-no-fiscales/gastos.component';
import { EntradasNoFiscalesModule } from './entradas-no-fiscales/entradas-no-fiscales.module';
import { EntradasNoFiscalesComponent } from './entradas-no-fiscales/entradas-no-fiscales.component';
import { CorteNoFiscalComponent } from './corte-no-fiscal/corte-no-fiscal.component';
import { EntradasFiscalesComponent } from './entradas-fiscales/entradas-fiscales.component';
import { GastosFiscalesComponent } from './gastos-fiscales/gastos-fiscales.component';
import { CorteFiscalComponent } from './corte-fiscal/corte-fiscal.component';
import { NominaSemanalComponent } from './nomina-semanal/nomina-semanal.component';
import { NominasComponent } from './nominas/nominas.component';
import { RolesComponent } from './users/roles/roles.component';
import { AuthGuard } from '../@core/guards/auth-guard.guard';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { SiniestrosComponent } from './siniestros/siniestros.component';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'dashboard',
      component: DashboardComponent,
      //canActivate: [AuthGuard],
      //data: { permissions: ['dashboard'] },
    },
    {
      path: 'maquinaria-pesada',
      component: MaquinariasComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['maquinaria_pesada'] },
    },
    {
      path: 'tractocamiones',
      component: MaquinariaPesadaComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['tractocamiones'] },
    },
    {
      path: 'vehiculos',
      component: VehiculosComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['vehiculos'] },
    },
    {
      path: 'users',
      component: UsersComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['users'] },
    },
    {
      path: 'profile',
      component: UserProfileComponent,
    },
    {
      path: 'expedientes-unicos',
      component: ExpedientesUnicosComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['expedientes_unicos'] },
    },
    {
      path: 'cotizaciones',
      component: CotizacionesComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['cotizaciones'] },
    },
    {
      path: 'clientes',
      component: ClientesComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['clientes'] },
    },
    {
      path: 'empleados',
      component: EmpleadosComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['empleados'] },
    },
    {
      path: 'entrevistas',
      component: EntrevistasComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['entrevistas'] },
    },
    {
      path: 'contratos-rh',
      component: ContratosRHComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['contratos_rh'] },
    },
    {
      path: 'licencias',
      component: LicenciasComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['licencias'] },
    },
    {
      path: 'configuraciones',
      component: ConfiguracionesComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['configuraciones'] },
    },
    {
      path: 'finiquitos',
      component: FiniquitosComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['finiquitos'] },
    },
    {
      path: 'equipos',
      component: EquiposComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['equipos'] },
    },
    {
      path: 'asegurados',
      component: EmpleadoSegurosComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['asegurados'] },
    },
    {
      path: 'gastos/no-fiscales',
      component: GastosComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['gastos_no_fiscales'] },
    },
    {
      path: 'entradas/no-fiscales',
      component: EntradasNoFiscalesComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['entradas_no_fiscales'] },
    },
    {
      path: 'cortes/no-fiscal',
      component: CorteNoFiscalComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['corte_no_fiscal'] },
    },
    {
      path: 'entradas/fiscales',
      component: EntradasFiscalesComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['entradas_fiscales'] },
    },
    {
      path: 'gastos/fiscales',
      component: GastosFiscalesComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['gastos_fiscales'] },
    },
    {
      path: 'cortes/fiscal',
      component: CorteFiscalComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['cortes_fiscales'] },
    },
    {
      path: 'nominas/semanal',
      component: NominaSemanalComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['nominas_semanales','nominas_obras'] },
    },
    // {
    //   path: 'nominas',
    //   component: NominasComponent,
    //   canActivate: [AuthGuard],
    //   data: { permissions: ['nominas'] },
    // },
    {
      path: 'sistema/usuarios',
      component: UsersComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['usuarios'] },
    },
    {
      path: 'sistema/roles',
      component: RolesComponent,
      canActivate: [AuthGuard],
      data: { permissions: ['roles'] },
    },
    {
      path: 'siniestros',
      component: SiniestrosComponent,
    },
    {
      path: '**',
      component: NotFoundComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
