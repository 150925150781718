import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NuevoVehiculoFormComponent } from './nuevo-vehiculo-form/nuevo-vehiculo-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NbAutocompleteModule, NbButtonModule, NbCardModule, NbInputModule, NbRadioModule, NbSelectModule, NbSelectWithAutocompleteModule, NbSpinnerModule } from '@nebular/theme';
import { MarcaService } from '../../../@core/services/marca.service';
import { VersionService } from '../../../@core/services/version.service';
import { EstadoService } from '../../../@core/services/estado.service';
import { SeguroService } from '../../../@core/services/seguro.service';



@NgModule({
  declarations: [
    NuevoVehiculoFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbAutocompleteModule,
    NbSelectModule,
    NbSelectWithAutocompleteModule,
    NbRadioModule,
    NbSpinnerModule
  ],
  exports:[
    NuevoVehiculoFormComponent
  ],
  providers: [MarcaService, VersionService, EstadoService, SeguroService]
})
export class VehiculosFormsModule { }
