import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { EmpleadoSeguro } from '../data/empleado-seguro-data';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoSeguroService extends BaseService<EmpleadoSeguro>{
  uploadHojaRetencion(id: number,file: File) {
    const formData = new FormData();
    formData.append('hoja_retencion', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${this.baseUrl}${this.getPath()}/${id}/upload-hoja-retencion`, formData,{headers: headers});

  }

  downloadHojaRetencion(id: number): Observable<Blob> {
    const url = `${this.baseUrl}${this.getPath()}/${id}?download_hoja_retencion=true`;
    return this.http.get(url, { responseType: 'blob' });
  }

  protected getPath(): string {
    return 'empleado-seguro';
  }
  public create(formGroup: FormGroup): Observable<EmpleadoSeguro> {
    const formData = new FormData();
    Object.keys(formGroup.controls).forEach(key => {
      if(formGroup.get(key).value instanceof File){
        const file = formGroup.get(key).value;
        formData.append(key, file, file.name);
      }else if(formGroup.get(key).value instanceof Date){
        console.log(formGroup.get(key).value,key);
        formData.append(key, formGroup.get(key).value.toISOString());
      }else if(formGroup.get(key).value instanceof Boolean){
        formData.append(key, formGroup.get(key).value ? '1' : '0');

      }
      else{
        formData.append(key, formGroup.get(key).value);
      }

    });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<EmpleadoSeguro>(`${this.baseUrl}${this.getPath()}`, formData,{headers: headers});
  }
}
