import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterValueDateComponent } from './filter-value-date.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbDatepickerModule, NbFormFieldModule, NbInputModule, NbButtonModule, NbIconModule } from '@nebular/theme';



@NgModule({
  declarations: [
    FilterValueDateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule
  ],
  exports: [
    FilterValueDateComponent
  ]
})
export class FilterValueDateModule { }
