import { CotizacionesTablesModule } from './../pages/cotizaciones/cotizaciones-tables/cotizaciones-tables.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiSelectComponent } from './api-select/api-select.component';
import { NbAutocompleteModule, NbButtonModule, NbCheckboxModule, NbIconModule, NbInputModule, NbSelectModule, NbSelectWithAutocompleteModule, NbSpinnerModule, NbTooltipModule } from '@nebular/theme';
import { NuevoClienteFormComponent } from './nuevo-cliente-form/nuevo-cliente-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NuevaMaquinariaFormComponent } from './nueva-maquinaria-form/nueva-maquinaria-form.component';
import { CotizacionMaquinariasFormComponent } from './cotizacion-maquinarias-form/cotizacion-maquinarias-form.component';
import { NuevaCotizacionFormComponent } from './nueva-cotizacion-form/nueva-cotizacion-form.component';
import { GenerarContratoFormComponent } from './generar-contrato-form/generar-contrato-form.component';
import { EditarCotizacionFormComponent } from './editar-cotizacion-form/editar-cotizacion-form.component';
import { EditarExpedienteFormComponent } from './editar-expediente-form/editar-expediente-form.component';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { NuevaEntrevistaFormComponent } from './nueva-entrevista-form/nueva-entrevista-form.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { EmpresaCuentaGroupSelectComponent } from './empresa-cuenta-group-select/empresa-cuenta-group-select.component';



@NgModule({
  declarations: [
    ApiSelectComponent,
    NuevoClienteFormComponent,
    NuevaMaquinariaFormComponent,
    CotizacionMaquinariasFormComponent,
    NuevaCotizacionFormComponent,
    GenerarContratoFormComponent,
    EditarCotizacionFormComponent,
    EditarExpedienteFormComponent,
    DeleteButtonComponent,
    NuevaEntrevistaFormComponent,
    CheckboxGroupComponent,
    EmpresaCuentaGroupSelectComponent
  ],
  imports: [
    CommonModule,
    NbSelectWithAutocompleteModule,
    NbButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NbIconModule,
    CotizacionesTablesModule,
    NbInputModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbSpinnerModule,
    NbAutocompleteModule,
  ],
  exports:[
    ApiSelectComponent,
    NuevoClienteFormComponent,
    NuevaMaquinariaFormComponent,
    CotizacionMaquinariasFormComponent,
    NuevaCotizacionFormComponent,
    GenerarContratoFormComponent,
    EditarCotizacionFormComponent,
    EditarExpedienteFormComponent,
    NuevaEntrevistaFormComponent,
    DeleteButtonComponent,
  ]
})

export class GCMARQFormsModule {

  static forChild() {
    return {
      ngModule: GCMARQFormsModule,
      providers: [],
    };
  }
 }
