import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-nueva-entrevista-form',
  templateUrl: './nueva-entrevista-form.component.html',
  styleUrls: ['./nueva-entrevista-form.component.scss']
})
export class NuevaEntrevistaFormComponent implements OnInit {

  @Output() entrevistaCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  form:FormGroup;
  constructor() {
    this.form = new FormGroup({
      nombre: new FormControl(''),
      apellido_paterno: new FormControl(''),
      apellido_materno: new FormControl(''),
      telefono: new FormControl(''),
      puesto: new FormControl(''),
      contratable: new FormControl(false),
      comentarios:  new FormControl(''),
    });
  }

  ngOnInit(): void {

  }

  onSubmit() {
    this.entrevistaCreated.emit();
  }
}
