import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Nomina, NominaEmpleado, NominaEmpleadoDiaria} from '../data/nomina-data';
import { Observable } from 'rxjs';
import { Empleado } from '../data/cotizacion-data';

@Injectable({
  providedIn: 'root'
})
export class NominasService extends BaseService<Nomina> {
  downloadReporteSemanal(id: number) {
    const url = `${this.baseUrl}${this.getPath()}/${id}/generar-reporte`;
    return this.http.get(url, { responseType: 'blob' });
  }
  protected getPath(): string {
    return 'nominas';
  }

  public getALLFirstBy(attributes: { [key: string]: any }[]): Observable<Nomina> {
    const url = `${this.baseUrl}${this.getPath()}?${attributes.map(attr => Object.keys(attr).map(key => `${key}=${attr[key]}`).join('&')).join('&')}`;
    return this.http.get<Nomina>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class NominaEmpleadoService extends BaseService<NominaEmpleado> {
  protected getPath(): string {
    return 'nominas-empleados';
  }
}

@Injectable({
  providedIn: 'root'
})
export class NominaEmpleadoDiariaService extends BaseService<NominaEmpleadoDiaria> {
  protected getPath(): string {
    return 'nominas-empleados-diarias';
  }

  public getAllByReturnEmpleados(attributes: { [key: string]: any; }[]): Observable<Empleado[]> {
    const url = `${this.baseUrl}${this.getPath()}?${attributes.map(attr => Object.keys(attr).map(key => `${key}=${attr[key]}`).join('&')).join('&')}`;
    return this.http.get<Empleado[]>(url);
  }
}
