import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { NbAuthModule, NbAuthSimpleToken, NbPasswordAuthStrategy } from '@nebular/auth';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../@core/services/auth.service';
import { NbSecurityModule, NbRoleProvider} from '@nebular/security';
import { RoleService } from './role.service';
import { NbButtonModule, NbInputModule } from '@nebular/theme';
import { AuthComponentComponent } from './auth-component/auth-component.component';


@NgModule({
  declarations: [
    LoginComponent,
    AuthComponentComponent
  ],
  providers: [
    AuthService,
    {
      provide: NbRoleProvider,
      useClass: RoleService,
    },

  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    NbInputModule,
    NbButtonModule,
    FormsModule,
    NbSecurityModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            class: NbAuthSimpleToken,
            key: 'token'
          },
          baseEndpoint: '/api/auth',
          login: {
            endpoint: '/login',
            method: 'post',
            redirect: {
              success: '/dashboard',
              failure: null,
            },
            defaultErrors: ['Algo salió mal, por favor intente de nuevo'],
            defaultMessages: ['Has iniciado sesión correctamente'],
          },
          logout: {
            endpoint: '/logout',
            method: 'post',
            defaultErrors: ['Algo salió mal, por favor intente de nuevo'],
            defaultMessages: ['Has cerrado sesión correctamente'],
          },

        }),
      ]
      ,
    }),
  ],

})
export class AuthModule { }
