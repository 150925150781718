
<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Vehiculos
      <button nbButton status="success"  nbTooltip="Agregar vehiculo"
        (click)="onClickOpenNuevoVehiculoForm()" class="btn btn-success btn-sm p-lg-1">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
        <button nbButton status="info" nbTooltip="Descargar vehiculos"
          (click)="onClickDownloadReporte()" class="btn btn-info btn-sm p-lg-1">
          <nb-icon icon="file-text-outline"></nb-icon>
        </button>  </div>
    <ngx-filter-value-date [items]="vehiculos" [filterField]="'created_at'" [filterFields]="filterFields" (filterChange)="onVehiculoSearch($event)"></ngx-filter-value-date>
  </nb-card-header>
  <nb-card-body class="no-padding">
    <ngx-mat-table-builder [columnsDef]="columnsDef" [data]="dataFiltered" [actions]="actions"  ></ngx-mat-table-builder>
  </nb-card-body>
</nb-card>

<ng-template  class="modal" let-data  #nuevoVehiculo>
  <div class="modal-body responsive-div-md">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onSavedVehiculo($event)" ></ngx-form-builder>
  </div>
</ng-template>

<ng-template class="modal" style="width: 90%;" let-data #mantenimientos>
  <div class="responsive-div-md">
    <ngx-vehiculos-mantenimiento-table [vehiculo]="data.vehiculo" (mantenimientoCreated)="onMantenimientoCreated($event)"></ngx-vehiculos-mantenimiento-table>
  </div>
</ng-template>


