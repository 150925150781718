import { CotizacionMaquinariaService } from '../../@core/services/cotizacion-maquinaria.service';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cliente, Cotizacion, CotizacionMaquinaria, Empleado } from '../../@core/data/cotizacion-data';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CotizacionService } from '../../@core/services/cotizacion.service';
import { Maquinaria } from '../../@core/data/maquinaria-data';
import { ClienteService } from '../../@core/services/cliente.service';

@Component({
  selector: 'ngx-editar-cotizacion-form',
  templateUrl: './editar-cotizacion-form.component.html',
  styleUrls: ['./editar-cotizacion-form.component.scss']
})
export class EditarCotizacionFormComponent implements OnInit {

nuevaCotizacionForm: FormGroup;
maquinaria:CotizacionMaquinaria[]=[];

clienteSelected: Cliente|null;
empleadoSelected: Empleado|null;
clientes: Cliente[];
empleados: Empleado[];
@Input() cotizacion: Cotizacion;
cotizacionToEdit: Cotizacion;
@Output() cotizacionSaved:EventEmitter<boolean> = new EventEmitter<boolean>();
selectedEmpleadoFormControl = new FormControl(null);
selectedClienteFormControl = new FormControl(null);

constructor(private fb: FormBuilder, private cotizacionService: CotizacionService,
  private EmpleadoService: EmpleadoService, private clienteService:ClienteService,
  private cotizacionMaquinariaService: CotizacionMaquinariaService
) {
  this.nuevaCotizacionForm = this.fb.group({
    cliente_id: [this.selectedClienteFormControl,Validators.required],
    empleado_id: [this.selectedEmpleadoFormControl,Validators.required],
    cotizacionMaquinarias: [[]]
  })
  this.clienteService.getAll().subscribe(clientes => {
    this.clientes = clientes;
  });

  this.EmpleadoService.getAll().subscribe(empleados => {
    this.empleados = empleados;
  });

}
  ngOnInit(): void {

    if (this.cotizacion!==null&&this.cotizacion!==undefined) {
      this.cotizacionToEdit = this.cotizacion;
      this.cotizacionMaquinariaService.getMaquinariasByCotizacionId(this.cotizacion.id).subscribe(cotizacionMaquinarias => {
        cotizacionMaquinarias.forEach(element => {
          element.subtotal=element.cantidad_horas*element.precio_renta_maquinaria;
        });
        this.maquinaria=cotizacionMaquinarias;
      });
      this.nuevaCotizacionForm.patchValue({
        cliente_id: this.cotizacionToEdit.cliente_id,
        empleado_id: this.cotizacionToEdit.empleado_id,
      });

    }
  }

  addRow() {
    this.maquinaria=[...this.maquinaria,{
      id:0,
      cotizacion_id:0,
      maquinaria_nombre:'',
      maquinaria_id:0,
      cantidad_horas:0,
      precio_renta_maquinaria:0,
      subtotal:0,
      maquinaria_numero:''
    }];
  }

  onNuevaCotizacionSubmit() {
    if (this.nuevaCotizacionForm.valid) {
      this.nuevaCotizacionForm.controls['cotizacionMaquinarias'].setValue(this.maquinaria);
      console.log(this.nuevaCotizacionForm.value);
      this.cotizacionService.update(this.nuevaCotizacionForm,this.cotizacion.id).subscribe(cotizacion => {
        this.cotizacion = cotizacion;
        this.cotizacionSaved.emit(true);
      });
    }
  }
}
