import { Component } from '@angular/core';

@Component({
  selector: 'ngx-nuevo-expediente-form',
  templateUrl: './nuevo-expediente-form.component.html',
  styleUrls: ['./nuevo-expediente-form.component.scss']
})
export class NuevoExpedienteFormComponent {

}
