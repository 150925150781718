import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Cotizacion } from '../data/cotizacion-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CotizacionService extends BaseService<Cotizacion>{
  generarReporte(idCotizacion: number, idEmpleadoFirmaSelected: number): Observable<void> {
    return this.http.get(`${this.baseUrl}reportes/cotizacion/${idCotizacion}/download/${idEmpleadoFirmaSelected}`, { responseType: 'blob' })
      .pipe(
        map((response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'reporte.docx';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
      );
  }

  generarContrato(idCotizacion: number, idEmpleadoRep: number, fechaInicial: string, fechaFinal: string,tiempoContrato:number): Observable<void> {
    return this.http.get(`${this.baseUrl}reportes/contrato/${idCotizacion}/${idEmpleadoRep}?fecha_inicial=${fechaInicial}&fecha_final=${fechaFinal}&tiempo_contrato=${tiempoContrato}`,
       { responseType: 'blob' })
      .pipe(
        map((response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'contrato.docx';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
      );
  }

  protected getPath(): string {
    return 'cotizaciones';
  }
}
