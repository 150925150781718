import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseService, TokenResponse } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService<TokenResponse> {

   protected getPath(): string {
    return 'auth/';
   }
    public login(username: string, password: string):Observable<TokenResponse>{
        return this.http.post<TokenResponse>(this.baseUrl + this.getPath()+ 'login',{email: username, password: password});
    }

    public logout():Observable<any>{
        return this.http.post<any>(this.baseUrl + this.getPath() + 'logout', {});
    }

    public me():Observable<any>{
        return this.http.get<any>(this.baseUrl + this.getPath() + 'user');
    }
}
