<table mat-table #table [dataSource]="conceptos">
  <ng-container matColumnDef="maquinaria_nombre">
    <th mat-header-cell *matHeaderCellDef>Maquinaria</th>
    <td mat-cell *matCellDef="let cotizacion">
      <nb-select (selectedChange)="onSelectMaquinaria($event,cotizacion)" [(ngModel)]="cotizacion.maquinaria_id" fullWidth placeholder="Selecciona una maquinaria">
        <nb-option *ngFor="let maquinaria of maquinarias" [value]="maquinaria.id">{{maquinaria.numero}}{{maquinaria.nombre}}</nb-option>
      </nb-select>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="duracion">
    <th mat-header-cell *matHeaderCellDef>Duración</th>
    <td mat-cell *matCellDef="let cotizacion">
      <input nbInput placeholder="Selecciona una duración" [nbAutocomplete]="autoDuracion" [(ngModel)]="cotizacion.duracion" (ngModelChange)="onDuracionChange($event)">
      <nb-autocomplete #autoDuracion>
        <nb-option *ngFor="let option of duracionOptions" [value]="option">
          {{ option }}
        </nb-option>
      </nb-autocomplete>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="precio_renta_maquinaria">
    <th mat-header-cell *matHeaderCellDef>Importe</th>
    <td mat-cell *matCellDef="let cotizacion">
      <input nbInput class="input-precio-renta" [value]="cotizacion.precio_renta_maquinaria" (change)="onPrecioChange($event,cotizacion)" (wheel)="preventDefault($event)" type="number">
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="concepto">
    <th mat-header-cell *matHeaderCellDef>Concepto</th>
    <td mat-cell *matCellDef="let cotizacion">
      <textarea nbInput [(ngModel)]="cotizacion.concepto"></textarea>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="cantidad">
    <th mat-header-cell *matHeaderCellDef>Cantidad</th>
    <td mat-cell *matCellDef="let cotizacion">
      <input nbInput class="input-cantidad-horas" [value]="cotizacion.cantidad_horas" (change)="onHorasChange($event,cotizacion)" (wheel)="preventDefault($event)" type="number">
    </td>
   
    <td mat-footer-cell *matFooterCellDef>
      IVA {{iva}}%
      <br>
      Total
    </td>
  </ng-container>
  <ng-container matColumnDef="subtotal">
    <th mat-header-cell *matHeaderCellDef>Subtotal</th>
    <td mat-cell *matCellDef="let cotizacion">
      {{cotizacion.subtotal | currency:"MXN":"symbol"}}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{getIvaAmount() | currency:"MXN":"symbol"}}
      <br>
      {{getTotalCost() | currency:"MXN":"symbol":"1.2-2"}} </td>
    
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let cotizacion">
      <button nbButton nbTooltip="Eliminar" size="small" status="primary"   nbButton (click)="removeCotizacionVehiculo(cotizacion)">
        <nb-icon icon="trash-2-outline"></nb-icon>
      </button>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: allColumns;">
  <tr mat-footer-row *matFooterRowDef="allColumns"></tr>
</table>


