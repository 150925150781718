import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Vehiculo } from "../../../@core/data/vehiculo-data";
import { FormGroup, FormControl } from "@angular/forms";
import { NbWindowRef, NbWindowService, NbToastrService } from "@nebular/theme";
import { Mantenimiento, Maquinaria } from "../../../@core/data/maquinaria-data";
import { MantenimientosService } from "../../../@core/services/mantenimientos.service";
import {
  FormFieldType,
  FormRowConfig,
  OptionType,
} from "../../../form-builder/form-builder.component";
import {
  MatTableColumnConfig,
  MatTableActionConfig,
} from "../../../mat-table-builder/mat-table-builder.component";
import { CSVGenerator } from "../../../@core/utils/csv-generator";

@Component({
  selector: "ngx-vehiculos-mantenimiento-table",
  templateUrl: "./vehiculos-mantenimiento-table.component.html",
  styleUrls: ["./vehiculos-mantenimiento-table.component.scss"],
})
export class VehiculosMantenimientoTableComponent implements OnChanges {
  @Input() vehiculo: Vehiculo;
  @Output() mantenimientoCreated: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("newMantenimientoForm") newMantenimientoForm: any;
  mantenimientoRef: NbWindowRef;
  mantenimientos: Mantenimiento[] = [];
  mantenimientosFiltered: Mantenimiento[] = [];
  filterFields: string[] = [
    "kilometraje_horometro_inicial",
    "kilometraje_horometro_programado",
    "kilometraje_horometro_final",
    "observaciones",
  ];
  tableDef: MatTableColumnConfig[] = [
    {
      name: "fecha_mantenimiento",
      label: "Fecha de mantenimiento",
      type: "date",
    },
    { name: "kilometraje_horometro_inicial", label: "Kilometraje inicial" },
    {
      name: "kilometraje_horometro_programado",
      label: "Kilometraje programado",
    },
    { name: "kilometraje_horometro_final", label: "Kilometraje final" },
    { name: "observaciones", label: "Observaciones" },
  ];
  actions: MatTableActionConfig[] = [
    {
      name: "view",
      label: "Ver evidencia",
      icon: "eye",
      action: (mantenimiento: Mantenimiento) => {
        this.mantenimientosService
          .downloadEvidencia(mantenimiento.id)
          .subscribe(
            (data) => {
              const url = window.URL.createObjectURL(data);
              window.open(url);
            },
            (error) => {
              if (error.status == 404) {
                this.toastrService.danger("No existe un evidencia", "Error", {
                  duration: 3000,
                });
              } else {
                this.toastrService.danger(error.message.message, "Error", {
                  duration: 3000,
                });
              }
            }
          );
      },
    },
    {
      name: "edit",
      label: "Editar",
      icon: "edit",
      action: (
        form: FormGroup,
        mantenimiento: Mantenimiento,
        ref: NbWindowRef
      ) => {
        this.mantenimientosService
          .update(form, mantenimiento.id)
          .subscribe(() => {
            this.toastrService.success("Mantenimiento actualizado", "Exito", {
              duration: 10000,
            });
            this.mantenimientosService
              .getByIdVehiculo(this.vehiculo.id)
              .subscribe((mantenimientos) => {
                this.mantenimientos = mantenimientos;
              });
            if (form.get("evidencia")?.value != null) {
              this.mantenimientosService
                .uploadEvidencia(mantenimiento.id, form.get("evidencia").value)
                .subscribe(
                  () => {
                    this.toastrService.success("Evidencia subida", "Exito", {
                      duration: 3000,
                    });
                  },
                  (error) => {
                    this.toastrService.danger(
                      "Error al subir evidencia",
                      "Error",
                      {
                        duration: 3000,
                      }
                    );
                  }
                );
            }
            ref.close();
          });
      },
      formConfig: (m: Mantenimiento) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              fecha_mantenimiento: {
                formControlName: "fecha_mantenimiento",
                label: "Fecha de mantenimiento",
                type: FormFieldType.Date,
                col: 12,
                validations: ["required"],
                value: m.fecha_mantenimiento,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              kilometraje_horometro_inicial: {
                formControlName: "kilometraje_horometro_inicial",
                label: "Kilometraje inicial",
                type: FormFieldType.Number,
                value: m.kilometraje_horometro_inicial,
                col: 4,
                validations: ["required"],
                disable: true,
              },
              kilometraje_horometro_final: {
                formControlName: "kilometraje_horometro_final",
                label: "Kilometro actual",
                disable: true,
                type: FormFieldType.Number,
                value: m.kilometraje_horometro_final,
                validations: ["required"],
                col: 4,
              },
              kilometraje_horometro_programado: {
                formControlName: "kilometraje_horometro_programado",
                label: "Matenimiento programado (km)",
                type: FormFieldType.Number,
                value: m.kilometraje_horometro_programado,
                validations: ["required"],
                col: 4,
                disable: true,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              observaciones: {
                formControlName: "observaciones",
                label: "Observaciones",
                type: FormFieldType.TextArea,
                col: 6,
                value: m.observaciones,
              },
              evidencia: {
                formControlName: "evidencia",
                label: "Evidencia",
                type: FormFieldType.File,
                col: 6,
                options: [
                  { key: OptionType.fileSelectTypes, value: "image/*" },
                ],
              },
            },
          },
        ];
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      icon: "trash",
      message: "¿Estas seguro de eliminar este mantenimiento?",
      action: (mantenimiento) => {
        this.mantenimientosService.delete(mantenimiento.id).subscribe(() => {
          this.toastrService.success("Mantenimiento eliminado", "Exito", {
            duration: 3000,
          });
          this.mantenimientosService
            .getByIdVehiculo(this.vehiculo.id)
            .subscribe((mantenimientos) => {
              this.mantenimientos = mantenimientos;
              this.mantenimientosFiltered = mantenimientos;
            });
        });
      },
    },
  ];

  constructor(
    private mantenimientosService: MantenimientosService,
    private windowsRef: NbWindowService,
    private toastrService: NbToastrService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vehiculo) {
      this.mantenimientosService
        .getByIdVehiculo(changes.vehiculo.currentValue.id)
        .subscribe((mantenimientos) => {
          this.mantenimientos = mantenimientos;
          this.mantenimientosFiltered = mantenimientos;
        });
    }
  }

  onClickOpenNuevoMantenimiento() {
    let mantenimientoFormDef: FormRowConfig[] = [
      {
        type: FormFieldType.Row,
        fields: {
          fecha_mantenimiento: {
            formControlName: "fecha_mantenimiento",
            label: "Fecha de mantenimiento",
            type: FormFieldType.Date,
            value: new Date(),
            col: 12,
            validations: ["required"],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          kilometraje_horometro_inicial: {
            formControlName: "kilometraje_horometro_inicial",
            label: "Horometro inicial",
            type: FormFieldType.Number,
            value: this.vehiculo.kilometraje_original_mantenimiento,
            col: 4,
            validations: ["required", { key: "min", value: 0 }],
            disable: true,
          },
          kilometraje_horometro_final: {
            formControlName: "kilometraje_horometro_final",
            label: "Horometro actual",
            disable: true,
            type: FormFieldType.Number,
            value: this.vehiculo.kilometraje,
            validations: ["required"],
            col: 4,
          },
          kilometraje_horometro_programado: {
            formControlName: "kilometraje_horometro_programado",
            label: "Matenimiento programado (hrs)",
            type: FormFieldType.Number,
            value:
              this.vehiculo.kilometraje_original_mantenimiento +
              this.vehiculo.kilometraje_proximo_mantenimiento,
            validations: ["required"],
            col: 4,
            disable: true,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          observaciones: {
            formControlName: "observaciones",
            label: "Observaciones",
            type: FormFieldType.TextArea,
            col: 6,
          },
          evidencia: {
            formControlName: "evidencia",
            label: "Evidencia",
            type: FormFieldType.File,
            col: 6,
            options: [{ key: OptionType.fileSelectTypes, value: "image/*" }],
          },
        },
      },
    ];
    this.mantenimientoRef = this.windowsRef.open(this.newMantenimientoForm, {
      title: "Nuevo mantenimiento",
      context: {
        vehiculo: this.vehiculo,
        formConfig: mantenimientoFormDef,
      },
    });
  }

  onCreateMantenimiento(form: FormGroup, v: Vehiculo) {
    form.addControl("id", new FormControl(v.id));
    form.addControl("tipo", new FormControl("VEHICULO"));
    this.mantenimientosService.create(form).subscribe(
      (m: Mantenimiento) => {
        this.toastrService.success("Mantenimiento creado", "Exito", {
          duration: 3000,
        });
        if (form.get("evidencia").value) {
          this.mantenimientosService
            .uploadEvidencia(m.id, form.get("evidencia").value)
            .subscribe(
              () => {
                this.toastrService.success("Evidencia subida", "Exito", {
                  duration: 3000,
                });
              },
              (error) => {
                this.toastrService.danger("Error al subir evidencia", "Error", {
                  duration: 3000,
                });
              }
            );
        }
        this.mantenimientoCreated.emit(true);
        this.mantenimientoRef.close();
        this.mantenimientosService
          .getByIdVehiculo(v.id)
          .subscribe((mantenimientos) => {
            this.mantenimientos = mantenimientos;
            this.mantenimientosFiltered = mantenimientos;
          });
      },
      (error) => {
        this.toastrService.danger("Error al crear mantenimiento", "Error", {
          duration: 3000,
        });
      }
    );
  }

  onFilterChange($event: any) {
    this.mantenimientosFiltered = $event;
  }

  onClickDownloadMantenimientos() {
    const nombre = `${this.vehiculo.serie}_${this.vehiculo.placas}_${this.vehiculo.anio}`;
    CSVGenerator.generateExcel(
      this.tableDef,
      this.mantenimientosFiltered,
      nombre
    );
  }
}
