<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>Licencias <button nbButton (click)="onNewDataClick()" nbTooltip="Nueva Licencia">
      <nb-icon  icon="plus"></nb-icon>
    </button>
    <button nbButton status="info" nbTooltip="Generar reporte"
        (click)="onClickGenerateReport()" class="btn btn-info btn-sm p-lg-1">
        <nb-icon icon="file-text-outline"></nb-icon>
      </button>
  </div>
    <div><input nbInput type="text" (keyup)="filter($event.target.value)" placeholder="Buscar licencia"></div>
  </nb-card-header>
  <nb-card-body>
    <ngx-mat-table-builder [columnsDef]="columns" [data]="filteredData" [actions]="acciones"></ngx-mat-table-builder>
  </nb-card-body>
</nb-card>

<ng-template #newDataFormView>
  <div class="responsive-div" >
    <ngx-form-builder [config]="formConfig" (formOuput)="onFormOutput($event)"></ngx-form-builder>
  </div>
</ng-template>
