<input
  [formControl]="inputFormControl"
  nbInput
  type="text"
  placeholder="Enter value"
  [nbAutocomplete]="auto"
/>

<nb-autocomplete #auto>
  <nb-option-group
    *ngFor="let group of filteredGroups$ | async; trackBy: trackByFn"
    [title]="group.name"
  >
    <nb-option *ngFor="let option of group.children" [value]="option.cuenta">
      {{ option.banco_nombre }} - {{ option.cuenta }}
    </nb-option>
  </nb-option-group>
</nb-autocomplete>
