<button

  nbTooltip="Eliminar"
  nbButton
  size="tiny"
  (click)="onClickOpenDelete()"
  status="danger"
>
<nb-icon icon="trash-2"></nb-icon>
</button>

<ng-template #openDelete let-data>
  <div>{{ message }}</div>
  <div style="display: flex; justify-content: space-between;">
    <button
      status="danger"
      (click)="onConfirmDelete()"
      nbButton
    >
      Eliminar
    </button>
    <button (click)="onCancelar()" nbButton>Cancelar</button>
  </div>
</ng-template>
