export interface Gasto {
  comentario: string;
  id: number;
  fecha_compra: string;
  importe: number;
  proveedor_id: number;
  obra_id: number;
  concepto: string;
  proveedor: string;
  obra: string;
  banco_id: number;
  banco: string;
  empresas_finiquito_id: number;
  empresa_finiquito: string;
}
export enum TipoGasto {
  FISCAL = "FISCAL",
  NO_FISCAL = "NO FISCAL",
}
export interface Proveedor {
  cuenta: string;
  id: number;
  nombre: string;
  banco: string;
}

export interface EntradaNoFiscal {
  cuenta: string;
  id: number;
  fecha_entrada: string;
  importe: number;
  cliente_id: number;
  obra_id: number;
  concepto: string;
  cliente_nombre: string;
  obra_nombre: string;
  tipo: string;
  tipo_pago: string;
  banco_id: number;
  banco_nombre: string;
  empresa_id: number;
  empresa_finiquita_nombre: string;
}

export enum TipoEntrada {
  FISCAL = "FISCAL",
  NO_FISCAL = "NO FISCAL",
}

export interface Banco {
  id: number;
  nombre: string;
}

export interface Corte{
  id: number;
  fecha: string;
  total_importes_gastos: number;
  total_importes_entradas: number;
  total: number;
  total_anterior: number;
  tipo: string;
}







