<div class="row">
    <div *ngFor="let card of cards" class="col-sm-12 col-md-6 col-lg-2">
      <nb-card>
        <nb-card-header>{{ card.title }}</nb-card-header>
        <nb-card-body>
          <div class="d-flex align-items-center">
            <nb-icon [icon]="card.icon" [status]="card.type" class="mr-3" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
            <h2>{{ card.value }}</h2>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
</div>
