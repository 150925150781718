import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Entrevista } from '../data/rh-data';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class EntrevistasService extends BaseService<Entrevista>{
  downloadDocumento(id: number):Observable<Blob> {
    return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?download_doc=true`, { responseType: 'blob' });
}

  protected getPath(): string {
    return 'entrevistas';
  }

  public create(formGroup: FormGroup): Observable<Entrevista> {
    const formData = new FormData();
    Object.keys(formGroup.controls).forEach(key => {
      if(formGroup.get(key).value instanceof File){
        const file = formGroup.get(key).value;
        formData.append(key, file, file.name);
      }else if(formGroup.get(key).value instanceof Date){
        console.log(formGroup.get(key).value,key);
        formData.append(key, formGroup.get(key).value.toISOString());
      }else{
        formData.append(key, formGroup.get(key).value);
      }

    });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<Entrevista>(`${this.baseUrl}${this.getPath()}`, formData,{headers: headers});
  }

  public updateDocumento(id:number,documento:File):Observable<Entrevista>{
    const formData = new FormData();
    formData.append('estudios_file',documento,documento.name);
    return this.http.post<Entrevista>(`${this.baseUrl}${this.getPath()}/${id}/actualizar-documento-estudios`,formData);
  }
}
