import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Banco, Gasto, Proveedor } from "../../@core/data/gastos-data";
import { GastoService } from "../../@core/services/gastos.service";
import {
  NbCalendarRange,
  NbToastrService,
  NbWindowRef,
  NbWindowService,
} from "@nebular/theme";
import {
  FormBuilderConfig,
  FormFieldType,
  OptionType,
} from "../../form-builder/form-builder.component";
import { FormControl, FormGroup } from "@angular/forms";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import { ObraService } from "../../@core/services/obra.service";
import { ProveedorService } from "../../@core/services/proveedor.service";
import { Obra } from "../../@core/data/maquinaria-data";
import { filterItems } from "../../@core/utils/filter-utils";
import { CSVGenerator } from "../../@core/utils/csv-generator";
import { error } from "console";
import { EmpresaFiniquito } from "../../@core/data/empresa-finiquito-data";
import { EmpresaFiniquitoService } from "../../@core/services/empresa-finiquito.service";
import { BancoService } from "../../@core/services/banco.service";

@Component({
  selector: "ngx-gastos",
  templateUrl: "./gastos.component.html",
  styleUrls: ["./gastos.component.scss"],
})
export class GastosComponent implements OnInit {
  filterTxt: string = "";
  filterDateTxt: any = "";
  eventDateFilter: any = null;
  public timeRange: NbCalendarRange<Date> | undefined;

  gastos: Gasto[] = [];
  gastosFiltrados: Gasto[] = [];
  @ViewChild("nuevoGastoWindow", { read: TemplateRef })
  nuevoGastoWindow: TemplateRef<HTMLElement>;
  nuevoGastoWindowRef: NbWindowRef;
  columns: MatTableColumnConfig[] = [
    {
      name: "fecha",
      label: "Fecha de Compra",
      type: "date",
      value: (gasto: Gasto) => gasto.fecha_compra,
    },
    {
      name: "proveedor",
      label: "Proveedor",
      value: (gasto: Gasto) => gasto.proveedor,
      type: "text",
    },
    {
      name: "concepto",
      label: "Concepto",
      value: (gasto: Gasto) => gasto.concepto,
      type: "text",
    },
    {
      name: "obra",
      label: "Obra",
      value: (gasto: Gasto) => gasto.obra,
      type: "text",
    },
    {
      name: "importe",
      label: "Importe",
      type: "number",
      value: (gasto: Gasto) => gasto.importe,
    },
    {
      name: "comentario",
      label: "Comentario",
      value: (gasto: Gasto) => gasto.comentario,
      type: "text",
    },
  ];

  acciones = [
    {
      name: "edit",
      label: "Editar",
      icon: "edit",
      formSize: "medium",
      action: (form: FormGroup, row: Gasto,ref) => {
        this.gastosService.update(form,row.id, ).subscribe(() => {
          ref.close();
          this.toastrService.show("Gasto editado", "Editar Gasto", {
            status: "success",
          });
          this.gastosService
            .getAllByAttributes([{ tipo: "NO FISCAL" }])
            .subscribe((gastos) => {
              this.gastos = gastos;
              this.gastosFiltrados = gastos;
            });
      },error=>{
        this.toastrService.show(error.error.message, "Editar Gasto", {
          status: "danger",
        });
      });
      },
      formConfig: (row: Gasto) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              fecha_compra: {
                type: FormFieldType.Date,
                validations: ["required"],
                col: 6,
                label: "Fecha de Compra",
                formControlName: "fecha_compra",
                value: row.fecha_compra,
                disable: true,
              },
              proveedor_id: {
                type: FormFieldType.Select,
                validations: ["required"],
                col: 6,
                label: "Proveedor",
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: this.proveedores.map((proveedor) => ({
                      key: proveedor.id,
                      value: proveedor.nombre,
                    })),
                  },
                ],
                formControlName: "proveedor_id",
                value: row.proveedor_id,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              importe: {
                type: FormFieldType.Number,
                validations: ["required", "number"],
                col: 6,
                label: "Importe",
                placeholder: "Importe del gasto",
                formControlName: "importe",
                value: row.importe,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              obra_id: {
                type: FormFieldType.Select,
                validations: ["required"],
                col: 6,
                label: "Obra",
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: this.obras.map((obra) => ({
                      key: obra.id,
                      value: obra.nombre,
                    })),
                  },
                ],
                formControlName: "obra_id",
                value: row.obra_id,
              },
              concepto: {
                type: FormFieldType.Input,
                validations: ["required"],
                col: 6,
                label: "Concepto",
                placeholder: "Concepto del gasto",
                formControlName: "concepto",
                value: row.concepto,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              comentario: {
                type: FormFieldType.Input,
                validations: ["required"],
                col: 12,
                label: "Comentario",
                placeholder: "Comentario del gasto",
                formControlName: "comentario",
                value: row.comentario,
              },
            },
          },
        ] as FormBuilderConfig;
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      icon: "trash",
      action: (gasto: Gasto) => this.onDeleteGasto(gasto),
      title: "Eliminar Gasto",
      message: "¿Estás seguro de que deseas eliminar este gasto?",
    },
  ] as MatTableActionConfig[];
  proveedores: Proveedor[];
  obras: Obra[];
  empresasFiniquito: EmpresaFiniquito[];
  bancos: Banco[];
  filtros: string[] = ["proveedor", "concepto", "obra", "importe", "monto"];

  constructor(
    private gastosService: GastoService,
    private windowsService: NbWindowService,
    private toastrService: NbToastrService,
    private proveedoresService: ProveedorService,
    private obrasService: ObraService,
    private bancosService: BancoService,
    private empresasFiniquitoService: EmpresaFiniquitoService
  ) {}

  ngOnInit(): void {
    this.gastosService
      .getAllByAttributes([{ tipo: "NO FISCAL" }])
      .subscribe((gastos) => {
        this.gastos = gastos;
        this.gastosFiltrados = gastos;
      });
    this.proveedoresService.getAll().subscribe((proveedores) => {
      this.proveedores = proveedores;
    });
    this.obrasService.getAll().subscribe((obras) => {
      this.obras = obras;
    });
    this.bancosService.getAll()
    .subscribe(bancos=>{
      this.bancos = bancos;
    });
    this.empresasFiniquitoService.getAll().subscribe(empresas=>{
      this.empresasFiniquito = empresas;
    });
  }
  onFilterChange(filteredItems: any) {
    this.gastosFiltrados = filteredItems;
  }
  downloadCsv() {
    CSVGenerator.generateExcel(
      this.columns,
      this.gastosFiltrados,
      "gastos-no-fiscales"
    );
  }

  getTotalImportes(): any {
    return this.gastosFiltrados.reduce(
      (total, gasto) => total + parseFloat(gasto.importe.toString()),
      0
    );
  }
  onNuevoClick() {
    const formConfig = [
      {
        type: FormFieldType.Row,
        fields: {
          fecha_compra: {
            type: FormFieldType.Date,
            validations: ["required"],
            col: 6,
            label: "Fecha de Compra",
            formControlName: "fecha_compra",
            value: new Date(),
            //disable: true,
          },
          proveedor_id: {
            type: FormFieldType.Select,
            validations: ["required"],
            col: 6,
            label: "Proveedor",
            placeholder: "Seleccione un proveedor",
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.proveedores.map((proveedor) => ({
                  key: proveedor.id,
                  value: proveedor.nombre,
                })),
              },
            ],
            formControlName: "proveedor_id",
            value: null,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          importe: {
            type: FormFieldType.Input,
            validations: ["required", "number"],
            col: 6,
            label: "Importe",
            placeholder: "Importe del gasto",
            formControlName: "importe",
            value: 0,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          obra_id: {
            type: FormFieldType.Select,
            validations: ["required"],
            col: 6,
            label: "Obra",
            placeholder: "Seleccione una obra",
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.obras.map((obra) => ({
                  key: obra.id,
                  value: obra.nombre,
                })),
              },
            ],
            formControlName: "obra_id",
            value: 0,
          },
          concepto: {
            type: FormFieldType.Input,
            validations: ["required"],
            col: 6,
            label: "Concepto",
            placeholder: "Concepto del gasto",
            formControlName: "concepto",
            value: "",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          comentario: {
            type: FormFieldType.Input,
            validations: ["required"],
            col: 12,
            label: "Comentario",
            placeholder: "Comentario del gasto",
            formControlName: "comentario",
            value: "",
          },
        },
      },

    ] as FormBuilderConfig;
    this.nuevoGastoWindowRef = this.windowsService.open(this.nuevoGastoWindow, {
      title: "Nuevo Gasto",
      context: { formConfig: formConfig },
    });
  }

  onGastoCreated(form: FormGroup) {
    let fechaCompra = form.get("fecha_compra").value;
    fechaCompra = new Date(fechaCompra);
    form.get("fecha_compra").setValue(fechaCompra);
    form.addControl("tipo", new FormControl("NO FISCAL"));
    this.gastosService.create(form,true).subscribe(() => {
      this.nuevoGastoWindowRef.close();
      this.toastrService.show("Gasto creado", "Nuevo Gasto", {
        status: "success",
      });
      this.gastosService.getAllByAttributes([{ tipo: "NO FISCAL" }])
      .subscribe((gastos) => {
        this.gastos = gastos;
        this.gastosFiltrados = gastos;
      });
    });
  }

  onEditGasto(gasto: Gasto) {
    // Lógica para editar el gasto
    this.nuevoGastoWindowRef = this.windowsService.open(this.nuevoGastoWindow, {
      title: "Editar Gasto",
    });
    // Cargar los datos del gasto en el formulario
  }

  onDeleteGasto(gasto: Gasto) {
    // Lógica para eliminar el gasto
    this.gastosService.delete(gasto.id).subscribe(() => {
      this.toastrService.show("Gasto eliminado", "Eliminar Gasto", {
        status: "success",
      });
      this.gastosService.getAllByAttributes([{ tipo: "NO FISCAL" }]).subscribe((gastos) => {
        this.gastos = gastos;
        this.gastosFiltrados = gastos;
      });
    });
  }
}
