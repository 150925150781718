import { ClienteService } from '../../@core/services/cliente.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Cliente } from '../../@core/data/cotizacion-data';


@Component({
  selector: 'ngx-nuevo-cliente-form',
  templateUrl: './nuevo-cliente-form.component.html',
  styleUrls: ['./nuevo-cliente-form.component.scss']
})
export class NuevoClienteFormComponent implements OnInit{
clienteForm: FormGroup;
@Input() cliente: Cliente;
@Output() clienteCreated = new EventEmitter<boolean>();
constructor(private clienteSerivce:ClienteService) { }
ngOnInit(): void {
  this.clienteForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    direccion: new FormControl('', Validators.required),
    num_tel: new FormControl('', Validators.required),
    representante: new FormControl(''),
    testigo: new FormControl(''),
    lugar_uso_maq: new FormControl(''),
    rep_clave_elect: new FormControl(''),
    rep_direccion: new FormControl(''),
    correo: new FormControl('', Validators.email)

  });
  if(this.cliente!==undefined&&this.cliente!==null) {
    this.clienteForm.patchValue({
      nombre: this.cliente.nombre,
      direccion: this.cliente.direccion,
      representante: this.cliente.representante,
      testigo: this.cliente.testigo,
      lugar_uso_maq: this.cliente.lugar_uso_maq,
      rep_clave_elect: this.cliente.rep_clave_elect,
      rep_direccion: this.cliente.rep_direccion,
      num_tel: this.cliente.num_tel,
      correo: this.cliente.correo
    });
  }
}
onSubmit() {
  if(this.cliente!==undefined&&this.cliente!==null) {
    this.clienteSerivce.update(this.clienteForm, this.cliente.id)
    .subscribe(() => {
      this.clienteCreated.emit(true);
    },
    error => {
      console.error(error);
    });
  }
  else {
    this.clienteSerivce.create(this.clienteForm)
    .subscribe(() => {
      this.clienteCreated.emit(true);
    },
    error => {
      console.error(error);
    });
  }
}

}
