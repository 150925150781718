import { Injectable } from '@angular/core';
import { Finiquito } from '../data/finiquito-data';
import { BaseService } from './base-service';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class FiniquitoService extends BaseService<Finiquito>{
  updateEvidencia(formData: FormData, id: number) {
    return this.http.post(`${this.baseUrl}${this.getPath()}/${id}/update-evidencia`, formData);
  }
  getEvidencia(id: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?evidencia=true`, { responseType: 'blob' });
  }
  getPath(): string {
    return 'finiquitos';
  }

  async getAllPromise(): Promise<Finiquito[]> {
    return this.http.get<Finiquito[]>(`${this.baseUrl}${this.getPath()}`).toPromise();
  }

  public create(formGroup: FormGroup): Observable<Finiquito> {
    console.log(formGroup);
    const formData = new FormData();
    Object.keys(formGroup.controls).forEach(key => {
      if(formGroup.get(key).value instanceof File){
        const file = formGroup.get(key).value;
        formData.append(key, file, file.name);
      }else if(formGroup.get(key).value instanceof Date){
        formData.append(key, formGroup.get(key).value.toISOString());

      }else{
        formData.append(key, formGroup.get(key).value);
      }

    });
    const headers = new HttpHeaders();
    headers.append('Content-Type','multipart/form-data');
    headers.append('Accept','application/json');
    return this.http.post<Finiquito>(`${this.baseUrl}${this.getPath()}`,formData,{headers: headers});
  }
}
