import { Component, TemplateRef, ViewChild } from "@angular/core";
import { Banco, EntradaNoFiscal, TipoEntrada } from "../../@core/data/gastos-data";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import { EntradasService } from "../../@core/services/entradas.service";
import {
  FormFieldConfig,
  FormFieldType,
  FormRowConfig,
  OptionType,
} from "../../form-builder/form-builder.component";

import { NbToastrService, NbWindowRef, NbWindowService } from "@nebular/theme";
import { FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { Cliente } from "../../@core/data/cotizacion-data";
import { ClienteService } from "../../@core/services/cliente.service";
import { BancoService } from "../../@core/services/banco.service";
import { CSVGenerator } from "../../@core/utils/csv-generator";
@Component({
  selector: "ngx-entradas-no-fiscales",
  templateUrl: "./entradas-no-fiscales.component.html",
  styleUrls: ["./entradas-no-fiscales.component.scss"],
})
export class EntradasNoFiscalesComponent {
filterFields:string[]=['proveedor_nombre','concepto','importe','monto','tipo_pago','banco_nombre','cuenta'];
  constructor(private entradasService: EntradasService, private clienteService: ClienteService,
    private windowService: NbWindowService, private toastrService: NbToastrService, private bancoService: BancoService
  ) {
    this.entradasService.getAllByAttributes([{tipo: TipoEntrada.NO_FISCAL}]).subscribe((data) => {
      this.data = data;
      this.filteredData = data;
    }, (error) => {
      this.toastrService.show(error.error.message, "Error", { status: "danger" });
    });
    this.clienteService.getAllBy([{tipo:'PROVEEDOR'}]).subscribe((data) => {
      this.clientes = data;
    }, (error) => {
      this.toastrService.show(error.error.message, "Error", { status: "danger" });
    });
    this.bancoService.getAll().subscribe((data) => {
      this.bancos = data;
    }, (error) => {
      this.toastrService.show(error.error.message, "Error", { status: "danger" });
    });
  }
  bancos: Banco[] = [];
  data: EntradaNoFiscal[] = [];
  filteredData: EntradaNoFiscal[] = [];
  @ViewChild("nuevaEntradaWindow", { read: TemplateRef })
  nuevaEntradaWindow: TemplateRef<HTMLElement>;
  nuevaEntradaWindowRef: NbWindowRef;
  columns: MatTableColumnConfig[] = [
    {
      name: "fecha_entrada",
      label: "Fecha entrada",
      type: "date",
      value: (row: EntradaNoFiscal) =>new Date(row.fecha_entrada),
    },
    {
      name: "cliente_nombre",
      label: "Cliente",
      type: "text",
    },
    {
      name: "concepto",
      label: "Concepto",
      type: "text",
    },
    {
      name: "importe",
      label: "Importe",
      type: "number",
    },
    {
      name: "tipo",
      label: "Tipo",
      type: "text",
    },
    {
      name: "banco_nombre",
      label: "Banco",
      type: "text",
    },
    {
      name: "cuenta",
      label: "Cuenta",
      type: "text",
    }
  ] as MatTableColumnConfig[];
  acciones: MatTableActionConfig[] = [
    {
      name: "edit",
      icon: "edit-2-outline",
      label: "Editar",
      action: (form: FormGroup,row: EntradaNoFiscal,ref) => {
        this.entradasService.update(form,row.id).subscribe((data) => {
          this.entradasService.getAllByAttributes([{tipo: TipoEntrada.NO_FISCAL}]).subscribe((data) => {
            this.data = data;
            this.filteredData = data;
            this.toastrService.show("Entrada actualizada", "Actualizado", { status: "success" });
          }, (error) => {

            this.toastrService.show(error.error, "Error", { status: "danger" });
          });
        }, (error) => {
          console.log(error.error.message);
          this.toastrService.show(error.error.message, "Error", { status: "danger" });
        });
        if(form.get('comprobante')?.value){
          this.entradasService.uploadComprobante(row.id,form.get('comprobante')?.value).subscribe((data) => {
            this.entradasService.getAllByAttributes([{tipo: TipoEntrada.NO_FISCAL}]).subscribe((data) => {
              this.data = data;
              this.filteredData = data;
              this.toastrService.show("Comprobante actualizado", "Actualizado", { status: "success" });
            }, (error) => {
              this.toastrService.show(error.error.message, "Error", { status: "danger" });
            });
          }, (error) => {
            this.toastrService.show(error.error.message, "Error", { status: "danger" });
          });
          ref.close();
        }else{
          ref.close();
        }
      },
      formConfig: (row: EntradaNoFiscal) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              fecha_entrada: {
                type: FormFieldType.Date,
                formControlName: "fecha_entrada",
                label: "Fecha entrada",
                value: row.fecha_entrada,
                col: 6,
                validations: ["required"],
                placeholder: "Fecha entrada",
                disabled: true,
              },
              cliente_id: {
                type: FormFieldType.Select,
                formControlName: "cliente_id",
                label: "Cliente",
                value: row.cliente_id,
                col: 6,
                validations: ["required"],
                placeholder: "Cliente",
                options: [{
                  key: OptionType.SelectOptions,
                  value: this.clientes.map((cliente) => ({
                    key: cliente.id,
                    value: cliente.nombre,
                  })),
                }],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              concepto: {
                type: FormFieldType.Input,
                formControlName: "concepto",
                label: "Concepto",
                value: row.concepto,
                col: 6,
                validations: ["required"],
                placeholder: "Concepto",
              },
              tipo_pago: {
                type: FormFieldType.Select,
                formControlName: "tipo_pago",
                label: "Tipo de pago",
                value: row.tipo_pago,
                col: 6,
                validations: ["required"],
                placeholder: "Tipo de pago",
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: [
                      { key: "EFECTIVO", value: "Efectivo" },
                      { key: "CHEQUE", value: "Cheque" },
                      { key: "DEPOSITO", value: "Depósito" },
                      { key: "TRANSFERENCIA", value: "Transferencia" },
                    ],
                  },
                ],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {

              importe: {
                type: FormFieldType.Input,
                formControlName: "importe",
                label: "Importe",
                value: row.importe,
                col: 6,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              banco_id: {
                type: FormFieldType.Select,
                formControlName: "banco_id",
                label: "Banco",
                col: 6,
                placeholder: "Banco",
                value: row.banco_id,
                options: [{
                  key: OptionType.SelectOptions,
                  value: this.bancos.map((banco) => ({
                    key: banco.id,
                    value: banco.nombre,
                  })),
                }],
              },
              cuenta: {
                type: FormFieldType.Input,
                formControlName: "cuenta",
                label: "Cuenta",
                col: 6,
                placeholder: "Cuenta",
                value: row.cuenta,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              comprobante:{
                type: FormFieldType.File,
                formControlName: "comprobante",
                label: "Comprobante",
                col: 6,
                placeholder: "Comprobante",
                value: null,
                options:[
                  {
                    key: OptionType.fileSelectTypes,
                    value: ['image/*']
                  }
                ]
              }
            },
          }
        ] as FormRowConfig[];
      }
    },
    {
      name: "delete",
      icon: "trash",
      label: "Eliminar",
      message: "¿Estás seguro de que deseas eliminar esta entrada?",
      title: "Eliminar entrada",
      action: (row: EntradaNoFiscal) => {
        this.entradasService.delete(row.id).subscribe((data) => {
          this.entradasService.getAll().subscribe((data) => {
            this.data = data;
            this.filteredData = data;
          });
        }, (error) => {
          this.toastrService.show(error.error.message, "Error", { status: "danger" });
        });
      }
    }
  ];
  clientes:  Cliente[] = [];
  downloadCsv() {
    CSVGenerator.generateExcel(this.columns, this.filteredData, "entradas_no_fiscales");
  }
  onNuevoClick() {
    const config = [
      {
        type: FormFieldType.Row,
        fields: {
          fecha_entrada: {
            type: FormFieldType.Date,
            formControlName: "fecha_entrada",
            label: "Fecha entrada",
            col: 6,
            validations: ["required"],
            placeholder: "Fecha entrada",
            value: new Date(),
            //disable: true,
          },
          importe: {
            type: FormFieldType.Number,
            formControlName: "importe",
            label: "Importe",
            col: 6,
            validations: ["required"],
            placeholder: "Importe",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          concepto: {
            type: FormFieldType.Input,
            formControlName: "concepto",
            label: "Concepto",
            col: 6,
            validations: ["required"],
            placeholder: "Concepto",
          },
          tipo_pago: {
            type: FormFieldType.Select,
            formControlName: "tipo_pago",
            label: "Tipo de pago",
            col: 6,
            validations: ["required"],
            placeholder: "Tipo de pago",
            options: [
              {
                key: OptionType.SelectOptions,
                value: [
                  { key: "EFECTIVO", value: "Efectivo" },
                  { key: "CHEQUE", value: "Cheque" },
                  { key: "DEPOSITO", value: "Deposito" },
                  { key: "TRANSFERENCIA", value: "Transferencia" },
                ],
              },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          cliente_id: {
            type: FormFieldType.Select,
            formControlName: "cliente_id",
            label: "Cliente",
            value: null,
            col: 12,
            validations: ["required"],
            placeholder: "Cliente",
            options: [{
              key: OptionType.SelectOptions,
              value: this.clientes.map((proveedor) => ({
                key: proveedor.id,
                value: proveedor.nombre,
              })),
            }],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          banco_id: {
            type: FormFieldType.Select,
            formControlName: "banco_id",
            label: "Banco",
            value: null,
            col: 6,
            placeholder: "Banco",
            options: [{
              key: OptionType.SelectOptions,
              value: this.bancos.map((banco) => ({
                key: banco.id,
                value: banco.nombre,
              })),
            }],
          },
          cuenta: {
            type: FormFieldType.Input,
            formControlName: "cuenta",
            label: "Cuenta",
            col: 6,
            placeholder: "Cuenta",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          comprobante:{
            type: FormFieldType.File,
            formControlName: "comprobante",
            label: "Comprobante",
            col: 6,
            placeholder: "Comprobante",
            value: null,
            options:[
              {
                key: OptionType.fileSelectTypes,
                value: ['image/*']
              }
            ]
          }
        },
      }
    ] as FormRowConfig[];
    this.nuevaEntradaWindowRef = this.windowService.open(
      this.nuevaEntradaWindow,
      { title: "Nueva entrada", context : {config : config}}
    );
  }
  onFilterChange($event: any) {
    this.filteredData = $event;
  }

  getTotalImportes(): any {
    return this.filteredData.reduce(
      (total, entrada) => total + parseFloat(entrada.importe.toString()),
      0
    );
  }
  onEntradaCreated(form: FormGroup):any {
    form.addControl("tipo", new FormControl(TipoEntrada.NO_FISCAL));
    this.entradasService.create(form,true).subscribe((data) => {
      this.entradasService.getAllByAttributes([{tipo: TipoEntrada.NO_FISCAL}]).subscribe((data) => {
        this.data = data;
        this.filteredData = data;
        this.nuevaEntradaWindowRef.close();
        this.toastrService.show("Entrada creada", "Creada", { status: "success" });
      }, (error) => {
        this.toastrService.show(error.error.message, "Error", { status: "danger" });
      });
    }, (error) => {
      this.toastrService.show(error.error.message, "Error", { status: "danger" });
    });
  }
}
