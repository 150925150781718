<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Maquinaria
      <button nbButton status="success" nbTooltip="Agregar maquinaria" [nbSpinner]="loadingNewButton" [disabled]="loadingNewButton"
              (click)="onClickOpenNuevaMaquinaria()" class="btn btn-success btn-sm p-lg-1">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
      <button nbButton status="info" nbTooltip="Generar reporte"
        (click)="onClickGenerateReport()" class="btn btn-info btn-sm p-lg-1">
        <nb-icon icon="file-text-outline"></nb-icon>
      </button>
    </div>

    <div>
      <input nbInput class="search-input" placeholder="Nombre, numero economico,serie o modelo"
      [(ngModel)]="searchText" (input)="onMaquinariaSearch()">
    </div>
  </nb-card-header>
  <nb-card-body>
    <ngx-mat-table-builder [columnsDef]="tableDef" [data]="maquinariaFiltered" [actions]="actions" ></ngx-mat-table-builder>
  </nb-card-body>
</nb-card>

<ng-template class="modal" style="width: 90%;" let-data #nuevaMaquinaria>
  <div class="modal-body responsive-div-md">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onMaquinariaCreated($event)"></ngx-form-builder>
  </div>
</ng-template>

<ng-template class="modal" style="width: 90%;" let-data #mantenimientos>
  <div class="modal-body responsive-div-md" style="min-width:200px; max-height: 400px;">
    <ngx-maquinaria-mantenimientos-table [maquinaria]="data.maquinaria" (mantenimientoCreated)="onMantenimientoCreated($event)"></ngx-maquinaria-mantenimientos-table>
  </div>
</ng-template>
