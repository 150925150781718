import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NuevoEmpleadoFormComponent } from './nuevo-empleado-form/nuevo-empleado-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbInputModule, NbRadioGroupComponent, NbRadioModule } from '@nebular/theme';
import { EmpleadoService } from '../../../@core/services/empleado.service';



@NgModule({
  declarations: [
    NuevoEmpleadoFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbInputModule,
    NbButtonModule,
    NbRadioModule,
  ],
  exports: [
    NuevoEmpleadoFormComponent
  ],
  providers: [
    EmpleadoService
  ]
})
export class EmpleadosFormsModule { }
