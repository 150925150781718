import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiniquitosComponent } from './finiquitos.component';
import { FiniquitosTableComponent } from './finiquitos-table/finiquitos-table.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbSpinnerModule, NbTooltipModule } from '@nebular/theme';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { FiniquitoService } from '../../@core/services/finiquito.service';

@NgModule({
  declarations: [
    FiniquitosComponent,
    FiniquitosTableComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    MatTableBuilderModule,
    FormBuilderModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbTooltipModule,
    NbSpinnerModule
  ],
  providers: [
    FiniquitoService
  ]
})
export class FiniquitosModule { }
