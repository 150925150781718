import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpleadosMatTableComponent } from './empleados-mat-table/empleados-mat-table.component';
import { EmpleadosFormsModule } from '../empleados-forms/empleados-forms.module';
import { NbIconModule } from '@nebular/theme';



@NgModule({
  declarations: [
    EmpleadosMatTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    EmpleadosFormsModule,
    NbIconModule,
  ],
  exports: [
    EmpleadosMatTableComponent
  ]
})
export class EmpleadosTablasModule { }
