<form [formGroup]="editarExpedienteForm" (ngSubmit)="onEditarExpedienteSubmit()" class="step-container">
  <div class="row">
    <div class="form-group col-sm-6">
      <label for="contrato">Contrato*</label>
      <input nbInput type="text" placeholder="Contrato" class="form-control" formControlName="contrato" />
    </div>
    <div class="form-group col-sm-6">
      <label for="contrato">Descripcion*</label>
      <textarea nbInput type="text" placeholder="Descripción" class="form-control" formControlName="descripcion"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-6">
      <label for="entrega">Quien entrega*</label>
      <input nbInput type="text" placeholder="Entrega" class="form-control" formControlName="entrega" />
    </div>
    <div class="form-group col-sm-6">
      <label for="recibe">Quien recibe*</label>
      <input nbInput type="text" placeholder="Recibe" class="form-control" formControlName="recibe"/>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-6">
      <label for="cargo_entrega">Cargo de quien entrega*</label>
      <input nbInput type="text" placeholder="Cargo de quien entrega" class="form-control"
        formControlName="cargo_entrega"/>
    </div>
    <div class="form-group col-sm-6">
      <label for="cargo_recibe">Cargo de quien recibe *</label>
      <input nbInput type="text" placeholder="Cargo de quien recibe" class="form-control"
        formControlName="cargo_recibe"/>
    </div>
  </div>
  <button type="submit" nbButton>Guardar</button>
</form>
