import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientesComponent } from './clientes.component';
import { TablasClientesModule } from './tablas-clientes/tablas-clientes.module';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbTooltipModule } from '@nebular/theme';
import { ClienteService } from '../../@core/services/cliente.service';
import { GCMARQFormsModule } from '../../forms/gcmarqforms.module';




@NgModule({
  declarations: [
    ClientesComponent
  ],
  imports: [
    CommonModule,
    TablasClientesModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
    GCMARQFormsModule
],
  providers: [
    ClienteService
  ],
})
export class ClientesModule { }
