import { Version } from "./../../@core/data/version-data";
import {
  FormFieldConfig,
  FormFieldType,
  FormRowConfig,
} from "./../../form-builder/form-builder.component";
import { Component, TemplateRef, ViewChild } from "@angular/core";
import { Seguro } from "../../@core/data/vehiculo-data";
import { Categoria, Obra } from "../../@core/data/maquinaria-data";
import { Marca } from "../../@core/data/marca-data";
import { EmpleadoCategoria } from "../../@core/data/cotizacion-data";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import { MarcaService } from "../../@core/services/marca.service";
import { FormGroup } from "@angular/forms";
import { ObraService } from "../../@core/services/obra.service";
import { SeguroService } from "../../@core/services/seguro.service";
import { VersionService } from "../../@core/services/version.service";
import { EmpleadoCategoriasService } from "../../@core/services/empleado-categorias.service";
import { NbToastrService, NbWindowService } from "@nebular/theme";
import { error } from "console";
import { CategoriaService } from "../../@core/services/categoria.service";
import { EmpresaFiniquitoService } from "../../@core/services/empresa-finiquito.service";
import { EmpresaFiniquito } from "../../@core/data/empresa-finiquito-data";
import { on } from "events";
import { ProveedorService } from "../../@core/services/proveedor.service";
import { Proveedor } from "../../@core/data/gastos-data";
import { forkJoin } from "rxjs";

@Component({
  selector: "ngx-configuraciones",
  templateUrl: "./configuraciones.component.html",
  styleUrls: ["./configuraciones.component.scss"],
})
export class ConfiguracionesComponent {
  @ViewChild("createForm", { read: TemplateRef })
  createForm: TemplateRef<HTMLElement>;
  createRef: any;

  marcasConfig: ConfigCrudDef = {
    data: [] as Marca[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        action: (form: FormGroup, marca: Marca, ref) => {
          this.marcasService.update(form, marca.id).subscribe((data) => {
            this.marcasService.getAll().subscribe((data) => {
              this.marcasConfig.data = data;
            });
          });
          ref.close();
        },
        formConfig: (marca: Marca) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: marca.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
          ] as FormRowConfig[];
        },
      },
      {
        name: "delete",
        label: "Eliminar",
        icon: "trash",
        action: ( marca: Marca) => {
          this.marcasService.delete(marca.id).subscribe(
            (data) => {
              this.marcasConfig.loadData();
            },
            (error) => {
              this.toastrService.warning(
                `Error: ${error.error.message}`,
                "Error",
                { duration: 10000 }
              );
            }
          );
        },
        message: "¿Está seguro que desea eliminar esta marca?",
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
        this.marcasService.create(form).subscribe((data) => {
          this.marcasConfig.loadData();
          this.createRef.close();
        });
      },
    },
    loadData: () => {
      return this.marcasService.getAll().subscribe((data) => {
        this.marcasConfig.data = data;
      });
    },
  };

  aseguradorasConfig: ConfigCrudDef = {
    data: [] as Seguro[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        action: (form: FormGroup, seguro: Seguro, ref) => {
          this.segurosService.update(form, seguro.id).subscribe((data) => {
            this.segurosService.getAll().subscribe(
              (data) => {
                this.aseguradorasConfig.data = data;
                ref.close();
              },
              (error) => {
                this.toastrService.warning(
                  `Error: ${error.error.message}`,
                  "Error",
                  { duration: 10000 }
                );
              }
            );
          });
        },
        formConfig: (seguro: Seguro) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: seguro.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
          ] as FormRowConfig[];
        },
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
        this.segurosService.create(form).subscribe((data) => {
          this.aseguradorasConfig.loadData();
          this.createRef.close();
        });
      },
    },
    loadData: () => {
      this.segurosService.getAll().subscribe((data) => {
        this.aseguradorasConfig.data = data;
      });
    },
  };

  empleadocategoriasConfig: ConfigCrudDef = {
    data: [] as EmpleadoCategoria[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        title: "Editar categoría de empleado",
        action: (form: FormGroup, categoria: EmpleadoCategoria, ref) => {
          this.empleadocategoriasService.update(form, categoria.id).subscribe(
            (data) => {
              this.empleadocategoriasService.getAll().subscribe((data) => {
                this.empleadocategoriasConfig.data = data;
                ref.close();
              });
            },
            (error) => {
              this.toastrService.warning(
                `Error: ${error.error.message}`,
                "Error",
                { duration: 10000 }
              );
            }
          );
        },
        formConfig: (categoria: EmpleadoCategoria) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: categoria.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
          ] as FormRowConfig[];
        },
      },
      {
        name: "delete",
        label: "Eliminar",
        icon: "trash",
        action: (categoria: EmpleadoCategoria) => {
          this.empleadocategoriasService.delete(categoria.id).subscribe(
            (data) => {
              this.empleadocategoriasConfig.loadData();
            },
            (error) => {
              this.toastrService.warning(
                `Error: ${error.error.message}`,
                "Error",
                { duration: 10000 }
              );
            }
          );
        },
        message: "¿Está seguro que desea eliminar esta categoría de empleado",
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
        this.empleadocategoriasService.create(form).subscribe((data) => {
          this.empleadocategoriasConfig.loadData();
          this.createRef.close();
        });
      },
    },
    loadData: () => {
      this.empleadocategoriasService.getAll().subscribe((data) => {
        this.empleadocategoriasConfig.data = data;
      });
    },
  };

  segurosConfig: ConfigCrudDef = {
    data: [] as Seguro[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        action: (form: FormGroup, seguro: Seguro, ref) => {
          this.segurosService.update(form, seguro.id).subscribe((data) => {
            this.segurosService.getAll().subscribe((data) => {
              this.segurosConfig.data = data;
            });
          });
          ref.close();
        },
        formConfig: (seguro: Seguro) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: seguro.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
          ] as FormRowConfig[];
        },
      },
      {
        name: "delete",
        label: "Eliminar",
        icon: "trash",
        action: (seguro: Seguro) => {
          this.segurosService.delete(seguro.id).subscribe((data) => {
            this.segurosConfig.loadData();
          });
        },
        message: "¿Está seguro que desea eliminar este seguro?",
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
        this.segurosService.create(form).subscribe((data) => {
          this.segurosConfig.loadData();
          this.createRef.close();
        });
      },
    },
    loadData: () => {
      this.segurosService.getAll().subscribe((data) => {
        this.segurosConfig.data = data;
      });
    },
  };

  versionesConfig: ConfigCrudDef = {
    data: [] as Version[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        action: (form: FormGroup, version: Version, ref) => {
          this.versionService.update(form, version.id).subscribe((data) => {
            this.versionService.getAll().subscribe((data) => {
              this.versionesConfig.data = data;
            });
          });
          ref.close();
        },
        formConfig: (version: Version) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: version.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
          ] as FormRowConfig[];
        },
      },
      {
        name: "delete",
        label: "Eliminar",
        icon: "trash",
        action: (version: Version) => {
          this.versionService.delete(version.id).subscribe((data) => {
            this.versionesConfig.loadData();
          });
        },
        message: "¿Está seguro que desea eliminar esta versión vehicular?",
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
        this.versionService.create(form).subscribe((data) => {
          this.versionesConfig.loadData();
          this.createRef.close();
        });
      },
    },
    loadData: () => {
      this.versionService.getAll().subscribe((data) => {
        this.versionesConfig.data = data;
      });
    },
  };

  ubicacionesConfig: ConfigCrudDef = {
    data: [] as Obra[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        action: (form: FormGroup, obra: Obra, ref) => {
          this.obrasService.update(form, obra.id).subscribe((data) => {
            this.obrasService.getAll().subscribe((data) => {
              this.ubicacionesConfig.data = data;
            });
          });
          ref.close();
        },
        formConfig: (obra: Obra) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: obra.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
          ] as FormRowConfig[];
        },
      },
      {
        name: "delete",
        label: "Eliminar",
        icon: "trash",
        action: (obra: Obra) => {
          this.obrasService.delete(obra.id).subscribe((data) => {
            this.ubicacionesConfig.loadData();
          });
        },
        message: "¿Está seguro que desea eliminar esta obra?",
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
        this.obrasService.create(form).subscribe((data) => {
          this.ubicacionesConfig.loadData();
          this.createRef.close();
        });
      },
    },
    loadData: () => {
      this.obrasService.getAll().subscribe((data) => {
        this.ubicacionesConfig.data = data;
      });
    },
  };

  maquinariaCategoriasConfig: ConfigCrudDef = {
    data: [] as Categoria[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        action: (form: FormGroup, categoria: Categoria, ref) => {
          this.categoriaService.update(form, categoria.id).subscribe((data) => {
            this.categoriaService.getAll().subscribe((data) => {
              this.maquinariaCategoriasConfig.data = data;
              ref.close();
            });
          });
        },
        formConfig: (categoria: Categoria) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: categoria.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
          ] as FormRowConfig[];
        },
      },
      {
        name: "delete",
        label: "Eliminar",
        icon: "trash",
        action: (categoria: Categoria) => {
          this.categoriaService.delete(categoria.id).subscribe((data) => {
            this.categoriaService.getAll().subscribe((data) => {
              this.maquinariaCategoriasConfig.data = data;
            });
          });
        },
        message:
          "¿Está seguro que desea eliminar esta categoría de maquinaria?",
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
        this.categoriaService.create(form).subscribe((data) => {
          this.maquinariaCategoriasConfig.loadData();
          this.createRef.close();
        });
      },
    },
    loadData: () => {
      this.categoriaService.getAll().subscribe((data) => {
        this.maquinariaCategoriasConfig.data = data;
      });
    },
  };

  empresasFiniquitoConfig = {
    data: [] as any[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        action: (form: FormGroup, empresa: any, ref) => {
          this.empresaFiniquitaService.update(form, empresa.id).subscribe(
            (data) => {
              this.empresaFiniquitaService.getAll().subscribe((data) => {
                this.empresasFiniquitoConfig.data = data;
                ref.close();
              });
            },
            (error) => {
              this.toastrService.warning(
                `Error: ${error.error.message}`,
                "Error",
                { duration: 10000 }
              );
            }
          );
        },
        formConfig: (empresa: any) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: empresa.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
          ] as FormRowConfig[];
        },
      },
      {
        name: "delete",
        label: "Eliminar",
        icon: "trash",
        action: (empresa: EmpresaFiniquito) => {
          this.empresaFiniquitaService.delete(empresa.id).subscribe(
            (data) => {
              this.empresasFiniquitoConfig.loadData();
            },
            (error) => {
              this.toastrService.warning(
                `Error: ${error.error.message}`,
                "Error",
                { duration: 10000 }
              );
            }
          );
        },
        message:
          "¿Está seguro que desea eliminar esta categoría de maquinaria?",
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
      this.empresaFiniquitaService.create(form).subscribe((data) => {
        this.empresasFiniquitoConfig.loadData();
        this.createRef.close();
      });
    }
    },
    loadData: () => {
      this.empresaFiniquitaService.getAll().subscribe((data) => {
        this.empresasFiniquitoConfig.data = data;
      });
    },
  } as ConfigCrudDef;

  proveedoresConfig: ConfigCrudDef = {
    data: [] as Proveedor[],
    tbDef: [
      { name: "id", label: "ID", type: "string" },
      { name: "nombre", label: "Nombre", type: "string" },
      { name: "banco", label: "Banco", type: "string" },
      { name: "cuenta", label: "Cuenta", type: "string" },
    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: "edit",
        label: "Editar",
        icon: "edit",
        action: (form: FormGroup, proveedor: Proveedor, ref) => {
          this.proveedorService.update(form, proveedor.id).subscribe((data) => {
            this.proveedorService.getAll().subscribe((data) => {
              this.proveedoresConfig.data = data;
              ref.close();
            });
          });
        },
        formConfig: (proveedor: Proveedor) => {
          return [
            {
              type: FormFieldType.Row,
              fields: {
                nombre: {
                  type: FormFieldType.Input,
                  label: "Nombre",
                  value: proveedor.nombre,
                  col: 12,
                  formControlName: "nombre",
                  validations: ["required"],
                  disable: false,
                },
                banco: {
                  type: FormFieldType.Input,
                  label: "Banco",
                  value: proveedor.banco,
                  col: 12,
                  formControlName: "banco",
                  validations: ["required"],
                  disable: false,
                },
              },
            },
            {
              type: FormFieldType.Row,
              fields: {
                cuenta: {
                  type: FormFieldType.Input,
                  label: "Cuenta",
                  value: proveedor.cuenta,
                  col: 12,
                  formControlName: "cuenta",
                  validations: ["required"],
                  disable: false,
                },
              },
            }
          ] as FormRowConfig[];
        },
      },
      {
        name: "delete",
        label: "Eliminar",
        icon: "trash",
        action: (proveedor: Proveedor) => {
          this.proveedorService.delete(proveedor.id).subscribe((data) => {
            this.proveedoresConfig.loadData();
          });
        },
        message: "¿Está seguro que desea eliminar este proveedor?",
      },
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [
        {
          type: FormFieldType.Row,
          fields: {
            nombre: {
              type: FormFieldType.Input,
              label: "Nombre",
              value: "",
              col: 12,
              formControlName: "nombre",
              validations: ["required"],
              disable: false,
            },
            banco: {
              type: FormFieldType.Input,
              label: "Banco",
              value: "",
              col: 12,
              formControlName: "banco",
              validations: ["required"],
              disable: false,
            },
          },
        },
      ] as FormRowConfig[],
      onSubmit: (form: FormGroup) => {
        this.proveedorService.create(form).subscribe((data) => {
          this.proveedoresConfig.loadData();
          this.createRef.close();
        });
      },
    },
    loadData: () => {
      this.proveedorService.getAll().subscribe((data) => {
        this.proveedoresConfig.data = data;
      });
    },
  };

  constructor(
    private marcasService: MarcaService,
    private obrasService: ObraService,
    private segurosService: SeguroService,
    private versionService: VersionService,
    private empleadocategoriasService: EmpleadoCategoriasService,
    private toastrService: NbToastrService,
    private windowService: NbWindowService,
    private categoriaService: CategoriaService,
    private empresaFiniquitaService: EmpresaFiniquitoService,
    private proveedorService: ProveedorService
  ) {
    const cachedMarcas = localStorage.getItem('marcas');
    const cachedObras = localStorage.getItem('obras');
    const cachedSeguros = localStorage.getItem('seguros');
    const cachedVersiones = localStorage.getItem('versiones');
    const cachedCategorias = localStorage.getItem('categorias');
    const cachedEmpresasFiniquito = localStorage.getItem('empresasFiniquito');
    const cachedProveedores = localStorage.getItem('proveedores');
    const cachedEmpleadoCategorias = localStorage.getItem('empleadoCategorias');

    if (cachedMarcas) {
      this.marcasConfig.data = JSON.parse(cachedMarcas);
    }
    if (cachedObras) {
      this.ubicacionesConfig.data = JSON.parse(cachedObras);
    }
    if (cachedSeguros) {
      this.segurosConfig.data = JSON.parse(cachedSeguros);
    }
    if (cachedVersiones) {
      this.versionesConfig.data = JSON.parse(cachedVersiones);
    }
    if (cachedCategorias) {
      this.maquinariaCategoriasConfig.data = JSON.parse(cachedCategorias);
    }
    if (cachedEmpresasFiniquito) {
      this.empresasFiniquitoConfig.data = JSON.parse(cachedEmpresasFiniquito);
    }
    if (cachedProveedores) {
      this.proveedoresConfig.data = JSON.parse(cachedProveedores);
    }
    if (cachedEmpleadoCategorias) {
      this.empleadocategoriasConfig.data = JSON.parse(cachedEmpleadoCategorias);
    }


    forkJoin({
      marcas: this.marcasService.getAll(),
      obras: this.obrasService.getAll(),
      seguros: this.segurosService.getAll(),
      versiones: this.versionService.getAll(),
      categorias: this.categoriaService.getAll(),
      empresasFiniquito: this.empresaFiniquitaService.getAll(),
      proveedores: this.proveedorService.getAll(),
      empleadoCategorias: this.empleadocategoriasService.getAll(),
    }).subscribe((results) => {
      this.marcasConfig.data = results.marcas;
      this.ubicacionesConfig.data = results.obras;
      this.segurosConfig.data = results.seguros;
      this.versionesConfig.data = results.versiones;
      this.maquinariaCategoriasConfig.data = results.categorias;
      this.empresasFiniquitoConfig.data = results.empresasFiniquito;
      this.proveedoresConfig.data = results.proveedores;
      this.empleadocategoriasConfig.data = results.empleadoCategorias;

      // Add results to cache
      localStorage.setItem('marcas', JSON.stringify(results.marcas));
      localStorage.setItem('obras', JSON.stringify(results.obras));
      localStorage.setItem('seguros', JSON.stringify(results.seguros));
      localStorage.setItem('versiones', JSON.stringify(results.versiones));
      localStorage.setItem('categorias', JSON.stringify(results.categorias));
      localStorage.setItem('empresasFiniquito', JSON.stringify(results.empresasFiniquito));
      localStorage.setItem('proveedores', JSON.stringify(results.proveedores));
      localStorage.setItem('empleadoCategorias', JSON.stringify(results.empleadoCategorias));
    });

  }

  openDialog(config: any) {
    this.createRef = this.windowService.open(this.createForm, {
      title: "Crear",
      context: { config: config },
    });
  }

  onSubmit(form: FormGroup, config: any) {
    config.newForm.onSubmit(form);
  }
}


export interface ConfigCrudDef {
  data: any[];
  tbDef: MatTableColumnConfig[];
  tbActions: MatTableActionConfig[];
  newForm: {
    formDef: FormRowConfig[];
    onSubmit: (form: FormGroup,row: any,ref) => void;
  };
  loadData: () => void;
}

