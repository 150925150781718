import { Component } from '@angular/core';
import { Obra } from '../../@core/data/maquinaria-data';
import { ObraService } from '../../@core/services/obra.service';
import { Nomina, NominaEmpleado, NominaEmpleadoDiaria } from '../../@core/data/nomina-data';
import { NominaEmpleadoService, NominasService } from '../../@core/services/nominas.service';
import { generate } from 'rxjs';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Empleado } from '../../@core/data/cotizacion-data';
import { ConfigCrudDef } from '../configuraciones/configuraciones.component';
import { MatTableActionConfig, MatTableColumnConfig } from '../../mat-table-builder/mat-table-builder.component';
import { FormFieldType, FormRowConfig, OptionType } from '../../form-builder/form-builder.component';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NbToastrService } from '@nebular/theme';
import { Role, User } from '../../@core/data/usuarios-data';

@Component({
  selector: 'ngx-nomina-semanal',
  templateUrl: './nomina-semanal.component.html',
  styleUrls: ['./nomina-semanal.component.scss']
})
export class NominaSemanalComponent {
  obras: Obra[] = [];
  empleados: Empleado[] = [];
  obraSeleccionada: number;
  semanaSeleccionada: Date;
  nomina: Nomina;
  filterFn = (date) => date.getDay() === 1;
  nominaSemanalConfig: ConfigCrudDef = {
    data: [] as Empleado[],
    tbDef: [
      {
        name:'id',
        label:'ID',
        value: 'id'
      },
      {
        name:'empleado',
        label:'Empleado',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.empleado
      },
      {
        name:'categoria',
        label:'Categoria',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.categoria,
      },
      {
        name: 'sueldo_base',
        label: 'Salario x senama',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.sueldo_base?nominaEmpleado.sueldo_base:0,
        type: 'money'
      },
      {
        name: 'sueldo_base_diario',
        label: 'Salario x dia',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.sueldo_base?nominaEmpleado.sueldo_base/6:0,
        type: 'money'
      },
      {
        name: 'hora_extra_precio',
        label: 'H.E $',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.precio_horas_extras,
        type: 'money'
      },
      {
        name: 'lunes',
        label: 'L',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Lunes').trabajado:0,
        type: 'boolean'
      },
      {
        name: 'lunes_horas_extras',
        label: 'H.E',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Lunes').horas_extras:0,
      },
      {
        name: 'martes',
        label: 'M',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Martes').trabajado:0,
        type: 'boolean'
      },
      {
        name: 'martes_horas_extras',
        label: 'H.E',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Martes').horas_extras:0,
      },
      {
        name: 'miercoles',
        label: 'M',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Miercoles').trabajado:0,
        type: 'boolean'
      },
      {
        name: 'miercoles_horas_extras',
        label: 'H.E',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Miercoles').horas_extras:0,
      },
      {
        name: 'jueves',
        label: 'J',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Jueves').trabajado:0,
        type: 'boolean'
      },
      {
        name: 'jueves_horas_extras',
        label: 'H.E',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Jueves').horas_extras:0,
      },
      {
        name: 'viernes',
        label: 'V',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Viernes').trabajado:0,
        type: 'boolean'
      },
      {
        name: 'viernes_horas_extras',
        label: 'H.E',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Viernes').horas_extras:0,
      },
      {
        name: 'sabado',
        label: 'S',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Sabado').trabajado:0,
        type: 'boolean'
      },
      {
        name: 'sabado_horas_extras',
        label: 'H.E',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Sabado').horas_extras:0,
      },
      {
        name: 'domingo',
        label: 'D',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Domingo').trabajado:0,
        type: 'boolean'
      },
      {
        name: 'domingo_horas_extras',
        label: 'H.E',
        value: (nominaEmpleado: NominaEmpleado) =>nominaEmpleado.nomina_empleado_diarias?this.getNominaByDia(nominaEmpleado.nomina_empleado_diarias, 'Domingo').horas_extras:0,
      },
      {
        name:'dias_trabajados',
        label:'Dias Trabajados',
        value: (nominaEmpleado: NominaEmpleado) =>this.diasTrabajados(nominaEmpleado),
        align:'center'
      },
      {
        name:'horas_extras',
        label:'Horas Extras',
        value: (nominaEmpleado: NominaEmpleado) =>this.totalHorasExtras(nominaEmpleado),
      },
      {
        name:'importe_semanal',
        label:'Importe Semanal',
        value: (nominaEmpleado: NominaEmpleado) =>this.importeSemanal(nominaEmpleado)?this.importeSemanal(nominaEmpleado):0,
        type: 'money'
      },
      {
        name:'importe_horas_extras',
        label:'Importe Horas Extras',
        value: (nominaEmpleado: NominaEmpleado) =>this.importeHorasExtras(nominaEmpleado),
        type: 'money'
      },
      {
        name:'comida_total',
        label:'Comida',
        value: (nominaEmpleado: NominaEmpleado) => nominaEmpleado.total_comidas,
        type: 'money'
      },
      {
        name:'total_nomina',
        label:'Total Nomina',
        value: (nominaEmpleado: NominaEmpleado) =>this.totalNomina(nominaEmpleado),
        type: 'money'
      }

    ] as MatTableColumnConfig[],
    tbActions: [
      {
        name: 'edit',
        label: 'Editar',
        icon: 'edit',
        formSize: 'medium',
        formConfig:(row: NominaEmpleado) => {
          const nomina_lunes = this.getNominaByDia(row.nomina_empleado_diarias, 'Lunes');
          const nomina_martes = this.getNominaByDia(row.nomina_empleado_diarias, 'Martes');
          const nomina_miercoles = this.getNominaByDia(row.nomina_empleado_diarias, 'Miercoles');
          const nomina_jueves = this.getNominaByDia(row.nomina_empleado_diarias, 'Jueves');
          const nomina_viernes = this.getNominaByDia(row.nomina_empleado_diarias, 'Viernes');
          const nomina_sabado = this.getNominaByDia(row.nomina_empleado_diarias, 'Sabado');
          const nomina_domingo = this.getNominaByDia(row.nomina_empleado_diarias, 'Domingo');
          return [
            {
              type: FormFieldType.Row,
              fields: {
                id: {
                  type: FormFieldType.Input,
                  formControlName: 'id',
                  value: row.empleado_id,
                  label: 'ID',
                  col: 1,
                  disable: true,
                },
                nombre:{
                  type: FormFieldType.Input,
                  formControlName: 'nombre',
                  value: row.empleado,
                  disable: true,
                  label: 'Empleado',
                  col: 4
                },
                categoria: {
                  type: FormFieldType.Input,
                  formControlName: 'categoria',
                  value: row.categoria,
                  disable: true,
                  label: 'Categoria',
                  col: 4
                },
                total_comidas: {
                  type: FormFieldType.Number,
                  formControlName: 'total_comidas',
                  placeholder: '0',
                  value:row.total_comidas ,
                  label: 'Comidas $',
                  col: 3
                }

              }
            },
            {
              type: FormFieldType.Row,
              fields: {
                sueldo_base: {
                  type: FormFieldType.Number,
                  formControlName: 'sueldo_base',
                  value: row.sueldo_base,
                  disable: true,
                  label: 'Salario x semana',
                  col: 4
                },
                sueldo_base_diario: {
                  type: FormFieldType.Number,
                  formControlName: 'sueldo_base_diario',
                  placeholder: '0',
                  value: row.sueldo_base/6,
                  disable: true,
                  label: 'Salario x dia',
                  col: 4
                },
                precio_horas_extras: {
                  type: FormFieldType.Number,
                  formControlName: 'precio_horas_extras',
                  placeholder: '0',
                  value: row.precio_horas_extras?row.precio_horas_extras:0,
                  label: 'H.E $',
                  col: 4
                }
              }
            },
            {
              type: FormFieldType.Row,
              fields: {
                lunes: {
                  type: FormFieldType.Radio,
                  formControlName: 'lunes',
                  placeholder: '0',
                  value: nomina_lunes.trabajado,
                  label: () => 'Lunes ' + this.getDia(row.nomina_empleado_diarias, 'Lunes'),
                  col: 6,
                  options: [
                    {key: OptionType.RadioOptions, value:[
                      {label: 'Trabajó', value: 1},
                      {label: 'No Trabajó', value: 0}
                    ]}
                  ],
                  onChange: () => {
                  }
                },
                lunes_horas_extras: {
                  type: FormFieldType.Number,
                  formControlName: 'lunes_horas_extras',
                  placeholder: '0',
                  value: nomina_lunes.horas_extras,
                  label:() => 'H.E. Lunes ' + this.getDia(row.nomina_empleado_diarias, 'Lunes'),
                  col: 6
                }
              }
            },
            {
              type: FormFieldType.Row,
              fields: {
                martes: {
                  type: FormFieldType.Radio,
                  formControlName: 'martes',
                  placeholder: '0',
                  value: nomina_martes.trabajado,
                  label: () => 'Martes ' + this.getDia(row.nomina_empleado_diarias, 'Martes'),
                  col: 6,
                  options: [
                    {key: OptionType.RadioOptions, value:[
                      {label: 'Trabajó', value: 1},
                      {label: 'No Trabajó', value: 0}
                    ]}
                  ]
                },
                martes_horas_extras: {
                  type: FormFieldType.Number,
                  formControlName: 'martes_horas_extras',
                  placeholder: '0',
                  value: nomina_martes.horas_extras,
                  label:() => 'H.E. Martes ' + this.getDia(row.nomina_empleado_diarias, 'Martes'),
                  col: 6
                }
              }
            },
            {
              type: FormFieldType.Row,
              fields: {
                miercoles: {
                  type: FormFieldType.Radio,
                  formControlName: 'miercoles',
                  placeholder: '0',
                  value: nomina_miercoles.trabajado,
                  label: () => 'Miercoles ' + this.getDia(row.nomina_empleado_diarias, 'Miercoles'),
                  col: 6,
                  options: [
                    {key: OptionType.RadioOptions, value:[
                      {label: 'Trabajó', value: 1},
                      {label: 'No Trabajó', value: 0}
                    ]}
                  ]
                },
                miercoles_horas_extras: {
                  type: FormFieldType.Number,
                  formControlName: 'miercoles_horas_extras',
                  placeholder: '0',
                  value: nomina_miercoles.horas_extras,
                  label:() => 'H.E. Miercoles ' + this.getDia(row.nomina_empleado_diarias, 'Miercoles'),
                  col: 6
                }
              }
            },
            {
              type: FormFieldType.Row,
              fields: {
                jueves: {
                  type: FormFieldType.Radio,
                  formControlName: 'jueves',
                  placeholder: '0',
                  value: nomina_jueves.trabajado,
                  label: () => 'Jueves ' + this.getDia(row.nomina_empleado_diarias, 'Jueves'),
                  col: 6,
                  options: [
                    {key: OptionType.RadioOptions, value:[
                      {label: 'Trabajó', value: 1},
                      {label: 'No Trabajó', value: 0}
                    ]}
                  ]
                },
                jueves_horas_extras: {
                  type: FormFieldType.Number,
                  formControlName: 'jueves_horas_extras',
                  placeholder: '0',
                  value: nomina_jueves.horas_extras,
                  label:() => 'H.E. Jueves ' + this.getDia(row.nomina_empleado_diarias, 'Jueves'),
                  col: 6
                }
              }
            },
            {
              type: FormFieldType.Row,
              fields: {
                viernes: {
                  type: FormFieldType.Radio,
                  formControlName: 'viernes',
                  placeholder: '0',
                  value: nomina_viernes.trabajado,
                  label: () => 'Viernes ' + this.getDia(row.nomina_empleado_diarias, 'Viernes'),
                  col: 6,
                  options: [
                    {key: OptionType.RadioOptions, value:[
                      {label: 'Trabajó', value: 1},
                      {label: 'No Trabajó', value: 0}
                    ]}
                  ],
                  // onChange: (value,form) => {

                  //   const lunes = form.get('lunes').value;
                  //   const lunes_horas_extras = form.get('lunes_horas_extras').value;
                  //   const martes = form.get('martes').value;
                  //   const martes_horas_extras = form.get('martes_horas_extras').value;
                  //   const miercoles = form.get('miercoles').value;
                  //   const miercoles_horas_extras = form.get('miercoles_horas_extras').value;
                  //   const jueves = form.get('jueves').value;
                  //   const jueves_horas_extras = form.get('jueves_horas_extras').value;
                  //   const viernes = form.get('viernes').value;
                  //   const viernes_horas_extras = form.get('viernes_horas_extras').value;
                  //   const sabado = form.get('sabado').value;
                  //   const sabado_horas_extras = form.get('sabado_horas_extras').value;
                  //   const domingo = form.get('domingo').value;
                  //   const domingo_horas_extras = form.get('domingo_horas_extras').value;
                  //   form.get('total_horas_extras').disable(false);
                  //   form.get('total_horas_extras').setValue(lunes_horas_extras + martes_horas_extras + miercoles_horas_extras + jueves_horas_extras + viernes_horas_extras + sabado_horas_extras + domingo_horas_extras);
                  //   form.get('total_nomina').disable(false);
                  //   form.get('total_nomina').setValue(this.totalNomina(row));
                  // }
                },
                viernes_horas_extras: {
                  type: FormFieldType.Number,
                  formControlName: 'viernes_horas_extras',
                  placeholder: '0',
                  value: nomina_viernes.horas_extras,
                  label:() => 'H.E. Viernes ' + this.getDia(row.nomina_empleado_diarias, 'Viernes'),
                  col: 6
                }
              }
            },
            {
              type: FormFieldType.Row,
              fields: {
                sabado: {
                  type: FormFieldType.Radio,
                  formControlName: 'sabado',
                  placeholder: '0',
                  value: nomina_sabado.trabajado,
                  label: () => 'Sabado ' + this.getDia(row.nomina_empleado_diarias, 'Sabado'),
                  col: 6,
                  options: [
                    {key: OptionType.RadioOptions, value:[
                      {label: 'Trabajó', value: 1},
                      {label: 'No Trabajó', value: 0}
                    ]}
                  ]
                },
                sabado_horas_extras: {
                  type: FormFieldType.Number,
                  formControlName: 'sabado_horas_extras',
                  placeholder: '0',
                  value: nomina_sabado.horas_extras,
                  label:() => 'H.E. Sabado ' + this.getDia(row.nomina_empleado_diarias, 'Sabado'),
                  col: 6
                }
              }
            },
            {
              type: FormFieldType.Row,
              fields: {
                domingo: {
                  type: FormFieldType.Radio,
                  formControlName: 'domingo',
                  placeholder: '0',
                  value: nomina_domingo.trabajado,
                  label: () => 'Domingo ' + this.getDia(row.nomina_empleado_diarias, 'Domingo'),
                  col: 6,
                  options: [
                    {key: OptionType.RadioOptions, value:[
                      {label: 'Trabajó', value: 1},
                      {label: 'No Trabajó', value: 0}
                    ]}
                  ]
                },
                domingo_horas_extras: {
                  type: FormFieldType.Number,
                  formControlName: 'domingo_horas_extras',
                  placeholder: '0',
                  value: row.nomina_empleado_diarias?this.getNominaByDia(row.nomina_empleado_diarias, 'Domingo').horas_extras:0,
                  label:() => 'H.E. Domingo ' + this.getDia(row.nomina_empleado_diarias, 'Domingo'),
                  col: 6
                }
              }
            },
            // {
            //   type: FormFieldType.Row,
            //   fields: {
            //     total_horas_extras: {
            //       type: FormFieldType.Number,
            //       formControlName: 'total_horas_extras',
            //       placeholder: '0',
            //       value: row.comidas_precio,
            //       label: 'Total Horas Extras',
            //       disable: true,
            //       col: 6
            //     },
            //     total_nomina: {
            //       type: FormFieldType.Number,
            //       formControlName: 'total_nomina',
            //       placeholder: '0',
            //       value: row.comidas_precio,
            //       label: 'Total Nomina',
            //       disable: true,
            //       col: 6
            //     }
            //   }
            // }

          ] as FormRowConfig[]
        },
        action: (form: FormGroup, row: NominaEmpleado,ref) => {

          let formGroup = new FormGroup({
            total_comidas: new FormControl(parseFloat(form.get('total_comidas').value)),
            precio_horas_extras: new FormControl(parseFloat(form.get('precio_horas_extras').value)),
            nomina_empleado_diarias: new FormArray([])
          });

          let nominasSemanales = formGroup.get('nomina_empleado_diarias') as FormArray;

          row.nomina_empleado_diarias.forEach(nomina => {
            const dia = this.getDiaDeLaSemana(nomina.fecha);
            console.log(dia);
            switch (dia) {
              case 'lunes':
                nominasSemanales.push(new FormGroup({
                  id: new FormControl(nomina.id),
                  horas_extras: new FormControl(form.get('lunes_horas_extras').value),
                  trabajado: new FormControl(form.get('lunes').value),
                }));
                break;
              case 'martes':
                nominasSemanales.push(new FormGroup({
                  id: new FormControl(nomina.id),
                  horas_extras: new FormControl(form.get('martes_horas_extras').value),
                  trabajado: new FormControl(form.get('martes').value),
                }));
                break;
              case 'miércoles':
                nominasSemanales.push(new FormGroup({
                  id: new FormControl(nomina.id),
                  horas_extras: new FormControl(form.get('miercoles_horas_extras').value),
                  trabajado: new FormControl(form.get('miercoles').value),
                }));
                break;
              case 'jueves':
                nominasSemanales.push(new FormGroup({
                  id: new FormControl(nomina.id),
                  horas_extras: new FormControl(form.get('jueves_horas_extras').value),
                  trabajado: new FormControl(form.get('jueves').value),
                }));
                break;
              case 'viernes':
                nominasSemanales.push(new FormGroup({
                  id: new FormControl(nomina.id),
                  horas_extras: new FormControl(form.get('viernes_horas_extras').value),
                  trabajado: new FormControl(form.get('viernes').value),
                }));
                break;
              case 'sábado':
                nominasSemanales.push(new FormGroup({
                  id: new FormControl(nomina.id),
                  horas_extras: new FormControl(form.get('sabado_horas_extras').value),
                  trabajado: new FormControl(form.get('sabado').value),
                }));
                break;
              case 'domingo':
                nominasSemanales.push(new FormGroup({
                  id: new FormControl(nomina.id),
                  horas_extras: new FormControl(form.get('domingo_horas_extras').value),
                  trabajado: new FormControl(form.get('domingo').value),
                }));
                break;
              default:
                console.error('Día no reconocido');
            }
          });


          this.nominaEmpleadoService.update(formGroup, row.id).subscribe((data) => {
            this.getNominasSemanales();
            ref.close();
            this.toastrService.success('Nomina actualizada', 'Exito');
          }, (error) => {
            this.toastrService.danger('Error al actualizar la nomina', 'Error');
          });
        }
      }
    ] as MatTableActionConfig[],
    newForm: {
      formDef: [] as FormRowConfig[],
      onSubmit: (form: FormGroup,row: Empleado,ref) => {
        //row
        this.nominaEmpleadoService.create(form).subscribe((data) => {

        });
      }
    },
    loadData: () => {
      this.getNominasSemanales();
    }
  }

  constructor(private obraService: ObraService, private nominaEmpleadoService: NominaEmpleadoService,private nominasService: NominasService
    ,private toastrService: NbToastrService
  ) {
    const user: User = JSON.parse(localStorage.getItem('userLogged'));

    const hasNomObrasPerm = user.roles.map((role) => role.permissions.map((perm) => perm.name).includes('nominas_obras'));
    if (hasNomObrasPerm.includes(true)) {
      if (!user.empleado) {
        this.toastrService.danger('No tiene un usuario asignado, consulte con su administrador', 'Error', {duration: 12000});
        return;
      }
      this.obraService.getById(user.empleado?.obra_id).subscribe((obra) => {
      this.obras = [obra];
      });
    } else {
      this.obraService.getAll().subscribe((obras) => {
      this.obras = obras;
      });
    }
  }

  downloadReporte(){
    this.nominasService.downloadReporteSemanal(this.nomina.id).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  getNominasSemanales() {
    const fecha_inicial = moment(this.semanaSeleccionada).format('YYYY-MM-DD');
    this.nominasService.getALLFirstBy([{obra_id: this.obraSeleccionada},{fecha_inicial: fecha_inicial},{detalles: true},{dias: 6},{first: true}]).subscribe((data) => {
      this.nomina = data;
      this.nominaSemanalConfig.data = this.nomina.nomina_empleados;
    });
  }

  onObraChange(obraId: number) {
    this.obraSeleccionada = obraId;
  }

  onDateChange($event: any) {
    this.semanaSeleccionada = $event;
  }



  getNominaByDia(nominas_personales: NominaEmpleadoDiaria[], dia: string): NominaEmpleadoDiaria | undefined {
    const dias = { 'Lunes': 1, 'Martes': 2, 'Miercoles': 3, 'Jueves': 4, 'Viernes': 5, 'Sabado': 6, 'Domingo': 0 };
    return nominas_personales?.find((nomina) => new Date(nomina.fecha).getDay() === dias[dia]);
  }
  diasTrabajados(nominaEmpleado: NominaEmpleado): number {
    return nominaEmpleado.nomina_empleado_diarias?.filter((nomina) => nomina.trabajado).length || 0;
  }

  totalHorasExtras(nominaEmpleado: NominaEmpleado): number {
    return nominaEmpleado.nomina_empleado_diarias?nominaEmpleado.nomina_empleado_diarias.reduce((acc, curr) => acc + Number.parseFloat(curr.horas_extras), 0):0;
  }

  importeSemanal(nominaEmpleado: NominaEmpleado): number {
    return this.diasTrabajados(nominaEmpleado) * (nominaEmpleado.sueldo_base/6 );
  }

  importeHorasExtras(nominaEmpleado: NominaEmpleado): number {
    return this.totalHorasExtras(nominaEmpleado) * nominaEmpleado.precio_horas_extras;
  }

  totalNomina(nominaEmpleado: NominaEmpleado): number {

    return (this.importeSemanal(nominaEmpleado) + this.importeHorasExtras(nominaEmpleado))+Number.parseFloat(nominaEmpleado.total_comidas);
  }
  getDia(nominas_personales: NominaEmpleadoDiaria[], dia: string) {
    const nomina = this.getNominaByDia(nominas_personales, dia);
    return  moment(nomina.fecha).format('DD');
  }
  getDiaDeLaSemana(fecha: string) {
    return moment(fecha).format('dddd');
  }
  buildForm(nominaEmpleado: NominaEmpleado,form: FormGroup){
    return new FormGroup({
      trabajado: new FormControl(form.get('lunes').value),
      horas_extras: new FormControl(form.get('lunes_horas_extras').value)
    });
  }

  getTotalNominaGeneral(): any {
    return this.nomina?.nomina_empleados?.reduce((acc, curr) => acc + this.totalNomina(curr), 0) || 0;
  }
}

