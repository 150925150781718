<nb-tabset>
  <nb-tab tabTitle="Detalle de la Incidencia" >

    <div class="row">
      <div class="col-md-6">
        <nb-card>
          <nb-card-header>Datos de la Incidencia</nb-card-header>
          <nb-card-body>
            <p><strong>Número de Siniestro:</strong> {{ incidencia.numero_siniestro }}</p>
            <p><strong>Fecha:</strong> {{ incidencia.fecha }}</p>
            <p><strong>Tipo:</strong> {{ incidencia.tipo }}</p>
            <p><strong>Estatus:</strong> {{ incidencia.estatus }}</p>
            <p><strong>Tipo de Siniestro:</strong> {{ incidencia.tipo_sinestro }}</p>
            <p><strong>Lugar del Incidente:</strong> {{ incidencia.lugar_incidente }}</p>
            <p><strong>Observaciones:</strong> {{ incidencia.observaciones }}</p>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6">
        <nb-card>
          <nb-card-header>Datos del Vehículo</nb-card-header>
          <nb-card-body>
            <p><strong>ID del Vehículo:</strong> {{ incidencia.vehiculo_id }}</p>
            <p><strong>Color:</strong> {{ incidencia.color }}</p>
            <p><strong>Año:</strong> {{ incidencia.anio }}</p>
            <p><strong>Marca:</strong> {{ incidencia.marca }}</p>
            <p><strong>Modelo:</strong> {{ incidencia.modelo }}</p>
            <p><strong>Seguro:</strong> {{ incidencia.seguro }}</p>
            <p><strong>Serie:</strong> {{ incidencia.serie }}</p>
            <p><strong>Placas:</strong> {{ incidencia.placas }}</p>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <nb-card>
          <nb-card-header>Datos del Ajustador</nb-card-header>
          <nb-card-body>
            <p><strong>Teléfono del Ajustador:</strong> {{ incidencia.tel_ajustador }}</p>
            <p><strong>Nombre del Ajustador:</strong> {{ incidencia.nombre_ajustador }}</p>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6">
        <nb-card>
          <nb-card-header>Datos del Beneficiario</nb-card-header>
          <nb-card-body>
            <p><strong>Beneficiario del Seguro:</strong> {{ incidencia.beneficiarioSeguro }}</p>
            <p><strong>Responsable del Siniestro:</strong> {{ incidencia.responsableSiniestro }}</p>
            <p><strong>Beneficiario:</strong> {{ incidencia.beneficiario }}</p>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <nb-card>
          <nb-card-header>Costos</nb-card-header>
          <nb-card-body>
            <p><strong>Costo:</strong> {{ incidencia.costo|currency }}</p>
            <p><strong>Total Pago:</strong> {{ incidencia.total_pago|currency }}</p>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6">
        <nb-card>
          <nb-card-header>Estatus de Documentos</nb-card-header>
          <nb-card-body>
            <p><strong>Estatus de Documentos:</strong> {{ incidencia.estatus_documentos }}</p>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </nb-tab>
  <nb-tab tabTitle="Bitácora de Avances">
    <div>
      <ngx-bitacora-table [incidencia]="incidencia"></ngx-bitacora-table>
    </div>
  </nb-tab>
  <nb-tab tabTitle="Gestión de Documentos">
  <div class="mb-3">
    <ngx-documentos [incidencia]="incidencia"></ngx-documentos>
  </div>

  </nb-tab>
</nb-tabset>


