import { Component, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Cotizacion } from '../../@core/data/cotizacion-data';
import { CotizacionService } from '../../@core/services/cotizacion.service';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';

@Component({
  selector: 'ngx-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.scss']
})
export class CotizacionesComponent implements OnInit{
  loadingNewButton: boolean=true;
  searchText: any;
  filteredData: Cotizacion[];
  data:Cotizacion[];
  nuevaCotizacionWindows: NbWindowRef;
  @ViewChild('nuevaCotizacion', { read: TemplateRef }) cotizacionTemplate: TemplateRef<HTMLElement>;
  constructor(private cotizacionService:CotizacionService, private windowsService:NbWindowService,
    private toastService: NbToastrService
  ) { }

  ngOnInit(): void {
    this.cotizacionService.getAll().subscribe((cotizaciones:Cotizacion[])=>{
      this.data = cotizaciones;
      this.filteredData = cotizaciones;
    });
  }

  onSearch() {
    this.filteredData = this.data.filter((cotizacion:Cotizacion)=>{
      return cotizacion.cliente.toLowerCase().includes(this.searchText.toLowerCase())||
      cotizacion.id.toString().toLowerCase().includes(this.searchText.toLowerCase())||
      cotizacion.empleado.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  onClickOpenNewCotizacion() {
    this.nuevaCotizacionWindows = this.windowsService.open(
      this.cotizacionTemplate,
      { title: 'Nueva Cotizacion', hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: true},
    );
  }

  onCotizacionSaved(){
    this.nuevaCotizacionWindows.close();
    this.toastService.show('Cotizacion guardada', 'Cotizacion', { status: 'success' });
    this.cotizacionService.getAll().subscribe((cotizaciones:Cotizacion[])=>{
      this.data = cotizaciones;
      this.filteredData = cotizaciones;
    });
  }
}
