<div  class="d-flex justify-content-between">
  <div>
    <button nbButton status="success" nbTooltip="Agregar mantenimiento" (click)="onClickOpenNuevoMantenimiento()" class="btn btn-success btn-sm p-lg-1">
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
    <button nbButton status="info" nbTooltip="Descargar mantenimiento" (click)="onClickDownloadMantenimientos()" class="btn btn-info btn-sm p-lg-1">
      <nb-icon icon="download-outline"></nb-icon>
    </button>
  </div>
  <div>
    <ngx-filter-value-date [items]="mantenimientos" [filterFields]="filterFields" filterField="fecha_mantenimiento" (filterChange)="onFilterChange($event)"></ngx-filter-value-date>
  </div>
</div>
<div>
  <ngx-mat-table-builder [columnsDef]="tableDef" [data]="mantenimientosFiltered" [actions]="actions" ></ngx-mat-table-builder>
</div>


<ng-template class="modal" style="width: 90%;" let-data #newMantenimientoForm>
  <div class="modal-body" style="min-width:200px; max-height: 400px;">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onCreateMantenimiento($event,data.vehiculo)"></ngx-form-builder>
  </div>
</ng-template>
