<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="assets/gcm-icon.jpg" class="logo-icon" alt="Logo">
    </a>
  </div>

</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu"
                nbContextMenuTag="user-context-menu"
               [onlyPicture]="userPictureOnly"
                [name]="user?.name"
               >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
