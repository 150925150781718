import { ClienteService } from './../../../../@core/services/cliente.service';
import { Component, Output, OnChanges, SimpleChange, SimpleChanges, Input, ViewChild, TemplateRef } from '@angular/core';
import { Cliente } from '../../../../@core/data/cotizacion-data';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NbWindowRef, NbWindowService } from '@nebular/theme';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ngx-clientes-mat-table',
  templateUrl: './clientes-mat-table.component.html',
  styleUrls: ['./clientes-mat-table.component.scss']
})
export class ClientesMatTableComponent implements OnChanges {
agregar() {
throw new Error('Method not implemented.');
}
  @Input() dataSource: Cliente[] = []
  @ViewChild('editarClienteFormView', { read: TemplateRef }) editarClienteFormView: TemplateRef<HTMLElement>;
  @ViewChild('openDelete', { read: TemplateRef }) openDelete: TemplateRef<HTMLElement>;
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:false}) sort: MatSort;
  editarClienteWindow: NbWindowRef;
  deleteWindows: NbWindowRef;
  defaultColumns: string[] = ['id', 'nombre','correo','direccion', 'representante', 'testigo', 'lugar_uso_maq', 'rep_clave_elect', 'rep_direccion', 'num_tel'];

  customColumns: string[] = ['acciones'];
  allColumns = [...this.defaultColumns, ...this.customColumns];
  data: MatTableDataSource<Cliente>;
  constructor(private clientesService:ClienteService,private windowSerivce:NbWindowService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataSource){
      this.dataSource = changes.dataSource.currentValue;
      this.data = new MatTableDataSource(this.dataSource);
      this.data.paginator = this.paginator;
      this.data.sort = this.sort;
    }
  }

  onOpenDelete(cliente: Cliente) {
    this.deleteWindows = this.windowSerivce.open(
      this.openDelete,
      { title: 'Eliminar cliente', context: { cliente:cliente }, hasBackdrop: true, closeOnEsc: true },
    );
  }

  onOpenEditar(cliente: Cliente) {
    this.editarClienteWindow = this.windowSerivce.open(
      this.editarClienteFormView,
      { title: 'Cliente ' + cliente.nombre, context: { cliente:cliente }, hasBackdrop: true, closeOnEsc: true },
    );
  }

  onConfirmDelete(cliente: Cliente) {
    this.clientesService.delete(cliente.id).subscribe(() => {
      this.dataSource = this.dataSource.filter(c => c.id !== cliente.id);
      this.deleteWindows.close();
    });
  }

  onClienteUpdated(){
    this.clientesService.getAll().subscribe(data => {
      this.dataSource = data;
    });
    this.editarClienteWindow.close();
  }
}
