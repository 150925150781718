import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpedientesUnicosTableComponent } from './expedientes-unicos-table/expedientes-unicos-table.component';
import { MatTableModule } from '@angular/material/table';
import { NbButtonModule, NbIconModule, NbInputModule, NbStepperModule, NbTable, NbTableModule, NbTooltipModule } from '@nebular/theme';
import { NuevoExpedienteFormComponent } from './nuevo-expediente-form/nuevo-expediente-form.component';
import { NuevoExpedienteStepperComponent } from './nuevo-expediente-stepper/nuevo-expediente-stepper.component';
import { TipoDocumentoExpedienteService } from '../../../@core/services/tipo-documento-expediente-service.service';
import { ReactiveFormsModule } from '@angular/forms';
import { ExpedienteUnicoDocService } from '../../../@core/services/expediente-unico-doc.service';
import { ExpedienteDocumentosFormComponent } from './expediente-documentos-form/expediente-documentos-form.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GCMARQFormsModule } from '../../../forms/gcmarqforms.module';


@NgModule({
  declarations: [
    ExpedientesUnicosTableComponent,
    NuevoExpedienteFormComponent,
    NuevoExpedienteStepperComponent,
    ExpedienteDocumentosFormComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    NbButtonModule,
    NbIconModule,
    NbStepperModule,
    ReactiveFormsModule,
    NbInputModule,
    NbTooltipModule,
    MatPaginatorModule,
    GCMARQFormsModule
  ],
  exports: [
    ExpedientesUnicosTableComponent,
    NuevoExpedienteFormComponent,
    NuevoExpedienteStepperComponent,
    ExpedienteDocumentosFormComponent
  ],
  providers: [
    TipoDocumentoExpedienteService,
    ExpedienteUnicoDocService,
  ]
})
export class ExpedientesUnicosFormsModule { }
