import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Empleado } from '../../../../@core/data/cotizacion-data';
import { NbWindowRef, NbWindowService } from '@nebular/theme';
import { EmpleadoService } from '../../../../@core/services/empleado.service';

@Component({
  selector: 'ngx-empleados-mat-table',
  templateUrl: './empleados-mat-table.component.html',
  styleUrls: ['./empleados-mat-table.component.scss']
})
export class EmpleadosMatTableComponent {
  @Input() dataSource: Empleado[] = []

  columns = ['email','nombre', 'telefono',  'cargo',  'firma']
}
