<table mat-table #table [dataSource]="dataSource">
  <ng-container *ngFor="let column of defaultColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef> {{ column }} </th>
    <td mat-cell *matCellDef="let data"> {{ data[column] }} </td>
  </ng-container>
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef> Acciones </th>
    <td mat-cell *matCellDef="let data">
      <button nbButton status="primary" size="small" nbTooltip="Editar" (click)="onOpenEditar(data)">
        <nb-icon icon="edit-2"></nb-icon>
      </button>
      <button nbButton status="danger" size="small" nbTooltip="Eliminar" (click)="onOpenDelete(data)">
        <nb-icon icon="trash-2"></nb-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: allColumns;"></tr>
</table>

<ng-template #openDelete let-data>
  <div>¿Desea eliminar el cliente {{data.cliente.nombre}}?</div>
  <div><button status="danger" (click)="onConfirmDelete(data.cliente)" nbButton>Eliminar</button>
    <button nbButton>
      Cancelar
    </button>
  </div>
</ng-template>

<ng-template #editarClienteFormView let-data>
  <ngx-nuevo-cliente-form [cliente]="data.cliente" (clienteCreated)="onClienteUpdated()" ></ngx-nuevo-cliente-form>
</ng-template>
