import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Carpeta, Incidencia } from '../../../@core/data/incidencias-data';

import { NbToastrService, NbTreeGridDataSource, NbTreeGridDataSourceBuilder, NbWindowRef, NbWindowService } from '@nebular/theme';
import { CarpetaService } from '../../../@core/services/incidencias-service';
import { FormBuilderConfig, FormFieldType } from '../../../form-builder/form-builder.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-detalle-incidencia',
  templateUrl: './detalle-incidencia.component.html',
  styleUrls: ['./detalle-incidencia.component.scss']
})
export class DetalleIncidenciaComponent {
  @Input() incidencia: Incidencia;
  @ViewChild('newFolderTemplate') newFolderTemplate: TemplateRef<HTMLElement>;
  newFolderRef: NbWindowRef;


  constructor(private windowService:NbWindowService, private toastrService:NbToastrService) { }

  clickDocs(){
    console.log('clickDocs');
  }

}

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FileNode {
  name: string;
  kind: string;
  [key: string]: any;
}
