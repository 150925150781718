import { FormBuilderConfig } from './../../form-builder/form-builder.component';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NbToastrService, NbTreeGridDataSourceBuilder, NbWindowRef, NbWindowService } from '@nebular/theme';
import { Seguro, Vehiculo } from '../../@core/data/vehiculo-data';
import { VehiculosService } from '../../@core/services/vehiculos.service';
import { MatTableActionConfig, MatTableColumnConfig } from '../../mat-table-builder/mat-table-builder.component';
import { FormGroup } from '@angular/forms';
import { FormFieldType, OptionType } from '../../form-builder/form-builder.component';
import { Marca } from '../../@core/data/marca-data';
import { Estado } from '../../@core/data/estado-data';
import { MarcaService } from '../../@core/services/marca.service';
import { VersionService } from '../../@core/services/version.service';
import { EstadoService } from '../../@core/services/estado.service';
import {Version} from '../../@core/data/version-data';
import { SeguroService } from '../../@core/services/seguro.service';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Empleado } from '../../@core/data/cotizacion-data';
import { Tipo_conductor } from '../../@core/enums/tipo_conductor.enum';
import { EstatusVehiculo } from '../../@core/enums/EstatusVehiculo.enum';
import { Obra } from '../../@core/data/maquinaria-data';
import { ObraService } from '../../@core/services/obra.service';
import { forkJoin } from 'rxjs';
import { removeEmptyControls } from '../../@core/utils/forms-util';
import { CSVGenerator } from '../../@core/utils/csv-generator';
@Component({
  selector: 'ngx-vehiculos',
  templateUrl: './vehiculos.component.html',
  styleUrls: ['./vehiculos.component.scss']
})
export class VehiculosComponent implements OnInit{
  vehiculos: Vehiculo[];
  dataFiltered:Vehiculo[];
  nuevoVehiculoWindows: NbWindowRef;
  @ViewChild('nuevoVehiculo', { read: TemplateRef }) vehiculoTemplate: TemplateRef<HTMLElement>;
  @ViewChild('mantenimientos', { read: TemplateRef }) mantenimientosTemplate: TemplateRef<HTMLElement>;
  mantenimientosRef: NbWindowRef;
  searchText: string='';
  marcas:Marca[];
  versiones:Version[]
  seguros:Seguro[]
  estados:Estado[]
  choferes:Empleado[]
  obras:Obra[]
  filterFields: string[] = ['serie', 'placas', 'color', 'marca', 'estado', 'version', 'seguro', 'obra', 'chofer', 'referendo_comentario'];
  columnsDef: MatTableColumnConfig[] = [
    { name: 'estatus', label: 'Estatus', type: 'text' },
    { name: 'serie', label: 'Serie', type: 'text' },
    { name: 'placas', label: 'Placas', type: 'text' },
    { name: 'color', label: 'Color', type: 'text' },
    { name: 'marca', label: 'Marca', type: 'text' },
    { name: 'estado', label: 'Estado', type: 'text' },
    { name: 'version', label: 'Version', type: 'text' },
    { name: 'obra', label: 'Ubicación', type: 'text',value:(row:Vehiculo) => {
      if(row.estatus === EstatusVehiculo.EN_OBRA){
        return row.obra;
      }
      return row.asignado_a;
      }
  },
  { name: 'seguro', label: 'Seguro', type: 'text' },
    {name: 'seguro_vigente', label: 'Seguro Vigente',value:'seguro_vigente' ,type: 'boolean'},
    {name: 'dias_vig_seguro', label: 'Dias restantes seguro',
      value:(row:Vehiculo) => {
        if(row.dias_vigencia_seguro){
          return row.dias_vigencia_seguro;
        }
        return 0;
      },
      backgroundColor:(row:Vehiculo) => {
        if(row.dias_vigencia_seguro<=0){
          return 'red';
        }else if(row.dias_vigencia_seguro<=30){
          return 'orange';
        }
      }
      ,
    }
    ,
    {name: 'fecha_fin_vigencia_seguro', label: 'Fecha fin vigencia seguro',value:'fech_fin_vig_seguro' ,type: 'date'},
    {name: 'ref_pagado', label: 'Referendo Pagado',value:'ref_pagado' ,type: 'boolean'},
    {name: 'referendo_comentario', label: 'Comentario Refrendo',value:'referendo_comentario' ,type: 'text'},

    {name: 'kilometraje', label: 'Kilometraje',value:'kilometraje' ,type: 'number'},
    {name: 'ultimo_mantenimiento', label: 'Ultimo mantenimiento',
      type:'number',
      value:(row:Vehiculo) => {
        if(row.kilometraje_proximo_mantenimiento>0){
         return row.kilometraje_original_mantenimiento;
        }
        return 0;
      },
    },
    {name: 'km_restantes_mantenimiento', label: 'Kilometraje restante mantenimiento',
      type:'number',
      value:(row:Vehiculo) => {
        if(row.kilometraje_proximo_mantenimiento>0){
         return row.kilometraje_original_mantenimiento + row.kilometraje_proximo_mantenimiento - row.kilometraje;
        }
        return 0;
      },
      backgroundColor:(row:Vehiculo) => {
        if( row.kilometraje_original_mantenimiento + row.kilometraje_proximo_mantenimiento - row.kilometraje<=0){
          return 'red';
        }else  if( row.kilometraje_original_mantenimiento + row.kilometraje_proximo_mantenimiento - row.kilometraje<=500){
          return 'orange';
        }
      }
    },

    {name: 'kilometraje_original_mantenimiento', label: 'Kilometraje proximo mantenimiento',value:(row:Vehiculo) => {
      if(row.kilometraje_proximo_mantenimiento>0){
        return row.kilometraje_original_mantenimiento+row.kilometraje_proximo_mantenimiento;
       }
       return 0;
      },type: 'number',
    },
    { name: 'chofer', label: 'Chofer/Siniestro/Asignado a', type: 'text', value:
       (row: Vehiculo) =>{
        if(row.estatus === EstatusVehiculo.EN_OBRA){
          return row.chofer;
        }
        return row.asignado_a;
       }
    },
    {
      name: 'created_at', label: 'Fecha de creación', type: 'date', value:'created_at'
    },
  ];


  actions: MatTableActionConfig[] = [
    {
      name:"mantenimientos",
      label:"Ver historial de mantenimiento",
      icon:"eye",
      action:(v:Vehiculo)=>{
        this.mantenimientosRef = this.windowsService.open(this.mantenimientosTemplate,{title:"Historial de mantenimiento",context:{vehiculo:v}});
      }
    },
    {
      name: "edit",
      label: "Agregar kilometros de trabajo",
      icon: "edit",
      action: (form:FormGroup,v:Vehiculo,ref) => {
        this.vehiculosService.update(form,v.id).subscribe(
          () => {
            this.vehiculosService.getAll().subscribe((data) => {
              this.vehiculos = data;
              this.dataFiltered = data;
            });
            this.toastrService.success(
              "El kilometraje ha sido actualizado",
              "Actualización exitosa"
            );
          },
          (error) => {
            this.toastrService.danger(
              "Error: " + error.error.message,
              "Error al actualizar el kilometraje"
            );
          });
          ref.close();
      },
      formConfig: (): FormBuilderConfig => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              kilometros_trabajados: {
                formControlName: "kilometros_trabajados",
                label: "Kilometros trabajados",
                placeholder: "Kilometros trabajados",
                col: 12,
                type: FormFieldType.Number,
              },
            },
          },
        ];
      },
    },
    {
      name: 'view',icon: 'eye', label: 'Ver poliza', action: (row) => {
        this.vehiculosService.downloadPoliza(row.id).subscribe((data) => {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'poliza.pdf';
          link.click();
        });
      },
    },
    { name:'edit',icon: 'edit', label: 'Editar',
      formConfig:(row:Vehiculo) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              anio:{
                formControlName: 'anio',
                label: 'Año',
                placeholder: 'Año',
                value: row.anio,
                col: 6,
                type: FormFieldType.Input,
              },
              placas:{
                formControlName: 'placas',
                label: 'Placas',
                placeholder: 'Placas',
                value: row.placas,
                col: 6,
                type: FormFieldType.Input,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              color:{
                formControlName: 'color',
                label: 'Color',
                placeholder: 'Color',
                value: row.color,
                col: 6,
                type: FormFieldType.Input,
              },
              serie:{
                formControlName: 'serie',
                label: 'Serie',
                placeholder: 'Serie',
                value: row.serie,
                col: 6,
                type: FormFieldType.Input,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              chofer_id:{
                formControlName: 'chofer_id',
                label: 'Chofer asginado',
                placeholder: 'Chofer',
                value: row.chofer_id,
                col: 12,
                type: FormFieldType.Select,
                options:[
                  {key:OptionType.SelectOptions,value:this.choferes.map(e => ({key:e.id,value:`${e.nombre} ${e.apellido_paterno} ${e.apellido_materno}`}))},
                ]
              },

            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              obra_id: {
                type: FormFieldType.Select,
                col: 12,
                formControlName: "obra_id",
                label: "Ubicación",
                placeholder: "Seleccione una obra",
                options:[
                  {key:OptionType.SelectOptions,value:this.obras.map(e => ({key:e.id,value:e.nombre}))},
                ]
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              referendo_pagado:{
                formControlName: 'referendo_pagado',
                label: '¿El refrendo ha sido pagado?',
                value: row.referendo_pagado,
                col: 6,
                type: FormFieldType.Radio,
                options:[
                  {key:OptionType.RadioOptions,value:[
                    {value:true,label:"Si"},
                    {value:false,label:"No"}
                  ]},
                ]
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              referendo_comentario:{
                formControlName: 'referendo_comentario',
                label: 'Comentario refrendo',
                placeholder: 'Comentario refrendo',
                value: row.referendo_comentario,
                type: FormFieldType.TextArea,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              marca_id:{
                formControlName: 'marca_id',
                label: 'Marca del vehiculo',
                placeholder: 'Marca',
                value: row.marca_id,
                col: 6,
                type: FormFieldType.Select,
                options:[
                  {key:OptionType.SelectOptions,value:this.marcas.map(m => ({key:m.id,value:m.nombre}))},
                ]
              },
              version_id:{
                formControlName: 'version_id',
                label: 'Version del vehiculo',
                placeholder: 'Version',
                value: row.version_id,
                col: 6,
                type: FormFieldType.Select,
                options:[
                  {key:OptionType.SelectOptions,value:this.versiones.map(m => ({key:m.id,value:m.nombre}))},
                ]
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              aseguradora_id:{
                formControlName: 'aseguradora_id',
                label: 'Seguro del vehiculo',
                placeholder: 'Seguro',
                value: row.aseguradora_id,
                col: 6,
                type: FormFieldType.Select,
                options:[
                  {key:OptionType.SelectOptions,value:this.seguros.map(s => ({key:s.id,value:s.nombre}))},
                ]
              },
              estado_id:{
                formControlName: 'estado_id',
                label: 'Estado del vehiculo',
                placeholder: 'Estado',
                value: row.estado_id,
                col: 6,
                type: FormFieldType.Select,
                options:[
                  {key:OptionType.SelectOptions,value:this.estados.map(e => ({key:e.id,value:e.nombre}))},
                ]
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              fecha_inicio_vigencia_seguro:{
                formControlName: 'fecha_inicio_vigencia_seguro',
                label: 'Fecha inicio vigencia seguro',
                placeholder: 'Fecha inicio vigencia seguro',
                value: row.fecha_inicio_vigencia_seguro,
                col: 6,
                type: FormFieldType.Date,
              },
              fecha_fin_vigencia_seguro:{
                formControlName: 'fecha_fin_vigencia_seguro',
                label: 'Fecha fin vigencia seguro',
                placeholder: 'Fecha fin vigencia seguro',
                value: row.fecha_fin_vigencia_seguro,
                col: 6,
                type: FormFieldType.Date,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              kilometraje:{
                formControlName: 'kilometraje',
                label: 'Kilometraje',
                placeholder: 'Kilometraje',
                value: row.kilometraje,
                col: 6,
                type: FormFieldType.Number,
              },
              kilometraje_proximo_mantenimiento:{
                formControlName: 'kilometraje_proximo_mantenimiento',
                label: 'Mantenimiento cada (km)',
                placeholder: '0',
                value: row.kilometraje_proximo_mantenimiento,
                col: 6,
                type: FormFieldType.Number,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              estatus:{
                formControlName: 'estatus',
                label: 'Estatus',
                value: row.estatus,
                col: 12,
                type: FormFieldType.Select,
                options:[
                  {key:OptionType.SelectOptions,value:[
                    {key:'EN OBRA',value:'En obra'},
                    {key:'VENDIDO',value:'Vendido'},
                    {key:'SISNIESTRADO',value:'Siniestrado'},
                    {key:'FAMILIAR',value:'Familiar'},
                  ]}
                ]
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              asignado_a:{
                formControlName: 'asignado_a',
                label: 'Asignado a/Siniestro',
                placeholder: 'Solo si el vehiculo tiene estatus vendido, siniestrado o familiar',
                value: row.asignado_a,
                col: 12,
                type: FormFieldType.Input,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              poliza_file:{
                formControlName: 'poliza_file',
                label: 'Poliza seguro',
                placeholder: 'Poliza seguro',
                col: 6,
                type: FormFieldType.File,
                options:[
                  {key:OptionType.fileSelectTypes,value:'application/pdf'}
                ]
              },
            }
          }
        ]
      },
      action:(form:FormGroup,vehiculo:Vehiculo,windowRef)=>{
        this.vehiculosService.update(form,vehiculo.id).subscribe((data) => {
          this.toastrService.show('Vehiculo actualizado con éxito',
            'Vehiculo Actualizado', { status: 'success' });
          if(form.get('poliza_file').value){
            this.vehiculosService.updatePoliza(vehiculo.id,form.get('poliza_file').value).subscribe((data) => {
              this.toastrService.show('Poliza actualizada con éxito',
                'Poliza Actualizada', { status: 'success' });
            }, (error) => {
              this.toastrService.danger('Error: ' + error.error.message,  'Error al actualizar la poliza');
            });
          }
          windowRef.close();
          this.vehiculosService.getAll().subscribe((data) => {
            this.vehiculos = data;
            this.dataFiltered = data;
          });
        }, (error) => {
          this.toastrService.danger('Error: ' + error.error.message,  'Error al actualizar el vehiculo');
        });
      }
    },
    { name:'delete',icon: 'trash', label: 'Eliminar',
      message: '¿Está seguro que desea eliminar el vehiculo?',
      title: 'Eliminar vehiculo',
      action:(vehiculo:Vehiculo)=>{
        this.vehiculosService.delete(vehiculo.id).subscribe(() => {
          this.vehiculosService.getAll().subscribe((data) => {
            this.vehiculos = data;
            this.dataFiltered = data;
          });
          this.toastrService.show('Vehiculo eliminado con éxito',
            'Vehiculo Eliminado', { status: 'success' });
        }, (error) => {
          this.toastrService.danger('Error: ' + error.error.message,  'Error al eliminar el vehiculo');
        });
      }
    },
  ];
  loadingNewButton: boolean=true;

  constructor(private titleService: Title, private windowService: NbWindowService,
              private toastrService: NbToastrService, private dataSourceBuilder: NbTreeGridDataSourceBuilder<Vehiculo>,
              private vehiculosService: VehiculosService,
              private obrasService:ObraService,
              private marcasService:MarcaService,
              private versionService:VersionService,
              private estadoService:EstadoService,
              private seguroService:SeguroService,
              private empleadosService:EmpleadoService,
              private windowsService:NbWindowService
            ) {
    this.titleService.setTitle('Vehiculos');
    this.vehiculosService.getAll().subscribe((data) => {
      this.vehiculos = data;
      this.dataFiltered = data;
    });
  }

  ngOnInit(): void {
    const cachedMarcas = localStorage.getItem('marcas');
    const cachedVersiones = localStorage.getItem('versiones');
    const cachedEstados = localStorage.getItem('estados');
    const cachedSeguros = localStorage.getItem('seguros');
    const cachedObras = localStorage.getItem('obras');
    const cachedChoferes = localStorage.getItem('choferes');

    if (cachedMarcas && cachedVersiones && cachedEstados && cachedSeguros && cachedObras && cachedChoferes) {
      this.marcas = JSON.parse(cachedMarcas);
      this.versiones = JSON.parse(cachedVersiones);
      this.estados = JSON.parse(cachedEstados);
      this.seguros = JSON.parse(cachedSeguros);
      this.obras = JSON.parse(cachedObras);
      this.choferes = JSON.parse(cachedChoferes);
      this.loadingNewButton = false;
    }

    forkJoin([
      this.marcasService.getAll(),
      this.versionService.getAll(),
      this.estadoService.getAll(),
      this.seguroService.getAll(),
      this.obrasService.getAll(),
      this.empleadosService.getAll()
    ]).subscribe(([marcas, versiones, estados, seguros, obras, empleados]) => {
      this.loadingNewButton = true;
      this.marcas = marcas;
      this.versiones = versiones;
      this.estados = estados;
      this.seguros = seguros;
      this.obras = obras;
      this.choferes = empleados.filter(e => e.tipo_conductor === Tipo_conductor.CHOFER);

      // Save data in cache
      localStorage.setItem('marcas', JSON.stringify(this.marcas));
      localStorage.setItem('versiones', JSON.stringify(this.versiones));
      localStorage.setItem('estados', JSON.stringify(this.estados));
      localStorage.setItem('seguros', JSON.stringify(this.seguros));
      localStorage.setItem('obras', JSON.stringify(this.obras));
      localStorage.setItem('choferes', JSON.stringify(this.choferes));
    });
  }

  onClickOpenNuevoVehiculoForm() {
    let formConfig: FormBuilderConfig = [
      {
        type: FormFieldType.Row,
        fields: {
          anio:{
            formControlName: 'anio',
            label: 'Año',
            placeholder: 'Año',
            col: 6,
            type: FormFieldType.Number,
            validations:['required',{key:'min',value:0},'number'],
          },
          placas:{
            formControlName: 'placas',
            label: 'Placas',
            placeholder: 'Placas',
            col: 6,
            type: FormFieldType.Input,
            validations:['required'],
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          color:{
            formControlName: 'color',
            label: 'Color',
            placeholder: 'Color',
            col: 6,
            type: FormFieldType.Input,
            validations:['required'],
          },
          serie:{
            formControlName: 'serie',
            label: 'Serie',
            placeholder: 'Serie',
            col: 6,
            type: FormFieldType.Input,
            validations:['required'],
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          chofer_id:{
            formControlName: 'chofer_id',
            label: 'Chofer asginado',
            placeholder: 'Chofer',
            col: 12,
            type: FormFieldType.Select,
            options:[
              {key:OptionType.SelectOptions,value:this.choferes.map(e => ({key:e.id,value:`${e.nombre} ${e.apellido_paterno} ${e.apellido_materno}`}))},
            ]
          },

        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          obra_id: {
            type: FormFieldType.Select,
            col: 12,
            formControlName: "obra_id",
            label: "Ubicación",
            placeholder: "Seleccione una obra",
            options:[
              {key:OptionType.SelectOptions,value:this.obras.map(e => ({key:e.id,value:e.nombre}))},
            ]
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          referendo_pagado:{
            formControlName: 'referendo_pagado',
            label: '¿El refrendo ha sido pagado?',
            value: false,
            col: 6,
            type: FormFieldType.Radio,
            options:[
              {key:OptionType.RadioOptions,value:[
                {value:true,label:"Si"},
                {value:false,label:"No"}
              ]},
            ]
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          referendo_comentario:{
            formControlName: 'referendo_comentario',
            label: 'Comentario refrendo',
            placeholder: 'Comentario refrendo',
            type: FormFieldType.TextArea,
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          marca_id:{
            formControlName: 'marca_id',
            label: 'Marca del vehiculo',
            placeholder: 'Marca',
            col: 6,
            type: FormFieldType.Select,
            options:[
              {key:OptionType.SelectOptions,value:this.marcas.map(m => ({key:m.id,value:m.nombre}))},
            ]
          },
          version_id:{
            formControlName: 'version_id',
            label: 'Version del vehiculo',
            placeholder: 'Version',
            col: 6,
            type: FormFieldType.Select,
            options:[
              {key:OptionType.SelectOptions,value:this.versiones.map(m => ({key:m.id,value:m.nombre}))},
            ]
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          aseguradora_id:{
            formControlName: 'aseguradora_id',
            label: 'Seguro del vehiculo',
            placeholder: 'Seguro',
            col: 6,
            type: FormFieldType.Select,
            options:[
              {key:OptionType.SelectOptions,value:this.seguros.map(s => ({key:s.id,value:s.nombre}))},
            ]
          },
          estado_id:{
            formControlName: 'estado_id',
            label: 'Estado del vehiculo',
            placeholder: 'Estado',
            col: 6,
            type: FormFieldType.Select,
            options:[
              {key:OptionType.SelectOptions,value:this.estados.map(e => ({key:e.id,value:e.nombre}))},
            ]
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          fecha_inicio_vigencia_seguro:{
            formControlName: 'fecha_inicio_vigencia_seguro',
            label: 'Fecha inicio vigencia seguro',
            placeholder: 'Fecha inicio vigencia seguro',
            col: 6,
            type: FormFieldType.Date,
          },
          fecha_fin_vigencia_seguro:{
            formControlName: 'fecha_fin_vigencia_seguro',
            label: 'Fecha fin vigencia seguro',
            placeholder: 'Fecha fin vigencia seguro',
            col: 6,
            type: FormFieldType.Date,
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          kilometraje:{
            formControlName: 'kilometraje',
            label: 'Kilometraje',
            placeholder: 'Kilometraje',
            col: 6,
            type: FormFieldType.Number,
            value: 0,
          },
          kilometraje_proximo_mantenimiento:{
            formControlName: 'kilometraje_proximo_mantenimiento',
            label: 'Mantenimiento cada (km)',
            placeholder: '0',
            value: 0,
            col: 6,
            type: FormFieldType.Number,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          estatus:{
            formControlName: 'estatus',
            label: 'Estatus',
            col: 12,
            type: FormFieldType.Select,
            options:[
              {key:OptionType.SelectOptions,value:[
                {key:'EN OBRA',value:'En obra'},
                {key:'VENDIDO',value:'Vendido'},
                {key:'SISNIESTRADO',value:'Siniestrado'},
                {key:'FAMILIAR',value:'Familiar'},
              ]}
            ]
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          asignado_a:{
            formControlName: 'asignado_a',
            label: 'Asignado a/Siniestro',
            placeholder: 'Solo si el vehiculo tiene estatus vendido, siniestrado o familiar',
            col: 12,
            type: FormFieldType.Input,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          poliza_file:{
            formControlName: 'poliza_file',
            label: 'Poliza seguro',
            placeholder: 'Poliza seguro',
            col: 6,
            type: FormFieldType.File,
            options:[
              {key:OptionType.fileSelectTypes,value:'application/pdf'}
            ]
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          comentarios:{
            formControlName: 'comentarios',
            label: 'Comentarios',
            placeholder: 'Comentario',
            col: 12,
            type: FormFieldType.TextArea,
          },
        }
      }
    ]
    this.nuevoVehiculoWindows = this.windowService.open(
      this.vehiculoTemplate,
      { title: 'Nuevo vehículo',context:{formConfig:formConfig}, hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: true},
    );
  }

  onSavedVehiculo(form: FormGroup) {
    removeEmptyControls(form);
    this.vehiculosService.create(form).subscribe(() => {
      this.nuevoVehiculoWindows.close();
      this.vehiculosService.getAll().subscribe((data) => {
        this.vehiculos = data;
        this.dataFiltered = data;
      });
      this.toastrService.success('Vehiculo creado con éxito',
        'Vehiculo Creado');
    }, (error) => {
      this.toastrService.danger('Error: ' + error.error.message,
        'Error al crear el vehiculo');
    });
  }

  onVehiculoEliminado($event: any) {
    if($event){
      this.vehiculosService.getAll().subscribe((data) => {
        this.vehiculos = data;
        this.searchText = '';
        this.dataFiltered = data;
      });
    }
  }
  onVehiculoSearch($event: any) {
    this.dataFiltered = $event;
  }

  onMantenimientoCreated($event: any) {
    this.vehiculosService.getAll().subscribe((data) => {
      this.vehiculos = data;
      this.dataFiltered = data;
    });
  }

  onClickDownloadReporte() {
    CSVGenerator.generateExcel(this.columnsDef, this.dataFiltered, 'vehiculos');
  }
}
