import { Component } from '@angular/core';

@Component({
  selector: 'ngx-maquinaria-filtro',
  templateUrl: './maquinaria-filtro.component.html',
  styleUrls: ['./maquinaria-filtro.component.scss']
})
export class MaquinariaFiltroComponent {

}
