<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Nomina semanales
      <button nbButton nbTooltip="Generar reporte" status="primary" (click)="downloadReporte()">
      <nb-icon icon="download-outline"></nb-icon>
      </button>
    </div>
    <div>
      <input nbInput [nbDatepicker]="dateTimePicker" placeholder="Selecciona un inicio de semana">
      <nb-datepicker #dateTimePicker [filter]="filterFn" (dateChange)="onDateChange($event)" style="padding-right: 5px ;"></nb-datepicker>
      <nb-select placeholder="Selecciona una obra" (selectedChange)="onObraChange($event)" style="padding-right: 5px;">
        <nb-option *ngFor="let obra of obras" [value]="obra.id">{{obra.nombre}}</nb-option>
      </nb-select>
      <button nbButton nbTooltip="Generar nomina" status="primary" (click)="getNominasSemanales()">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
    </div>
    <!-- <ngx-filter-value-date [items]="gastos" [filterField]="'fecha_compra'" (filterChange)="onFilterChange($event)"></ngx-filter-value-date> -->
  </nb-card-header>
  <nb-card-body class="no-padding">
    <ngx-mat-table-builder [columnsDef]="nominaSemanalConfig.tbDef" [data]="nominaSemanalConfig.data" [actions]="nominaSemanalConfig.tbActions"></ngx-mat-table-builder>
    <div class="total-container">
      <label class="total-label">Total: {{ getTotalNominaGeneral() | currency:"MXN":"symbol":"1.2-2" }}</label>

    </div>
  </nb-card-body>
</nb-card>

<ng-template let-data #nuevoGastoWindow>
  <div class="responsive-div-lg">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onGastoCreated($event)">
    </ngx-form-builder>
  </div>
</ng-template>
