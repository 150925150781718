import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Estado } from '../data/estado-data';
@Injectable()
export class EstadoService extends BaseService<Estado>{

  protected getPath(): string {
    return 'estados';
  }
}
