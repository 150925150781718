import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquiposComponent } from './equipos.component';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbSpinnerModule, NbTooltipModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EquiposService } from '../../@core/services/equipos.service';



@NgModule({
  declarations: [
    EquiposComponent
  ],
  imports: [
    CommonModule,
    MatTableBuilderModule,
    FormBuilderModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    NbSpinnerModule
  ],
  providers: [
    EquiposService
  ]
})
export class EquiposModule { }
