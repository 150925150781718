import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ExpedienteUnico } from '../../../../@core/data/expediente-unico-data';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { ExpedienteUnicoDocService } from '../../../../@core/services/expediente-unico-doc.service';
import { ExpedienteUnicoService } from '../../../../@core/services/expediente-unico.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ngx-expedientes-unicos-table',
  templateUrl: './expedientes-unicos-table.component.html',
  styleUrls: ['./expedientes-unicos-table.component.scss']
})
export class ExpedientesUnicosTableComponent implements OnChanges{
  @Input () expedientesUnicos: ExpedienteUnico[];
  datasource: MatTableDataSource<ExpedienteUnico>;
  @ViewChild('documentosView', { read: TemplateRef }) documentosView: TemplateRef<HTMLElement>;
  @ViewChild('openDelete', { read: TemplateRef }) openDelete: TemplateRef<HTMLElement>;
  @ViewChild('openEdit', { read: TemplateRef }) openEdit: TemplateRef<HTMLElement>;

  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:false}) sort: MatSort;
  fileUploadWindows: NbWindowRef;
  deleteWindows: NbWindowRef;
  editWindows: NbWindowRef;
  colums: string[] = ['id','contrato','cargo_entrega','recibe','cargo_recibe']
  customColums: string[] = ['opciones']
  allColumns: string[] = [...this.colums, ...this.customColums]

  constructor(private windowService: NbWindowService,
    private expedienteDocsService: ExpedienteUnicoDocService,
  private expedientesService:ExpedienteUnicoService,
  private toastrService:NbToastrService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expedientesUnicos) {
      this.datasource = new MatTableDataSource(changes.expedientesUnicos.currentValue);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    }

  }
  openDialogDocumentos(expedienteUnico: ExpedienteUnico) {
    this.expedienteDocsService.filterByIdExpediente(expedienteUnico.id).subscribe((docs) => {
      this.fileUploadWindows = this.windowService.open(
        this.documentosView,
        { title: 'Documentos', context: { docs: docs }, hasBackdrop: true, closeOnEsc: true },
      );
    });
  }

  onOpenDeleteExpediente(expedienteUnico: ExpedienteUnico) {
    this.deleteWindows = this.windowService.open(
      this.openDelete,
      { title: 'Eliminar Expediente', context: { expediente:expedienteUnico }, hasBackdrop: true, closeOnEsc: true },
    );
  }

  onConfirmDeleteExpediente(expedienteUnico: ExpedienteUnico) {
    this.expedientesService.delete(expedienteUnico.id).subscribe(()=>{
      this.expedientesUnicos = this.expedientesUnicos.filter((expediente) => expediente.id !== expedienteUnico.id);
      this.datasource = new MatTableDataSource(this.expedientesUnicos);
      this.deleteWindows.close();
    });
  }

  onConfirmEditExpediente() {
    this.editWindows.close();
    this.expedientesService.getAll().subscribe((expedientes) => {
      this.expedientesUnicos = expedientes;
      this.datasource = new MatTableDataSource(this.expedientesUnicos);
    });
    this.toastrService.show('Expediente editado con éxito', 'Editar Expediente', { status: 'success' });
  }


  onClickEditExpediente(expediente: ExpedienteUnico) {
    this.editWindows = this.windowService.open(
      this.openEdit,
      { title: 'Editar Expediente', context: { expediente:expediente }, hasBackdrop: true, closeOnEsc: true },
    );
  }
}
