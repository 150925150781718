import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CotizacionService } from '../../../@core/services/cotizacion.service';
import { NbAutocompleteModule, NbButton, NbButtonModule, NbCardModule, NbDatepickerModule, NbIconModule, NbInputModule, NbSelectModule, NbTooltipModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CotizacionMaquinariasTableComponent } from './cotizacion-maquinarias-table/cotizacion-maquinarias-table.component';
import { CotizacionMaquinariaService } from '../../../@core/services/cotizacion-maquinaria.service';
import { EmpleadoService } from '../../../@core/services/empleado.service';




@NgModule({
  declarations: [
    CotizacionMaquinariasTableComponent,
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    NbInputModule,
    NbButtonModule,
    NbSelectModule,
    CurrencyPipe,
    NbTooltipModule,
    NbDatepickerModule,
    ReactiveFormsModule,
    NbAutocompleteModule
  ],
  exports: [
    CotizacionMaquinariasTableComponent,
  ],
  providers: [
    CotizacionService,
    CotizacionMaquinariaService,
    EmpleadoService
  ]
})
export class CotizacionesTablesModule { }
