import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntradasNoFiscalesComponent } from './entradas-no-fiscales.component';
import { NbCardModule, NbIconModule, NbButtonModule, NbInputModule, NbDatepickerModule, NbSelectModule, NbTableModule, NbAlertModule } from '@nebular/theme';
import { FilterValueDateModule } from '../../forms/filter-value-date/filter-value-date.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { ProveedorService } from '../../@core/services/proveedor.service';



@NgModule({
  declarations: [
    EntradasNoFiscalesComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    NbDatepickerModule,
    NbSelectModule,
    NbTableModule,
    NbAlertModule,
    FilterValueDateModule,
    MatTableBuilderModule,
    FormBuilderModule
  ],
  providers: [ProveedorService]
})
export class EntradasNoFiscalesModule { }
