import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Equipo } from '../data/equipo-data';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EquiposService extends BaseService<Equipo>{
  downloadDocumento(id: number):Observable<Blob> {
    return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?download_evidencia=true`, { responseType: 'blob' });
  }

  updateEvidencia(id: number, evidencia: File): Observable<Equipo> {
    const formData = new FormData();
    formData.append('evidencia', evidencia, evidencia.name);
    return this.http.post<Equipo>(`${this.baseUrl}${this.getPath()}/${id}/actualizar-evidencia`, formData);
  }

  protected getPath(): string {
    return 'equipos';
  }

  public create(formGroup: FormGroup): Observable<Equipo> {
    const formData = new FormData();
    Object.keys(formGroup.controls).forEach(key => {
      if(formGroup.get(key).value instanceof File){
        const file = formGroup.get(key).value;
        formData.append(key, file, file.name);
      }else if(formGroup.get(key).value instanceof Date){
        console.log(formGroup.get(key).value,key);
        formData.append(key, formGroup.get(key).value.toISOString());
      }else{
        formData.append(key, formGroup.get(key).value);
      }

    });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<Equipo>(`${this.baseUrl}${this.getPath()}`, formData,{headers: headers});
  }
}
