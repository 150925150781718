import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {User} from '../../../@core/data/usuarios-data';
import {UserService} from '../../../@core/services/users.service';
import {
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
  NbWindowService,
} from '@nebular/theme';
import {convertToTree, TreeNode} from '../../../@core/utils/TreeNodeData.service';
@Component({
  selector: 'ngx-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent {

  columns = [ 'usuario', 'nombre', 'apellidos', 'email' ];
  @ViewChild('userPerfil', { read: TemplateRef }) escCloseTemplate: TemplateRef<HTMLElement>;
  datasource: NbTreeGridDataSource<User>;
  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<User>,
              private service: UserService, private windowsService: NbWindowService) {
    this.service.getAll().subscribe(data => {
      const usuarios: TreeNode<User>[] = convertToTree(data);
      this.datasource = this.dataSourceBuilder.create(usuarios);
    });
  }
  openUserPerfil(usuario: User) {
    this.windowsService.open(this.escCloseTemplate, { title: `Perfil de usuario`, hasBackdrop: true,
      context: {usuario: usuario } });
  }
}
