import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { CotizacionMaquinaria } from '../data/cotizacion-data';

@Injectable()
export class CotizacionMaquinariaService extends BaseService<CotizacionMaquinaria>{

  protected getPath(): string {
    return 'cotizacion-maquinarias';
  }

  getMaquinariasByCotizacionId(id: number) :Observable<CotizacionMaquinaria[]> {
    return this.http.get<CotizacionMaquinaria[]>(`${this.baseUrl}${this.getPath()}?cotizacion_id=${id}`);
  }
}
