import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { ContratoRH } from '../data/rh-data';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContratosRhService extends BaseService<ContratoRH>{

  protected getPath(): string {
    return 'contratos-rh';
  }

  public create(formGroup: FormGroup): Observable<ContratoRH> {
    const formData = new FormData();
    Object.keys(formGroup.controls).forEach(key => {
      if(formGroup.get(key).value instanceof File){
        const file = formGroup.get(key).value;
        formData.append(key, file, file.name);
      }else if(formGroup.get(key).value instanceof Date){
        formData.append(key, formGroup.get(key).value.toISOString());

      }else{
        formData.append(key, formGroup.get(key).value);
      }

    });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<ContratoRH>(`${this.baseUrl}${this.getPath()}`, formData,{headers: headers});
  }
  public downloadDoc(idDoc: number,key:string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}${this.getPath()}/${idDoc}?download=true&tipo=${key}`, { responseType: 'blob' });
  }

  public updateDoc(idDoc: number, file:File,key:string): Observable<ContratoRH> {
    console.log(idDoc);
    const formData = new FormData();
    formData.append('file',file,file.name);
    formData.append('tipo',key);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<ContratoRH>(`${this.baseUrl}${this.getPath()}/${idDoc}/update-file`, formData,{headers: headers});
  }

}
