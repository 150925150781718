import { Component, EventEmitter, Input, Output } from '@angular/core';
import { filterItems } from '../../@core/utils/filter-utils';

@Component({
  selector: 'ngx-filter-value-date',
  templateUrl: './filter-value-date.component.html',
  styleUrls: ['./filter-value-date.component.scss']
})
export class FilterValueDateComponent {

  filterTxt: string = "";
  @Input() items: any[] = [];
  @Input() filterFields: string[] = [];
  timeRange: any;
  @Input() filterField: string = "created_at";
  filteredItems: any[] = [];
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();

  filter(value: any) {
    this.filteredItems = filterItems(this.items,
      this.filterFields,
       value, this.timeRange?.start,
       this.timeRange?.end, this.filterField);
    this.filterChange.emit(this.filteredItems);

  }

  clearFilter() {
    this.filterTxt = "";
    this.filter(this.filterTxt);
    this.filterChange.emit(this.filteredItems);
  }

  clearDateFilter() {
    this.timeRange = undefined;
    this.filter(this.filterTxt);
    this.filterChange.emit(this.filteredItems);
  }

  filterDate(event: any) {
    this.filteredItems = filterItems(this.items, this.filterFields, this.filterTxt, event.start, event.end, this.filterField);
    this.filterChange.emit(this.filteredItems);
  }
}
