<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <!-- Datos del Siniestro -->
  <h5>Datos del Siniestro</h5>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="numero_siniestro">Número de Siniestro</label>
        <input nbInput fullWidth id="numero_siniestro" formControlName="numero_siniestro"/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="fecha">Fecha</label>
        <input nbInput fullWidth id="fecha" formControlName="fecha" type="date"/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="tipo">Tipo</label>
        <nb-select fullWidth id="tipo" formControlName="tipo">
          <nb-option value="SINIESTRO">SINIESTRO</nb-option>
          <nb-option value="ROBO">ROBO</nb-option>
        </nb-select>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label for="lugar_incidente">Lugar del Incidente</label>
        <textarea nbInput fullWidth id="lugar_incidente" formControlName="lugar_incidente"></textarea>
      </div>
    </div>
  </div>

  <!-- Datos del Vehículo -->
  <h5>Vehículo</h5>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Información del Vehículo</label>
        <div class="p-3 border rounded">
          <p class="mb-0">
            Marca: {{incidencia?.marca}},
            Modelo: {{incidencia?.modelo}},
            Placas: {{incidencia?.placas}},
            Serie: {{incidencia?.serie}},
            Color: {{incidencia?.color}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Datos del Seguro -->
  <h5>Datos del Seguro</h5>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="seguro">Seguro</label>
        <input nbInput fullWidth id="seguro" formControlName="seguro"/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="beneficiarioSeguro">Beneficiario Seguro</label>
        <input nbInput fullWidth id="beneficiarioSeguro" formControlName="beneficiarioSeguro"/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="tipo_sinestro">Tipo Siniestro</label>
        <nb-select fullWidth id="tipo_sinestro" formControlName="tipo_sinestro">
          <nb-option value="TOTAL">TOTAL</nb-option>
          <nb-option value="PARCIAL">PARCIAL</nb-option>
        </nb-select>
      </div>
    </div>
  </div>

  <!-- Datos del Ajustador -->
  <h5>Datos del Ajustador</h5>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="nombre_ajustador">Nombre Ajustador</label>
        <input nbInput fullWidth id="nombre_ajustador" formControlName="nombre_ajustador"/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="tel_ajustador">Teléfono Ajustador</label>
        <input nbInput fullWidth id="tel_ajustador" formControlName="tel_ajustador"/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="responsableSiniestro">Responsable Siniestro</label>
        <input nbInput fullWidth id="responsableSiniestro" formControlName="responsableSiniestro"/>
      </div>
    </div>
  </div>

  <!-- Datos Financieros -->
  <h5>Datos Financieros</h5>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="costo">Costo</label>
        <nb-form-field>
          <p nbPrefix>$</p>
        <input nbInput fullWidth id="costo" formControlName="costo" />
        </nb-form-field>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="total_pago">Total Pago</label>
        <nb-form-field>
          <p nbPrefix>$</p>
        <input nbInput fullWidth id="total_pago" formControlName="total_pago"/>
        </nb-form-field>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="beneficiario">Beneficiario</label>
        <input nbInput fullWidth id="beneficiario" formControlName="beneficiario"/>
      </div>
    </div>
  </div>

  <!-- Estado y Observaciones -->
  <h5>Estado del pago y Observaciones</h5>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="estatus">Estatus</label>
        <nb-select fullWidth id="estatus" formControlName="estatus">
          <nb-option value="PAGADA">PAGADA</nb-option>
          <nb-option value="EN PROCESO">EN PROCESO</nb-option>
        </nb-select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="estatus_documentos">Estatus Documentos</label>
        <nb-select fullWidth id="estatus_documentos" formControlName="estatus_documentos">
          <nb-option value="COMPLETOS">COMPLETOS</nb-option>
          <nb-option value="GENERANDO">GENERANDO</nb-option>
        </nb-select>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label for="observaciones">Observaciones</label>
        <textarea nbInput fullWidth id="observaciones" formControlName="observaciones"></textarea>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <button nbButton status="primary" type="submit">Guardar</button>
    </div>
  </div>
</form>
