import { User } from './../@core/data/usuarios-data';
import { NgModule } from '@angular/core';
import { NB_TIME_PICKER_CONFIG, NbMenuModule } from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { PagesComponent } from './pages.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { PagesRoutingModule } from './pages-routing.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import {UsersModule} from './users/users.module';
import { MaquinariasModule } from './maquinarias/maquinarias.module';
import { VehiculosModule } from './vehiculos/vehiculos.module';
import { ExpedientesUnicosModule } from './expedientes-unicos/expedientes-unicos.module';
import { CotizacionesModule } from './cotizaciones/cotizaciones.module';
import { ClientesModule } from './clientes/clientes.module';
import { EmpleadosModule } from './empleados/empleados.module';
import { GCMARQFormsModule } from '../forms/gcmarqforms.module';
import { GCMarqTablesModule } from '../tables/gcmarq-tables.module';
import { EntrevistasModule } from './entrevistas/entrevistas.module';
import { ContratosRHComponent } from './contratos-rh/contratos-rh.component';
import { ContratosRHModule } from './contratos-rh/contratos-rh.module';
import { LicenciasModule } from './licencias/licencias.module';
import { MaquinariaPesadaModule } from './tractocamiones/maquinaria-pesada.module';
import { ConfiguracionesModule } from './configuraciones/configuraciones.module';
import { FiniquitosModule } from './finiquitos/finiquitos.module';
import { EquiposModule } from './equipos/equipos.module';
import { EmpleadoSegurosModule } from './empleado-seguros/empleado-seguros.module';
import { GastosModule } from './gastos-no-fiscales/gastos.module';
import { EntradasNoFiscalesModule } from './entradas-no-fiscales/entradas-no-fiscales.module';
import { CorteNoFiscalModule } from './corte-no-fiscal/corte-no-fiscal.module';
import { EntradasFiscalesModule } from './entradas-fiscales/entradas-fiscales.module';
import { GastosFiscalesComponent } from './gastos-fiscales/gastos-fiscales.component';
import { GastosFiscalesModule } from './gastos-fiscales/gastos-fiscales.module';
import { CorteFiscalModule } from './corte-fiscal/corte-fiscal.module';
import { NominaSemanalModule } from './nomina-semanal/nomina-semanal.module';
import { NominasModule } from './nominas/nominas.module';
import { SiniestrosModule } from './siniestros/siniestros.module';

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    DashboardModule,
    MiscellaneousModule,
    UsersModule,
    MaquinariasModule,
    VehiculosModule,
    ExpedientesUnicosModule,
    CotizacionesModule,
    ClientesModule,
    EmpleadosModule,
    GCMARQFormsModule,
    GCMarqTablesModule,
    EntrevistasModule,
    ContratosRHModule,
    LicenciasModule,
    MaquinariaPesadaModule,
    ConfiguracionesModule,
    FiniquitosModule,
    EquiposModule,
    EmpleadoSegurosModule,
    GastosModule,
    EntradasNoFiscalesModule,
    CorteNoFiscalModule,
    EntradasFiscalesModule,
    GastosFiscalesModule,
    CorteFiscalModule,
    NominaSemanalModule,
    NominasModule,
    UsersModule,
    SiniestrosModule
  ],
  declarations: [
    PagesComponent,
  ],
  providers:[
    {
      provide:NB_TIME_PICKER_CONFIG,
      useValue:{}
    }
  ]
})
export class PagesModule {
}
