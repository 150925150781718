import { Component } from '@angular/core';

@Component({
  selector: 'ngx-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent {

}
