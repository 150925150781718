import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ExpedienteUnicoDoc } from '../../../../@core/data/expediente-unico-doc-data';
import { ExpedienteUnicoDocService } from '../../../../@core/services/expediente-unico-doc.service';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';

@Component({
  selector: 'ngx-expediente-documentos-form',
  templateUrl: './expediente-documentos-form.component.html',
  styleUrls: ['./expediente-documentos-form.component.scss']
})
export class ExpedienteDocumentosFormComponent {
  @Input() docsExpediente: ExpedienteUnicoDoc[] = [];
  @ViewChild('fileInputWindow', { read: TemplateRef }) fileInputWindow: TemplateRef<HTMLElement>;
  fileInputWindowRef: NbWindowRef;
  filSelected: File|null = null;
  constructor(private expedienteDosService: ExpedienteUnicoDocService, private windowService: NbWindowService,
    private toastrService: NbToastrService
  ) { }



  onFileSelected(event: any) {
    this.filSelected = event.target.files[0];

  }

  onSavedDoc(doc: ExpedienteUnicoDoc) {
    this.expedienteDosService.uploadDoc(doc.id,this.filSelected).subscribe((data) => {
      const index = this.docsExpediente.findIndex((doc) => doc.id === data.id);
      this.docsExpediente[index] = data;
      this.fileInputWindowRef.close();
      this.toastrService.show('Documento guardado con éxito',
        'Nuevo Documento', { status: 'success' });
    });
    this.filSelected = null;
  }

  openUploadFileWindow(doc: ExpedienteUnicoDoc) {
    this.fileInputWindowRef=this.windowService.open(
      this.fileInputWindow,
      { title: 'Subir documento', hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: true, context: { doc:doc } },
    );
  }
  viewFile(doc: ExpedienteUnicoDoc) {
    this.expedienteDosService.downloadDoc(doc.id).subscribe((data) => {
      const url = window.URL.createObjectURL(data);
      window.open(url);
    });
  }
}
