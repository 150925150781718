import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ngx-api-select',
  templateUrl: './api-select.component.html',
  styleUrls: ['./api-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApiSelectComponent),
      multi: true
    }
  ]
})
export class ApiSelectComponent {
  @Input() id: string = '';
  @Input() placeholder: string = '';
  @Input() class: string = '';
  @Input() formControlName: string = '';
  @Output() valueSelected: EventEmitter<any> = new EventEmitter<any>();
}
