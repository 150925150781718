import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Categoria } from '../data/maquinaria-data';

@Injectable()
export class CategoriaService extends BaseService<Categoria>{
  protected getPath(): string {
    return 'categorias';
  }
}
