import { CategoriaService } from './../../@core/services/categoria.service';
import { Categoria, Maquinaria, Obra } from "./../../@core/data/maquinaria-data";
import { MaquinariaService } from "../../@core/services/maquinaria.service";
import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { Title } from "@angular/platform-browser";
import {
  NbGlobalLogicalPosition,
  NbToastrService,
  NbWindowRef,
  NbWindowService,
} from "@nebular/theme";
import { FormGroup } from "@angular/forms";
import {
  FormBuilderConfig,
  FormFieldType,
  FormRowConfig,
  OptionType,
} from "../../form-builder/form-builder.component";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import { ObraService } from '../../@core/services/obra.service';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Empleado } from '../../@core/data/cotizacion-data';
import { Tipo_conductor } from '../../@core/enums/tipo_conductor.enum';
import { forkJoin } from 'rxjs';
import { CSVGenerator } from '../../@core/utils/csv-generator';

@Component({
  selector: "ngx-maquinarias",
  templateUrl: "./maquinarias.component.html",
  styleUrls: ["./maquinarias.component.scss"],
})
export class MaquinariasComponent {
  loadingNewButton: boolean=true;
  searchText: string = "";
  maquinaria: Maquinaria[];
  maquinariaFiltered: Maquinaria[];
  categorias: Categoria[];
  obras: Obra[];
  empleados:Empleado[];
  nuevaMaquinariaWindows: NbWindowRef;
  @ViewChild("nuevaMaquinaria", { read: TemplateRef })
   nuevaMaquinariaTemplate: TemplateRef<HTMLElement>;
  @ViewChild("mantenimientos", { read: TemplateRef })
  mantenimientosTemplate: TemplateRef<HTMLElement>;
  mantenimientosRef: NbWindowRef;
  tableDef: MatTableColumnConfig[] = [
    { name: "numero", label: "No. Económico", type: "text" },
    { name: "nombre", label: "Nombre", type: "text" },
    { name: "serie", label: "Serie", type: "text" },
    { name: "modelo", label: "Modelo", type: "text" },
    {name: "categoria", label: "Categoria", type: "text"},
    { name: "obra", label: "Ubicación", type: "text", },
    { name: "operador", label: "Operador", type: "text" },
    { name: "horometro", label: "Horometro", type: "text" },
    {name: "ultimo_mantenimiento", label:"Ult. mtto.", type:"text",
      value: (m:Maquinaria)=>{
        if(m.mantenimiento_horas_restantes==0){
          return "No hay un mantenimiento programado";
        }
        return (m.horometro_original_matenimiento);
      },
    },
    {name: "manetenimiento_horas", label:"Sig. mtto. en", type:"text",
      value: (m:Maquinaria)=>{
        if(m.mantenimiento_horas_restantes==0){
          return "No hay un mantenimiento programado";
        }
        return (m.horometro_original_matenimiento + m.mantenimiento_horas_restantes - m.horometro) + " horas.";
      },
      backgroundColor: (m:Maquinaria)=>{
        if(m.horometro_original_matenimiento + m.mantenimiento_horas_restantes - m.horometro<=0){
          return "red";
        } else if(m.horometro_original_matenimiento + m.mantenimiento_horas_restantes - m.horometro<=50){
          return "yellow";
        }
      }
    },
    {
      name: "mantenimiento_horas_restantes",
      label: "Mantenimiento cada/hrs",
      type: "text",
    },
  ];
  actions: MatTableActionConfig[] = [
    {
      name:"mantenimientos",
      label:"Ver historial de mantenimiento",
      icon:"eye",
      action:(m:Maquinaria)=>{
        this.mantenimientosRef = this.windowService.open(this.mantenimientosTemplate,{title:"Historial de mantenimiento",context:{maquinaria:m}});
      }
    },
    {
      name: "edit",
      label: "Agregar horas de trabajo",
      icon: "edit",
      action: (form:FormGroup,m:Maquinaria,ref) => {
        this.maquinariasService.update(form,m.id).subscribe(
          () => {
            this.maquinariasService.getMaquinarias().subscribe((data) => {
              this.maquinaria = data;
              this.maquinariaFiltered = data;
            });
            this.toastrService.success(
              "Las horas de trabajo han sido agregadas correctamente",
              "Horas de trabajo agregadas"
            );
          },
          (error) => {
            this.toastrService.danger(
              "Hubo un error al agregar las horas de trabajo",
              "Error al agregar las horas de trabajo"
            );
          });
          ref.close();
      },
      formConfig: (): FormBuilderConfig => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              horas_trabajadas: {
                type: FormFieldType.Number,
                label: "Horas trabajadas",
                formControlName: "horas_trabajadas",
                placeholder: "0",
                value: 0,
                col: 12,
                validations: ["required"],
              },
            },
          },
        ];
      },
    },
    {
      name: "view",
      label: "Ver pedimento",
      icon: "eye",
      action: (m: Maquinaria) => {
        this.maquinariasService.downloadPedimento(m.id).subscribe((data) => {
          const blob = new Blob([data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          window.open(url);
        },
        (error)=>{
          if(error.status==404){
            this.toastrService.danger(
              "La maquinaria no tiene pedimento",
              "Error"
            );
          }
          else{
            this.toastrService.danger(
              "Hubo un error al descargar el pedimento",
              "Error"
            );
          }
        });
      }
    },
    {
      name: "view",
      label: "Ver poliza",
      icon: "eye",
      action: (m: Maquinaria) => {
        this.maquinariasService.downloadPoliza(m.id).subscribe((data) => {
          const blob = new Blob([data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },(error)=>{
          if(error.status==404){
            this.toastrService.danger(
              "La maquinaria no tiene poliza",
              "Error"
            );
          }
          else{
            this.toastrService.danger(
              "Hubo un error al descargar la poliza",
              "Error"
            );
          }

        });
      }
    },
    {
      name: "edit",
      label: "Editar",
      icon: "edit",
      formConfig: (m: Maquinaria): FormBuilderConfig => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              nombre: {
                type: FormFieldType.Input,
                label: "Nombre*",
                formControlName: "nombre",
                value: m.nombre,
                col: 6,
                validations: ["required"],
              },
              numero: {
                type: FormFieldType.Input,
                label: "No. Economico*",
                formControlName: "numero",
                value: m.numero,
                col: 6,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              serie: {
                type: FormFieldType.Input,
                label: "Serie*",
                formControlName: "serie",
                col: 6,
                value: m.serie,
                validations: ["required"],
              },
              modelo: {
                type: FormFieldType.Input,
                label: "Modelo*",
                formControlName: "modelo",
                col: 6,
                value: m.modelo,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              horometro: {
                type: FormFieldType.Number,
                label: "Horometro",
                formControlName: "horometro",
                placeholder: "0",
                value: m.horometro,
                col: 6,
                validations: ["required"],
              },
              mantenimiento_horas_restantes: {
                type: FormFieldType.Number,
                label: "Horas restantes para el sig. mtto.",
                formControlName: "mantenimiento_horas_restantes",
                placeholder: "0",
                value: m.mantenimiento_horas_restantes,
                col: 6,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              categoria_id: {
                type: FormFieldType.Select,
                label: "Categoria",
                formControlName: "categoria_id",
                placeholder: "Seleccionar Categoria",
                col: 6,
                value: m.categoria_id,
                validations: ["required"],
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: this.categorias?.map((c) => {
                      return { key: c.id, value: c.nombre };
                    }),
                  },
                ],
              },
              obra_id: {
                type: FormFieldType.Select,
                label: "Ubicacion",
                placeholder: "Seleccionar la obran en la que se encuentra",
                formControlName: "obra_id",
                value: m.obra_id,
                options: [{ key: OptionType.SelectOptions, value: this.obras?.map((o) => {
                  return { key: o.id, value: o.nombre };
                })}],
                col: 6,
                validations: ["required"],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              operador_id: {
                type: FormFieldType.Select,
                label: "Operdador",
                placeholder: "Seleccionar un operador",
                formControlName: "operador_id",
                col: 12,
                value: m.operador_id,
                options: [{ key: OptionType.SelectOptions, value:
                  this.empleados?.filter(e=>e.tipo_conductor==Tipo_conductor.OPERADOR).map((e) => {
                    return { key: e.id, value: `${e.nombre} ${e.apellido_paterno} ${e.apellido_materno}` };
                  })
                 }],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              obra_id: {
                type: FormFieldType.Select,
                label: "Obra",
                placeholder: "Seleccionar una obra",
                formControlName: "obra_id",
                col: 12,
                value: m.obra_id,
                options: [{ key: OptionType.SelectOptions, value: this.obras?.map((o) => {
                  return { key: o.id, value: o.nombre };
                })}],
              },
            },
          },

          {
            type: FormFieldType.Row,
            fields: {
              pedimento: {
                type: FormFieldType.File,
                label: "Pedimento",
                formControlName: "pedimento",
                col: 12,
                value: null,
                options: [
                  { key: OptionType.fileSelectTypes, value: "application/pdf" },
                ],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              poliza_file: {
                type: FormFieldType.File,
                label: "Poliza",
                placeholder: "Seleccionar Archivo",
                formControlName: "poliza_file",
                col: 12,
                value: null,
                options: [
                  { key: OptionType.fileSelectTypes, value: "application/pdf" },
                ],
              },
            },
          }
        ];
      },
      action: (form: FormGroup, m: Maquinaria, windowRef) => {
        this.maquinariasService.update(form, m.id).subscribe(
          () => {
            this.toastrService.success(
              "La maquinaria ha sido editada correctamente",
              "Maquinaria Editada"
            );
            let pedimento = form.get("pedimento");

            if(pedimento.value!=null){
              this.maquinariasService
              .updatePedimento(m.id, pedimento.value)
              .subscribe(
                () => {
                  this.toastrService.success(
                    "El pedimento ha sido actualizado correctamente",
                    "Pedimento Actualizado"
                  );
                  windowRef.close();
                },
                (error) => {
                  this.toastrService.danger(
                    "Hubo un error al actualizar el pedimento",
                    "Error al actualizar el pedimento"
                  );
                }
              );
            }
            let poliza = form.get("poliza_file");
            if(poliza.value!=null){
              this.maquinariasService
              .updatePoliza(m.id, poliza.value)
              .subscribe(
                () => {
                  this.toastrService.success(
                    "La poliza ha sido actualizada correctamente",
                    "Poliza Actualizada"
                  );
                  windowRef.close();
                },
                (error) => {
                  this.toastrService.danger(
                    "Hubo un error al actualizar la poliza",
                    "Error al actualizar la poliza"
                  );
                }
              );
            }
          },
          (error) => {
            this.toastrService.danger(
              "Hubo un error al editar la maquinaria",
              "Error al editar la maquinaria"
            );
          }
        );
        this.maquinariasService.getMaquinarias().subscribe((data) => {
          this.maquinaria = data;
          this.maquinariaFiltered = data;
        });
        windowRef.close();
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      icon: "trash",
      message: `¿Estas seguro que deseas eliminar la maquinaria?`,
      title: "Eliminar Maquinaria",
      action: (m:Maquinaria) => {
        this.maquinariasService.delete(m.id).subscribe(
          () => {
            this.toastrService.success(
              "La maquinaria ha sido eliminada correctamente",
              "Maquinaria Eliminada"
            );
            this.maquinariasService.getMaquinarias().subscribe((data) => {
              this.maquinaria = data;
              this.maquinariaFiltered = data;
            });
            this.onMaquinariaSearch();
          },
          (error) => {
            this.toastrService.danger(
              "Hubo un error al eliminar la maquinaria",
              "Error al eliminar la maquinaria"
            );
          }
        );
      },
    },
  ];

  constructor(
    private maquinariasService: MaquinariaService,
    private titleService: Title,
    private windowService: NbWindowService,
    private toastrService: NbToastrService,
    private categoriaService: CategoriaService,
    private obraService: ObraService,
    private empleadoService: EmpleadoService
  ) {
    this.maquinariasService.getMaquinarias().subscribe((data) => {
      this.maquinaria = data;
      this.maquinariaFiltered = data;
    });
    forkJoin
    ([
      this.categoriaService.getAll(),
      this.obraService.getAll(),
      this.empleadoService.getAll()
    ]).subscribe(([categorias, obras, empleados]) => {
      this.categorias = categorias;
      this.obras = obras;
      this.empleados = empleados;
      this.loadingNewButton = false;
    });
    this.titleService.setTitle("Maquinaria");
  }
  onClickOpenNuevaMaquinaria() {
    let formConfig: FormRowConfig[] = [
      {
        type: FormFieldType.Row,
        fields: {
          nombre: {
            type: FormFieldType.Input,
            label: "Nombre*",
            formControlName: "nombre",
            value: "",
            col: 6,
            validations: ["required"],
          },
          numero: {
            type: FormFieldType.Input,
            label: "No. Economico*",
            formControlName: "numero",
            value: "",
            col: 6,
            validations: ["required"],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          serie: {
            type: FormFieldType.Input,
            label: "Serie*",
            formControlName: "serie",
            col: 6,
            value: "",
            validations: ["required"],
          },
          modelo: {
            type: FormFieldType.Input,
            label: "Modelo*",
            formControlName: "modelo",
            col: 6,
            value: "",
            validations: ["required"],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          categoria:{
            type: FormFieldType.Select,
            label: "Categoria",
            formControlName: "categoria_id",
            placeholder: "Seleccionar Categoria",
            col: 6,
            value: "",
            validations: ["required"],
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.categorias?.map((c) => {
                  return { key: c.id, value: c.nombre };
                }),
              },
            ]
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          horometro: {
            type: FormFieldType.Number,
            label: "Horometro",
            formControlName: "horometro",
            placeholder: "0",
            value: 0,
            col: 6,
            validations: ["required"],
          },
          mantenimiento_horas_restantes: {
            type: FormFieldType.Number,
            label: "Mantenimiento cada/hrs",
            formControlName: "mantenimiento_horas_restantes",
            placeholder: "0",
            value: 0,
            col: 6,
            validations: ["required"],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          operador_id: {
            type: FormFieldType.Select,
            label: "Operdador",
            placeholder: "Seleccionar un operador",
            formControlName: "operador_id",
            col: 12,
            value: "",
            options: [{ key: OptionType.SelectOptions, value:
              this.empleados?.filter(e=>e.tipo_conductor==Tipo_conductor.OPERADOR).map((e) => {
                return { key: e.id, value: `${e.nombre} ${e.apellido_paterno} ${e.apellido_materno}` };
              })
             }],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          obra_id: {
            type: FormFieldType.Select,
            label: "Obra",
            placeholder: "Seleccionar una obra",
            formControlName: "obra_id",
            col: 12,
            value: "",
            options: [{ key: OptionType.SelectOptions, value: this.obras?.map((o) => {
              return { key: o.id, value: o.nombre };
            })}],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          pedimento: {
            type: FormFieldType.File,
            label: "Pedimento",
            formControlName: "pedimento",
            placeholder: "Seleccionar Archivo",
            col: 12,
            value: null,
            options: [
              { key: OptionType.fileSelectTypes, value: "application/pdf" },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          poliza_file: {
            type: FormFieldType.File,
            label: "Poliza",
            placeholder: "Seleccionar Archivo",
            formControlName: "poliza_file",
            col: 12,
            value: null,
            options: [
              { key: OptionType.fileSelectTypes, value: "application/pdf" },
            ],
          },
        },
      }
    ];
    this.nuevaMaquinariaWindows = this.windowService.open(
      this.nuevaMaquinariaTemplate,
      { title: "Nueva Maquinaria", context: { formConfig: formConfig } }
    );
  }

  onMaquinariaSearch() {
    this.maquinariaFiltered = this.maquinaria.filter((maquinaria) => {
      return (
        maquinaria.nombre
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.numero
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.serie
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.modelo?.toLowerCase()
          ?.includes(this.searchText.toLowerCase()) ||
        maquinaria.placas?.toLowerCase()
          ?.includes(this.searchText.toLowerCase()) ||
        maquinaria.ubicacion?.toLowerCase()
          ?.includes(this.searchText?.toLowerCase()) ||
        maquinaria.lts
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.toneladas
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.empleado
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.precio
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        maquinaria.mantenimiento
          ?.toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase())||
        maquinaria.categoria
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    });
  }

  onMaquinariaCreated(form: FormGroup) {
    this.maquinariasService.create(form).subscribe((m) => {
      this.nuevaMaquinariaWindows.close();
      this.toastrService.success(
        "La maquinaria ha sido creada correctamente",
        "Maquinaria Creada",
        {
          duration: 5000,
          status: "success",
          position: NbGlobalLogicalPosition.BOTTOM_END,
          hasIcon: false,
        }
      );
      let pedimento = form.get("pedimento");
      if(pedimento.value!=null){
        this.maquinariasService
        .updatePedimento(m.id, pedimento.value)
        .subscribe(
          () => {
            this.toastrService.success(
              "El pedimento ha sido actualizado correctamente",
              "Pedimento Actualizado"
            );
          },
          (error) => {
            this.toastrService.danger(
              "Hubo un error al actualizar el pedimento",
              "Error al actualizar el pedimento"
            );
          }
        );
      }
      let poliza = form.get("poliza_file");
      if(poliza.value!=null){
        this.maquinariasService
        .updatePoliza(m.id, poliza.value)
        .subscribe(
          () => {
            this.toastrService.success(
              "La poliza ha sido actualizada correctamente",
              "Poliza Actualizada"
            );
          },
          (error) => {
            this.toastrService.danger(
              "Hubo un error al actualizar la poliza",
              "Error al actualizar la poliza"
            );
          }
        );
      }
      this.maquinariasService.getMaquinarias().subscribe((data) => {
        this.maquinaria = data;
        this.maquinariaFiltered = data;
      });
      this.onMaquinariaSearch();
    });
  }

  onMaquinariaEdited() {
    this.maquinariasService.getAll().subscribe((data) => {
      this.maquinaria = data;
      this.maquinariaFiltered = data;
    });
    this.toastrService.success(
      "La maquinaria ha sido editada correctamente",
      "Maquinaria Editada",
      {
        duration: 5000,
        status: "success",
        position: NbGlobalLogicalPosition.BOTTOM_END,
        hasIcon: false,
      }
    );
    this.onMaquinariaSearch();
  }

  onClickGenerateReport(){
    CSVGenerator.generateExcel(this.tableDef,this.maquinariaFiltered,"maquinaria");
  }

}
