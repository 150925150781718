import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Incidencia } from '../../../@core/data/incidencias-data';

@Component({
  selector: 'ngx-edit-incidencia-form',
  templateUrl: './edit-incidencia-form.component.html',
  styleUrls: ['./edit-incidencia-form.component.scss']
})
export class EditIncidenciaFormComponent implements OnChanges{

  @Input() incidencia: Incidencia;
  @Output() emitForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  form: FormGroup;

  constructor(private fb:FormBuilder) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.incidencia) {
      this.form = this.fb.group({
        vehiculo_id: [this.incidencia.vehiculo_id, Validators.required],
        numero_siniestro: [this.incidencia.numero_siniestro],
        fecha: [this.incidencia.fecha],
        tipo: [this.incidencia.tipo],
        color: [this.incidencia.color],
        anio: [this.incidencia.anio],
        marca: [this.incidencia.marca],
        modelo: [this.incidencia.modelo],
        seguro: [{value: this.incidencia.seguro, disabled: true}],
        serie: [this.incidencia.serie],
        estatus_documentos: [this.incidencia.estatus_documentos],
        placas: [this.incidencia.placas],
        beneficiarioSeguro: [this.incidencia.beneficiarioSeguro],
        responsableSiniestro: [this.incidencia.responsableSiniestro],
        costo: [this.incidencia.costo],
        total_pago: [this.incidencia.total_pago],
        tel_ajustador: [this.incidencia.tel_ajustador],
        nombre_ajustador: [this.incidencia.nombre_ajustador],
        beneficiario: [this.incidencia.beneficiario],
        observaciones: [this.incidencia.observaciones],
        estatus: [this.incidencia.estatus],
        tipo_sinestro: [this.incidencia.tipo_sinestro],
        lugar_incidente: [this.incidencia.lugar_incidente],
      });

      this.formatNumber(this.form.get('costo').value,'costo');
      this.formatNumber(this.form.get('total_pago').value,'total_pago');

      this.form.get('total_pago').valueChanges.subscribe((value) => {
        if (value) {
          this.formatNumber(value,'total_pago');
        }
      });


      this.form.get('costo').valueChanges.subscribe((value) => {
        if (value) {
          this.formatNumber(value,'costo');
        }
      });

      this.form.get('total_pago').valueChanges.subscribe((value) => {
        if (value) {
          this.formatNumber(value,'total_pago');
        }
      });
    }
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.emitForm.emit(this.form);
    }
  }

  formatNumber(value: string,form:string): void {
    if (value === null || value === undefined || value === '') {
      return;
    }

    // Elimina comas del valor actual
    const unformattedValue = value.toString().replace(/,/g, '');

    // Convierte a número
    const numericValue = parseFloat(unformattedValue);

    if (!isNaN(numericValue)) {
      // Actualiza el control con el valor formateado
      this.form.get(form)
      .setValue(
        numericValue.toLocaleString('en-US', { maximumFractionDigits: 2 }),
        { emitEvent: false } // Evita bucles infinitos
      );
    }
  }
}
