import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { ExpedienteUnicoDoc } from '../data/expediente-unico-doc-data';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ExpedienteUnicoDocService extends BaseService<ExpedienteUnicoDoc> {
  protected getPath(): string {
    return 'expedientes-unicos-docs'
  }

  public filterByIdExpediente(idExpediente: number): Observable<ExpedienteUnicoDoc[]> {
    return this.http.get<ExpedienteUnicoDoc[]>(`${this.baseUrl}${this.getPath()}?expediente_id=${idExpediente}`);
  }

  uploadDoc(id: number, file: File): Observable<ExpedienteUnicoDoc> {
    const formData = new FormData();
    formData.append('file', file,file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<ExpedienteUnicoDoc>(`${this.baseUrl}${this.getPath()}/${id}/upload`, formData,{headers: headers});
  }

  downloadDoc(idDoc: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}${this.getPath()}/${idDoc}?download=true`, { responseType: 'blob' });
  }
}
