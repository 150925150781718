import { Corte, EntradaNoFiscal, Gasto } from './../data/gastos-data';
import { MatTableColumnConfig } from "../../mat-table-builder/mat-table-builder.component";
import * as ExcelJS from 'exceljs';

export class CSVGenerator {
  static generateCSV(headers: string[], data: any[][], filename: string): void {
    const encabezados = headers.join(',');
    const filas = data.map(fila => fila.map(value => typeof value === 'boolean' ? (value ? 'activo' : 'inactivo') : value).join(','));
    const contenidoCSV = [encabezados, ...filas].join('\n');

    const blob = new Blob([contenidoCSV], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  static generateCSVColumnDefs(columnDefs: MatTableColumnConfig[], data: any[], filename: string): void {
    const encabezados = columnDefs.map(columna => columna.label);
    const filas = data.map(item =>
      columnDefs.map(columna => {
        let value = typeof columna.value === 'function' ? columna.value(item) : item[columna.name];
        return typeof value === 'boolean' ? (value ? 'activo' : 'inactivo') : value;
      })
    );

    const contenidoCSV = [encabezados.join(','), ...filas.map(fila => fila.join(','))].join('\n');

    const blob = new Blob([contenidoCSV], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  static generateExcel(columnDefs: MatTableColumnConfig[], data: any[], fileName: string): void {
    this.generateExcelWithCustomBooleanMessages(columnDefs, data, fileName, {});
  }

  static generateExcelWithCustomBooleanMessages(columnDefs: MatTableColumnConfig[], data: any[], fileName: string, booleanMessages: { [key: string]: [string, string] }): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Add headers
    const headers = columnDefs.map(columna => columna.label);
    worksheet.addRow(headers);

    // Add data
    data.forEach((item) => {
      const row = columnDefs.map(columna => {
        let value = typeof columna.value === 'function' ? columna.value(item) : item[columna.name];
        if (typeof value === 'boolean' && booleanMessages[columna.name]) {
          value = value ? booleanMessages[columna.name][0] : booleanMessages[columna.name][1];
        }
        return value;
      });
      worksheet.addRow(row);
    });

    // Adjust column widths
    headers.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1);
      let maxLength = header.length;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        if (cellValue.length > maxLength) {
          maxLength = cellValue.length;
        }
      });
      column.width = maxLength + 2; // Add some padding
    });

    // Define the table range
    const tableRange = {
      start: { row: 1, col: 1 },
      end: { row: data.length + 1, col: headers.length }
    };

    // Add table
    worksheet.addTable({
      name: 'Table1',
      ref: `A1`,
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium2',
        showRowStripes: true,
      },
      columns: headers.map(header => ({ name: header })),
      rows: data.map(item => columnDefs.map(columna => {
        let value = typeof columna.value === 'function' ? columna.value(item) : item[columna.name];
        if (typeof value === 'boolean' && booleanMessages[columna.name]) {
          value = value ? booleanMessages[columna.name][0] : booleanMessages[columna.name][1];
        }
        return value;
      }))
    });

    // Save the workbook to a blob
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${fileName}.xlsx`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  static generateCorteExcel(entradas: EntradaNoFiscal[], gastos: Gasto[], corte: Corte, filename: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Corte');

    // Add Corte data as a card
    worksheet.addRow(['Corte']).font = { bold: true, size: 16 };
    worksheet.addRow(['Fecha', corte.fecha]).font = { bold: true };
    worksheet.addRow(['Total Entradas', +corte.total_importes_entradas]).font = { bold: true };
    worksheet.addRow(['Total Gastos', +-corte.total_importes_gastos]).font = { bold: true };
    worksheet.addRow(['Total', +corte.total]).font = { bold: true };
    worksheet.addRow([]); // Empty row for spacing


    // Add Entradas and Gastos table
    const tableHeaders = ['Cliente/Proveedor', 'Concepto', 'Importe', 'Banco/Obra', 'Cuenta/Comentario'];
    const tableRows = [
      ...entradas.map(e => [e.cliente_nombre, e.concepto, e.importe, e.banco_nombre, e.cuenta]),
      ...gastos.map(g => [g.proveedor, g.concepto, -g.importe, g.obra, g.comentario])
    ];


    // Add Entradas and Gastos table
    worksheet.addTable({
      name: 'EntradasGastosTable',
      ref: `A8`,
      headerRow: true,
      totalsRow: false,
      style: {
      theme: 'TableStyleMedium2',
      showRowStripes: true,
      },
      columns: tableHeaders.map(header => ({ name: header })),
      rows: tableRows
    });

    // Adjust column widths to fit the content
    tableHeaders.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1);
      let maxLength = header.length;
      tableRows.forEach(row => {
      const cellValue = row[index] ? row[index].toString() : '';
      if (cellValue.length > maxLength) {
        maxLength = cellValue.length;
      }
      });
      column.width = maxLength + 2; // Add some padding
    });


    worksheet.addRow([]); // Empty row for spacing

    // Add Total
    worksheet.addRow([ '', 'Total del día', +corte.total_importes_entradas - corte.total_importes_gastos])
    worksheet.addRow([ '', 'Total corte anterior', +corte.total_anterior])
    worksheet.addRow([ '', 'Total corte actual', +corte.total])

    // Save the workbook to a blob
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${filename}.xlsx`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }
}
