import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Empleado } from '../data/cotizacion-data';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService extends BaseService<Empleado>{
  downloadFoto(id: number,url:boolean =false) :Observable<any> {
    if(url){
      return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?url=true`, { responseType: 'text' });
    }
    return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?foto=true`,{ responseType: 'blob' });
  }
  getEmpleadosFirma(): Observable<Empleado[]> {
    return this.http.get<Empleado[]>(this.baseUrl + this.getPath()+"?emlpeado_firma=true");
  }

  public create(formGroup: FormGroup): Observable<Empleado> {
    if(!formGroup.get('foto').value){
      formGroup.removeControl('foto');
    }
    const formData = new FormData();
    Object.keys(formGroup.controls).forEach(key => {
      if(formGroup.get(key).value instanceof File){
        const file = formGroup.get(key).value;
        formData.append(key, file, file.name);
      }else if(formGroup.get(key).value instanceof Date){
        console.log(formGroup.get(key).value,key);
        formData.append(key, formGroup.get(key).value.toISOString());
      }else if(formGroup.get(key).value instanceof Boolean){
        formData.append(key, formGroup.get(key).value ? '1' : '0');

      }
      else{
        formData.append(key, formGroup.get(key).value);
      }

    });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<Empleado>(`${this.baseUrl}${this.getPath()}`, formData,{headers: headers});
  }

  public updateFoto(id:number,foto:File):Observable<Empleado>{
    const formData = new FormData();
    formData.append('foto',foto,foto.name);
    return this.http.post<Empleado>(`${this.baseUrl}${this.getPath()}/${id}/actualizar-foto`,formData);
  }

  getPath(): string {
    return 'empleados';
  }
}
