import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthService, NbAuthJWTToken, NbAuthSimpleToken } from '@nebular/auth';
import { log } from 'console';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
    constructor(private authService: NbAuthService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authService.onTokenChange()
        .subscribe((token: NbAuthSimpleToken) => {
          const tokenStorage = localStorage.getItem('token');
            if(token.isValid()){
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token.getValue()}`
                    }
                });
                return next.handle(request);
            }else if(tokenStorage){
              request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${tokenStorage}`
                }
            });
            }
        });
        return next.handle(request);
    }}
