import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import { ContratosRhService } from "../../@core/services/contratos-rh.service";
import { ContratoRH } from "../../@core/data/rh-data";
import { NbToastrService, NbWindowRef, NbWindowService } from "@nebular/theme";
import {
  FormBuilderConfig,
  FormFieldType,
  FormRowConfig,
  OptionType,
} from "../../form-builder/form-builder.component";
import { EmpleadoService } from "../../@core/services/empleado.service";
import { FormGroup } from "@angular/forms";
import { Empleado, EmpleadoCategoria } from "../../@core/data/cotizacion-data";
import { EmpleadoCategoriasService } from "../../@core/services/empleado-categorias.service";
import { Categoria, Obra } from "../../@core/data/maquinaria-data";
import { CategoriaService } from "../../@core/services/categoria.service";
import { ObraService } from "../../@core/services/obra.service";
import { forkJoin } from "rxjs";
import { removeEmptyControls } from "../../@core/utils/forms-util";

@Component({
  selector: "ngx-contratos-rh",
  templateUrl: "./contratos-rh.component.html",
  styleUrls: ["./contratos-rh.component.scss"],
})
export class ContratosRHComponent implements OnInit {
  @ViewChild("newWindow", { read: TemplateRef })
  nuevaEntrevistaWindow: TemplateRef<HTMLElement>;
  @ViewChild("editWindow", { read: TemplateRef })
  editWindow: TemplateRef<HTMLElement>;
  clienteWindowRef: NbWindowRef;
  editWindowRef: NbWindowRef;
  data: ContratoRH[] = [];
  filteredData: ContratoRH[] = [];
  empleados: Empleado[] = [];
  obras: Obra[] = [];
  columns: MatTableColumnConfig[] = [
    { name: "id", label: "ID", value: "id" },
    {
      name: "empleado",
      label: "Empleado",
      value: (row: ContratoRH) =>
        `${row.empleado_nombre} ${row.empleado_apellido_paterno} ${row.empleado_apellido_materno}`,
    },
    { name: "fecha_inicio", label: "Fecha Inicio", value: "fecha_inicio" },
    { name: "fecha_fin", label: "Fecha Fin", value: "fecha_fin" },
    {
      name: "fecha_renovacion",
      label: "Fecha Renovación",
      value: "fecha_renovacion",
    },
    { name: 'direccion', label: 'Dirección', value: 'direccion' },
    { name: 'contacto', label: 'Num. Tel.', value: 'contacto' },
    { name: 'emer_nombre', label: 'Nombre de Emergencia', value: 'emer_nombre' },
    { name: 'emer_tel', label: 'Teléfono de Emergencia', value: 'emer_tel' },
    { name: 'empleado_categoria', label: 'Categoría', value: 'empleado_categoria' },
    { name: 'obra', label: 'Nómina', value: 'obra' },
    { name: 'sueldo_base', label: 'Sueldo Base', value: 'sueldo_base' },
    { name: 'gratificacion', label: 'Gratificación', value: 'gratificacion' },
    { name: 'universidad', label: 'Universidad', value: 'universidad' },
  ];

  acciones: MatTableActionConfig[] = [
    {
      name:"updload",
      label:"Administrar documentos",
      icon:"cloud-upload-outline",
      action: (row) => {
        this.editWindowRef = this.windowService.open(this.editWindow, {
          title: "Administrar documentos",
          context: { formConfig: this.formConfig, contrato: row },
        });
      },

    },
    {
      name: "edit",
      label: "Editar",
      icon: "edit-2-outline",
      formSize: "medium",
      formConfig: (row:ContratoRH):FormRowConfig[] => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              empleado_id: {
                type: FormFieldType.Select,
                col: 6,
                options: [
                  {key: OptionType.SelectOptions, value: this.empleados.map((empleado) => ({ key: empleado.id, value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}` }))},
                ],
                validations: ["required"],
                formControlName: "empleado_id",
                label: "Empleado",
                placeholder: "Seleccione un empleado",
                value: row.empleado_id,
              },
              fecha_inicio: {
                type: FormFieldType.Date,
                col: 6,
                validations: ["required"],
                formControlName: "fecha_inicio",
                label: "Fecha Inicio",
                placeholder: "Fecha de inicio del contrato",
                value: row.fecha_inicio,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              fecha_fin: {
                type: FormFieldType.Date,
                col: 6,
                validations: ["required"],
                formControlName: "fecha_fin",
                label: "Fecha Fin",
                placeholder: "Fecha de fin del contrato",
                value: row.fecha_fin,
              },
              fecha_renovacion: {
                type: FormFieldType.Date,
                col: 6,
                formControlName: "fecha_renovacion",
                label: "Fecha Renovación",
                placeholder: "Fecha de renovación del contrato",
                value: row.fecha_renovacion,

              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              direccion: {
                type: FormFieldType.Input,
                col: 6,
                formControlName: "direccion",
                label: "Dirección",
                placeholder: "Ingrese la dirección",
                value: row.direccion,
              },
              contacto: {
                type: FormFieldType.Number,
                col: 6,
                validations: ['minlength:10', 'maxlength:10'],
                formControlName: "contacto",
                label: "Num. Tel.",
                placeholder: "Ingrese el contacto",
                value: row.contacto,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              emer_nombre: {
                type: FormFieldType.Input,
                col: 6,
                formControlName: "emer_nombre",
                label: "Nombre de emergencia",
                placeholder: "Ingrese el nombre de emergencia",
                value: row.emer_nombre,
              },
              emer_tel: {
                type: FormFieldType.Input,
                col: 6,
                formControlName: "emer_tel",
                label: "Teléfono de emergencia",
                placeholder: "Ingrese el teléfono de emergencia",
                value: row.emer_tel,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              categoria_id: {
                type: FormFieldType.Select,
                col: 4,
                formControlName: "categoria_id",
                options: [
                  {key: OptionType.SelectOptions, value: this.categorias.map((categoria) => ({ key: categoria.id, value: categoria.nombre }))},
                ],
                label: "Categoría",
                placeholder: "Categoría del empleado",
                validations:['required'],
                value: row.categoria_id,
              },
              obra_id: {
                type: FormFieldType.Select,
                col: 4,
                options: [
                  {key: OptionType.SelectOptions, value: this.obras.map((obra) => ({ key: obra.id, value: obra.nombre }))},
                ],
                formControlName: "obra_id",
                label: "Nómina",
                placeholder: "Seleccione la nómina",
                value: row.obra_id,
                validations:['required']
              },
              sueldo_base: {
                type: FormFieldType.Number,
                col: 4,
                formControlName: "sueldo_base",
                label: "Sueldo base",
                placeholder: "Ingrese el sueldo base",
                value: row.sueldo_base,
                validations:['required']
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              gratificacion: {
                type: FormFieldType.Number,
                col: 6,
                formControlName: "gratificacion",
                label: "Gratificación",
                placeholder: "Ingrese la gratificación",
                value: row.gratificacion,
              },
              universidad: {
                type: FormFieldType.Input,
                col: 6,
                formControlName: "universidad",
                label: "Universidad",
                placeholder: "Ingrese la universidad",
                value: row.universidad,
              },
            },
          },

          {
            type: FormFieldType.Row,
            fields: {

              comentarios: {
                type: FormFieldType.TextArea,
                col: 12,
                formControlName: "comentarios",
                label: "Comentarios",
                placeholder: "",
                value: row.comentarios,
              },
            }
          },
        ];
      },
      action: (form: FormGroup, row: ContratoRH,ref: NbWindowRef) => {
        removeEmptyControls(form);
        this.contratoSerivce.update(form, row.id).subscribe((data) => {
          this.filteredData = this.data;
          this.toastrService.success("Contrato actualizado correctamente", "Contrato actualizado");
          this.contratoSerivce.getAll().subscribe((data) => {
            this.data = data;
            this.filteredData = data;
          });
          ref.close();
        });
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      icon: "trash-2-outline",
      action: (row) => {
        this.contratoSerivce.delete(row.id).subscribe((data) => {
          this.data = this.data.filter((contrato) => contrato.id !== row.id);
          this.filteredData = this.data;
          this.toastrService.success("Contrato eliminado correctamente", "Contrato eliminado");
      });
    },
    }
  ];

  formConfig: FormBuilderConfig = [];
  categorias: EmpleadoCategoria[] = [];

  constructor(
    private contratoSerivce: ContratosRhService,
    private windowService: NbWindowService,
    private empleadosService: EmpleadoService,
    private toastrService: NbToastrService,
    private categoriasService: EmpleadoCategoriasService,
    private obrasService: ObraService
  ) {}

  ngOnInit(): void {
    this.contratoSerivce.getAll().subscribe((data) => {
      this.data = data;
      this.filteredData = data;
    });
    forkJoin([this.empleadosService.getAll(), this.categoriasService.getAll(), this.obrasService.getAll()]).subscribe(([empleados, categorias, obras]) => {
      this.empleados = empleados;
      this.categorias = categorias;
      this.obras = obras;
    });
  }

  filter(value: string) {
    this.filteredData = this.data.filter((item) => {
      return item.empleado_nombre.toLowerCase().includes(value.toLowerCase())
      ||item.empleado_apellido_paterno.toLowerCase().includes(value.toLowerCase())
      ||item.empleado_apellido_materno.toLowerCase().includes(value.toLowerCase())
      ||item.fecha_inicio.toLowerCase().includes(value.toLowerCase())
      ||item.fecha_fin.toLowerCase().includes(value.toLowerCase())
      ||item.fecha_renovacion?.toLowerCase().includes(value.toLowerCase());
    });
  }
  onNuevoClick() {
    this.formConfig = [
      {
        type: FormFieldType.Row,
        fields: {
          empleado_id: {
            type: FormFieldType.Select,
            col: 6,
            options: [
              {key: OptionType.SelectOptions, value: this.empleados.map((empleado) => ({ key: empleado.id, value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}` }))},
            ],
            validations: ["required"],
            formControlName: "empleado_id",
            label: "Empleado",
            placeholder: "Seleccione un empleado",
          },
          fecha_inicio: {
            type: FormFieldType.Date,
            col: 6,
            validations: ["required"],
            formControlName: "fecha_inicio",
            label: "Fecha Inicio",
            placeholder: "Fecha de inicio del contrato",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          fecha_fin: {
            type: FormFieldType.Date,
            col: 6,
            validations: ["required"],
            formControlName: "fecha_fin",
            label: "Fecha Fin",
            placeholder: "Fecha de fin del contrato",
          },
          fecha_renovacion: {
            type: FormFieldType.Date,
            col: 6,
            formControlName: "fecha_renovacion",
            label: "Fecha Renovación",
            placeholder: "Fecha de renovación del contrato",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          direccion: {
            type: FormFieldType.Input,
            col: 6,
            formControlName: "direccion",
            label: "Dirección",
            placeholder: "Ingrese la dirección",
          },
          contacto: {
            type: FormFieldType.Number,
            col: 6,
            validations: ['minlength:10', 'maxlength:10'],
            formControlName: "contacto",
            label: "Num. Tel.",
            placeholder: "Ingrese el contacto",
            value:0,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          emer_nombre: {
            type: FormFieldType.Input,
            col: 6,
            formControlName: "emer_nombre",
            label: "Nombre de emergencia",
            placeholder: "Ingrese el nombre de emergencia",
          },
          emer_tel: {
            type: FormFieldType.Input,
            col: 6,
            formControlName: "emer_tel",
            label: "Teléfono de emergencia",
            placeholder: "Ingrese el teléfono de emergencia",
            value:0,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          categoria_id: {
            type: FormFieldType.Select,
            col: 4,
            formControlName: "categoria_id",
            options: [
              {key: OptionType.SelectOptions, value: this.categorias.map((categoria) => ({ key: categoria.id, value: categoria.nombre }))},
            ],
            label: "Categoría",
            placeholder: "Categoría del empleado",
            validations:['required']
          },
          obra_id: {
            type: FormFieldType.Select,
            col: 4,
            options: [
              {key: OptionType.SelectOptions, value: this.obras.map((obra) => ({ key: obra.id, value: obra.nombre }))},
            ],
            formControlName: "obra_id",
            label: "Nómina",
            placeholder: "Seleccione la nómina",
            validations:['required']
          },
          sueldo_base: {
            type: FormFieldType.Number,
            col: 4,
            formControlName: "sueldo_base",
            label: "Sueldo base",
            placeholder: "Ingrese el sueldo base",
            validations:['required'],
            value: 0,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          gratificacion: {
            type: FormFieldType.Number,
            col: 6,
            formControlName: "gratificacion",
            label: "Gratificación",
            placeholder: "Ingrese la gratificación",
            value: 0,
          },
          universidad: {
            type: FormFieldType.Input,
            col: 6,
            formControlName: "universidad",
            label: "Universidad",
            placeholder: "Ingrese la universidad",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          ine_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "ine_file",
            label: "INE",
            placeholder: "Seleccione el archivo INE",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
          curp_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "curp_file",
            label: "CURP",
            placeholder: "Seleccione el archivo CURP",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          acta_nac_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "acta_nac_file",
            label: "Acta de nacimiento",
            placeholder: "Seleccione el acta de nacimiento",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
          nss_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "nss_file",
            label: "NSS",
            placeholder: "Seleccione el archivo NSS",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          cif_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "cif_file",
            label: "CIF",
            placeholder: "Seleccione el archivo CIF",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
          comp_dom_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "comp_dom_file",
            label: "Comprobante de domicilio",
            placeholder: "Seleccione el comprobante de domicilio",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          const_ret: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "const_ret",
            label: "Constancia de retención",
            placeholder: "Ingrese la constancia de retención",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
          licencia_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "licencia_file",
            label: "Licencia",
            placeholder: "Seleccione el archivo de licencia",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          titulo_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "titulo_file",
            label: "Título",
            placeholder: "Seleccione el archivo de título",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
          cedula_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "cedula_file",
            label: "Cédula",
            placeholder: "Seleccione el archivo de cédula",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          cuenta_file: {
            type: FormFieldType.File,
            col: 6,
            formControlName: "cuenta_file",
            label: "Cuenta",
            placeholder: "Seleccione el archivo de cuenta",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
          comentarios: {
            type: FormFieldType.TextArea,
            col: 6,
            formControlName: "comentarios",
            label: "Comentarios",
            placeholder: "",
          },
        }
      },
    ];
    this.clienteWindowRef = this.windowService.open(
      this.nuevaEntrevistaWindow,
      { title: "Nuevo contrato", context: { formConfig: this.formConfig } }
    );
  }

  onEditClick(row: ContratoRH) {
    let editConfig = [
      {
        type: FormFieldType.Row,
        fields: {
          empleado_id: {
            type: FormFieldType.Select,
            col: 6,
            options: [
              {key: "select-options", value: this.empleados.map((empleado) => ({ key: empleado.id, value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}` }))},
            ],
            formControlName: "empleado_id",
            label: "Empleado",
            placeholder: "Seleccione un empleado",
            value: row.empleado_id,
          },
          fecha_inicio: {
            type: FormFieldType.Date,
            col: 6,
            formControlName: "fecha_inicio",
            label: "Fecha Inicio",
            placeholder: "Fecha de inicio del contrato",
            value: row.fecha_inicio,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          fecha_fin: {
            type: FormFieldType.Date,
            col: 6,
            formControlName: "fecha_fin",
            label: "Fecha Fin",
            placeholder: "Fecha de fin del contrato",
            value: row.fecha_fin,
          },
          fecha_renovacion: {
            type: FormFieldType.Date,
            col: 6,
            formControlName: "fecha_renovacion",
            label: "Fecha Renovación",
            placeholder: "Fecha de renovación del contrato",
            value: row.fecha_renovacion,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          contrato: {
            type: FormFieldType.File,
            col: 12,
            formControlName: "contrato",
            label: "Contrato",
            placeholder: "Selecciona un archivo",
            options: [
              { key: OptionType.fileSelectTypes, value: "Application/pdf" },
            ],
          },
        },
      }
    ];
    this.editWindowRef = this.windowService.open(this.editWindow, {
      title: "Editar Contrato",
      context: { formConfig: editConfig, data: row },
    });
  }



  onContratoCreated(form: FormGroup) {
   removeEmptyControls(form);

    this.contratoSerivce.create(form).subscribe((data) => {
      this.data.push(data);
      this.filteredData = this.data;
      this.clienteWindowRef.close();
      this.toastrService.success("Contrato creado correctamente", "Contrato creado");
      this.contratoSerivce.getAll().subscribe((data) => {
        this.data = data;
        this.filteredData = data;
      });
    });
  }
}
