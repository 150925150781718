import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { ExpedienteUnico } from '../data/expediente-unico-data';

@Injectable()
export class ExpedienteUnicoService extends BaseService<ExpedienteUnico>{

  protected getPath(): string {
    return 'expedientes-unicos';
  }
}
