import { Injectable } from '@angular/core';
import { NbRoleProvider } from '@nebular/security';
import { Observable } from 'rxjs';
import { User } from '../@core/data/usuarios-data';

@Injectable({
  providedIn: 'root'
})
export class RoleService implements NbRoleProvider {
  constructor() { }
  getRole(): Observable<string | string[]> {
    const user:User = JSON.parse(localStorage.getItem('user'));
    return new Observable(observer => {
      if (user) {
        observer.next(user.roles.map(role => role.name));
      } else {
        observer.next('guest');
      }
    });
  }
}
