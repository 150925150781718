import { Component, Input } from '@angular/core';
import { Finiquito } from '../../../@core/data/finiquito-data';
import { MatTableActionConfig, MatTableColumnConfig } from '../../../mat-table-builder/mat-table-builder.component';
import * as moment from 'moment';
import { FiniquitoService } from '../../../@core/services/finiquito.service';
import { FormGroup } from '@angular/forms';
import { NbToastrService, NbWindowRef } from '@nebular/theme';
import { FormFieldType, FormRowConfig, OptionType } from '../../../form-builder/form-builder.component';
import { EmpleadoService } from '../../../@core/services/empleado.service';
import { EmpleadoCategoriasService } from '../../../@core/services/empleado-categorias.service';
import { ClienteService } from '../../../@core/services/cliente.service';
import { Cliente, Empleado, EmpleadoCategoria } from '../../../@core/data/cotizacion-data';
import { EmpresaFiniquitoService } from '../../../@core/services/empresa-finiquito.service';

@Component({
  selector: 'ngx-finiquitos-table',
  templateUrl: './finiquitos-table.component.html',
  styleUrls: ['./finiquitos-table.component.scss']
})
export class FiniquitosTableComponent {
  @Input() data: Finiquito[];
  empleados: Empleado[];
  categorias: EmpleadoCategoria[];
  clientes: Cliente[];
  empresas: any[] = [];
  constructor(private finiquitoService: FiniquitoService,private empleadoService: EmpleadoService,
    private categoriaEmpleadoService: EmpleadoCategoriasService,
    private clienteService: ClienteService,private toastrService: NbToastrService,
    private empresaFiniquitoService: EmpresaFiniquitoService) {
    this.empleadoService.getAll().subscribe((res) => {
      this.empleados = res;
    });
    this.categoriaEmpleadoService.getAll().subscribe((res) => {
      this.categorias = res;
    });
    this.empresaFiniquitoService.getAll().subscribe((res) => {
      this.empresas = res;
    });
  }

  columns: MatTableColumnConfig[] = [
    {
      name:'inicio_labores',
      label: 'Inicio labores',
      type: 'date',
    },
    {
      name:'fin_labores',
      label: 'Fin labores',
      type: 'date',
    },
    {
      name:'monto',
      label: 'Monto',
      type: 'money',
    },
    {
      name: 'nombre_completo_empleado',
      label: 'Empleado',
      type: 'text',

    },
    {
      name:'categoria_empleado_nombre',
      label: 'Categoría',
      type: 'text',
    },
    {
      name: 'empresa_finiquita_nombre',
      label: 'Empresa finiquito',
      type: 'text',
    },
    {
      name:'dias_trabajados',
      label: 'Días trabajados',
      type: 'number',
      value: (data: Finiquito) => {
        return moment(data.fin_labores).diff(moment(data.inicio_labores), 'days');
      }
    },

  ];

  actions: MatTableActionConfig[] = [
    {
      name: 'ver',
      label: 'Ver evidencia',
      icon: 'eye',
      action: (data: Finiquito) => {
        this.finiquitoService.getEvidencia(data.id).subscribe((res) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        if (res.type === 'application/pdf') {
          window.open(url, '_blank');
        } else {
          const a = document.createElement('a');
          a.href = url;
          a.download = 'evidencia_finiquito.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
        });
      }
    },
    {
      name: 'edit',
      label: 'Editar',
      icon: 'edit',
      formSize: 'medium',
      action: (form: FormGroup,data: Finiquito,ref: NbWindowRef) => {
        this.finiquitoService.update( form,data.id).subscribe((res) => {
          this.finiquitoService.getAll().subscribe((res) => {
            this.data = res;
            this.toastrService.success("Finiquito actualizado", "Finiquito actualizado correctamente");
          });
          ref.close();
        });
        const formData = new FormData();
        if (form.get('evidencia_file').value instanceof File) {
          formData.append('evidencia_file', form.get('evidencia_file').value);
          this.finiquitoService.updateEvidencia(formData,data.id).subscribe((res) => {
            this.finiquitoService.getAll().subscribe((res) => {
              this.data = res;
              this.toastrService.success("Evidencia actualizada", "Evidencia actualizada correctamente");
            });
            ref.close();
          });
        }

      },
      formConfig: (data: Finiquito) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              empleado: {
                type: FormFieldType.Select,
                label: "Empleado",
                formControlName: "empleado_id",
                placeholder: "Empleado",
                col: 6,
                value: data.empleado_id,
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: this.empleados.map((empleado) => ({
                      key: empleado.id,
                      value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}`,
                    })),
                  },
                ],
              },
              categoria: {
                type: FormFieldType.Select,
                label: "Categoría",
                formControlName: "categoria_empleado_id",
                value: data.categoria_empleado_id,
                placeholder: "Categoría",
                col: 6,
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: this.categorias.map((categoria) => ({
                      key: categoria.id,
                      value: categoria.nombre,
                    })),
                  },
                ],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              monto: {
                type: FormFieldType.Input,
                label: "Monto",
                formControlName: "monto",
                value: data.monto,
                placeholder: "Monto",
                col: 6,
              },
              empresa_finiquita_id: {
                type: FormFieldType.Select,
                label: "Empresa finiquita",
                formControlName: "empresa_finiquita_id",
                value: data.empresa_finiquita_id,
                placeholder: "Selecciona una empresa",
                col: 6,
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: this.empresas.map((empresa) => ({
                      key: empresa.id,
                      value: empresa.nombre,
                    })),
                  },
                ],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              inicio_labores: {
                type: FormFieldType.Date,
                label: "Fecha de inicio",
                formControlName: "inicio_labores",
                value: data.inicio_labores,
                placeholder: "Fecha de inicio",
                col: 6,
              },
              fin_labores: {
                type: FormFieldType.Date,
                label: "Fecha de fin",
                formControlName: "fin_labores",
                value: data.fin_labores,
                placeholder: "Fecha de fin",
                col: 6,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              evidencia_file: {
                type: FormFieldType.File,
                label: "Evidencias",
                formControlName: "evidencia_file",
                value: "",
                placeholder: "Evidencias",
                col: 12,
                options: [
                  {
                    key: OptionType.fileSelectTypes,
                    value: ['image/*', 'application/pdf'],
                  },
                ],
              },
            },
          },
        ] as FormRowConfig[];
      }
    },
    {
      name: 'delete',
      label: 'Eliminar',
      message: '¿Estás seguro de que deseas eliminar este finiquito?',
      title: 'Eliminar finiquito',
      icon: 'trash',
      action: (data: Finiquito) => {
        this.finiquitoService.delete(data.id).subscribe((res) => {
          this.toastrService.success('Finiquito eliminado correctamente', 'Eliminado');
          this.finiquitoService.getAll().subscribe((res) => {
            this.data = res;
          });
        });
      }
    },
  ];
}
