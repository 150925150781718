import { Component, Input, OnInit } from '@angular/core';
import { Cotizacion, Empleado } from '../../@core/data/cotizacion-data';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { CotizacionService } from '../../@core/services/cotizacion.service';

@Component({
  selector: 'ngx-generar-contrato-form',
  templateUrl: './generar-contrato-form.component.html',
  styleUrls: ['./generar-contrato-form.component.scss']
})
export class GenerarContratoFormComponent implements OnInit {
  @Input() cotizacion: Cotizacion;
  empleados: Empleado[] = [];
  empleadoRepSelected=new FormControl();;
  contratoForm :FormGroup;
  constructor(private empleadosService:EmpleadoService,private cotizacionService:CotizacionService) {
    this.contratoForm = new FormGroup({
      'fecha_inicial': new FormControl('',[Validators.required,]),
      'fecha_final': new FormControl('',[Validators.required,]),
      'id_empleado_rep': new FormControl(this.empleadoRepSelected,[Validators.required,]),
      'tiempo_contrato': new FormControl(1,[Validators.required,]),
      'lugar_uso_maq': new FormControl('',[Validators.required,]),
    });
   }

  ngOnInit(): void {
    this.empleadosService.getAll().subscribe(
      empleados => this.empleados = empleados
    );
  }
  onSubmit(){
    this.cotizacionService
    .generarContrato(this.cotizacion.id, this.empleadoRepSelected.value,
      this.contratoForm.value.fecha_inicial, this.contratoForm.value.fecha_final,this.contratoForm.value.tiempo_contrato)
      .subscribe();
  }
  onFechaCambio($event){
    let newDate = new Date($event.target.value);
    newDate.setMonth(newDate.getMonth() + this.contratoForm.value.tiempo_contrato);
    let formattedDate = newDate.toISOString().split('T')[0];
    this.contratoForm.patchValue({
      fecha_final: formattedDate
    });
  }
  ontiempoContratoChange($event){
    if(this.contratoForm.value.fecha_inicial){
      let newDate = new Date(this.contratoForm.value.fecha_inicial);
      newDate.setFullYear(newDate.getFullYear() + Math.floor($event.target.value / 12));
      newDate.setMonth(newDate.getMonth() + ($event.target.value % 12));
      let formattedDate = newDate.toISOString().split('T')[0];
      this.contratoForm.patchValue({
        fecha_final: formattedDate
      });
    }
  }
}
