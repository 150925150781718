import { MarcaService } from './../../../../@core/services/marca.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VehiculosService } from '../../../../@core/services/vehiculos.service';
import { Marca } from '../../../../@core/data/marca-data';
import { Observable, of } from 'rxjs';
import { Version } from '../../../../@core/data/version-data';
import { VersionService } from '../../../../@core/services/version.service';
import { Estado } from '../../../../@core/data/estado-data';
import { EstadoService } from '../../../../@core/services/estado.service';
import { Seguro, Vehiculo } from '../../../../@core/data/vehiculo-data';
import { SeguroService } from '../../../../@core/services/seguro.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'ngx-nuevo-vehiculo-form',
  templateUrl: './nuevo-vehiculo-form.component.html',
  styleUrls: ['./nuevo-vehiculo-form.component.scss']
})
export class NuevoVehiculoFormComponent implements OnInit {

  @Input() vehiculoIdToEdit: number;
  vehiculoToEdit: Vehiculo;
  marcas: Marca[] = [];
  versiones: Version[] = [];
  estados: Estado[] = [];
  seguros: Seguro[] = [];

  selectedMarcaFormControl = new FormControl(null);
  selectedVersionFormControl = new FormControl(null);
  selectedEstadoFormControl = new FormControl(null);
  selectedSeguroFormControl = new FormControl(null);

  vehiculoForm: FormGroup;

  @Output() vehiculoSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  loading: boolean = false;

  constructor(private vehiculosService: VehiculosService,
    private marcaService:MarcaService,
    private versionService: VersionService,
    private estadoService: EstadoService,
    private seguroService: SeguroService
  ) { }

  ngOnInit(): void {
    this.vehiculoForm = new FormGroup({
      anio: new FormControl('', Validators.required),
      serie: new FormControl('', Validators.required),
      placas: new FormControl('', Validators.required),
      color: new FormControl('', Validators.required),
      referendo_pagado: new FormControl(false),
      referendo_comentario: new FormControl(''),
      chofer: new FormControl('', Validators.required),
      observaciones: new FormControl('', Validators.required),
      fecha_inicio_vigencia_seguro: new FormControl('',),
      fecha_fin_vigencia_seguro: new FormControl('',),
      marca_id: this.selectedMarcaFormControl,
      estado_id: this.selectedEstadoFormControl,
      version_id: this.selectedVersionFormControl,
      aseguradora_id: this.selectedSeguroFormControl

    });
    this.loading = true;


    this.marcaService.getAll().subscribe((marcas) => {
      this.marcas = marcas;
    });

    this.versionService.getAll().subscribe((versiones) => {
      this.versiones = versiones;
    });

    this.estadoService.getAll().subscribe((estados) => {
      this.estados = estados;
    });

    this.seguroService.getAll().subscribe((seguros) => {
      this.seguros = seguros;
    });

    if (this.vehiculoIdToEdit!=null) {
      this.vehiculosService.getById(this.vehiculoIdToEdit).subscribe((vehiculo) => {
        this.vehiculoToEdit = vehiculo;
        console.log(this.vehiculoToEdit);
        this.vehiculoForm.patchValue({
          anio: this.vehiculoToEdit.anio,
          serie: this.vehiculoToEdit.serie,
          placas: this.vehiculoToEdit.placas,
          color: this.vehiculoToEdit.color,
          referendo_pagado: this.vehiculoToEdit.referendo_pagado,
          referendo_comentario: this.vehiculoToEdit.referendo_comentario,
          chofer: this.vehiculoToEdit.chofer,
          observaciones: this.vehiculoToEdit.observaciones,
          fecha_inicio_vigencia_seguro: formatDate(this.vehiculoToEdit.fecha_inicio_vigencia_seguro, 'yyyy-MM-dd', 'en-US'),
          fecha_fin_vigencia_seguro: formatDate(this.vehiculoToEdit.fecha_fin_vigencia_seguro, 'yyyy-MM-dd', 'en-US'),
          marca_id: this.vehiculoToEdit.marca_id,
          estado_id: this.vehiculoToEdit.estado_id,
          version_id: this.vehiculoToEdit.version_id,
          aseguradora_id: this.vehiculoToEdit.aseguradora_id
        });
      });

    }
    this.loading = false;
  }
  onSubmit() {
    if (this.vehiculoToEdit) {
      this.vehiculosService.update(this.vehiculoForm, this.vehiculoToEdit.id)
        .subscribe(() => {
          this.vehiculoSaved.emit(true);
        },
        error => {
          console.error(error);
        });
    } else {
      this.vehiculosService.create(this.vehiculoForm)
        .subscribe(() => {
          this.vehiculoSaved.emit(true);
        },
        error => {
          console.error(error);
        });
    }
  }

}
