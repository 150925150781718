<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="numero">
    <th mat-header-cell *matHeaderCellDef>No. Ecomnomico</th>
    <td mat-cell *matCellDef="let row">{{ row.numero || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef>Nombre</th>
    <td mat-cell *matCellDef="let row">{{ row.nombre || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="serie">
    <th mat-header-cell *matHeaderCellDef>Serie</th>
    <td mat-cell *matCellDef="let row">{{ row.serie || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="modelo">
    <th mat-header-cell *matHeaderCellDef>Modelo</th>
    <td mat-cell *matCellDef="let row">{{ row.modelo || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="ubicacion">
    <th mat-header-cell *matHeaderCellDef>Ubicación</th>
    <td mat-cell *matCellDef="let row">{{ row.ubicacion || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="observaciones">
    <th mat-header-cell *matHeaderCellDef>Observaciones</th>
    <td mat-cell *matCellDef="let row">{{ row.observaciones || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="lts">
    <th mat-header-cell *matHeaderCellDef>Lts</th>
    <td mat-cell *matCellDef="let row">{{ row.lts || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="toneladas">
    <th mat-header-cell *matHeaderCellDef>Tonaleadas</th>
    <td mat-cell *matCellDef="let row">{{ row.toneladas || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="precio">
    <th mat-header-cell *matHeaderCellDef>Precio</th>
    <td mat-cell *matCellDef="let row">{{ row.precio || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="mantenimiento">
    <th mat-header-cell *matHeaderCellDef>Mantenimiento</th>
    <td mat-cell *matCellDef="let row">{{ row.mantenimiento || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="encargado">
    <th mat-header-cell *matHeaderCellDef>Encargado</th>
    <td mat-cell *matCellDef="let row">{{ row.encargado || "-" }}</td>
  </ng-container>
  <ng-container matColumnDef="opciones">
    <th mat-header-cell *matHeaderCellDef>Opciones</th>
    <td mat-cell *matCellDef="let row">
      <button nbTooltip="Modificar" nbButton size="small" (click)="onClickOpenEditarMaquinaria(row)">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </button>
      <button nbButton nbTooltip="Eliminar" size="small" status="primary"   (click)="onClickEliminarMaquinaria(row)">
        <nb-icon icon="trash-2-outline"></nb-icon>
      </button>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: allColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 21]" aria-label="Selecciona una pagina" showFirstLastButtons></mat-paginator>
<ng-template #editarMaquinaria let-data>
  <div class="modal-body" style="min-width:200px; max-height: 400px;">
    <ngx-nueva-maquinaria-form [maquinaria]="data.maquinaria" (maquinariaCreated)="onMaquinariaEdited()" ></ngx-nueva-maquinaria-form>
  </div>
</ng-template>

<ng-template #deleteMaquinaria let-data>
  <div>¿Desea eliminar la maquinaria con el No. Económico {{data.maquinaria.numero}}?</div>
  <div><button status="danger" (click)="onConfirmDeleteNaquinaria(data.maquinaria)" nbButton>Eliminar</button>
    <button nbButton>
      Cancelar
    </button>
  </div>
</ng-template>
