import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { LoginComponent } from './login/login.component';
import { CommonModule } from '@angular/common';

export const routes: Routes = [

    {
        path: '',
        component: NbAuthComponent,
        children: [
        {
            path: '',
            component: NbLoginComponent,
        },
        {
            path: 'login',
            component: LoginComponent,
        },
        {
            path: 'register',
            component: NbRegisterComponent,
        },
        {
            path: 'logout',
            component: NbLogoutComponent,
        },
        {
            path: 'request-password',
            component: NbRequestPasswordComponent,
        },
        {
            path: 'reset-password',
            component: NbResetPasswordComponent,
        },
        ],
  }
]
@NgModule({
    imports: [
      RouterModule.forChild(routes),
      CommonModule],
    exports: [RouterModule],
  })
  export class AuthRoutingModule {
  }
