<table mat-table [dataSource]="dataSource" equalColumnsWidth multiTemplateDataRows>
  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: allColumns;"></tr>
  <ng-container matColumnDef="folio">
    <th mat-header-cell *matHeaderCellDef>Folio</th>
    <td mat-cell *matCellDef="let cotizacion">
      {{ cotizacion.id }}
    </td>
  </ng-container>
  <ng-container
  matColumnDef="{{ column }}"
  *ngFor="let column of defaultColumns"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.replace('_', ' ').charAt(0).toUpperCase() + column.replace('_', ' ').slice(1) }}</th>
    <td mat-cell *matCellDef="let cotizacion">
      {{ cotizacion[column] }}
    </td>
  </ng-container>
  <ng-container matColumnDef="fecha">
    <th mat-header-cell *matHeaderCellDef>Fecha</th>
    <td mat-cell *matCellDef="let cotizacion">
      {{ cotizacion.created_at | date: 'dd/MM/yyyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let cotizacion">
      <button nbTooltip="Generar documento de cotizacion" status="info" nbButton size="small" (click)="onGenerarReporteClickBtn(cotizacion)">
        <nb-icon icon="file-text-outline"></nb-icon>
      </button>
      <button nbTooltip="Generar contrato" status="info" nbButton size="small" (click)="onOpenGenerarContrato(cotizacion)">
        <nb-icon icon="file-text-outline"></nb-icon>
      </button>
      <button nbTooltip="Modificar" status="info" nbButton size="small" (click)="onOpenEditCotizacion(cotizacion)">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </button>
      <button nbButton nbTooltip="Eliminar" size="small" status="primary"   nbButton (click)="onOpenDelete(cotizacion)">
        <nb-icon icon="trash-2-outline"></nb-icon>
      </button>
    </td>
  </ng-container>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 21]" aria-label="Selecciona una pagina" showFirstLastButtons></mat-paginator>
<ng-template #documentosView let-data>
  <div  style="min-width: 800px; max-height: 400px; overflow-y: auto;">
    <ngx-expediente-documentos-form [docsExpediente]="data.docs"></ngx-expediente-documentos-form>
  </div>
</ng-template>
<ng-template #openDelete let-data>
  <div>¿Desea eliminar la cotizacion con el folio {{data.cotizacion.id}} para el cliente
    {{data.cotizacion.cliente}}?</div>
  <div><button status="danger" (click)="onConfirmDelete(data.cotizacion)" nbButton>Eliminar</button>
    <button nbButton>
      Cancelar
    </button>
  </div>
</ng-template>
<ng-template #openEdit let-data>
  <ngx-editar-cotizacion-form [cotizacion]="data.cotizacion" (cotizacionSaved)="onConfirmEditCotizacion()"></ngx-editar-cotizacion-form>
</ng-template>

<ng-template #selectEmpleadoFirmaView let-data>
  <div>Generar reporte de cotización</div>
  <div class="row">
    <nb-select fullWidth id="empleado" [(selected)]="empleadoFirmaSelected"  placeholder="Selecciona un empleado">
      <nb-option *ngFor="let empleado of empleadosFirma" [value]="empleado.id">{{empleado.nombre}}</nb-option>
    </nb-select>
  </div>
  <div class="row"><button status="primary" (click)="generarReporte(data.cotizacion)" nbButton>Seleccionar</button>
    <button nbButton>
      Cancelar
    </button>
  </div>
</ng-template>

<ng-template #openGenerarContrato let-data>
  <ngx-generar-contrato-form [cotizacion]="data.cotizacion"></ngx-generar-contrato-form>
</ng-template>

