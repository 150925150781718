<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Gastos Fiscales
      <button nbButton (click)="onNuevoClick()" size="small" nbTooltip="Nuevo Gasto"
      status="success" [nbSpinner]="loading" [disabled]="loading">
        <nb-icon  icon="plus"></nb-icon>
      </button>
      <button nbButton (click)="downloadCsv()" size="small">
        <nb-icon nbTooltip="Descargar CSV" icon="download"></nb-icon>
      </button>
    </div>

    <ngx-filter-value-date [items]="gastos" [filterField]="'fecha_compra'" [filterFields]="filterFields" (filterChange)="onFilterChange($event)"></ngx-filter-value-date>
  </nb-card-header>
  <nb-card-body class="no-padding">
    <ngx-mat-table-builder [columnsDef]="columns" [data]="gastosFiltrados" [actions]="acciones"></ngx-mat-table-builder>
    <div class="total-container">
      <label class="total-label">Total de Importes: {{ getTotalImportes() | currency:"MXN":"symbol":"1.2-2" }}</label>
    </div>
  </nb-card-body>
</nb-card>

<ng-template let-data #nuevoGastoWindow>
  <div class="responsive-div-md">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onGastoCreated($event)">
    </ngx-form-builder>
  </div>
</ng-template>
