import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilderComponent } from './form-builder.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCheckboxModule, NbDatepickerModule, NbInputModule, NbRadioModule, NbSelectModule, NbSpinnerModule } from '@nebular/theme';
import { GCMARQFormsModule } from '../forms/gcmarqforms.module';



@NgModule({
  declarations: [
    FormBuilderComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NbInputModule,
    NbCheckboxModule,
    NbSelectModule,
    NbDatepickerModule,
    NbButtonModule,
    NbDatepickerModule,
    FormsModule,
    NbRadioModule,
    NbSpinnerModule,
    GCMARQFormsModule
  ],
  exports:[
    FormBuilderComponent
  ]
})
export class FormBuilderModule { }
