<form [formGroup]="maquinariaForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="form-group input-responsive">
      <label for="nombre">Nombre*</label>
      <input class="form-control" type="text" id="nombre" name="nombre" required formControlName="nombre" nbInput
        fullWidth status="basic" placeholder="Nombre">
        <span class="text-danger"
          *ngIf="(loginFormControls().nombre.touched) && loginFormControls().nombre.errors?.required">
          Nombre es requerido
      </span>
    </div>
    <div class="form-group input-responsive">
      <label for="numero">No. Economico*</label>
      <input class="form-control" type="text" id="numero" name="numero" required formControlName="numero" nbInput
        fullWidth status="basic" placeholder="Número">
      <span class="text-danger"
        *ngIf="(loginFormControls().numero.touched) && loginFormControls().numero.errors?.required">
        Número economico es requerido
      </span>
    </div>
  </div>
  <div class="row">
    <!-- Repite este bloque para los campos restantes como serie, modelo, etc. -->
    <div class="form-group col-sm-6">
      <label for="serie">Serie*</label>
      <input class="form-control" type="text" id="serie" name="serie" required formControlName="serie" nbInput fullWidth
        status="basic" placeholder="Serie">
      <span class="text-danger"
        *ngIf="(loginFormControls().serie.touched) && loginFormControls().serie.errors?.required">
        Serie es requerida
      </span>
    </div>
    <div class="form-group col-sm-6">
      <label for="modelo">Modelo*</label>
      <input class="form-control" type="text" id="modelo" name="modelo" required formControlName="modelo" nbInput
        fullWidth status="basic" placeholder="Modelo">
      <span class="text-danger" *ngIf="(loginFormControls().modelo.touched) && loginFormControls().modelo.errors?.required">
        Modelo es requerido
      </span>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-12">
      <label for="ubicacion">Ubicación</label>
      <input class="form-control" type="text" id="ubicacion" name="ubicacion" required formControlName="ubicacion"
        nbInput fullWidth status="basic" placeholder="Ubicación">
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-6">
      <label for="toneladas">Toneladas</label>
      <input class="form-control" type="number" id="toneladas" name="toneladas" required formControlName="toneladas"
        nbInput fullWidth status="basic" placeholder="Toneladas">
    </div>
    <div class="form-group col-sm-6">
      <label for="lts">Litros</label>
      <input class="form-control" type="number" id="lts" name="lts" required formControlName="lts" nbInput fullWidth
        status="basic" placeholder="Lts">
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-6">
      <label for="precio">Precio renta (HRS)*</label>
      <input class="form-control" type="number" id="precio" name="precio" required formControlName="precio" nbInput
        fullWidth status="basic" placeholder="Precio">
    </div>
    <div class="form-group col-sm-6">
      <label for="mtm">Precio mantenimiento</label>
      <input class="form-control" type="number" id="mtm" name="fecha" required formControlName="mantenimiento" nbInput fullWidth
        status="basic" placeholder="Mantenimiento">
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-12">
      <label for="encargado">Encargado</label>
      <input class="form-control" type="text" id="encargado" name="encargado" required formControlName="encargado"
        nbInput fullWidth status="basic" placeholder="Encargado">
    </div>
  </div>
  <button type="submit" nbButton status="primary">Guardar</button>
</form>
