<div class="d-flex">
  <div class="flex-grow-1">
    <nb-card>
      <nb-card-header class="d-flex justify-content-between">
        <div>
          Entradas
          <button nbButton (click)="downloadCsvEntradas()" size="small">
            <nb-icon nbTooltip="Descargar CSV" icon="download"></nb-icon>
          </button>
        </div>
        <div>
          <input
            nbInput
            placeholder="Selecciona una fecha"
            [nbDatepicker]="dateTimePicker"
          />
          <nb-datepicker
            #dateTimePicker
            (dateChange)="onDateChange($event)"
          ></nb-datepicker>
        </div>
      </nb-card-header>
      <nb-card-body class="no-padding">
        <ngx-mat-table-builder
          [columnsDef]="entradasColumns"
          [data]="filteredEntradasData"
          [showActions]="false"
        ></ngx-mat-table-builder>
      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-header class="d-flex justify-content-between">
        <div>
          Gastos
          <button nbButton (click)="downloadCsvGastos()" size="small">
            <nb-icon nbTooltip="Descargar CSV" icon="download"></nb-icon>
          </button>
        </div>
      </nb-card-header>
      <nb-card-body class="no-padding">
        <ngx-mat-table-builder
          [columnsDef]="salidasColums"
          [data]="filtereSalidasdData"
          [showActions]="false"
        ></ngx-mat-table-builder>
      </nb-card-body>
    </nb-card>
  </div>
  <div
    class="totals-card"
    *ngIf="
      corte === null
    "
  >
    <nb-card>
      <nb-card-body>
        <div>
          <div class="total-label">Total Entradas:</div>
        </div>
        <div>
          <div class="value-label">
            {{
              getTotalImportesEntradas() | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
        <div>
          <div class="total-label">Total Gastos:</div>
        </div>
        <div>
          <div class="value-label">
            -{{
              getTotalImportesSalidas() | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
        <div>
          <div class="total-label total-highlight">Total:</div>
        </div>
        <div>
          <div class="value-label">
            {{
              getTotalImportesEntradas() - getTotalImportesSalidas()
                | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
        <div>
          <div class="total-label total-highlight">
            Total corte fecha anterior:
          </div>
        </div>
        <div>
          <div class="value-label">
            {{
              getTotalImportesEntradasAnterior() -
                getTotalImportesSalidasAnterior()
                | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
        <div>
          <div class="total-label total-highlight">Total (Corte):</div>
        </div>
        <div>
          <div class="value-label">
            {{
              getTotalImportesEntradas() -
                getTotalImportesSalidas() +
                getTotalImportesEntradasAnterior() -
                getTotalImportesSalidasAnterior()
                | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>

        <div>
          <div class="total-label" *ngIf="corte !== null">Estatus:</div>
          <div class="total-label" *ngIf="corte === null">
            Estatus: Corte No Realizado
          </div>
          <div class="total-label" *ngIf="corte !== null">
            Fecha Corte el: {{ corte?.fecha | date }}
          </div>
        </div>

        <div>
          <div class="total-label">
            Ultimo corte realizado el: {{ ultimoCorte?.fecha | date }}
          </div>
        </div>
      </nb-card-body>

      <nb-card-footer>
        <button
          nbButton
          [disabled]="corte !== null||fechaSeleccionada === null"
          (click)="generarCorteConfirm()"
          size="small"
          fullWidth
        >
          Generar Corte
        </button>
      </nb-card-footer>
    </nb-card>
  </div>
  <div
    class="totals-card"
    *ngIf="corte !== null && corte.fecha !== fechaSeleccionada"
  >
    <nb-card>
      <nb-card-body>
        <div>
          <div class="total-label">Total Entradas:</div>
        </div>
        <div class="value-label">
          {{
            getTotalImportesEntradas() | currency : "MXN" : "symbol" : "1.2-2"
          }}
        </div>
        <div>
          <div class="total-label">Total Gastos:</div>
        </div>
        <div class="value-label">
          -{{
            getTotalImportesSalidas() | currency : "MXN" : "symbol" : "1.2-2"
          }}
        </div>
        <div>
          <div class="total-label" *ngIf="corte !== null">
            Estatus: Corte Realizado
          </div>
          <div class="total-label" *ngIf="corte === null">
            Estatus: Corte No Realizado
          </div>
          <div class="total-label" *ngIf="corte !== null">
            Fecha Corte el: {{ corte?.fecha | date }}
          </div>
        </div>
        <div>
          <div class="total-label">
            Ultimo corte realizado el: {{ ultimoCorte?.fecha | date }}
          </div>
        </div>
        <div>
          <div class="total-label total-highlight">Total:</div>
        </div>
        <div>
          <div class="value-label">
            {{
              getTotalImportesEntradas() - getTotalImportesSalidas()
                | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
        <div>
          <div class="total-label total-highlight">Total (Corte):</div>
        </div>
        <div>
          <div class="value-label">
            {{
              getTotalImportesEntradas() -
                getTotalImportesSalidas() +
                ultimoCorte?.total
                | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
        <div>
          <button nbButton status="primary" (click)="generarCorte()" size="small" fullWidth>
            Generar Corte
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
  <div
    class="totals-card"
    *ngIf="corte !== null && corte.fecha === fechaSeleccionada"
  >
    <nb-card>
      <nb-card-body>
        <div>
          <div class="total-label">Total Entradas:</div>
        </div>
        <div class="value-label">
          {{
            getTotalImportesEntradas() | currency : "MXN" : "symbol" : "1.2-2"
          }}
        </div>
        <div>
          <div class="total-label">Total Gastos:</div>
        </div>
        <div class="value-label">
          -{{
            getTotalImportesSalidas() | currency : "MXN" : "symbol" : "1.2-2"
          }}
        </div>
        <div>
          <div class="total-label" *ngIf="corte !== null">
            Estatus: Corte Realizado
          </div>
          <div class="total-label" *ngIf="corte === null">
            Estatus: Corte No Realizado
          </div>
        </div>
        <div>
          <div class="total-label total-highlight">Total:</div>
        </div>
        <div>
          <div class="value-label">
            {{
              getTotalImportesEntradas() - getTotalImportesSalidas()
                | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
        <div>
          <div class="total-label total-highlight">Total (Corte):</div>
        </div>
        <div>
          <div class="value-label">
            {{
              +ultimoCorte?.total + +corte?.total_importes_entradas - +ultimoCorte?.total_importes_gastos
                | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
        <div>
          <button nbButton (click)="generarReporte()" size="small" fullWidth>
            Generar Reporte
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
<ng-template let-data #corteConfirm>
  <div class="responsive-div">
    <nb-card class="no-padding">
      <nb-card-body>
        <p>
          ¿Estás seguro de que deseas generar el corte? Esta acción no se podrá
          deshacer.
        </p>
        <div>
          <div class="total-label">
            Total (Corte):
            {{
              getTotalImportesEntradas() -
                getTotalImportesSalidas() + +ultimoCorte?.total
                | currency : "MXN" : "symbol" : "1.2-2"
            }}
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-end">
        <button nbButton status="danger" (click)="cancelarCorte()">
          Cancelar
        </button>
        <button nbButton status="success" (click)="generarCorte()">
          Confirmar
        </button>
      </nb-card-footer>
    </nb-card>
  </div>
</ng-template>
