import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CotizacionesMatTableComponent } from './cotizaciones-mat-table/cotizaciones-mat-table.component';
import { NbButtonModule, NbCardModule, NbDatepickerModule, NbIconModule, NbInputModule, NbSelectModule, NbTooltipModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GCMARQFormsModule } from '../forms/gcmarqforms.module';
import { EntrevistasMatTableComponent } from './entrevistas-mat-table/entrevistas-mat-table.component';
import { FormBuilderModule } from "../form-builder/form-builder.module";
import { MatTableBuilderModule } from '../mat-table-builder/mat-table-builder.module';



@NgModule({
  declarations: [
    CotizacionesMatTableComponent,
    EntrevistasMatTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    CommonModule,
    NbCardModule,
    NbIconModule,
    FormsModule,
    MatPaginatorModule,
    NbInputModule,
    NbButtonModule,
    NbSelectModule,
    CurrencyPipe,
    NbTooltipModule,
    NbDatepickerModule,
    ReactiveFormsModule,
    GCMARQFormsModule,
    FormBuilderModule,
    MatTableBuilderModule
],
  exports: [
    CotizacionesMatTableComponent,
    EntrevistasMatTableComponent
  ]
})
export class GCMarqTablesModule { }
