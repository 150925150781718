import { filter } from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, TemplateRef, ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Permission, Role, User } from '../../@core/data/usuarios-data';
import { PermisionsService, RoleService, UserService } from '../../@core/services/users.service';
import { MatTableActionConfig, MatTableColumnConfig } from '../../mat-table-builder/mat-table-builder.component';
import { MatColumnDef } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { FormFieldConfig, FormFieldType, FormRowConfig, OptionType } from '../../form-builder/form-builder.component';
import { NbToastrService, NbWindowModule, NbWindowService } from '@nebular/theme';
import { forkJoin } from 'rxjs';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Empleado } from '../../@core/data/cotizacion-data';
import { CSVGenerator } from '../../@core/utils/csv-generator';
import { removeEmptyControls } from '../../@core/utils/forms-util';

@Component(
  {
    selector: 'ngx-users',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './users.component.html',
  })

export class UsersComponent {

  @ViewChild('newDataFormView') window: TemplateRef<any>;
  windowRef: any;
  permisos: Permission[] = [];
  roles: Role[] = [];
  empleados: Empleado[] = [];
  colums:MatTableColumnConfig[] = [
    {
      name: 'name',
      label: 'Nombre',

    },
    {
      name: 'email',
      label: 'Correo',
    },
    {
      name: 'empleado',
      label: 'Empleado',
      value: (row: User) => row.empleado ? `${row.empleado.titulo} ${row.empleado.nombre} ${row.empleado.apellido_paterno} ${row.empleado.apellido_materno}`  : ''
    },
    {
      name: 'roles',
      label: 'Roles',
      type: 'tag',
      value: (row: User) => row.roles.map((role: { name: string }) => role.name)
    }
  ];
  loading: boolean = true;
  data:User[] = [];
  filteredData:User[] = [];
  searchText: string = '';
  actions:MatTableActionConfig[] = [
    {
      name: 'edit',
      label: 'Cambiar contraseña',
      icon: 'edit',
      color: 'primary',
      formSize: 'medium',
      title: 'Cambiar contraseña',
      action: (form:FormGroup,row: User,ref) => {
        this.userService.update(form, row.id).subscribe(() => {
          ref.close();
          this.userService.getAll().subscribe((users: User[]) => {
            this.data = users;
            this.filteredData = users;
          });
        });
      },
      formConfig:(row:User) => {
        return [
          {
            type: FormFieldType.Row,
            fields:{
              password: {
                type: FormFieldType.Input,
                label: 'Contraseña',
                formControlName: 'password',
                value: '',
                validations: ['required'],
                col: 12,
              },
            }
          }
        ] as FormRowConfig[];
      }
    },
    {
      name: 'edit',
      label: 'Editar',
      icon: 'edit',
      color: 'accent',
      formSize: 'medium',
      title: 'Editar usuario',
      action: (form:FormGroup,row: User,ref) => {
        removeEmptyControls(form);
        this.userService.update(form, row.id).subscribe(() => {
          ref.close();
          this.userService.getAll().subscribe((users: User[]) => {
            this.data = users;
            this.filteredData = users;
          });
        });
      },
      formConfig:(row:User) => {
        return [
          {
            type: FormFieldType.Row,
            fields:{
              empleado_id: {
                type: FormFieldType.Select,
                label: 'Empleado',
                formControlName: 'empleado_id',
                placeholder: 'Selecciona un empleado',
                value: row.empleado?.id,
                col: 6,
                options: [{key: OptionType.SelectOptions, value: this.empleados.map((empleado: Empleado) => ({key: empleado.id, value: `${empleado.titulo} ${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}`}))}]
              },
              name: {
                type: FormFieldType.Input,
                label: 'Nombre',
                formControlName: 'name',
                placeholder: 'Nombre',
                value: row.name,
                validations: ['required'],
                col: 6,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields:{
              email: {
                type: FormFieldType.Input,
                label: 'Correo',
                formControlName: 'email',
                placeholder: 'Correo',
                value: row.email,
                validations: ['required'],
                col: 6,
              },
              roles: {
                type: FormFieldType.Select,
                label: 'Roles',
                formControlName: 'roles',
                placeholder: 'Selecciona los roles',
                value: row.roles.map((role: Role) => role.id),
                col: 6,
                multiple: true,
                options: [{key: OptionType.SelectOptions, value: this.roles.map((role: Role) => ({key: role.id, value: role.name}))}]
              }
            }
          }
        ] as FormRowConfig[];
      }
    },
    {
      name: 'delete',
      label: 'Eliminar',
      icon: 'delete',
      color: 'warn',
      title: 'Eliminar usuario',
      message: (row: User) => `¿Estás seguro de eliminar el usuario ${row.name}?`,
      action: (row: User) => {
        this.userService.delete(row.id).subscribe(() => {
          this.toastrService.success('Usuario eliminado', 'Éxito');
          this.userService.getAll().subscribe((users: User[]) => {
            this.data = users;
            this.filteredData = users;
          });
        });
      }
    }
  ];

  constructor(private titleService: Title, private userService: UserService, private toastrService: NbToastrService,
    private permisionsService:PermisionsService, private rolesService:RoleService, private empleadosService: EmpleadoService,
    private windowService: NbWindowService,
  ) {
    this.titleService.setTitle('Usuarios');
    this.userService.getAll().subscribe((users: User[]) => {
      this.data = users;
      this.filteredData = users;
    });

    forkJoin({
      permisos: this.permisionsService.getAll(),
      roles: this.rolesService.getAll(),
      empleados: this.empleadosService.getAll()
    }).subscribe(({ permisos, roles,empleados }) => {
      this.permisos = permisos;
      this.roles = roles;
      this.empleados = empleados;
      this.loading = false;
    });

  }

  generarReporteCSV() {
    CSVGenerator.generateCSVColumnDefs(this.colums, this.data, 'usuarios.csv');
  }
    onUsuarioSearch() {
    this.filteredData = this.data.filter((user: User) => {
      return user.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(this.searchText.toLowerCase()) ||
        user.roles.map((role: Role) => role.name).join(' ').toLowerCase().includes(this.searchText.toLowerCase()) ||
        (user.empleado ? `${user.empleado.titulo} ${user.empleado.nombre} ${user.empleado.apellido_paterno} ${user.empleado.apellido_materno}`.toLowerCase().includes(this.searchText.toLowerCase()) : false);
    });
  }

    onClickOpenNuevaMaquinaria() {
      const formConfig = [
        {
          type: FormFieldType.Row,
          fields:{
            empleado: {
              type: FormFieldType.Select,
              label: 'Empleado',
              formControlName: 'empleado',
              placeholder: 'Selecciona un empleado',
              col: 6,
              options: [{key: OptionType.SelectOptions, value: this.empleados.map((empleado: Empleado) => ({key: empleado.id, value: `${empleado.titulo} ${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}`}))}]
            },
            name: {
              type: FormFieldType.Input,
              label: 'Nombre',
              formControlName: 'name',
              placeholder: 'Nombre',
              validations: ['required'],
              col: 6,
            },
          }
        },
        {
          type: FormFieldType.Row,
          fields:{
            email: {
              type: FormFieldType.Input,
              label: 'Correo',
              formControlName: 'email',
              placeholder: 'Correo',
              validations: ['required'],
              col: 6,
            },
            password: {
              type: FormFieldType.Input,
              label: 'Contraseña',
              formControlName: 'password',
              placeholder: 'Contraseña',
              validations: ['required'],
              col: 6,
            },
          }
        },
        {
          type: FormFieldType.Row,
          fields:{
            roles: {
              type: FormFieldType.Select,
              label: 'Roles',
              formControlName: 'roles',
              placeholder: 'Selecciona los roles',
              multiple: true,
              options: [{key: OptionType.SelectOptions, value: this.roles.map((role: Role) => ({key: role.name, value: role.name}))}]
            }
          }
        }
      ]
      this.windowRef = this.windowService.open( this.window, { title: 'Nuevo usuario', context: { formConfig:formConfig } });
    }

    onFormOutput(event) {
      this.userService.create(event).subscribe({
      next: () => {
        this.toastrService.success('Usuario creado', 'Éxito');
        this.windowRef.close();
        this.userService.getAll().subscribe((users: User[]) => {
        this.data = users;
        this.filteredData = users;
        });
      },
      error: (err) => {
        this.toastrService.danger('Error al crear el usuario', 'Error');
        console.error(err);
      }
      });
    }
}
