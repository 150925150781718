import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { EmpleadoCategoria } from '../data/cotizacion-data';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoCategoriasService extends BaseService<EmpleadoCategoria>{

  protected getPath(): string {
    return 'empleado-categorias';
  }

}
