import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Maquinaria } from '../../@core/data/maquinaria-data';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MaquinariaService } from '../../@core/services/maquinaria.service';

@Component({
  selector: 'ngx-nueva-maquinaria-form',
  templateUrl: './nueva-maquinaria-form.component.html',
  styleUrls: ['./nueva-maquinaria-form.component.scss']
})
export class NuevaMaquinariaFormComponent implements OnInit{
  maquinariaForm: FormGroup;
  @Output() maquinariaCreated = new EventEmitter<boolean>();
  @Input() maquinaria: Maquinaria;

  constructor(private maquinariaService: MaquinariaService) { }
  ngOnInit(): void {
    this.maquinariaForm = new FormGroup({
      nombre: new FormControl('', Validators.required),
      numero: new FormControl('', Validators.required),
      serie: new FormControl('', Validators.required),
      modelo: new FormControl('', Validators.required),
      ubicacion: new FormControl('', Validators.required),
      lts: new FormControl('', Validators.required),
      toneladas: new FormControl('', Validators.required),
      encargado: new FormControl('', Validators.required),
      precio: new FormControl('', Validators.required),
      mantenimiento: new FormControl('', Validators.required),
    });
    if(this.maquinaria!==undefined&&this.maquinaria!==null) {
      this.maquinariaService.getById(this.maquinaria.id).subscribe((data) => {
        this.maquinariaForm.patchValue({
          nombre: data.nombre,
          numero: data.numero,
          serie: data.serie,
          modelo: data.modelo,
          ubicacion: data.ubicacion,
          lts: data.lts,
          toneladas: data.toneladas,
          encargado: data.encargado,
          precio: data.precio,
          mantenimiento: data.mantenimiento,
        });
      });
    }
  }

  loginFormControls() {
    return this.maquinariaForm.controls;
  }

  onSubmit() {
    if(this.maquinaria!==null&&this.maquinaria!==undefined) {
      this.maquinariaService.update(this.maquinariaForm, this.maquinaria.id)
      .subscribe(() => {
        this.maquinariaCreated.emit(true);
      },
      error => {
        console.error(error);
      });
    }else{
      this.maquinariaService.create(this.maquinariaForm)
      .subscribe(() => {
        this.maquinariaCreated.emit(true);
      },
      error => {
        console.error(error);
      });
    }
  }
}
