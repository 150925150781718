import { NgModule } from '@angular/core';
import {
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbTreeGridCellDirective,
  NbTreeGridModule,
  NbWindowModule,
} from '@nebular/theme';
import { NbTableModule } from '@nebular/theme';
import {UsersComponent} from './users.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import {UserTableComponent} from './user-table/user-table.component';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {UserService} from '../../@core/services/users.service';
import {MatButtonModule} from '@angular/material/button';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { RolesComponent } from './roles/roles.component';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [UsersComponent, UserProfileComponent, UserTableComponent, RolesComponent],
    imports: [
        NbCardModule,
        NbTableModule,
        NbTreeGridModule,
        CommonModule,
        MatTableModule,
        MatButtonModule,
        NbWindowModule.forChild(),
        NbButtonModule,
        Ng2SmartTableModule,
        NbInputModule,
        NbTreeGridModule,
        FormBuilderModule,
        MatTableBuilderModule,
        NbIconModule,
        NbTooltipModule,
        NbSpinnerModule,
        ReactiveFormsModule,
        FormsModule,
        FormBuilderModule,
    ],
  providers: [UserService],
})
export class UsersModule { }
