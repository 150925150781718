import { NbWindowService } from '@nebular/theme';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FormFieldConfig, FormRowConfig } from '../form-builder/form-builder.component';
import * as moment from 'moment';
@Component({
  selector: 'ngx-mat-table-builder',
  templateUrl: './mat-table-builder.component.html',
  styleUrls: ['./mat-table-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MatTableBuilderComponent implements OnChanges {
  @Input() data: any;
  @Input() columnsDef: MatTableColumnConfig[];
  @Input() showActions: boolean = true;
  @Input() hasFooter: boolean = false;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('editWindow',{ read: TemplateRef }) editWindow: TemplateRef<HTMLElement>;
  editFormRef: any;
  columns: string[];
  customColumns: string[] = [];
  allColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  @Input() actions: MatTableActionConfig[]= [];
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columnsDef) {
      this.updateColumns(changes.columnsDef.currentValue);
    }
    if (changes.data) {
      this.updateDataSource(changes.data.currentValue);
    }
  }

  private updateColumns(columnsDef: MatTableColumnConfig[]): void {
    this.columns = columnsDef.map(column => column.name);
    this.customColumns = this.showActions ? ["actions"] : [];
    this.allColumns = [...this.columns, ...this.customColumns];
  }

  private updateDataSource(data: any): void {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.table) {
      this.table.renderRows();
    }
    this.cdr.detectChanges();
  }

  constructor(
    private windowService: NbWindowService,private cdr: ChangeDetectorRef
  ) { }

  getColumnName(column:string){
    return this.columnsDef.find(c => c.name === column).label;
  }
  getValue(column: string, row: any): any {
    const columnConfig = this.columnsDef.find(c => c.name === column);
    if (typeof columnConfig.value === 'function') {
      return columnConfig.value(row);
    } else {
      return row[column];
    }
  }


  getTypo(column: string): string {
    return this.columnsDef.find(c => c.name === column).type;
  }

  getMessage(action: MatTableActionConfig, row: any): string {
    if (typeof action.message === 'function') {
      return action.message(row);
    } else {
      return action.message;
    }
  }

  getBackgroundColor(column:string,row:any):string{
    const columnConfig = this.columnsDef.find(c => c.name === column);
    if(typeof columnConfig.backgroundColor === 'function'){
      return columnConfig.backgroundColor(row);
    }
    return columnConfig.backgroundColor;
  }

  getAlign(column:string, row: any):string{
    const columnConfig = this.columnsDef.find(c => c.name === column);
    return columnConfig.align;
  }

  onClickEdit(row:any, action:MatTableActionConfig){
    this.editFormRef = this.windowService.open(this.editWindow,
      { title: 'Editar',hasBackdrop: false, context: { data: row,config:action.formConfig(row),button:action } });
  }

  getFormSize(action:MatTableActionConfig):string{
    console.log(action);
    switch(action.formSize){
      case 'small':
        return 'responsive-div-sm';
      case 'medium':
        return 'responsive-div-md';
      case 'large':
        return 'responsive-div-lg';

      default:
        return 'responsive-div-sm';
    }
  }

}

export class MatTableColumnConfig {
  name: string;
  label: string;
  value?: any|Function;
  template?: TemplateRef<any>;
  type?: string;
  tooltip?: string;
  icon?: string;
  action?: Function;
  size?: string;
  show?: Function;
  backgroundColor?: string|Function;
  align?: string;
}

export class MatTableActionConfig {
  name: string;
  size?: string;
  color?: string;
  formSize?: string;
  icon_color?: string;
  label?: string;
  icon?: string;
  action?: Function;
  message?: string|Function;
  title?: string;
  formConfig?:Function;
  show?: Function;
}


