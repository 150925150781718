import { Component, TemplateRef, ViewChild } from '@angular/core';
import { EmpleadoSeguro } from '../../@core/data/empleado-seguro-data';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { EmpleadoSeguroService } from '../../@core/services/empleado-seguro.service';
import { FormFieldType, FormBuilderConfig, OptionType } from '../../form-builder/form-builder.component';
import { MatTableColumnConfig, MatTableActionConfig } from '../../mat-table-builder/mat-table-builder.component';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Empleado } from '../../@core/data/cotizacion-data';
import { removeEmptyControls } from '../../@core/utils/forms-util';

@Component({
  selector: 'ngx-empleado-seguros',
  templateUrl: './empleado-seguros.component.html',
  styleUrls: ['./empleado-seguros.component.scss']
})
export class EmpleadoSegurosComponent {
  empleadoSeguros: EmpleadoSeguro[];
  dataFiltrada: EmpleadoSeguro[];
  nuevoEmpleadoSeguroVentana: NbWindowRef;
  @ViewChild('nuevoAsegurado', { read: TemplateRef }) empleadoSeguroTemplate: TemplateRef<HTMLElement>;
  textoFiltro: string = '';
  loading: boolean = true;
  columnasDef: MatTableColumnConfig[] = [
    { name: 'empleado', label: 'Empleado', type: 'text' },
    { name: 'registro_patronal', label: 'Registro Patronal', type: 'text' },
    { name: 'fecha_alta', label: 'Fecha de Alta', type: 'date' },
    { name: 'fecha_baja', label: 'Fecha de Baja', type: 'date' },
    { name: 'lugar', label: 'Lugar', type: 'text' },
    { name: 'nombre_responsable', label: 'Nombre del Responsable', type: 'text' },
    { name: 'telefono_responsable', label: 'Teléfono del Responsable', type: 'text' },
    { name: 'credito_infonavit', label: 'Crédito Infonavit', type: 'boolean' },
    { name: 'total_pagar_infonavit', label: 'Total a Pagar Infonavit', type: 'number' },
  ];

  acciones: MatTableActionConfig[] = [
    {
      name: 'view', icon: 'eye', label: 'Ver hoja retención',
      action: (empleadoSeguro: EmpleadoSeguro) => {
        this.empleadoSeguroService.downloadHojaRetencion(empleadoSeguro.id).subscribe((datos) => {
          const url = window.URL.createObjectURL(datos);
          window.open(url);
      });
    }
    },
    {
      name: 'edit', icon: 'edit', label: 'Editar',
      formSize: 'medium',
      formConfig: (e: EmpleadoSeguro) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              empleado_id: {
                formControlName: 'empleado_id',
                label: 'Empleado',
                placeholder: 'Seleccione un empleado',
                value:e.empleado_id ,
                col: 6,
                validations: ['required'],
                type: FormFieldType.Select,

                options: [{ key: OptionType.SelectOptions, value: this.empleados.map((e) => ({ key: e.id, value: e.titulo+' '+ e.nombre +' '+ e.apellido_paterno +' '+ e.apellido_paterno })) }]
              },
              registro_patronal: {
                formControlName: 'registro_patronal',
                label: 'Registro Patronal',
                placeholder: 'Registro Patronal',
                value: e.registro_patronal,
                validations: ['required'],
                col: 6,
                type: FormFieldType.Input,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              fecha_alta: {
                formControlName: 'fecha_alta',
                label: 'Fecha de Alta',
                placeholder: 'Fecha de Alta',
                value: e.fecha_alta,
                validations: ['required'],
                col: 6,
                type: FormFieldType.Date,
              },
              fecha_baja: {
                formControlName: 'fecha_baja',
                label: 'Fecha de Baja',
                placeholder: 'Fecha de Baja',
                value: e.fecha_baja,
                col: 6,
                type: FormFieldType.Date,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              lugar: {
                formControlName: 'lugar',
                label: 'Lugar',
                placeholder: 'Lugar',
                value: e.lugar,
                col: 6,
                type: FormFieldType.Input,
              },
              nombre_responsable: {
                formControlName: 'nombre_responsable',
                label: 'Nombre del Responsable',
                placeholder: 'Nombre del Responsable',
                value: e.nombre_responsable,
                col: 6,
                type: FormFieldType.Input,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              telefono_responsable: {
                formControlName: 'telefono_responsable',
                label: 'Teléfono del Responsable',
                placeholder: 'Teléfono del Responsable',
                value: e.telefono_responsable,
                col: 6,
                type: FormFieldType.Input,
              },
              credito_infonavit: {
                formControlName: 'credito_infonavit',
                label: 'Crédito Infonavit',
                placeholder: 'Crédito Infonavit',
                value: e.credito_infonavit,
                col: 6,
                type: FormFieldType.Radio,
                options: [{key:OptionType.RadioOptions,value:[
                  {label: 'Sí', value: true},
                  {label: 'No', value: false},
                ]}
                ]
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              total_pagar_infonavit: {
                formControlName: 'total_pagar_infonavit',
                label: 'Total a Pagar Infonavit',
                value: e.total_pagar_infonavit,
                col: 6,
                type: FormFieldType.Number,
              }
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              hoja_retencion: {
                formControlName: 'hoja_retencion',
                label: 'Hoja de Retención',
                placeholder: 'Hoja de Retención',
                col: 12,
                type: FormFieldType.File,
                options: [
                  { key: OptionType.fileSelectTypes, value: ['application/pdf'] },
                ]
              },
            }
          },
        ];
      },
      action: (formulario: FormGroup, empleadoSeguro: EmpleadoSeguro, referenciaVentana) => {
        this.empleadoSeguroService.update(formulario, empleadoSeguro.id).subscribe((datos) => {
          this.toastrService.show('Seguro de empleado actualizado con éxito',
            'Seguro de Empleado Actualizado', { status: 'success' });
          referenciaVentana.close();
          this.empleadoSeguroService.getAll().subscribe((datos) => {
            this.empleadoSeguros = datos;
            this.dataFiltrada = datos;
          });
        }, (error) => {
          this.toastrService.danger('Error: ' + error.error.message, 'Error al actualizar el seguro de empleado');
        });
        if (formulario.get('hoja_retencion').value) {
          this.empleadoSeguroService.uploadHojaRetencion(empleadoSeguro.id,formulario.get('hoja_retencion').value).subscribe(() => {
            this.toastrService.show('Hoja de retención actualizada con éxito',
              'Hoja de Retención Actualizada', { status: 'success' });
          }, (error) => {
            this.toastrService.danger('Error: ' + error.error.message, 'Error al actualizar la hoja de retención');
          });
        }
      }
    },
    {
      name: 'delete', icon: 'trash', label: 'Eliminar',
      message: '¿Está seguro que desea eliminar este seguro de empleado?',
      title: 'Eliminar seguro de empleado',
      action: (empleadoSeguro: EmpleadoSeguro) => {
        this.empleadoSeguroService.delete(empleadoSeguro.id).subscribe(() => {
          this.empleadoSeguroService.getAll().subscribe((datos) => {
            this.empleadoSeguros = datos;
            this.dataFiltrada = datos;
          });
          this.toastrService.show('Seguro de empleado eliminado con éxito',
            'Seguro de Empleado Eliminado', { status: 'success' });
        });
      }
    },
  ];
  empleados: Empleado[];

  constructor(
    private titleService: Title,
    private windowService: NbWindowService,
    private toastrService: NbToastrService,
    private empleadoSeguroService: EmpleadoSeguroService,
    private empleadoService: EmpleadoService
  ) {
    this.titleService.setTitle('Seguros de Empleados');
    this.empleadoService.getAll().subscribe((datos) => {
      this.empleados = datos;
      this.loading = false;
    });
    this.empleadoSeguroService.getAll().subscribe((datos) => {
      this.empleadoSeguros = datos;
      this.dataFiltrada = datos;
    });

  }

  abrirFormularioNuevoEmpleadoSeguro() {
    let formConfig: FormBuilderConfig = [
      {
        type: FormFieldType.Row,
        fields: {
          empleado_id: {
            formControlName: 'empleado_id',
            label: 'Empleado',
            placeholder: 'Seleccione un empleado',
            value: null,
            validations: ['required'],
            col: 6,
            type: FormFieldType.Select,
            options: [{ key: OptionType.SelectOptions, value: this.empleados.map((e) => ({ key: e.id, value: e.titulo+' '+ e.nombre +' '+ e.apellido_paterno +' '+ e.apellido_paterno })) }]
          },
          registro_patronal: {
            formControlName: 'registro_patronal',
            label: 'Registro Patronal',
            placeholder: 'Registro Patronal',
            validations: ['required'],
            value: null,
            col: 6,
            type: FormFieldType.Input,
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          fecha_alta: {
            formControlName: 'fecha_alta',
            label: 'Fecha de Alta',
            placeholder: 'Fecha de Alta',
            validations: ['required'],
            value: null,
            col: 6,
            type: FormFieldType.Date,
          },
          fecha_baja: {
            formControlName: 'fecha_baja',
            label: 'Fecha de Baja',
            placeholder: 'Fecha de Baja',
            value: null,
            col: 6,
            type: FormFieldType.Date,
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          lugar: {
            formControlName: 'lugar',
            label: 'Lugar',
            placeholder: 'Lugar',
            value: null,
            col: 6,
            type: FormFieldType.Input,
          },
          nombre_responsable: {
            formControlName: 'nombre_responsable',
            label: 'Nombre del Responsable',
            placeholder: 'Nombre del Responsable',
            value: null,
            col: 6,
            type: FormFieldType.Input,
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          telefono_responsable: {
            formControlName: 'telefono_responsable',
            label: 'Teléfono del Responsable',
            placeholder: 'Teléfono del Responsable',
            value: null,
            col: 6,
            type: FormFieldType.Input,
          },
          credito_infonavit: {
            formControlName: 'credito_infonavit',
            label: 'Crédito Infonavit',
            placeholder: 'Crédito Infonavit',
            value: false,
            col: 6,
            type: FormFieldType.Radio,
            options: [{key:OptionType.RadioOptions,value:[
              {label: 'Sí', value: true},
              {label: 'No', value: false},
            ]}
            ]
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          total_pagar_infonavit: {
            formControlName: 'total_pagar_infonavit',
            label: 'Total a Pagar Infonavit',
            value: 0,
            col: 6,
            type: FormFieldType.Number,
          }
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          hoja_retencion: {
            formControlName: 'hoja_retencion',
            label: 'Hoja de Retención',
            placeholder: 'Hoja de Retención',
            col: 12,
            type: FormFieldType.File,
            options: [
              { key: OptionType.fileSelectTypes, value: ['application/pdf'] },
            ]
          },
        }
      },
    ];
    this.nuevoEmpleadoSeguroVentana = this.windowService.open(
      this.empleadoSeguroTemplate,
      { title: 'Nuevo Seguro de Empleado', context: { formConfig: formConfig } },
    );
  }

  guardarNuevoEmpleadoSeguro(formulario: FormGroup) {
    removeEmptyControls(formulario);
    this.empleadoSeguroService.create(formulario).subscribe(() => {
      this.nuevoEmpleadoSeguroVentana.close();
      this.empleadoSeguroService.getAll().subscribe((datos) => {
        this.empleadoSeguros = datos;
        this.dataFiltrada = datos;
      });
      this.toastrService.success('Seguro de empleado creado con éxito',
        'Seguro de Empleado Creado');
    }, (error) => {
      this.toastrService.danger('Error: ' + error.error.message,
        'Error al crear el seguro de empleado');
    });
  }

  filtrarEmpleadoSeguros() {
    const textoFiltroMinusculas = this.textoFiltro.toLowerCase();
    this.dataFiltrada = this.empleadoSeguros.filter((empleadoSeguro) => {
      return (
        empleadoSeguro.empleado?.toLowerCase().includes(textoFiltroMinusculas) ||
        empleadoSeguro.registro_patronal?.toLowerCase().includes(textoFiltroMinusculas) ||
        empleadoSeguro.lugar?.toLowerCase().includes(textoFiltroMinusculas) ||
        empleadoSeguro.nombre_responsable?.toLowerCase().includes(textoFiltroMinusculas)
      );
    });
  }

}
