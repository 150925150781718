import { Component, Input } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { ContratoRH } from '../../../@core/data/rh-data';
import { ContratosRhService } from '../../../@core/services/contratos-rh.service';

@Component({
  selector: 'ngx-manage-documents',
  templateUrl: './manage-documents.component.html',
  styleUrls: ['./manage-documents.component.scss']
})
export class ManageDocumentsComponent {
  @Input() contrato: ContratoRH;

  pdfFiles:
  { name: string, key: string,path: string, file: File | null }[] = [
    { name: 'INE', key: 'ine_file', path: 'ine_path', file: null },
    { name: 'CURP', key: 'curp_file', path: 'curp_path', file: null },
    { name: 'Acta de nacimiento', key: 'acta_nac_file', path: 'acta_nac_path', file: null },
    { name: 'NSS', key: 'nss_file', path: 'nss_path', file: null },
    { name: 'CIF', key: 'cif_file', path: 'cif_path', file: null },
    { name: 'Comp de domicilio', key: 'comp_dom_file', path: 'comp_dom_path', file: null },
    { name: 'Constancia de retiro', key: 'const_ret_file', path: 'const_ret_path', file: null },
    { name: 'Licencia', key: 'licencia_file', path: 'licencia_path', file: null },
    { name: 'Cuenta bancaria', key: 'cuenta_file', path: 'cuenta_path', file: null },
    { name: 'Título profesional', key: 'titulo_file', path: 'titulo_path', file: null },
    { name: 'Cedula profesional', key: 'cedula_file', path: 'cedula_path', file: null },
  ];

  constructor(private toastrService: NbToastrService, private contratoService: ContratosRhService) {}

  contratoHasFile(path: string): boolean {
    return this.contrato[path] !== null && this.contrato[path] !== undefined && this.contrato[path] !== '';
  }

  onFileSelected(event: any, index: number) {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      this.pdfFiles[index].file = file;
    } else {
      this.toastrService.danger('Please select a valid PDF file', 'Error');
    }
  }

  uploadFile(index: number) {
    if (this.pdfFiles[index].file) {
      console.log(this.contrato.id);
      this.contratoService.updateDoc(this.contrato.id,this.pdfFiles[index].file,this.pdfFiles[index].key).subscribe((data) => {
        this.contrato = data;
        this.toastrService.success(`${this.pdfFiles[index].name} uploaded successfully`, 'Success');
        this.pdfFiles[index].file = null;
      }, (error) => {
        this.toastrService.danger('Error uploading file', 'Error');
      });

    } else {
      this.toastrService.warning('Please select a file first', 'Warning');
    }
  }

  downloadFile(index: number) {
    this.contratoService.downloadDoc(this.contrato.id,this.pdfFiles[index].key).subscribe((data) => {
      const blob = new Blob([data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url);

      this.toastrService.success(`${this.pdfFiles[index].name} downloaded successfully`, 'Success');
    }, (error) => {
      this.toastrService.danger('Error downloading file', 'Error');
    });
  }
}
