<button nbButton status="primary" (click)="openNewFolder()">
  <nb-icon icon="folder-add-outline"></nb-icon>
  Nueva Carpeta
</button>
<table [nbTreeGrid]="dataSource" equalColumnsWidth>
  <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>

  <ng-container [nbTreeGridColumnDef]="customColumn">
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <nb-tree-grid-row-toggle
        [expanded]="row.expanded"
        *ngIf="row.data.kind === 'dir'">
      </nb-tree-grid-row-toggle>
      {{row.data.name}}
    </td>
  </ng-container>

  <ng-container *ngFor="let column of defaultColumns" [nbTreeGridColumnDef]="column">
    <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column]}}</td>
  </ng-container>

  <ng-container [nbTreeGridColumnDef]="'actions'">
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <button nbButton size="tiny" *ngIf="row.data.kind === 'dir'" (click)="renameFolder(row.data)" nbTooltip="Renambrar carpeta"><nb-icon icon="edit-2-outline"></nb-icon></button>
      <button nbButton size="tiny" *ngIf="row.data.kind === 'dir'" (click)="addDocument(row.data)" nbTooltip="Agregar documento"><nb-icon icon="plus-outline"></nb-icon></button>
      <button nbButton size="tiny" *ngIf="row.data.kind === 'dir'" (click)="deleteFolder(row.data)" nbTooltip="Eliminar carpeta"><nb-icon icon="trash-2-outline"></nb-icon></button>
      <button nbButton size="tiny" *ngIf="row.data.kind === 'file'" (click)="downloadDocument(row.data)" nbTooltip="Descargar documento"><nb-icon icon="download-outline"></nb-icon></button>
      <button nbButton size="tiny" *ngIf="row.data.kind === 'file'" (click)="editDocument(row.data)" nbTooltip="Editar documento"><nb-icon icon="edit-2-outline"></nb-icon></button>
      <button nbButton size="tiny" *ngIf="row.data.kind === 'file'" (click)="deleteDocument(row.data)" nbTooltip="Eliminar documento"><nb-icon icon="trash-2-outline"></nb-icon></button>
    </td>
  </ng-container>
</table>

<ng-template #newFolderTemplate let-data >
  <ngx-form-builder [config]="data.formDef" (formOuput)="onSubmitNewFolder($event)" ></ngx-form-builder>
</ng-template>

<ng-template #renameFolderTemplate let-data>
  <ngx-form-builder [config]="data.formDef" (formOuput)="onSubmitRenameFolder($event, data.carpeta)"></ngx-form-builder>
</ng-template>

<ng-template #addDocumentTemplate let-data>
  <ngx-form-builder [config]="data.formDef" (formOuput)="onSubmitAddDocument($event, data.carpeta)"></ngx-form-builder>
</ng-template>

<ng-template #editDocumentTemplate let-data>
  <ngx-form-builder [config]="data.formDef" (formOuput)="onSubmitEditDocument($event, data.carpeta)"></ngx-form-builder>
</ng-template>
