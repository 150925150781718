import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CotizacionMaquinaria } from '../../../../@core/data/cotizacion-data';
import { MatTable } from '@angular/material/table';
import { CotizacionMaquinariaService } from '../../../../@core/services/cotizacion-maquinaria.service';
import { MaquinariaService } from '../../../../@core/services/maquinaria.service';
import { Maquinaria } from '../../../../@core/data/maquinaria-data';

@Component({
  selector: 'ngx-cotizacion-maquinarias-table',
  templateUrl: './cotizacion-maquinarias-table.component.html',
  styleUrls: ['./cotizacion-maquinarias-table.component.scss']
})
export class CotizacionMaquinariasTableComponent implements OnInit {

  maquinarias: Maquinaria[] = [];
  @Input() conceptos: CotizacionMaquinaria[];
  defaultColumns: string[] = [ 'maquinaria_nombre', 'concepto', 'duracion','cantidad', 'precio_renta_maquinaria', 'subtotal', 'acciones'];
  customColumns: string[] = [];
  allColumns: string[] = [...this.defaultColumns, ...this.customColumns];
  @ViewChild(MatTable) table: MatTable<CotizacionMaquinaria>;
  @Output() saveCotizacion: EventEmitter<CotizacionMaquinaria[]> = new EventEmitter<CotizacionMaquinaria[]>();

  options: string[] = [];
  duracionOptions: string[] = ['15 DÍAS O 100 HORAS', '30 DÍAS O 200 HORAS'];
  filteredControlOptions$: Observable<string[]>;
  inputFormControl: FormControl;

  iva: number = 16; // Porcentaje de IVA configurable

  constructor(private cotizacionMaqService: CotizacionMaquinariaService, private maquinariaService: MaquinariaService) { }

  ngOnInit(): void {
    this.maquinariaService.getAllBy([{ rentable: true }, { rentado: false }]).subscribe(maquinarias => {
      this.maquinarias = maquinarias;
      this.options = maquinarias.map(maquinaria => `(${maquinaria.numero}) ${maquinaria.nombre}`);
      this.filteredControlOptions$ = of(this.options);
    });

    this.inputFormControl = new FormControl();
    this.filteredControlOptions$ = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  onModelChange(value: string, row: CotizacionMaquinaria): void {
    this.filteredControlOptions$ = of(this.filter(value));
    const index = this.conceptos.indexOf(row);
    if (index > -1) {
      const numero = value.split(' ')[0].replace('(', '').replace(')', '');
      const nombre = value.split(' ')[1];
      this.conceptos[index].maquinaria_nombre = nombre;
      this.conceptos[index].maquinaria_numero = numero;
    }
  }

  onSelectMaquinaria(maquinariaNombre: string, row: CotizacionMaquinaria): void {
    const index = this.conceptos.indexOf(row);
    const maquinaria = this.maquinarias.find(maq => `${maq.numero} ${maq.nombre}` === maquinariaNombre);
    if (maquinaria) {
      this.conceptos[index].subtotal = maquinaria.precio * this.conceptos[index].cantidad_horas;
      this.conceptos[index].maquinaria_id = maquinaria.id;
      if (row.cantidad_horas > 0) {
        this.conceptos[index].subtotal = maquinaria.precio * row.cantidad_horas;
      }
      this.table.renderRows();
    }
  }

  /**
   * Actualiza las horas de la cotización y recalcula el subtotal.
   * @param $event - Evento de cambio del input.
   * @param row - Fila de la cotización de maquinaria.
   */
  onHorasChange($event: Event, row: CotizacionMaquinaria): void {
    const value: number = parseFloat(($event.target as HTMLInputElement).value);
    const index = this.conceptos.indexOf(row);
    if (index > -1) {
      this.conceptos[index].cantidad_horas = value;
      this.conceptos[index].subtotal = this.calculateSubtotal(this.conceptos[index].precio_renta_maquinaria, value);
      this.table.renderRows();
    }
  }

  /**
   * Actualiza el precio de la cotización y recalcula el subtotal.
   * @param $event - Evento de cambio del input.
   * @param row - Fila de la cotización de maquinaria.
   */
  onPrecioChange($event: Event, row: CotizacionMaquinaria): void {
    const value: number = parseFloat(($event.target as HTMLInputElement).value);
    const index = this.conceptos.indexOf(row);
    if (index > -1) {
      this.conceptos[index].precio_renta_maquinaria = value;
      this.conceptos[index].subtotal = this.calculateSubtotal(value, this.conceptos[index].cantidad_horas);
      this.table.renderRows();
    }
  }

  /**
   * Maneja el cambio de duración y convierte el texto a mayúsculas.
   * @param $event - Evento de cambio del input.
   */
  onDuracionChange($event: Event): void {
    const input = $event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }

  /**
   * Previene el comportamiento por defecto del evento.
   * @param event - Evento a prevenir.
   */
  preventDefault(event: Event): void {
    event.preventDefault();
  }

  /**
   * Calcula el costo total de todas las cotizaciones incluyendo el IVA.
   * @returns El costo total con IVA.
   */
  getTotalCost(): number {
    const subtotal = this.conceptos.reduce((acc, cotizacion) => acc + cotizacion.subtotal, 0);
    const ivaAmount = (subtotal * this.iva) / 100;
    return subtotal + ivaAmount;
  }

  /**
   * Calcula el monto del IVA basado en el subtotal.
   * @returns El monto del IVA.
   */
  getIvaAmount(): number {
    const subtotal = this.conceptos.reduce((acc, cotizacion) => acc + cotizacion.subtotal, 0);
    return (subtotal * this.iva) / 100;
  }

  /**
   * Elimina una cotización de la lista.
   * @param cotizacion - Cotización a eliminar.
   */
  removeCotizacionVehiculo(cotizacion: CotizacionMaquinaria): void {
    const index = this.conceptos.indexOf(cotizacion);
    if (index > -1) {
      this.conceptos.splice(index, 1);
      this.table.renderRows();
    }
  }

  /**
   * Calcula el subtotal basado en el precio de renta y la cantidad de horas.
   * @param precio - Precio de renta de la maquinaria.
   * @param horas - Cantidad de horas de renta.
   * @returns El subtotal calculado.
   */
  private calculateSubtotal(precio: number, horas: number): number {
    return parseFloat((precio * horas).toFixed(2));
  }
}
