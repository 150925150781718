import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NbAuthService } from '@nebular/auth';
import { NbAccessChecker } from '@nebular/security';
import { Role, User } from '../data/usuarios-data';
import { NbToastrService } from '@nebular/theme';

export const AuthGuard = (
  route: ActivatedRouteSnapshot,
  authService: NbAuthService,
  router: Router
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const toastsService = inject(NbToastrService);
  try {

    const userJson = localStorage.getItem('userLogged');
    if (!userJson) {
      toastsService.danger('El usuario no se encuentra loagueado', 'Error');

      router.navigate(['/auth/login']);
    }

    const user: User = JSON.parse(userJson);
    if (!user || !user.roles) {
      toastsService.danger('El usuario no se encuentra loagueado', 'Error');
      return false
    }

    const requiredPermissions: string[] = route.data.permissions;
    const userPermission: string[] = user.roles
      .map((role: Role) => role.permissions.map((permission) => permission.name))
      .reduce((acc, val) => acc.concat(val), []);
      console.log(requiredPermissions.every((perm: string) => userPermission.includes(perm)));
    return of(requiredPermissions.some((perm: string) => userPermission.includes(perm)));
  } catch (error) {
    toastsService.danger('Error al validar los permisos', 'Error');
    return false;
  }
};
