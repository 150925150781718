import { MaquinariaService } from './../../../@core/services/maquinaria.service';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { TreeNode } from '../../../@core/utils/TreeNodeData.service';
import { Maquinaria } from '../../../@core/data/maquinaria-data';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ngx-tabla-maquinaria',
  templateUrl: './tabla-maquinaria.component.html',
  styleUrls: ['./tabla-maquinaria.component.scss']
})
export class TablaMaquinariaComponent implements OnChanges{
  @Input() maquinaria: Maquinaria[];
  customColumns = ['numero', 'nombre','serie','modelo','ubicacion','observaciones','lts','toneladas','encargado','precio','mantenimiento'];
  columns = [];
  allColumns = [...this.customColumns, ...this.columns,'opciones'];
  editarMaquinariaWindows: NbWindowRef;
  @ViewChild('editarMaquinaria', { read: TemplateRef }) editarMaquinariaTemplate: TemplateRef<HTMLElement>;
  deleteMaquinariaWindows: NbWindowRef;
  @ViewChild('deleteMaquinaria', { read: TemplateRef }) deleteMaquinariaTemplate: TemplateRef<HTMLElement>;
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:false}) sort: MatSort;
  dataSource:MatTableDataSource<Maquinaria>;
  constructor(private windowService:NbWindowService,
    private maquinariaService:MaquinariaService,private toastrService:NbToastrService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maquinaria) {
      this.updateDataSource(changes.maquinaria.currentValue);
    }

  }
  private updateDataSource(changes: Maquinaria[]) {
    this.dataSource = new MatTableDataSource(changes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  onClickOpenEditarMaquinaria(maquinaria: Maquinaria) {
    this.editarMaquinariaWindows = this.windowService.open(
      this.editarMaquinariaTemplate,
      { title: 'Editar Maquinaria', hasBackdrop: true,
        closeOnBackdropClick: false, closeOnEsc: true,
        context: {maquinaria:maquinaria}},
    );
  }

  onMaquinariaEdited() {
    this.maquinariaService.getMaquinarias().subscribe((data) => {
      this.maquinaria = data;
      this.editarMaquinariaWindows.close();
    });
  }
  onClickEliminarMaquinaria(maquina:Maquinaria){
    this.deleteMaquinariaWindows = this.windowService.open(
      this.deleteMaquinariaTemplate,
      { title: 'Eliminar Maquinaria', hasBackdrop: true,
        closeOnBackdropClick: false, closeOnEsc: true,
        context: {maquinaria:maquina}},
    );
  }

  onConfirmDeleteNaquinaria(maquina:Maquinaria){
    this.maquinariaService.delete(maquina.id).subscribe((data) => {
      this.maquinariaService.getMaquinarias().subscribe((data) => {
        this.maquinaria = data;
        this.deleteMaquinariaWindows.close();
      });
    },(error) => {
      this.toastrService.danger(error.error.message,'Ha ocurrido un error.',
        {duration: 10000, status: 'danger', hasIcon: false});
    });
  }
}
