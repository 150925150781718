import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AvanceService } from '../../../@core/services/incidencias-service';
import { Avance, Incidencia } from '../../../@core/data/incidencias-data';
import { NbWindowService, NbWindowRef, NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-bitacora-table',
  templateUrl: './bitacora-table.component.html',
  styleUrls: ['./bitacora-table.component.scss']
})
export class BitacoraTableComponent implements OnInit, OnChanges {
  @Input() incidencia: Incidencia;
  avances: Avance[] = [];
  selectedAvance: Avance;
  avanceForm: FormGroup;
  windowRef: NbWindowRef;
  dialogRef: NbDialogRef<any>;
  displayedColumns: string[] = ['fecha', 'taller', 'contacto', 'observaciones', 'usuario', 'acciones'];
  @ViewChild('avanceTemplate') avanceTemplate: TemplateRef<HTMLElement>;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<HTMLElement>;
  constructor(
    private avanceService: AvanceService,
    private windowService: NbWindowService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private fb: FormBuilder
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.incidencia && changes.incidencia.currentValue) {
      this.loadAvances();
    }
  }

  ngOnInit(): void {
    this.loadAvances();
    this.avanceForm = this.fb.group({
      fecha: ['', Validators.required],
      taller: ['', Validators.required],
      contacto: ['', Validators.required],
      observaciones: ['', Validators.required],
      usuario: ['', Validators.required],
    });
  }

  loadAvances(): void {
    this.avanceService.getAllBy([{incidencia_id:this.incidencia.id}]).subscribe((data: Avance[]) => {
      this.avances = data;
    });
  }

  openNewAvance(): void {
    this.selectedAvance = null;
    this.avanceForm.reset();
    this.windowRef = this.windowService.open(this.avanceTemplate, { title: 'Nuevo Avance' });
  }

  openEditAvance(avance: Avance): void {
    this.selectedAvance = avance;
    this.avanceForm.patchValue(avance);
    this.windowRef = this.windowService.open(this.avanceTemplate, { title: 'Editar Avance' });
  }

  deleteAvance(avance: Avance): void {
    this.avanceService.delete(avance.id).subscribe(() => {
      this.toastrService.show('Avance eliminado correctamente', 'Eliminado', { status: 'success' });
      this.loadAvances();
    }, () => {
      this.toastrService.show('Error al eliminar el avance', 'Error', { status: 'danger' });
    });
  }

  confirmDeleteAvance(avance: Avance): void {
   this.dialogRef = this.dialogService.open(this.confirmDialog);
   this.dialogRef.onClose.subscribe((confirmed: boolean) => {
     if (confirmed) {
       this.deleteAvance(avance);
     }
   });
  }

  onSubmit(): void {
    if (this.avanceForm.invalid) {
      return;
    }
    this.avanceForm.addControl('incidencia_id', this.fb.control(this.incidencia.id));
    const avanceData = this.avanceForm.value;
    if (this.selectedAvance) {
      this.avanceService.update(avanceData, this.selectedAvance.id).subscribe(() => {
        this.toastrService.show('Avance actualizado correctamente', 'Actualizado', { status: 'success' });
        this.loadAvances();
        this.windowRef.close();
      }, () => {
        this.toastrService.show('Error al actualizar el avance', 'Error', { status: 'danger' });
      });
    } else {
      this.avanceService.create(avanceData).subscribe(() => {
        this.toastrService.show('Avance creado correctamente', 'Creado', { status: 'success' });
        this.loadAvances();
        this.windowRef.close();
      }, () => {
        this.toastrService.show('Error al crear el avance', 'Error', { status: 'danger' });
      });
    }
  }
}
