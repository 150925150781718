import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExpedienteUnicoService } from '../../@core/services/expediente-unico.service';
import { ExpedienteUnico } from '../../@core/data/expediente-unico-data';

@Component({
  selector: 'ngx-editar-expediente-form',
  templateUrl: './editar-expediente-form.component.html',
  styleUrls: ['./editar-expediente-form.component.scss']
})
export class EditarExpedienteFormComponent implements OnInit {
  @Input() expediente: ExpedienteUnico;
  @Output() expedienteEdited: EventEmitter<boolean> = new EventEmitter<boolean>();
  editarExpedienteForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private expedienteUnicoService: ExpedienteUnicoService,
  ) {}

  ngOnInit(): void {
    console.log(this.expediente);
    if (this.expediente !== null || this.expediente !== undefined) {
      this.editarExpedienteForm = this.fb.group({
        contrato: [this.expediente.contrato, Validators.required],
        descripcion: [this.expediente.descripcion, Validators.required],
        entrega: [this.expediente.entrega, Validators.required],
        cargo_entrega: [this.expediente.cargo_entrega, Validators.required],
        recibe: [this.expediente.recibe, Validators.required],
        cargo_recibe: [this.expediente.cargo_recibe, Validators.required],
      });
    }
  }

  controls(){
    return this.editarExpedienteForm.controls;
  }

  onEditarExpedienteSubmit() {
    this.expedienteUnicoService.update(this.editarExpedienteForm, this.expediente.id).subscribe(expediente => {
      this.expedienteEdited.emit(true);
    });
  }
}
