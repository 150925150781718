import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Incidencia } from '../../../@core/data/incidencias-data';
import { Vehiculo } from '../../../@core/data/vehiculo-data';
import { VehiculosService } from '../../../@core/services/vehiculos.service';
import { EstatusVehiculo } from '../../../@core/enums/EstatusVehiculo.enum';


@Component({
  selector: 'ngx-siniestro-form',
  templateUrl: './siniestro-form.component.html',
  styleUrls: ['./siniestro-form.component.scss']
})
export class SiniestroFormComponent implements OnInit {
  incidenciaForm: FormGroup;
  @Output() emitForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  vehiculos:Vehiculo[] = [];
  constructor(private fb: FormBuilder, private vehiculosService:VehiculosService) {
    this.vehiculosService.getAll().subscribe((vehiculos) => {
      this.vehiculos = vehiculos;
    });

  }

  ngOnInit(): void {

    this.incidenciaForm = this.fb.group({
      vehiculo_id: ['', Validators.required],
      numero_siniestro: [''],
      fecha: [''],
      tipo: [''],
      color: [''],
      anio: [''],
      marca: [''],
      modelo: [''],
      seguro: [{value: '', disabled: true}],
      serie: [''],
      estatus_documentos: [''],
      placas: [''],
      beneficiarioSeguro: [''],
      responsableSiniestro: [''],
      costo: [0.00],
      total_pago: [0.00],
      tel_ajustador: [''],
      nombre_ajustador: [''],
      beneficiario: [''],
      observaciones: [''],
      estatus: [''],
      tipo_sinestro: [''],
      lugar_incidente: [''],
    });

    this.incidenciaForm.get('vehiculo_id').valueChanges.subscribe((vehiculoId) => {
      const vehiculo = this.vehiculos.find((v) => v.id === vehiculoId);
      if (vehiculo) {
        this.incidenciaForm.get('seguro').setValue(vehiculo.seguro);
      }
    });

    this.incidenciaForm.get('costo').valueChanges.subscribe((value) => {
      if (value) {
        this.formatNumber(value,'costo');
      }
    });

    this.incidenciaForm.get('total_pago').valueChanges.subscribe((value) => {
      if (value) {
        this.formatNumber(value,'total_pago');
      }
    });
  }

  onSubmit(): void {

    if (this.incidenciaForm.valid) {
      this.emitForm.emit(this.incidenciaForm);
    }
  }

  formatNumber(value: string,form:string): void {
    if (value === null || value === undefined || value === '') {
      return;
    }

    // Elimina comas del valor actual
    const unformattedValue = value.toString().replace(/,/g, '');

    // Convierte a número
    const numericValue = parseFloat(unformattedValue);

    if (!isNaN(numericValue)) {
      // Actualiza el control con el valor formateado
      this.incidenciaForm.get(form)
      .setValue(
        numericValue.toLocaleString('en-US', { maximumFractionDigits: 2 }),
        { emitEvent: false } // Evita bucles infinitos
      );
    }
  }
}
