
<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>Clientes <button nbButton  status="success" size="small" nbTooltip="Nuevo Cliente" (click)="onNuevoClienteClick()">
      <nb-icon  icon="plus" ></nb-icon>
    </button></div>
    <div><input nbInput type="text" (keyup)="filter($event.target.value)" placeholder="Buscar cliente"></div>
  </nb-card-header>
  <nb-card-body>
    <ngx-clientes-mat-table [dataSource]="dataSource"></ngx-clientes-mat-table>
  </nb-card-body>
</nb-card>

<ng-template #nuevoClienteFormView let-data>
  <ngx-nuevo-cliente-form class="responsive-div-md" (clienteCreated)="onClienteCreated()"></ngx-nuevo-cliente-form>
</ng-template>
