import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaquinariaPesadaComponent } from './maquinaria-pesada.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NbButtonModule, NbDatepickerModule, NbInputModule, NbCheckboxModule, NbTreeGridModule, NbSelectModule, NbCardModule, NbTabsetModule, NbListModule, NbButtonGroupModule, NbAccordionModule, NbIconModule, NbTooltipModule, NbSpinnerModule } from '@nebular/theme';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { MaquinariaService } from '../../@core/services/maquinaria.service';
import { TractocamionMantenimientosTablaComponent } from './tractocamion-mantenimientos-tabla/tractocamion-mantenimientos-tabla.component';
import { FilterValueDateModule } from '../../forms/filter-value-date/filter-value-date.module';



@NgModule({
  declarations: [
    MaquinariaPesadaComponent,
    TractocamionMantenimientosTablaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NbButtonModule,
    NbDatepickerModule,
    NbInputModule,
    NbCheckboxModule,
    NbTreeGridModule,
    NbSelectModule,
    NbCardModule,
    NbTabsetModule,
    NbListModule,
    NbButtonGroupModule,
    NbAccordionModule,
    NbIconModule,
    NbTooltipModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormBuilderModule,
    MatTableBuilderModule,
    NbSpinnerModule,
    FilterValueDateModule
  ],
  providers:[
    MaquinariaService
  ]
})
export class MaquinariaPesadaModule { }
