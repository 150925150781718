import { Component, TemplateRef, ViewChild } from "@angular/core";
import { Banco, EntradaNoFiscal, Proveedor, TipoEntrada } from "../../@core/data/gastos-data";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import { EntradasService } from "../../@core/services/entradas.service";
import {
  FormFieldConfig,
  FormFieldType,
  FormRowConfig,
  OptionType,
} from "../../form-builder/form-builder.component";
import { ProveedorService } from "../../@core/services/proveedor.service";
import { NbToastrService, NbWindowRef, NbWindowService } from "@nebular/theme";
import { FormControl, FormGroup } from "@angular/forms";
import { BancoService } from "../../@core/services/banco.service";
import { EmpresaFiniquitoService } from "../../@core/services/empresa-finiquito.service";
import { EmpresaFiniquito } from "../../@core/data/empresa-finiquito-data";
import { Cliente } from "../../@core/data/cotizacion-data";
import { ClienteService } from "../../@core/services/cliente.service";
import { CSVGenerator } from "../../@core/utils/csv-generator";
@Component({
  selector: 'ngx-entradas-fiscales',
  templateUrl: './entradas-fiscales.component.html',
  styleUrls: ['./entradas-fiscales.component.scss']
})
export class EntradasFiscalesComponent {
  empresas: EmpresaFiniquito[];
  clientes:  Cliente[] = [];
  bancos: Banco[] = [];
  filterFields:string[]=['cliente_nombre','concepto','importe','empresa_finiquita_nombre','tipo_pago','banco_nombre','cuenta'];
  constructor(private entradasService: EntradasService, private clientesService: ClienteService,
    private windowService: NbWindowService, private toastrService: NbToastrService,
    private bancoService: BancoService, private empresaService: EmpresaFiniquitoService
  ) {
    this.entradasService.getAllByAttributes([{tipo: TipoEntrada.FISCAL}]).subscribe((data) => {
      this.data = data;
      this.filteredData = data;
    }, (error) => {
      this.toastrService.show(error.error.message, "Error", { status: "danger" });
    });
    this.clientesService.getAll().subscribe((data) => {
      this.clientes = data;
    }, (error) => {
      this.toastrService.show(error.error.message, "Error", { status: "danger" });
    });
    this.bancoService.getAll().subscribe((data) => {
      this.bancos = data;
    }, (error) => {
      this.toastrService.show(error.error.message, "Error", { status: "danger" });
    });
    this.empresaService.getAll().subscribe((data) => {
      this.empresas = data;
    }, (error) => {
      this.toastrService.show(error.error.message, "Error", { status: "danger" });
    });
  }

  data: EntradaNoFiscal[] = [];
  filteredData: EntradaNoFiscal[] = [];
  @ViewChild("nuevaEntradaWindow", { read: TemplateRef })
  nuevaEntradaWindow: TemplateRef<HTMLElement>;
  nuevaEntradaWindowRef: NbWindowRef;
  columns: MatTableColumnConfig[] = [
    {
      label: "Fecha",
      name: "fecha_entrada",
      type: "date",
    },
    {
      name: "cliente_nombre",
      label: "Cliente",
      type: "text",
    },
    {
      name: "concepto",
      label: "Concepto",
      type: "text",
    },
    {
      name: "importe",
      label: "Importe",
      type: "number",
    },
    {
      name: "tipo_pago",
      label: "Tipo",
      type: "text",
    },
    {
      name: "empresa_finiquita_nombre",
      label: "Empresa",
      type: "text",
    },
    {
      name: "banco_nombre",
      label: "Banco",
      type: "text",
    },
    {
      name: "cuenta",
      label: "Cuenta",
      type: "text",
    }
  ] as MatTableColumnConfig[];
  acciones: MatTableActionConfig[] = [
    {
      name: "edit",
      icon: "edit",
      label: "Editar",
      action: (form: FormGroup,row: EntradaNoFiscal,ref) => {
        this.entradasService.update(form,row.id).subscribe((data) => {
          ref.close();
          this.entradasService.getAll().subscribe((data) => {
            this.data = data;
            this.filteredData = data;

            this.toastrService.show("Entrada actualizada", "Actualizado", { status: "success" });
          }, (error) => {
            this.toastrService.show(error.error.message, "Error", { status: "danger" });
          });
        }, (error) => {
          this.toastrService.show(error.error.message, "Error", { status: "danger" });
        });
      },
      formConfig: (row: EntradaNoFiscal) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              fecha_entrada: {
                type: FormFieldType.Date,
                formControlName: "fecha_entrada",
                label: "Fecha entrada",
                value: row.fecha_entrada,
                col: 6,
                validations: ["required"],
                placeholder: "Fecha entrada",
                disable: true,
              },
              cliente_id: {
                type: FormFieldType.Select,
                formControlName: "cliente_id",
                label: "Cliente",
                value: row.cliente_id,
                col: 6,
                validations: ["required"],
                placeholder: "Cliente",
                options: [{
                  key: OptionType.SelectOptions,
                  value: this.clientes.map((proveedor) => ({
                    key: proveedor.id,
                    value: proveedor.nombre,
                  })),
                }],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              concepto: {
                type: FormFieldType.Input,
                formControlName: "concepto",
                label: "Concepto",
                value: row.concepto,
                col: 6,
                validations: ["required"],
                placeholder: "Concepto",
              },
              tipo_pago: {
                type: FormFieldType.Select,
                formControlName: "tipo_pago",
                label: "Tipo de pago",
                value: row.tipo_pago,
                col: 6,
                validations: ["required"],
                placeholder: "Tipo de pago",
                options: [
                  {
                    key: OptionType.SelectOptions,
                    value: [
                      { key: "EFECTIVO", value: "Efectivo" },
                      { key: "CHEQUE", value: "Cheque" },
                      { key: "DEPOSITO", value: "Depósito" },
                      { key: "TRANSFERENCIA", value: "Transferencia" },
                    ],
                  },
                ],
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              importe: {
                type: FormFieldType.Input,
                formControlName: "importe",
                label: "Importe",
                value: row.importe,
                col: 6,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              empresa_finiquita_id: {
                type: FormFieldType.Select,
                formControlName: "empresa_finiquita_id",
                label: "Empresa",
                col: 6,
                validations: ["required"],
                placeholder: "Empresa",
                value: row.empresa_id,
                options: [{
                  key: OptionType.SelectOptions,
                  value: this.empresas.map((empresa) => ({
                    key: empresa.id,
                    value: empresa.nombre,
                  })),
                }],
              },
              banco_id: {
                type: FormFieldType.Select,
                formControlName: "banco_id",
                label: "Banco",
                col: 6,
                validations: ["required"],
                placeholder: "Banco",
                value: row.banco_id,
                options: [{
                  key: OptionType.SelectOptions,
                  value: this.bancos.map((banco) => ({
                    key: banco.id,
                    value: banco.nombre,
                  })),
                }],
              },
            },
          }
        ] as FormRowConfig[];
      }
    },
    {
      name: "delete",
      icon: "trash",
      label: "Eliminar",
      message: "¿Estás seguro de que deseas eliminar esta entrada?",
      title: "Eliminar entrada",
      action: (row: EntradaNoFiscal) => {
        this.entradasService.delete(row.id).subscribe((data) => {
          this.entradasService.getAll().subscribe((data) => {
            this.data = data;
            this.filteredData = data;
          });
        }, (error) => {
          this.toastrService.show(error.error.message, "Error", { status: "danger" });
        });
      }
    }
  ];

  downloadCsv() {
    CSVGenerator.generateExcel(this.columns,this.filteredData, "entradas-fiscales");
  }
  onNuevoClick() {
    const config = [
      {
        type: FormFieldType.Row,
        fields: {
          fecha_entrada: {
            type: FormFieldType.Date,
            formControlName: "fecha_entrada",
            label: "Fecha entrada",
            col: 6,
            validations: ["required"],
            placeholder: "Fecha entrada",
            value: new Date(),
           // disable: true,
          },
          cliente_id: {
            type: FormFieldType.Select,
            formControlName: "cliente_id",
            label: "Cliente",
            value: null,
            col: 6,
            validations: ["required"],
            placeholder: "Cliente",
            options: [{
              key: OptionType.SelectOptions,
              value: this.clientes.map((proveedor) => ({
                key: proveedor.id,
                value: proveedor.nombre,
              })),
            }],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          concepto: {
            type: FormFieldType.Input,
            formControlName: "concepto",
            label: "Concepto",
            col: 6,
            validations: ["required"],
            placeholder: "Concepto",
          },
          tipo_pago: {
            type: FormFieldType.Select,
            formControlName: "tipo_pago",
            label: "Tipo de pago",
            col: 6,
            validations: ["required"],
            placeholder: "Tipo de pago",
            options: [
              {
                key: OptionType.SelectOptions,
                value: [
                  { key: "EFECTIVO", value: "Efectivo" },
                  { key: "CHEQUE", value: "Cheque" },
                  { key: "DEPOSITO", value: "Depósito" },
                  { key: "TRANSFERENCIA", value: "Transferencia" },
                ],
              },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          importe: {
            type: FormFieldType.Number,
            formControlName: "importe",
            label: "Importe",
            col: 6,
            validations: ["required"],
            placeholder: "Importe",
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          empresa_id: {
            type: FormFieldType.Select,
            formControlName: "empresa_id",
            label: "Empresa",
            col: 6,
            validations: ["required"],
            placeholder: "Empresa",
            options: [{
              key: OptionType.SelectOptions,
              value: this.empresas.map((empresa) => ({
                key: empresa.id,
                value: empresa.nombre,
              })),
            }],
          },
          banco_id: {
            type: FormFieldType.Select,
            formControlName: "banco_id",
            label: "Banco",
            col: 6,
            validations: ["required"],
            placeholder: "Banco",
            options: [{
              key: OptionType.SelectOptions,
              value: this.bancos.map((banco) => ({
                key: banco.id,
                value: banco.nombre,
              })),
            }],
          },
        },
      }
    ] as FormRowConfig[];
    this.nuevaEntradaWindowRef = this.windowService.open(
      this.nuevaEntradaWindow,
      { title: "Nueva entrada", context : {config : config}}
    );
  }
  onFilterChange($event: any) {
    console.log($event);
    this.filteredData = $event;
  }

  getTotalImportes(): any {
    return this.filteredData.reduce(
      (total, entrada) => total + parseFloat(entrada.importe.toString()),
      0
    );
  }
  onEntradaCreated(form: FormGroup):any {
    form.addControl("tipo", new FormControl(TipoEntrada.FISCAL));
    this.entradasService.create(form,true).subscribe((data) => {
      this.entradasService.getAllByAttributes([{tipo: TipoEntrada.FISCAL}]).subscribe((data) => {
        this.data = data;
        this.filteredData = data;
        this.nuevaEntradaWindowRef.close();
        this.toastrService.show("Entrada creada", "Creada", { status: "success" });
      }, (error) => {
        this.toastrService.show(error.error.message, "Error", { status: "danger" });
      });
    });
  }
}
