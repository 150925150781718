
<form [formGroup]="vehiculoForm" [nbSpinner]="loading"
  nbSpinnerStatus="danger"
  nbSpinnerSize="large"
  nbSpinnerMessage="Cargando"
  (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="anio">Año</label>
        <input
          nbInput
          type="number"
          class="form-control"
          id="anio"
          formControlName="anio"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="placas">Placas</label>
        <input
          nbInput
          type="text"
          class="form-control"
          id="placas"
          formControlName="placas"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="serie">Serie</label>
        <input
          nbInput
          type="text"
          class="form-control"
          id="serie"
          formControlName="serie"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="color">Color</label>
        <input
          nbInput
          type="text"
          class="form-control"
          id="color"
          formControlName="color"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="chofer">Chofer</label>
        <input
          nbInput
          type="text"
          class="form-control"
          id="chofer"
          formControlName="chofer"
          fullWidth
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="ref_pagado">¿Se encuentra pagado el refrendo?</label>
        <nb-radio-group formControlName="referendo_pagado">
          <nb-radio [value]="true">Sí</nb-radio>
          <nb-radio [value]="false">No</nb-radio>
        </nb-radio-group>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="ref_comentario">Comentario de refrendo</label>
        <input
          nbInput
          type="text"
          class="form-control"
          id="ref_comentario"
          formControlName="referendo_comentario"
          fullWidth
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="marca">Marca del vehículo</label>
        <nb-select
          id="marca"
          placeholder="Marca del vehículo"
          formControlName="marca_id"
          [formControl]="selectedMarcaFormControl"
          fullWidth
        >
          <nb-option *ngFor="let marca of marcas" [value]="marca.id">
            {{ marca.nombre }}
          </nb-option>
        </nb-select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="version">Versión del vehículo</label>
        <nb-select
          id="version"
          placeholder="Versión del vehiculo"
          formControlName="version_id"
          [formControl]="selectedVersionFormControl"
          fullWidth
        >
          <nb-option *ngFor="let version of versiones" [value]="version.id">
            {{ version.nombre }}
          </nb-option>
        </nb-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="segVehiculo">Seguro del vehículo</label>
        <nb-select
          id="segVehiculo"
          placeholder="Seguro del vehículo"
          formControlName="aseguradora_id"
          [formControl]="selectedSeguroFormControl"
          fullWidth
        >
          <nb-option *ngFor="let seguro of seguros" [value]="seguro.id">
            {{ seguro.nombre }}
          </nb-option>
        </nb-select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="estado">Estado del vehículo</label>
        <nb-select
          id="estado"
          placeholder="Estado del vehículo"
          formControlName="estado_id"
          [formControl]="selectedEstadoFormControl"
          fullWidth
        >
          <nb-option *ngFor="let estado of estados" [value]="estado.id">
            {{ estado.nombre }}
          </nb-option>
        </nb-select>
      </div>
    </div>
  </div>
  <div *ngIf="selectedSeguroFormControl.value !== null" class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="fechaInicio">Fecha de inicio</label>
        <input
          nbInput
          type="date"
          class="form-control"
          id="fechaInicio"
          formControlName="fecha_inicio_vigencia_seguro"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="fechaFinal">Fecha final</label>
        <input
          nbInput
          type="date"
          class="form-control"
          id="fechaFinal"
          formControlName="fecha_fin_vigencia_seguro"
        />
      </div>
    </div>
  </div>
  <button type="submit" nbButton status="primary">Guardar</button>
</form>
