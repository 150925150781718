import { Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Incidencia } from '../../../@core/data/incidencias-data';
import { Vehiculo } from '../../../@core/data/vehiculo-data';
import { VehiculosService } from '../../../@core/services/vehiculos.service';
import { EstatusVehiculo } from '../../../@core/enums/EstatusVehiculo.enum';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'ngx-siniestro-form',
  templateUrl: './siniestro-form.component.html',
  styleUrls: ['./siniestro-form.component.scss']
})
export class SiniestroFormComponent implements OnInit {
  incidenciaForm: FormGroup;
  @Output() emitForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  vehiculos:Vehiculo[] = [];
  busquedaVehiculo: string = '';
  vehiculosFiltrados: any[] = [];
  loadingVehiculos: boolean = false;

  constructor(private fb: FormBuilder, private vehiculosService:VehiculosService, private dialogService: NbDialogService) {
    this.loadingVehiculos = true;
    this.vehiculosService.getAll().subscribe((vehiculos) => {
      this.vehiculos = vehiculos;
      this.vehiculosFiltrados = [...this.vehiculos];
      this.loadingVehiculos = false;
    });

  }

  ngOnInit(): void {
    this.vehiculosFiltrados = [...this.vehiculos];

    this.incidenciaForm = this.fb.group({
      vehiculo_id: ['', Validators.required],
      vehiculo: [''],
      numero_siniestro: [''],
      fecha: [''],
      tipo: [''],
      color: [''],
      anio: [''],
      marca: [''],
      modelo: [''],
      seguro: [{value: '', disabled: true}],
      serie: [''],
      estatus_documentos: [''],
      placas: [''],
      beneficiarioSeguro: [''],
      responsableSiniestro: [''],
      costo: [0.00],
      total_pago: [0.00],
      tel_ajustador: [''],
      nombre_ajustador: [''],
      beneficiario: [''],
      observaciones: [''],
      estatus: [''],
      tipo_sinestro: [''],
      lugar_incidente: [''],
    });

    this.incidenciaForm.get('vehiculo_id').valueChanges.subscribe((vehiculoId) => {
      const vehiculo = this.vehiculos.find((v) => v.id === vehiculoId);
      if (vehiculo) {
        this.incidenciaForm.get('seguro').setValue(vehiculo.seguro);
      }
    });
  }

  onSubmit(): void {
    console.log(this.incidenciaForm.value);
    if (this.incidenciaForm.valid) {
      console.log('Formulario válido');
      this.removeFormatting('costo');
      this.removeFormatting('total_pago');
      this.emitForm.emit(this.incidenciaForm);
    }
  }

  // Formatea el número al perder el foco
  formatNumber(name:string): void {
    const control = this.incidenciaForm.get(name);
    if (control) {
      const rawValue = control.value;
      if (!isNaN(rawValue) && rawValue !== null && rawValue !== '') {
        const formattedValue = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(parseFloat(rawValue));
        control.setValue(formattedValue, { emitEvent: false });
      }
    }
  }

  // Elimina el formato al enfocar el input
  removeFormatting(name:string): void {
    const control = this.incidenciaForm.get(name);
    if (control) {
      const formattedValue = control.value;
      if (formattedValue) {
        const rawValue = formattedValue.replace(/,/g, '');
        control.setValue(rawValue, { emitEvent: false });
      }
    }
  }


  onSearch(event: any) {
    const query = event.target.value.toLowerCase();
    this.vehiculosFiltrados = this.vehiculos.filter(vehiculo => 
      vehiculo.marca.toLowerCase().includes(query) ||
      vehiculo.placas.toLowerCase().includes(query) ||
      vehiculo.serie.toLowerCase().includes(query) ||
      vehiculo.color.toLowerCase().includes(query)
    );
  }

  onSelectionChange(vehiculoDisplay: string) {
    //Id: 45 Marca: Volkswagen, Placas: CX9468D, Serie: WV1DB12H8B8017207, Color: BLANCO
    const id = vehiculoDisplay.split(' ')[1];
    const vehiculo = this.vehiculos.find(v => v.id === parseInt(id));
    if (vehiculo) {
      this.incidenciaForm.patchValue({
        vehiculo_id: vehiculo.id,
        seguro: vehiculo.seguro,
        vehiculo: `Marca: ${vehiculo.marca}, Placas: ${vehiculo.placas}, Serie: ${vehiculo.serie}, Color: ${vehiculo.color}`
      });
    }
  }

  onDisplayFn(vehiculo: Vehiculo): string {
    if(!vehiculo) return '';
    return `Id: ${vehiculo.id} Marca: ${vehiculo.marca}, Placas: ${vehiculo.placas}, Serie: ${vehiculo.serie}, Color: ${vehiculo.color}`;
  }
}
