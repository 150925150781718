import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Marca } from '../data/marca-data';

@Injectable()
export class MarcaService extends BaseService<Marca> {

protected getPath(): string {
  return 'marcas';
}

}
