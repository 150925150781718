import { Component } from '@angular/core';

@Component({
  selector: 'ngx-auth-component',
  templateUrl: './auth-component.component.html',
  styleUrls: ['./auth-component.component.scss']
})
export class AuthComponentComponent {

}
