import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ClientesMatTableComponent } from './clientes-mat-table/clientes-mat-table.component';
import { MatTableModule } from '@angular/material/table';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbSelectModule, NbTooltipModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ClienteService } from '../../../@core/services/cliente.service';
import { GCMARQFormsModule } from '../../../forms/gcmarqforms.module';



@NgModule({
  declarations: [
    ClientesMatTableComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    NbInputModule,
    NbButtonModule,
    NbSelectModule,
    CurrencyPipe,
    NbTooltipModule,
    NbTooltipModule,
    GCMARQFormsModule
  ],
  exports: [
    ClientesMatTableComponent
  ],
  providers: [
    ClienteService
  ]
})
export class TablasClientesModule { }
