import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConfigCrudDef } from '../configuraciones/configuraciones.component';
import { FormGroup } from '@angular/forms';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { IncidenciasService } from '../../@core/services/incidencias-service';
import { Incidencia } from '../../@core/data/incidencias-data';

@Component({
  selector: 'ngx-siniestros',
  templateUrl: './siniestros.component.html',
  styleUrls: ['./siniestros.component.scss']
})
export class SiniestrosComponent implements OnInit{
  @ViewChild('contentTemplate') contentTemplate: TemplateRef<HTMLElement>;
  @ViewChild('newTemplate') newTemplate: TemplateRef<HTMLElement>;
  @ViewChild('editTemplate') editTemplate: TemplateRef<HTMLElement>;

  editFormRef: NbWindowRef;
  formRef: NbWindowRef;
  siniestros:ConfigCrudDef = {
    data: [],
    tbDef: [
      // Información principal del siniestro
      {
        name: 'id',
        label: 'ID',
      },
      {
        name: 'numero_siniestro',
        label: 'Numero de Siniestro',
      },
      {
        name: 'fecha',
        label: 'Fecha',
      },
      {
        name: 'tipo_sinestro',
        label: 'Tipo de Siniestro',
      },
      {
        name: 'estatus',
        label: 'Estatus',
      },

      // Información del vehículo
      {
        name: 'placas',
        label: 'Placas',
      },
      {
        name: 'serie',
        label: 'Serie',
      },
      // Información del seguro
      {
        name: 'seguro',
        label: 'Seguro',
      },
      {
        name: 'total_pago',
        label: 'Total de Pago',
      },
      {
        name: 'estatus_documentos',
        label: 'Estatus de Documentos',
      },
    ],
    tbActions: [
      {
        name:'view',
        label: 'Ver detalles',
        icon: 'eye-outline',
        color: 'info',
        action: (row:Incidencia) => {
          this.windowService.open(
            this.contentTemplate,
            { title: 'Incidencia '+ row.id, context: { incidencia:row } },
          );
        }
      },
      {
        name:'editar',
        label: 'Editar',
        icon: 'edit-2-outline',
        color: 'success',
        action: (row:Incidencia) => {
          console.log(row);
          this.openEditIncidentForm(row);
        }
      },
      {
        name:'delete',
        message: '¿Estás seguro de eliminar la incidencia?',
        label: 'Eliminar',
        icon: 'trash-2-outline',
        color: 'danger',
        action: (row) => {
          this.incidenciasService.delete(row.id).subscribe((res) => {
            this.siniestros.data = this.siniestros.data.filter((d) => d.id !== row.id);
            this.toastrService.show('Incidencia eliminada correctamente', 'Incidencia Eliminada', {
              status: 'success',
              duration: 5000,
              destroyByClick: true,
              preventDuplicates: true,
            });
          },(err) => {
            this.toastrService.show('Error al eliminar la incidencia', 'Error', {
              status: 'danger',
              duration: 5000,
              destroyByClick: true,
              preventDuplicates: true,
            });
          });
        }
      }
    ],
    newForm: {
      formDef: [],
      onSubmit: (form:FormGroup) => {

      }
    },
    loadData: async () => {
      this.incidenciasService.getAll().subscribe((res) => {
        this.siniestros.data = res;
      });

    }
  };

  constructor(private windowService:NbWindowService,private incidenciasService:IncidenciasService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit(): void {
    this.siniestros.loadData();
  }
  openNewIncidentForm() {
    this.formRef=this.windowService.open(
      this.newTemplate,
      { title: 'Nueva Incidencia' },
    );
  }
  openEditIncidentForm(incidencia:Incidencia) {
    this.editFormRef=this.windowService.open(
      this.editTemplate,
      { title: 'Editar incidencia',context:{incidencia:incidencia} },
    );
  }

  private cleanNumericValues(form: FormGroup): FormGroup {
    const formValue = {...form.value};
    if (formValue.costo) {
      formValue.costo = Number(formValue.costo.toString().replace(/,/g, ''));
    }
    if (formValue.total_pago) {
      formValue.total_pago = Number(formValue.total_pago.toString().replace(/,/g, ''));
    }
    return formValue;
  }

  onSubmit(form: FormGroup) {
   

    this.incidenciasService.create(form).subscribe((res) => {
      this.siniestros.data.push(res);
      this.toastrService.show('Incidencia creada correctamente', 'Incidencia Creada', {
        status: 'success',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
      this.formRef.close();
    }, (err) => {
      this.toastrService.show('Error al crear la incidencia', 'Error', {
        status: 'danger',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    });
  }

  onSaved(form:FormGroup,id:number){
    const cleanedForm = this.cleanNumericValues(form);
    this.incidenciasService.update(cleanedForm,id).subscribe((res) => {
      this.siniestros.data = this.siniestros.data.map((d) => {
        if (d.id === res.id) {
          return res;
        }
        return d;
      });
      this.editFormRef.close();
      this.toastrService.show('Incidencia actualizada correctamente', 'Incidencia Actualizada', {
        status: 'success',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    },(err) => {
      this.toastrService.show('Error al actualizar la incidencia', 'Error', {
        status: 'danger',
        duration: 5000,
        destroyByClick: true,
        preventDuplicates: true,
      });
    });
  }
}
