import { NbMenuItem } from '@nebular/theme';
import { User } from '../@core/data/usuarios-data';
import { AuthGuard } from '../@core/guards/auth-guard.guard';
import { UserService } from '../@core/services/users.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService{
  user: User;

  constructor(private userService: UserService) {

  }
  async getMenu(): Promise<NbMenuItem[]> {
    const user = await this.userService.getLoggedInUser().toPromise();
    this.user = user;
    let permissionNames = user ? user.roles?.reduce((acc, role) => {
      return acc.concat(role.permissions.map(permission => permission.name));
    }, []) : [];

    let menu: NbMenuItem[] = [
      {
        title: 'Dashboard',
        icon: 'pie-chart-outline',
        link: '/pages/dashboard',
      },
      {
        title: 'Vehículos',
        icon: 'car-outline',
        link: '/pages/vehiculos',
        hidden: !permissionNames.includes('vehiculos')
      },
      {
        title: 'Maquinaria pesada',
        icon: 'settings-outline',
        link: '/pages/maquinaria-pesada',
        hidden: !permissionNames.includes('maquinaria_pesada')
      },
      {
        title: 'Tractocamiones',
        icon: 'activity-outline',
        link: '/pages/tractocamiones',
        hidden: !permissionNames.includes('tractocamiones')
      },
      {
        title: 'Expedientes unicos',
        icon: 'folder-outline',
        link: '/pages/expedientes-unicos',
        hidden: !permissionNames.includes('expedientes_unicos')
      },
      {
        title: 'Renta de maquinarias',
        icon: 'shopping-cart-outline',
        hidden: !permissionNames.includes('cotizaciones'),        
        children: [
          {
            title: 'Cotizaciones',
            icon: 'shopping-cart-outline',
            link: '/pages/cotizaciones',
            hidden: !permissionNames.includes('cotizaciones')
          },
          {
            title: 'Fletes',
            icon: 'credit-card-outline',
            link: '/pages/entradas/no-fiscales',
            hidden: !permissionNames.includes('entradas_no_fiscales')
          },
          {
            title: 'Equipos en renta',
            icon: 'credit-card-outline',
            link: '/pages/cortes/no-fiscal',
            hidden: !permissionNames.includes('corte_no_fiscal')
          },
          {
            title: 'Control en renta',
            icon: 'credit-card-outline',
            link: '/pages/cortes/no-fiscal',
            hidden: !permissionNames.includes('corte_no_fiscal')
          }
        ]
      },
      {
        title: 'Clientes',
        icon: 'person-outline',
        link: '/pages/clientes',
        hidden: !permissionNames.includes('clientes')
      },
      {
        title: 'Empleados',
        icon: 'person-outline',
        link: '/pages/empleados',
        hidden: !permissionNames.includes('empleados')
      },
      {
        title: 'Entrevistas',
        icon: 'message-square-outline',
        link: '/pages/entrevistas',
        hidden: !permissionNames.includes('entrevistas')
      },
      {
        title: 'Contratos',
        icon: 'file-text-outline',
        link: '/pages/contratos-rh',
        hidden: !permissionNames.includes('contratos_rh')
      },
      {
        title: 'Licencias',
        icon: 'award-outline',
        link: '/pages/licencias',
        hidden: !permissionNames.includes('licencias')
      },
      {
        title: 'Finiquitos',
        icon: 'clipboard-outline',
        link: '/pages/finiquitos',
        hidden: !permissionNames.includes('finiquitos')
      },
      {
        title: 'Equipos',
        icon: 'hard-drive-outline',
        link: '/pages/equipos',
        hidden: !permissionNames.includes('equipos')
      },
      {
        title: 'Asegurados',
        icon: 'shield-outline',
        link: '/pages/asegurados',
        hidden: !permissionNames.includes('asegurados')
      },
      {
        title: 'Nomina semanal',
        icon: 'credit-card-outline',
        link: '/pages/nominas/semanal',
        hidden: !permissionNames.includes('nominas_semanales')&& !permissionNames.includes('nominas_obras')
      },
      {
        title: 'Gastos no fiscales',
        icon: 'credit-card-outline',
        hidden: !permissionNames.includes('gastos_no_fiscales') && !permissionNames.includes('entradas_no_fiscales') && !permissionNames.includes('corte_no_fiscal'),
        children: [
          {
            title: 'Gastos',
            icon: 'credit-card-outline',
            link: '/pages/gastos/no-fiscales',
            hidden: !permissionNames.includes('gastos_no_fiscales')
          },
          {
            title: 'Ingresos',
            icon: 'credit-card-outline',
            link: '/pages/entradas/no-fiscales',
            hidden: !permissionNames.includes('entradas_no_fiscales')
          },
          {
            title: 'Cortes',
            icon: 'credit-card-outline',
            link: '/pages/cortes/no-fiscal',
            hidden: !permissionNames.includes('corte_no_fiscal')
          }
        ]
      },
      {
        title: 'Gastos fiscales',
        icon: 'credit-card-outline',
        hidden: !permissionNames.includes('gastos_fiscales') && !permissionNames.includes('entradas_fiscales') && !permissionNames.includes('cortes_fiscales'),
        children: [
          {
            title: 'Gastos',
            icon: 'credit-card-outline',
            link: '/pages/gastos/fiscales',
            hidden: !permissionNames.includes('gastos_fiscales')
          },
          {
            title: 'Ingresos',
            icon: 'credit-card-outline',
            link: '/pages/entradas/fiscales',
            hidden: !permissionNames.includes('entradas_fiscales')
          },
          {
            title: 'Corte',
            icon: 'credit-card-outline',
            link: '/pages/cortes/fiscal',
            hidden: !permissionNames.includes('cortes_fiscales')
          }
        ]
      },
      {
        title: 'Sistema',
        icon: 'people-outline',
        expanded: false,
        hidden: !permissionNames.includes('usuarios') && !permissionNames.includes('roles'),
        children: [
          {
            title: 'Usuarios',
            icon: 'people-outline',
            link: '/pages/sistema/usuarios',
            hidden: !permissionNames.includes('usuarios')
          },
          {
            title: 'Roles',
            icon: 'people-outline',
            link: '/pages/sistema/roles',
            hidden: !permissionNames.includes('roles')
          },
        ]
      },
      {
        title:'Siniestros',
        icon: 'alert-triangle-outline',
        link: '/pages/siniestros',
      },
      {
        title: 'Configuraciones',
        icon: 'settings-2-outline',
        link: '/pages/configuraciones',
      }
    ];
    return menu;
  }
}
