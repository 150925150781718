import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenciasComponent } from './licencias.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { FormsModule } from '@angular/forms';
import { LicenciasService } from '../../@core/services/licencias.service';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { FormBuilderModule } from '../../form-builder/form-builder.module';



@NgModule({
  declarations: [
    LicenciasComponent,
  ],
  imports: [
    CommonModule,
    NbCardModule,
    MatTableBuilderModule,
    FormBuilderModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule
  ],
  providers:[
    LicenciasService,
    EmpleadoService
  ]
})
export class LicenciasModule { }
