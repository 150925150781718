import { Injectable } from '@angular/core';
import { Mantenimiento } from '../data/maquinaria-data';
import { BaseService } from './base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MantenimientosService extends BaseService<Mantenimiento> {
  downloadEvidencia(id: number) {
    return this.http.get(`${this.baseUrl}${this.getPath()}/${id}?download=true`, {
      responseType: 'blob'
    });
  }
  
  uploadEvidencia(idMantenimiento,evidenciaFile): Observable<void> {
    const formData = new FormData();
    formData.append('evidencia', evidenciaFile);
    return this.http.post<void>(`${this.baseUrl}${this.getPath()}/${idMantenimiento}/upload-evidencia`,formData);
  }
  protected getPath(): string {
    return 'mantenimientos';
  }

  public getByIdMaquinaria(id: number): Observable<Mantenimiento[]> {
    return this.http.get<Mantenimiento[]>(`${this.baseUrl}${this.getPath()}?maquinaria_id=${id}`);
  }

  public getByIdVehiculo(id: number): Observable<Mantenimiento[]> {
    return this.http.get<Mantenimiento[]>(`${this.baseUrl}${this.getPath()}?vehiculo_id=${id}`);
  }
}
