<button mat-raised-button color="primary" (click)="openNewAvance()">Nuevo Avance</button>

<table mat-table [dataSource]="avances" class="mat-elevation-z8">

  <!-- Fecha Column -->
  <ng-container matColumnDef="fecha">
    <th mat-header-cell *matHeaderCellDef> Fecha </th>
    <td mat-cell *matCellDef="let avance"> {{avance.fecha}} </td>
  </ng-container>

  <!-- Taller Column -->
  <ng-container matColumnDef="taller">
    <th mat-header-cell *matHeaderCellDef> Taller </th>
    <td mat-cell *matCellDef="let avance"> {{avance.taller}} </td>
  </ng-container>

  <!-- Contacto Column -->
  <ng-container matColumnDef="contacto">
    <th mat-header-cell *matHeaderCellDef> Contacto </th>
    <td mat-cell *matCellDef="let avance"> {{avance.contacto}} </td>
  </ng-container>

  <!-- Observaciones Column -->
  <ng-container matColumnDef="observaciones">
    <th mat-header-cell *matHeaderCellDef> Observaciones </th>
    <td mat-cell *matCellDef="let avance"> {{avance.observaciones}} </td>
  </ng-container>

  <!-- Usuario Column -->
  <ng-container matColumnDef="usuario">
    <th mat-header-cell *matHeaderCellDef> Usuario </th>
    <td mat-cell *matCellDef="let avance"> {{avance.usuario}} </td>
  </ng-container>

  <!-- Acciones Column -->
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef> Acciones </th>
    <td mat-cell *matCellDef="let avance">
      <button mat-icon-button color="accent" (click)="openEditAvance(avance)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="confirmDeleteAvance(avance)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<ng-template #avanceTemplate>
  <form [formGroup]="avanceForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="fecha">Fecha</label>
      <input nbInput fullWidth id="fecha" formControlName="fecha" type="date" />
    </div>
    <div class="form-group">
      <label for="taller">Taller</label>
      <input nbInput fullWidth id="taller" formControlName="taller" />
    </div>
    <div class="form-group">
      <label for="contacto">Contacto</label>
      <input nbInput fullWidth id="contacto" formControlName="contacto" />
    </div>
    <div class="form-group">
      <label for="observaciones">Observaciones</label>
      <input nbInput fullWidth id="observaciones" formControlName="observaciones" />
    </div>
    <button nbButton status="success" type="submit">Guardar</button>
  </form>
</ng-template>

<ng-template #confirmDialog let-data>
  <nb-card>
    <nb-card-header>Confirmación</nb-card-header>
    <nb-card-body>
      ¿Estás seguro de que deseas eliminar este avance?
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="danger" (click)="dialogRef.close(true)">Eliminar</button>
      <button nbButton status="basic" (click)="dialogRef.close(false)">Cancelar</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
