import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Corte } from '../data/gastos-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CortesService extends BaseService<Corte>{
  protected getPath(): string {
    return 'cortes'; 
  }

  public getBy(attributes: { [key: string]: any }[]): Observable<Corte> {
    const url = `${this.baseUrl}${this.getPath()}?${attributes.map(attr => Object.keys(attr).map(key => `${key}=${attr[key]}`).join('&')).join('&')}`;
    return this.http.get<Corte>(url);
  }
  
}
