import {Component, OnDestroy} from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators' ;
import { DashboardService } from '../../@core/services/dashboard.service';


interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: 'ngx-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent{
  dashboardData: any;
  vehiculosData: {
    chartBar: {
      xAxisLabel: 'Vehiculos',
      yAxisLabel: 'total',
      result: any[]
    },
  };

  cards = [
    {
      title: 'Vehiculos',
      value: 0,
      icon: 'car-outline',
      type: 'primary',
    },
    {
      title: 'Maquinaria',
      value: 0,
      icon: 'car-outline',
      type: 'success',
    },
    {
      title: 'Tractocamiones',
      value: 0,
      icon: 'car-outline',
      type: 'danger',
    },
    {
      title: 'Empleados',
      value: 0,
      icon: 'people-outline',
      type: 'info',
    },
    {
      title: 'Obras',
      value: 0,
      icon: 'settings-outline', // Changed to a valid icon
      type: 'warning',
    }
  ];

  constructor(private dashboardService: DashboardService) {
    this.dashboardService.getAll().subscribe((data) => {
      this.dashboardData = data;
      this.cards[0].value = this.dashboardData.vehiculos.total;
      this.cards[1].value = this.dashboardData.maquinaria.total;
      this.cards[2].value = this.dashboardData.tractocamiones.total;
      this.cards[3].value = this.dashboardData.empleados.total;
      this.cards[4].value = this.dashboardData.obras.total;
    });
  }

}
