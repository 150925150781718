<form (ngSubmit)="onSubmit()" [formGroup]="contratoForm" #form="ngForm">
  <div class="row">
    <div class="col-md-12">
      Folio de la cotización: {{cotizacion.id}}
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      Cliente: {{cotizacion.cliente}}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      Total: {{cotizacion.total}}
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="nombreEmpleado">Nombre del empleado representante</label>
        <nb-select fullWidth id="empleado" name="empleado" [formControl]="empleadoRepSelected"  placeholder="Selecciona un empleado">
          <nb-option *ngFor="let empleado of empleados" [value]="empleado.id">{{empleado.nombre}} {{empleado.apellido_paterno}} {{empleado.apellido_materno}}</nb-option>
        </nb-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="tiempo_contrato">Tiempo de duración del contrato (Meses)</label>
        <input nbInput fullWidth type="number" id="tiempo_contrato" name="tiempo_contrato" (change)="ontiempoContratoChange($event)" formControlName="tiempo_contrato" required>
      </div>
      </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="fecha_inicial">Fecha de inicio del contrato</label>
        <input nbInput fullWidth type="date" id="fecha_inicial" name="fecha_inicial" (change)="onFechaCambio($event)" formControlName="fecha_inicial" required>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="fecha_final">Fecha de fin del contrato</label>
        <input nbInput fullWidth type="date" id="fecha_final" name="fecha_final" formControlName="fecha_final" required>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="lugar_uso_maq">Lugar de uso de la maquinaria</label>
        <input nbInput fullWidth type="text" id="lugar_uso_maq" name="lugar_uso_maq" formControlName="lugar_uso_maq" required>
      </div>
    </div>
  </div>
  <button nbButton type="submit">Generar</button>
</form>
