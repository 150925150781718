<form [formGroup]="nuevaCotizacionForm" (ngSubmit)="onNuevaCotizacionSubmit()">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="cliente">Cliente</label>
          <nb-select fullWidth id="cliente" formControlName="cliente_id" placeholder="Selecciona un cliente">
            <nb-option *ngFor="let cliente of clientes" [value]="cliente.id">{{cliente.nombre}}</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="empleado">Empleado que atendio</label>
          <nb-select fullWidth id="empleado" formControlName="empleado_id" placeholder="Selecciona un empleado">
            <nb-option *ngFor="let empleado of empleados" [value]="empleado.id">{{empleado.nombre}}</nb-option>
          </nb-select>
        </div>
      </div>
    </div>
  </form>
  <ngx-cotizacion-maquinarias-table [cotizaciones]="maquinaria"></ngx-cotizacion-maquinarias-table>
  <button  nbButton nbTooltip="Agregar maquinaria" size="small" status="primary" (click)="addRow()">
  <nb-icon icon="plus-outline"></nb-icon> Agrega maquinaria
</button>
<button nbButton nbTooltip="Agregar maquinaria" size="small" status="primary" (click)="onNuevaCotizacionSubmit()">
  <nb-icon icon="plus-outline"></nb-icon> Guardar cotizacion
</button>


