<nb-card>
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <h5>Siniestros</h5>
    <div>

      <button nbButton status="primary" (click)="openNewIncidentForm()">
        <nb-icon icon="plus-outline"></nb-icon>
        Nuevo Siniestro
      </button>
    </div>
  </nb-card-header>

  <nb-card-body>
   <ngx-mat-table-builder
      [columnsDef]="siniestros.tbDef"
      [data]="siniestros.data"
      [actions]="siniestros.tbActions"
    ></ngx-mat-table-builder>
  </nb-card-body>
</nb-card>

<ng-template class="modal" #contentTemplate let-data>
  <div class="responsive-div-md">
    <ngx-detalle-incidencia class="modal-body" [incidencia]="data.incidencia"></ngx-detalle-incidencia>
  </div>
</ng-template>

<ng-template class="modal" #newTemplate let-data>
  <div class="responsive-div-lg">
    <ngx-siniestro-form class="modal-body" (emitForm)="onSubmit($event)"></ngx-siniestro-form>
  </div>
</ng-template>

<ng-template class="modal" #editTemplate let-data>
  <div class="responsive-div-lg">
    <ngx-edit-incidencia-form class="modal-body" (emitForm)="onSaved($event,data.incidencia.id)" [incidencia]="data.incidencia"></ngx-edit-incidencia-form>
  </div>
</ng-template>
