import { Inject, Injectable } from "@angular/core";
import { BaseService } from "./base-service";
import { Avance, Carpeta, Documento, Incidencia } from "../data/incidencias-data";
import { Observable } from "rxjs";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class IncidenciasService extends BaseService<Incidencia> {
  getPath(): string {
    return 'incidencias';
  }

}

@Injectable({
  providedIn: 'root'
})
export class AvanceService extends BaseService<Avance> {
  getPath(): string {
    return 'avances';
  }

}

@Injectable({
  providedIn: 'root'
})
export class CarpetaService extends BaseService<Carpeta> {
  
  getPath(): string {
    
    return 'bitacora-carpetas';
  }

  addDocument(id: any, file: File,nombre:string):Observable<Documento>  {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('nombre',nombre);
    formData.append('carpeta_id',id);
    return this.http.post<Documento>(`${this.baseUrl}${this.getPath()}/${id}/documentos`, formData);
  }

  updateDocument(form: FormGroup,file:File,idDoc:number) : Observable<Documento> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.put<Documento>(`${this.baseUrl}documentos/${idDoc}/update`, formData)
  }
  
  downloadDoc(id: number) {
    return this.http.get(`${this.baseUrl}documentos/${id}/download`,{responseType: 'blob'});
  }

}
