<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Asegurados
      <button nbButton status="success" nbTooltip="Agregar asegurado"
            [nbSpinner]="loading"
            [disabled]="loading"
              (click)="abrirFormularioNuevoEmpleadoSeguro()" class="btn btn-success btn-sm p-lg-1">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
    </div>
    <div>
      <input nbInput class="search-input" placeholder="Nombre, póliza, tipo"
      [(ngModel)]="textoFiltro" (input)="filtrarEmpleadoSeguros()">
    </div>
  </nb-card-header>
  <nb-card-body>
    <ngx-mat-table-builder [data]="dataFiltrada" [columnsDef]="columnasDef" [actions]="acciones"></ngx-mat-table-builder>
  </nb-card-body>
</nb-card>

<ng-template let-data #nuevoAsegurado>
  <div class="responsive-div-md">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="guardarNuevoEmpleadoSeguro($event)"></ngx-form-builder>
  </div>
</ng-template>
