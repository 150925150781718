import { CotizacionMaquinariaService } from '../../@core/services/cotizacion-maquinaria.service';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Cliente, Cotizacion, CotizacionMaquinaria, Empleado } from '../../@core/data/cotizacion-data';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CotizacionService } from '../../@core/services/cotizacion.service';
import { Maquinaria } from '../../@core/data/maquinaria-data';
import { ClienteService } from '../../@core/services/cliente.service';

@Component({
  selector: 'ngx-nueva-cotizacion-form',
  templateUrl: './nueva-cotizacion-form.component.html',
  styleUrls: ['./nueva-cotizacion-form.component.scss']
})
export class NuevaCotizacionFormComponent implements OnInit, OnChanges{

nuevaCotizacionForm: FormGroup;
maquinaria:CotizacionMaquinaria[]=[];

clienteSelected: Cliente|null;
empleadoSelected: Empleado|null;
clientes: Cliente[];
empleados: Empleado[];
@Input() cotizacion: Cotizacion;
@Output() cotizacionSaved:EventEmitter<boolean> = new EventEmitter<boolean>();
selectedEmpleadoFormControl = new FormControl(null);
selectedClienteFormControl = new FormControl(null);
empleadoLoading = true;
clienteLoading = true;
ngOnChanges(changes: SimpleChanges): void {
  if (changes.cotizacion) {
    this.cotizacion = changes.cotizacion.currentValue;
    console.log(this.cotizacion);
  }
}

constructor(private fb: FormBuilder, private cotizacionService: CotizacionService,
  private EmpleadoService: EmpleadoService, private clienteService:ClienteService,
  private cotizacionMaquinariaService: CotizacionMaquinariaService
) {
  }
  ngOnInit(): void {
    this.nuevaCotizacionForm = this.fb.group({
      cliente_id: [this.selectedClienteFormControl,Validators.required],
      empleado_id: [this.selectedEmpleadoFormControl,Validators.required],
      cotizacionMaquinarias: [[]]
    })
    this.clienteService.getAll().subscribe(clientes => {
      this.clientes = clientes;
      this.clienteLoading = false;
    });

    this.EmpleadoService.getAll().subscribe(empleados => {
      this.empleados = empleados;
      this.empleadoLoading = false;
    });

    if(this.cotizacion!==undefined&&this.cotizacion!==null){
      let cotizacionMaquinariasAsignadas =[]
      this.cotizacionMaquinariaService.getMaquinariasByCotizacionId(this.cotizacion.id).subscribe(cotizacionMaquinarias => {
        cotizacionMaquinariasAsignadas = cotizacionMaquinarias;
      });
      this.nuevaCotizacionForm.patchValue({
        cliente_id:this.cotizacion.cliente_id,
        empleado_id:this.cotizacion.empleado_id,
        cotizacionMaquinarias:cotizacionMaquinariasAsignadas
      });
    }
  }

  addRow() {
    this.maquinaria=[...this.maquinaria,{
      id:0,
      cotizacion_id:0,
      maquinaria_nombre:'',
      maquinaria_id:0,
      cantidad_horas:0,
      precio_renta_maquinaria:0,
      subtotal:0,
      maquinaria_numero:''
    }];
  }

  onNuevaCotizacionSubmit() {
    if (this.nuevaCotizacionForm.valid) {
      this.nuevaCotizacionForm.controls['cotizacionMaquinarias'].setValue(this.maquinaria);
      if(this.cotizacion!==undefined&&this.cotizacion!==null){
        this.cotizacionService.update(this.nuevaCotizacionForm,this.cotizacion.id).subscribe(()=>{
          this.cotizacionSaved.emit(true);
        });
      }else{
        this.cotizacionService.create(this.nuevaCotizacionForm).subscribe(cotizacion => {
          this.cotizacion = cotizacion;
          this.cotizacionSaved.emit(true);
        });
      }
    }
  }
}
