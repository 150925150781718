import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {Permission, Role, User} from '../data/usuarios-data';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base-service';

@Injectable({providedIn: 'root'})
export class UserService extends BaseService<User> {
  uploadPhoto(value: any, id: number) {
    const formData = new FormData();
    formData.append('photo', value);
    return this.http.post(this.baseUrl + this.getPath() + '/' + id + '/upload-photo', formData);
  }
  protected getPath(): string {
    return 'users';
  }

  getLoggedInUser(): Observable<User> {
    return this.http.get<User>(this.baseUrl+'auth/user');
  }
}

@Injectable(  {providedIn: 'root'})
export class RoleService extends BaseService<Role> {
  protected getPath(): string {
    return 'roles';
  }
}

@Injectable(  {providedIn: 'root'})
export class PermisionsService extends BaseService<Permission> {
  protected getPath(): string {
    return 'permissions';
  }
}
