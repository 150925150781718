<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Equipos
      <button nbButton status="success" nbTooltip="Agregar equipo"
            [nbSpinner]="loadin"
            [disabled]="loadin"
              (click)="onClickOpenNuevoEquipoForm()" class="btn btn-success btn-sm p-lg-1">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
    </div>
    <div>
      <input nbInput class="search-input" placeholder="Contrato, cargo, nombre"
      [(ngModel)]="searchText" (input)="onEquipoSearch()">
    </div>
  </nb-card-header>
  <nb-card-body>
    <ngx-mat-table-builder [data]="dataFiltered" [columnsDef]="columnsDef" [actions]="actions"></ngx-mat-table-builder>
  </nb-card-body>
</nb-card>

<ng-template  let-data #nuevoEquipo>
  <div class="responsive-div">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onSavedEquipo($event)"></ngx-form-builder>
  </div>
</ng-template>
