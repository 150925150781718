import { Component, Input, ViewChild } from '@angular/core';
import { CotizacionMaquinaria } from '../../@core/data/cotizacion-data';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'ngx-cotizacion-maquinarias-form',
  templateUrl: './cotizacion-maquinarias-form.component.html',
  styleUrls: ['./cotizacion-maquinarias-form.component.scss']
})
export class CotizacionMaquinariasFormComponent {
  @Input() data:CotizacionMaquinaria[];
  datasource: MatTableDataSource<CotizacionMaquinaria>;
  @ViewChild(MatSort) paginator: MatSort;
  defaultColums: string[] = ['maquinaria_nombre', 'precio_renta_maquinaria', 'duracion_horas'];
  customColumns: string[] = [ 'cantidad_horas','acciones'];
  allColumns: string[] = [...this.defaultColums, ...this.customColumns];
}
