<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>Contratos <button nbButton size="small" (click)="onNuevoClick()">
      <nb-icon  nbTooltip="Nuevo contrato" icon="plus"></nb-icon>
    </button></div>
    <div><input nbInput type="text" (keyup)="filter($event.target.value)" placeholder="Buscar contrato"></div>
  </nb-card-header>
  <nb-card-body>
    <ngx-mat-table-builder [columnsDef]="columns" [data]="filteredData" [actions]="acciones"></ngx-mat-table-builder>
  </nb-card-body>
</nb-card>

<ng-template #newWindow let-data>
  <div class="responsive-div">
    <ngx-form-builder [config]="formConfig" (formOuput)="onContratoCreated($event)"></ngx-form-builder>
  </div>
</ng-template>

<ng-template #editWindow let-data>
  <div class="responsive-div-md">
    <ngx-manage-documents [contrato]="data.contrato"></ngx-manage-documents>
  </div>
</ng-template>
