<div class="d-flex justify-content-between">
  <div style="margin-right: 5px;">
    <nb-form-field>
      <input nbInput type="text" [value]="filterTxt" placeholder="Buscar" (input)="filter($event.target.value)" />
      <button nbSuffix nbButton ghost (click)="clearFilter()">
        <nb-icon icon="close"></nb-icon>
      </button>
    </nb-form-field>
  </div>
  <div>
    <nb-form-field>
      <input nbInput placeholder="Filtrar por fecha" [nbDatepicker]="formpicker" [(ngModel)]="timeRange">
      <button nbSuffix nbButton ghost (click)="clearDateFilter()">
        <nb-icon icon="close"></nb-icon>
      </button>
      <nb-rangepicker #formpicker (rangeChange)="filterDate($event)"></nb-rangepicker>
    </nb-form-field>
  </div>
</div>
