import { NgModule } from '@angular/core';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbTabsetModule,
  NbUserModule,
  NbRadioModule,
  NbSelectModule,
  NbListModule,
  NbIconModule,
} from '@nebular/theme';
import { NgxEchartsModule } from 'ngx-echarts';

import { ThemeModule } from '../../@theme/theme.module';
import { DashboardComponent } from './dashboard.component';

import { FormsModule } from '@angular/forms';
import { DashboardService } from '../../@core/services/dashboard.service';
import { BarChartModule } from '@swimlane/ngx-charts';
import { StatusCardComponent } from './status-card/status-card.component';

@NgModule({
  imports: [
    FormsModule,
    ThemeModule,
    NbCardModule,

    NbButtonModule,
    NbTabsetModule,
    NbActionsModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbButtonModule,
    BarChartModule,
  ],
  declarations: [
    DashboardComponent,
    StatusCardComponent,
  ],
  providers:[
    DashboardService
  ]
})
export class DashboardModule { }
