import { AuthService } from './../../@core/services/auth.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthSimpleToken, NbAuthToken, NbLoginComponent, NbTokenService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent{
  username: string;
  password: string;
  form: FormGroup;
  loginError: boolean = false;

  constructor(private authService: AuthService, private tokenService: NbTokenService,
    private toastrService: NbToastrService,private router: Router,) {
    this.form = new FormGroup({
      username: new FormControl(''),
      password: new FormControl(''),
    });
   }

  submit() {

    this.authService.login(this.form.value.username, this.form.value.password).subscribe(response => {
      const token: NbAuthSimpleToken = new NbAuthSimpleToken(response.token, 'email', new Date());
      this.tokenService.set(token);
      localStorage.setItem('userLogged', JSON.stringify(response.user));
      localStorage.setItem('token', response.token);
      this.toastrService.success('Logueado correctamente', 'Success');
      this.router.navigate(['/pages/dashboard']);

    }, error => {
     this.loginError = true;
    });
  }
  loginFormControls() {
    return this.form.controls;
  }
}
