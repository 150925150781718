<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
      <label for="titulo">Titulo</label>
      <input class="form-control" type="text" name="titulo" id="titulo" formControlName="titulo" nbInput fullWidth required> 
    </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="nombre">Nombre</label>
        <input class="form-control" type="text" name="nombre" id="nombre" formControlName="nombre" nbInput fullWidth required>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="apellido_paterno">Apellido Paterno</label>
        <input class="form-control" type="text" name="apellido_paterno" id="apellido_paterno" formControlName="apellido_paterno" nbInput fullWidth required>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="apellido_materno">Apellido Materno</label>
        <input class="form-control" type="text" name="apellido_materno" id="apellido_materno" formControlName="apellido_materno" nbInput fullWidth required>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="email">Email</label>
        <input class="form-control" type="email" name="email" id="email" formControlName="email" nbInput fullWidth required>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="telefono">Telefono</label>
        <input class="form-control" type="text" name="telefono" id="telefono" formControlName="telefono" nbInput fullWidth required>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="cargo">Cargo</label>
        <input class="form-control" type="text" name="cargo" id="cargo" formControlName="cargo" nbInput fullWidth required>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="firma">¿El empleado puede firmar cotizaciones?</label>
        <nb-radio-group formControlName="firma">
          <nb-radio [value]="true">Si</nb-radio>
          <nb-radio [value]="false">No</nb-radio>
        </nb-radio-group>
    </div>
  </div>
  </div>
  <button nbButton type="submit" >
    Guardar
  </button>
</form>