import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Finiquito } from "../../@core/data/finiquito-data";
import { FiniquitoService } from "../../@core/services/finiquito.service";
import { NbToastrService, NbWindowRef, NbWindowService } from "@nebular/theme";
import {
  FormFieldConfig,
  FormFieldType,
  FormRowConfig,
  OptionType,
} from "../../form-builder/form-builder.component";
import { Cliente, Empleado, EmpleadoCategoria } from "../../@core/data/cotizacion-data";
import { EmpleadoCategoriasService } from "../../@core/services/empleado-categorias.service";
import { EmpleadoService } from "../../@core/services/empleado.service";
import { ClienteService } from "../../@core/services/cliente.service";
import { FormGroup } from "@angular/forms";
import { EmpresaFiniquitoService } from "../../@core/services/empresa-finiquito.service";
import { EmpresaFiniquito } from "../../@core/data/empresa-finiquito-data";

@Component({
  selector: "ngx-finiquitos",
  templateUrl: "./finiquitos.component.html",
  styleUrls: ["./finiquitos.component.scss"],
})
export class FiniquitosComponent implements OnInit {
  dataFiltered: Finiquito[];
  data: Finiquito[];
  @ViewChild("newWindow") newWindow: TemplateRef<any>;
  @ViewChild("editWindow") editWindow: TemplateRef<any>;
  newRef: NbWindowRef;
  editRef: NbWindowRef;
  empleados: Empleado[];
  categorias: EmpleadoCategoria[];
  clientes: Cliente[];
  disabledNewButton: boolean = true;
  empresas: EmpresaFiniquito[];
  constructor(
    private finiquitoService: FiniquitoService,
    private windowService: NbWindowService,
    private toasterService: NbToastrService,
    private empleadoService: EmpleadoService,
    private empleadoCategoriaService: EmpleadoCategoriasService,
    private clienteService: ClienteService,
    private empresasFiniquitoService: EmpresaFiniquitoService
  ) {

    this.finiquitoService.getAll().subscribe((data) => {
      this.data = data;
      this.dataFiltered = data;
    });
  }

  ngOnInit(): void {
    this.empleadoService.getAll().subscribe((data) => {
      this.empleados = data;
    });
    this.empleadoCategoriaService.getAll().subscribe((data) => {
      this.categorias = data;
    });

    this.empresasFiniquitoService.getAll().subscribe((data) => {
      this.empresas = data;
    });

    this.clienteService.getAll().subscribe((data) => {
      this.clientes = data;
      this.disabledNewButton = false;
    });
  }

  filter(value: string) {
    this.dataFiltered = this.data.filter(
      (item) =>
        item.fin_labores.toISOString().includes(value) ||
        item.monto.toString().includes(value) ||
        item.nombre_completo_empleado.includes(value) ||
        item.categoria_empleado_nombre.includes(value) ||
        item.cliente_nombre.includes(value)
    );
  }
  onNuevoClick() {
    let formConfig = [
      {
        type: FormFieldType.Row,
        fields: {
          empleado: {
            type: FormFieldType.Select,
            label: "Empleado",
            formControlName: "empleado_id",
            value: "",
            placeholder: "Empleado",
            col: 6,
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.empleados.map((empleado) => ({
                  key: empleado.id,
                  value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}`,
                })),
              },
            ],
          },
          categoria: {
            type: FormFieldType.Select,
            label: "Categoría",
            formControlName: "categoria_empleado_id",
            value: "",
            placeholder: "Categoría",
            col: 6,
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.categorias.map((categoria) => ({
                  key: categoria.id,
                  value: categoria.nombre,
                })),
              },
            ],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          monto: {
            type: FormFieldType.Input,
            label: "Monto",
            formControlName: "monto",
            value: "",
            placeholder: "Monto",
            col: 6,
          },
          empresa_finiquita_id: {
            type: FormFieldType.Select,
            label: "Empresa finiquita",
            formControlName: "empresa_finiquita_id",
            value: "",
            placeholder: "Selecciona una empresa",
            col: 6,
            options: [
              {
                key: OptionType.SelectOptions,
                value: this.empresas.map((empresa) => ({
                  key: empresa.id,
                  value: empresa.nombre,
                })),
              },
            ],
          },
        },
      },

      {
        type: FormFieldType.Row,
        fields: {
          inicio_labores: {
            type: FormFieldType.Date,
            label: "Fecha de inicio",
            formControlName: "inicio_labores",
            value: "",
            placeholder: "Fecha de inicio",
            col: 6,
          },
          fin_labores: {
            type: FormFieldType.Date,
            label: "Fecha de fin",
            formControlName: "fin_labores",
            value: "",
            placeholder: "Fecha de fin",
            col: 6,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          evidencia_file:{
            formControlName: 'evidencia_file',
            label: 'Evidencias',
            placeholder: 'Selecciona un archivo',
            col: 6,
            type: FormFieldType.File,
            options:[
              {key:OptionType.fileSelectTypes,value:'application/pdf'}
            ]
          },
        },
      },
    ] as FormRowConfig[];

    this.newRef = this.windowService.open(this.newWindow, {
      title: "Nuevo finiquito",
      context: { formConfig: formConfig },
    });
  }

  onCreated(form: FormGroup) {
    this.finiquitoService.create(form).subscribe((data) => {
      this.finiquitoService.getAll().subscribe((data) => {
        this.data = data;
        this.dataFiltered = data;
        this.toasterService.success("Finiquito creado", "Finiquito creado correctamente");
      });
      this.newRef.close();
    });
  }
}
