import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Cliente } from '../data/cotizacion-data';

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends BaseService<Cliente>{

  getPath(): string {
    return 'clientes';
  }


}
