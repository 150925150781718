import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Vehiculo } from '../../../@core/data/vehiculo-data';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VehiculosService } from '../../../@core/services/vehiculos.service';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';

@Component({
  selector: 'ngx-vehiculos-table',
  templateUrl: './vehiculos-table.component.html',
  styleUrls: ['./vehiculos-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VehiculosTableComponent implements OnChanges{
  title = 'angularmaterial';
  @Input() vehiculos: Vehiculo[] = [];
  columnsToDisplay: string[] = ['serie', 'placas', 'color', 'marca','estado','version','seguro'];
  columns= ['vendido','ref_pagado','ref_comentario','seguro_vigente','dias_vig_seguro','fech_fin_vig_seguro', 'chofer'];
  dataSource: MatTableDataSource<Vehiculo>;
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:false}) sort: MatSort;
  expandedVehiculo: Vehiculo | null | undefined;
  columnsToDisplayWithExpand = [...this.columnsToDisplay,...this.columns ,'expand'];
  deleteVehiculoRef: NbWindowRef;
  editVehiculoRef: NbWindowRef;
  @Output() vehiculoModificado = new EventEmitter<boolean>();
  @ViewChild('vehiculoTemplate', { read: TemplateRef }) vehiculoTemplate: TemplateRef<HTMLElement>;
  @ViewChild('editarVehiculoTemplate', { read: TemplateRef }) editarVehiculoTemplate: TemplateRef<HTMLElement>;
  constructor(private vehiculosService:VehiculosService,private windowService: NbWindowService,
    private toastrService: NbToastrService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vehiculos) {
      console.log(changes.vehiculos.currentValue);
      this.updateDataSource(changes.vehiculos.currentValue);
    }
  }

  private updateDataSource(changes: Vehiculo[]) {
    this.dataSource = new MatTableDataSource(changes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onClickEliminarVehiculo(idVehiculo: number) {
    this.vehiculosService.delete(idVehiculo).subscribe(() => {
      this.vehiculoModificado.emit(true);
      this.toastrService.show('Vehículo eliminado con éxito',
        'Eliminar Vehículo', { status: 'success' });
      this.vehiculosService.getAll().subscribe((data) => {
        this.vehiculos = data;
        this.updateDataSource(this.vehiculos);
      });
      this.deleteVehiculoRef.close();
    });
  }

  onClickOpenEliminarVehiculoForm(vehiculo: Vehiculo) {
    this.deleteVehiculoRef = this.windowService.open(
      this.vehiculoTemplate,
      { title: 'Eliminar vehículo', hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: true, context: { idVehiculo:vehiculo.id } },
    );
  }
  onClickCancelarEliminarVehiculo(){
    this.deleteVehiculoRef.close();
  }
  onSavedVehiculo(){
    this.toastrService.show('Vehiculo guardado con éxito',
      'Nuevo Vehiculo', { status: 'success' });
    this.vehiculoModificado.emit(true);
    this.editVehiculoRef.close();
  }
  onClickOpenEditarVehiculoForm(vehiculo: Vehiculo) {
    this.editVehiculoRef = this.windowService.open(
      this.editarVehiculoTemplate,
      { title: 'Editar vehículo', hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: true, context: { vehiculo:vehiculo } },
    );
  }
}
