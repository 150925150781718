import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,ChangeDetectionStrategy } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'ngx-form-builder',
  templateUrl: './form-builder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements  OnChanges {
  private cache: { [key: string]: BehaviorSubject<any[]> } = {};
  @Input() config: FormBuilderConfig;
  form: FormGroup;
  @Output() formOuput:EventEmitter<any>= new EventEmitter<any>();
  selectedItemsFormControl:SelectedItem[] = [];
  checkboxes:[]=[];
  optionTypes=OptionType;
  fieldType=FormFieldType;
  constructor(private fb: FormBuilder,private toastrService:NbToastrService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config.currentValue) {
      // Resolve all functions in fields for all rows
      this.config.forEach(row => {
        if (typeof row.fields === 'function') {
          row.fields = row.fields();
        }
      });
      this.form = this.createForm(this.config);
    }
  }

  createForm(config: FormBuilderConfig): FormGroup {
    const group = this.fb.group({});

    config.forEach(row => {
      const fields = typeof row.fields === 'function' ? row.fields() : row.fields;
      Object.keys(fields).forEach(key => {
        let value = this.getFieldValue(fields[key]);

        if (fields[key].type === this.fieldType.MULTIPLECHECKBOX) {
          this.addMultipleCheckboxControls(group, fields[key]);
        } else {
          this.addControl(group, fields[key], value);
        }
      });
    });

    return group;
  }

  getFieldValue(field: any): any {
    let value = field.value;
    if (typeof value === 'function') {
      value = value(this.form);
    }

    if (field.type === this.fieldType.Number) {
      value = value === null || value === '' ? 0 : parseFloat(value);
    }

    return value;
  }

  addMultipleCheckboxControls(group: FormGroup, field: any): void {
    const options = this.getOption(this.optionTypes.CheckboxOptions, field);
    options.forEach(o => {
      const bool = field.value.includes(o.key);
      const control = new FormControl(bool);
      group.addControl(o.key, control);
    });
  }

  addControl(group: FormGroup, field: FormFieldConfig, value: any): void {
    let control = new FormControl(value, this.mapValidations(field.validations));
    control.valueChanges.subscribe((value) => {
      if (field.onChange) {
        field.onChange(value, this.form);
      }
    });
    if (field.disable) {
      control.disable();
    }
    group.addControl(field.formControlName, control);
  }

  mapValidations(validations: any[] = []): ValidatorFn[] {
    const validatorMap = {
      string: {
        required: Validators.required,
        email: Validators.email,
        numeric: Validators.pattern(/^[0-9]+$/),
      },
      object: {
        min: Validators.min,
        max: Validators.max,
        minlength: Validators.minLength,
        maxlength: Validators.maxLength,
        pattern: Validators.pattern,
      }
    };

    return validations
      .map(validation => {
        if (typeof validation === 'string') {
          return validatorMap.string[validation];
        } else if (typeof validation === 'object' && validation.key) {
          return validatorMap.object[validation.key]?.(validation.value);
        }
        return null;
      })
      .filter(Boolean);
  }
  getKeys(fields: { [key: string]: FormFieldConfig } | (() => { [key: string]: FormFieldConfig })): string[] {
    const resolvedFields = typeof fields === 'function' ? fields() : fields;
    return Object.keys(resolvedFields);
  }
  getOption(option:string,field:FormFieldConfig): any {
    let o = field.options.find(o => o.key === option)
    if(o.value instanceof Function){
      return o.value();
    }
    return o.value;
  }

  getAsyncData(option: string, field: FormFieldConfig): Observable<any[]> {
    let o = field.options.find(o => o.key === option);
    if (o && o.value instanceof Observable) {
      if (!this.cache[option]) {
        this.cache[option] = new BehaviorSubject<any[]>([]);
        o.value.pipe(
          map((data: any[]) => o.map ? o.map(data) : data),
          shareReplay(1)
        ).subscribe(this.cache[option]);
      }
      return this.cache[option].asObservable();
    }
    return of([]); // Devuelve un Observable vacío si no se encuentra la opción
  }

  mapAsyncData(option:string,field:FormFieldConfig): any {
    let o = field.options.find(o => o.key === option)
    return o.value;
  }

  getSelectedFormControl(key: string) {
    return this.selectedItemsFormControl.find((control) => control.formControlName === key) || null;
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.formOuput.emit(this.form);
    }else{
      this.toastrService.warning('Favor de llenar todos los campos','Campos requeridos',{duration: 10000});
      this.form.markAllAsTouched();
    }
  }

  onFileSelected(event:any, key:string): void {
    this.form.get(key).setValue(event.target.files[0]);
  }

  onDateChange(event:Date, key:string): void {
    this.form.get(key).setValue(moment(event).format('DD/MM/YYYY'));
  }
  getLabel(row:FormRowConfig,key:string):string{
    const label = row.fields[key].label;
    if (typeof label === 'function') {
      return label(); // Llama a la función si es un callable
    }
    return label !== undefined ? label : row.fields[key].formControlName; // Retorna el label o el nombre del control
  }
  onInputChange(event:any, key:string): void {
    this.form.get(key).setValue(event.target.value);
    if(this.form.get(key).value !== event.target.value){
      this.form.get(key).setValue(event.target.value);
    }
  }
  onNumberChange(event:any, key:string): void {
    const value = event.target.value;
    this.form.get(key).setValue(value < 0 || value === null || value === '' ? 0 : value);
  }
}

export interface FormFieldConfig {
  type: FormFieldType;
  validations?: any[];
  maxLength?: number;
  minLength?: number;
  col?: number;
  label?: string | Function;
  value?: any|Function|CheckboxDefinition;
  formControlName: string;
  placeholder?: string;
  options?: any|Function;
  disable?: boolean;
  multiple?: boolean;
  onChange?: Function;
}
export interface FormRowConfig {
  type:FormFieldType;
  fields: { [key: string]: FormFieldConfig }|Function;
}
export enum FormFieldType {
  Input = 'input',
  Select = 'select',
  SELECT_ASYNC = 'select-async',
  Date = 'date',
  DateTime = 'datetime',
  Checkbox = 'checkbox',
  MULTIPLECHECKBOX = 'multiple-checkbox',
  Row = 'row',
  TextArea = 'textarea',
  File = 'file',
  Radio = 'radio',
  Number = 'number',
  Col= 'col',
  SelectGroup= 'select-group',
}
export type FormBuilderConfig = FormRowConfig[];

export interface SelectedItem {
  selectedItem: FormControl;
  formControlName: string;
}

export enum OptionType{
  fileSelectTypes = 'file-select-types',
  labelSi= 'label-si',
  labelNo= 'label-no',
  SelectOptions= 'select-options',
  RadioOptions= 'radio-options',
  CheckboxOptions= 'checkbox-options',
}

export interface CheckboxDefinition {
  [key: string]: {
    formControl: FormControl;
    label: string;
    value: boolean;
  };
}
