
<div *ngFor="let doc of docsExpediente" class="row">
  <div class="col-1">
    {{docsExpediente.indexOf(doc) + 1}}
  </div>
  <div class="col-8">
    {{doc.tipo_documento.nombre}}
  </div>
  <div class="col-1">
    <nb-icon *ngIf="doc.documento_id!==null" icon="checkmark-outline" status="success"></nb-icon>
    <nb-icon *ngIf="doc.documento_id===null" icon="close-outline" status="danger" ></nb-icon>
  </div>
  <div class="col-2">
    <button nbButton status="primary" nbTooltip="Subir documento" size="small" (click)="openUploadFileWindow(doc)">
      <nb-icon icon="edit-2-outline"></nb-icon>
    </button>

    <button *ngIf="doc.documento_id!==null" nbButton status="primary" nbTooltip="Ver documento" size="small" (click)="viewFile(doc)">
      <nb-icon icon="eye-outline"></nb-icon>
    </button>
  </div>
</div>

<ng-template  #fileInputWindow let-data>
  <div style="min-width: 400px; max-height: 400px;">
    <div class="row">
      <div class="col-12">
        <input nbInput type="file" (change)="onFileSelected($event)" />
      </div>
    </div>
    <div *ngIf="filSelected!==null" class="row">
      <div class="col-12">
        <button nbButton status="primary"   (click)="onSavedDoc(data.doc)">Subir</button>
      </div>
    </div>
  </div>
</ng-template>
