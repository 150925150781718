<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="Nombre">Nombre</label>
        <input type="text" class="form-control" id="Nombre" formControlName="nombre">
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="apellido_paterno">Apellido paterno</label>
        <input type="text" class="form-control" id="apellido_paterno" formControlName="apellido_paterno">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="apellido_materno">Apellido materno</label>
        <input type="text" class="form-control" id="apellido_materno" formControlName="apellido_materno">
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="telefono">Telefono</label>
        <input type="text" class="form-control" id="telefono" formControlName="telefono">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
      <label for="puesto">Puesto</label>
      <input type="text" class="form-control" id="puesto" formControlName="puesto">
    </div>
  </div>
    <div class="col-6">
      <div class="form-group">
        <label for="contratable">Contratable</label>
        <nb-checkbox class="form-control-check"  status="primary" formControlName="contratable" >
          {{ form.get('contratable').value ? 'Contratable' : 'No contratable' }}
        </nb-checkbox>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="comentarios">Comentarios</label>
        <textarea class="form-control" id="comentarios" formControlName="comentarios"></textarea>
      </div>
    </div>
  </div>

  <button type="submit" class="btn btn-primary">Guardar</button>
</form>
