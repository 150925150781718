<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Cotizaciones
      <button nbButton status="success" nbTooltip="Agregar cotización" nbTooltipStatus="primary"
              (click)="onClickOpenNewCotizacion()" class="btn btn-success btn-sm p-lg-1">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
    </div>
    <div>
      <input nbInput class="search-input" placeholder="Folio, cliente, empleado"
      [(ngModel)]="searchText" (input)="onSearch()">
    </div>
  </nb-card-header>
  <nb-card-body>
    <ngx-cotizaciones-mat-table [conceptos]="filteredData"></ngx-cotizaciones-mat-table>
  </nb-card-body>
</nb-card>

<ng-template  #nuevaCotizacion>
  <div class="responsive-div-lg">
    <ngx-nueva-cotizacion-form (cotizacionSaved)="onCotizacionSaved()"></ngx-nueva-cotizacion-form>
  </div>
</ng-template>


