import { MantenimientosService } from './../../@core/services/mantenimientos.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaquinariasComponent } from './maquinarias.component';
import { MaquinariaService } from '../../@core/services/maquinaria.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbAccordionModule, NbButtonGroupModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDatepickerModule, NbIconModule, NbInputModule, NbListModule, NbSelectModule, NbSpinnerModule, NbTabsetModule, NbTooltipModule, NbTreeGridModule } from '@nebular/theme';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TablaMaquinariaComponent } from './tabla-maquinaria/tabla-maquinaria.component';
import { NuevaMaquinariaFormComponent } from '../../forms/nueva-maquinaria-form/nueva-maquinaria-form.component';
import { MaquinariaFiltroComponent } from './maquinaria-filtro/maquinaria-filtro.component';
import { GCMARQFormsModule } from '../../forms/gcmarqforms.module';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { CategoriaService } from '../../@core/services/categoria.service';
import { ObraService } from '../../@core/services/obra.service';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { MaquinariaMantenimientosTableComponent } from './maquinaria-mantenimientos-table/maquinaria-mantenimientos-table.component';
import { FilterValueDateModule } from '../../forms/filter-value-date/filter-value-date.module';



@NgModule({
  declarations: [
    MaquinariasComponent,
    TablaMaquinariaComponent,
    MaquinariaFiltroComponent,
    MaquinariaMantenimientosTableComponent
  ],
  imports: [
    GCMARQFormsModule,
    CommonModule,
    FormsModule,
    NbButtonModule,
    NbDatepickerModule,
    NbInputModule,
    NbCheckboxModule,
    NbTreeGridModule,
    NbSelectModule,
    NbCardModule,
    NbTabsetModule,
    NbListModule,
    NbButtonGroupModule,
    NbAccordionModule,
    NbIconModule,
    NbTooltipModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormBuilderModule,
    MatTableBuilderModule,
    NbSpinnerModule,
    FilterValueDateModule
  ],
  providers: [
    MaquinariaService,
    CategoriaService,
    ObraService,
    EmpleadoService,
    MantenimientosService
  ]
})
export class MaquinariasModule { }
