import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CotizacionMaquinaria } from '../../../../@core/data/cotizacion-data';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CotizacionMaquinariaService } from '../../../../@core/services/cotizacion-maquinaria.service';
import { MaquinariaService } from '../../../../@core/services/maquinaria.service';
import { Maquinaria } from '../../../../@core/data/maquinaria-data';

@Component({
  selector: 'ngx-cotizacion-maquinarias-table',
  templateUrl: './cotizacion-maquinarias-table.component.html',
  styleUrls: ['./cotizacion-maquinarias-table.component.scss']
})
export class CotizacionMaquinariasTableComponent implements OnInit {

  maquinarias:Maquinaria[]=[];
  @Input() cotizaciones: CotizacionMaquinaria[];
  defaultColumns: string[] = ['maquinaria_numero','maquinaria_nombre', 'precio_renta_maquinaria','cantidad_horas','subtotal' ,'acciones'];
  customColumns: string[] = [];
  allColumns: string[] = [...this.defaultColumns, ...this.customColumns];
  @ViewChild(MatTable) table: MatTable<CotizacionMaquinaria>;
  @Output() saveCotizacion:EventEmitter<CotizacionMaquinaria[]> = new EventEmitter<CotizacionMaquinaria[]>();
  constructor(private cotizacionMaqService: CotizacionMaquinariaService,private maquinariaService:MaquinariaService) {
  }

  ngOnInit(): void {
    this.maquinariaService.getAll().subscribe(maquinarias => {
      this.maquinarias = maquinarias;
    });
  }


  onSelectMaquinaria($event,row: CotizacionMaquinaria){
    let index = this.cotizaciones.indexOf(row);
    let maquinaria = this.maquinarias.find(maq => maq.id == $event);
    this.cotizaciones[index].subtotal=maquinaria.precio*this.cotizaciones[index].cantidad_horas;
    this.cotizaciones[index].maquinaria_numero=maquinaria.numero;
    this.cotizaciones[index].maquinaria_id=maquinaria.id;
    if(row.cantidad_horas>0){

      this.cotizaciones[index].subtotal=maquinaria.precio*row.cantidad_horas;
    }
    this.table.renderRows();
  }

  onHorasChage($event:Event,row: CotizacionMaquinaria){
      const value:number = parseFloat(($event.target as HTMLInputElement).value);
      let index = this.cotizaciones.indexOf(row);
      this.cotizaciones[index].subtotal =
        parseFloat((this.cotizaciones[index].precio_renta_maquinaria * value).toFixed(2));
      this.cotizaciones[index].cantidad_horas=value;

      this.table.renderRows();
  }

  onPrecioChange($event:Event,row: CotizacionMaquinaria){
    const value:number = parseFloat(($event.target as HTMLInputElement).value);
    let index = this.cotizaciones.indexOf(row);
    this.cotizaciones[index].precio_renta_maquinaria=value;
    this.cotizaciones[index].subtotal =
      parseFloat((this.cotizaciones[index].cantidad_horas * value).toFixed(2));
    this.table.renderRows();
  }

  getTotalCost(){
    return this.cotizaciones.map(t => t.subtotal)
    .reduce((acc, value) => acc + value, 0);
  }

  removeCotizacionVehiculo(cotizacion: CotizacionMaquinaria) {
    const index = this.cotizaciones.indexOf(cotizacion);
    if (index > -1) {
      this.cotizaciones.splice(index, 1);
      this.table.renderRows();
    }

  }

}
