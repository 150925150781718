import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Mantenimiento, Maquinaria } from '../../../@core/data/maquinaria-data';
import { FormFieldType, FormRowConfig, OptionType } from '../../../form-builder/form-builder.component';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { MantenimientosService } from '../../../@core/services/mantenimientos.service';
import { MatTableActionConfig, MatTableColumnConfig } from '../../../mat-table-builder/mat-table-builder.component';
import { CSVGenerator } from '../../../@core/utils/csv-generator';

@Component({
  selector: 'ngx-tractocamion-mantenimientos-tabla',
  templateUrl: './tractocamion-mantenimientos-tabla.component.html',
  styleUrls: ['./tractocamion-mantenimientos-tabla.component.scss']
})
export class TractocamionMantenimientosTablaComponent implements OnChanges{
  @Input() maquinaria: Maquinaria;
  @Output() mantenimientoCreated: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("newMantenimientoForm") newMantenimientoForm: any;
  mantenimientoRef: NbWindowRef;
  mantenimientos: Mantenimiento[] = [];
  mantenimientosFiltered: Mantenimiento[] = [];
  filterFields: string[] = [ "kilometraje_horometro_inicial", "kilometraje_horometro_programado", "kilometraje_horometro_final", "observaciones"];
  tableDef: MatTableColumnConfig[] = [
    {
      name: "fecha_mantenimiento",
      label: "Fecha de mantenimiento",
      type: "date",
    },
    { name: "kilometraje_horometro_inicial", label: "Kilometraje inicial" },
    { name: "kilometraje_horometro_programado", label: "Kilometraje programado" },
    { name: "kilometraje_horometro_final", label: "Kilometraje final" },
    { name: "observaciones", label: "Observaciones" },
  ];
  actions: MatTableActionConfig[] = [
    {
      name: "view",
      label: "Ver evidencia",
      icon: "eye",
      action: (mantenimiento: Mantenimiento) => {
        this.mantenimientosService.downloadEvidencia(mantenimiento.id).subscribe((data)=>{
          const url = window.URL.createObjectURL(data);
          window.open(url);

        },(error)=>{
          this.toastrService.danger(error.message, "Error", {
            duration: 3000,
            });
          });
      },
    },
    {
      name: "edit",
      label: "Editar",
      icon: "edit",
      action: (form: FormGroup, mantenimiento: Mantenimiento, ref) => {
        this.mantenimientosService.update(form,mantenimiento.id).subscribe(() => {
          this.toastrService.success("Mantenimiento actualizado", "Exito", {
            duration: 10000,
          });
          this.mantenimientosService.uploadEvidencia(mantenimiento.id,form.get("evidencia").value).subscribe(()=>{
            this.toastrService.success("Evidencia subida", "Exito", {
              duration: 10000,
            });
          });
          ref.close();
          this.mantenimientosService
            .getByIdMaquinaria(this.maquinaria.id)
            .subscribe((mantenimientos) => {
              this.mantenimientos = mantenimientos;
              this.mantenimientosFiltered = mantenimientos;
            });
        });

      },
      formConfig: (m: Mantenimiento) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              fecha_mantenimiento: {
                formControlName: "fecha_mantenimiento",
                label: "Fecha de mantenimiento",
                type: FormFieldType.Date,
                col: 12,
                validations: ["required"],
                value: m.fecha_mantenimiento
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              kilometraje_horometro_inicial: {
                formControlName: "kilometraje_horometro_inicial",
                label: "Kilometraje inicial",
                type: FormFieldType.Number,
                value: m.kilometraje_horometro_inicial,
                col: 4,
                validations: ["required"],
                disable: true,
              },
              kilometraje_horometro_final: {
                formControlName: "kilometraje_horometro_final",
                label: "Kilometraje actual",
                disable: true,
                type: FormFieldType.Number,
                value: m.kilometraje_horometro_final,
                validations: ["required"],
                col: 4,
              },
              kilometraje_horometro_programado: {
                formControlName: "kilometraje_horometro_programado",
                label: "Matenimiento programado (km)",
                type: FormFieldType.Number,
                value: m.kilometraje_horometro_programado,
                validations: ["required"],
                col: 4,
                disable: true,
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              observaciones: {
                formControlName: "observaciones",
                label: "Observaciones",
                type: FormFieldType.TextArea,
                col: 6,
                value: m.observaciones
              },
              evidencia: {
                formControlName: "evidencia",
                label: "Evidencia",
                type: FormFieldType.File,
                col: 6,
                value: null,
                options: [
                  {key: OptionType.fileSelectTypes, value: "image/*"},
                ]
              },
            },
          },
        ];
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      icon: "trash",
      message: "¿Estas seguro de eliminar este mantenimiento?",
      action: (mantenimiento) => {
        this.mantenimientosService.delete(mantenimiento.id).subscribe(() => {
          this.toastrService.success("Mantenimiento eliminado", "Exito", {
            duration: 3000,
          });
          this.mantenimientosService
            .getByIdMaquinaria(this.maquinaria.id)
            .subscribe((mantenimientos) => {
              this.mantenimientos = mantenimientos;
              this.mantenimientosFiltered = mantenimientos;
            });
        });
      },
    },
  ];

  constructor(
    private mantenimientosService: MantenimientosService,
    private windowsRef: NbWindowService,
    private toastrService: NbToastrService,

  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maquinaria) {
      this.mantenimientosService
        .getByIdMaquinaria(changes.maquinaria.currentValue.id)
        .subscribe((mantenimientos) => {
          this.mantenimientos = mantenimientos;
          this.mantenimientosFiltered = mantenimientos;
        });
    }
  }

  onClickOpenNuevoMantenimiento() {
    let mantenimientoFormDef: FormRowConfig[] = [
      {
        type: FormFieldType.Row,
        fields: {
          fecha_mantenimiento: {
            formControlName: "fecha_mantenimiento",
            label: "Fecha de mantenimiento",
            type: FormFieldType.Date,
            value: new Date(),
            col: 12,
            validations: ["required"],
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          kilometraje_horometro_inicial: {
            formControlName: "kilometraje_horometro_inicial",
            label: "Horometro inicial",
            type: FormFieldType.Number,
            value: this.maquinaria.horometro_original_matenimiento,
            col: 4,
            validations: ["required"],
            disable: true,
          },
          kilometraje_horometro_final: {
            formControlName: "kilometraje_horometro_final",
            label: "Horometro actual",
            disable: true,
            type: FormFieldType.Number,
            value: this.maquinaria.horometro,
            validations: ["required"],
            col: 4,
          },
          kilometraje_horometro_programado: {
            formControlName: "kilometraje_horometro_programado",
            label: "Matenimiento programado (hrs)",
            type: FormFieldType.Number,
            value: this.maquinaria.mantenimiento_horas_restantes,
            validations: ["required"],
            col: 4,
            disable: true,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          observaciones: {
            formControlName: "observaciones",
            label: "Observaciones",
            type: FormFieldType.TextArea,
            col: 6,
          },
          evidencia: {
            formControlName: "evidencia",
            label: "Evidencia",
            type: FormFieldType.File,
            col: 6,
            options: [
              {key: OptionType.fileSelectTypes, value: "image/*"},
            ]
          },
        },
      },
    ];
    this.mantenimientoRef = this.windowsRef.open(this.newMantenimientoForm, {
      title: "Nuevo mantenimiento",
      context: {
        maquinaria: this.maquinaria,
        formConfig: mantenimientoFormDef,
      },
    });
  }

  onCreateMantenimiento(form: FormGroup, maquinaria: Maquinaria) {
    form.addControl("id", new FormControl(maquinaria.id));
    form.addControl("tipo", new FormControl("MAQUINARIA"));
    this.mantenimientosService.create(form).subscribe(
      (m:Mantenimiento) => {
        this.toastrService.success("Mantenimiento creado", "Exito", {
          duration: 3000,
        });
        this.mantenimientoCreated.emit(true);
        this.mantenimientoRef.close();
        console.log(m);
        this.mantenimientosService.uploadEvidencia(m.id,form.get("evidencia").value).subscribe(()=>{
          this.toastrService.success("Evidencia subida", "Exito", {
            duration: 3000,
          });
        },(error)=>{
          this.toastrService.danger("Error al subir evidencia", "Error", {
            duration: 3000,
            });
          });
        this.mantenimientosService
          .getByIdMaquinaria(maquinaria.id)
          .subscribe((mantenimientos) => {
            this.mantenimientos = mantenimientos;
            this.mantenimientosFiltered = mantenimientos;
          });
      },
      (error) => {
        this.toastrService.danger("Error al crear mantenimiento", "Error", {
          duration: 3000,
        });
      }
    );
  }
  onFilterChange($event: any) {
    console.log($event);
    this.mantenimientosFiltered = $event;
  }

  onClickDownloadReporte() {
    const nombre = `${this.maquinaria.nombre}_${this.maquinaria.numero}_${this.maquinaria.modelo}`;
    CSVGenerator.generateExcel(this.tableDef, this.mantenimientos,nombre);
  }
}
