import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Dashboard } from '../data/dashboard-data';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService<Dashboard> {
  protected getPath(): string {
    return 'dashboard';
  }
}
