export interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>;
  expanded?: boolean;
}

export function convertToTree<T>(data: T[]): TreeNode<T>[] {
  return data.map(item => {
    const treeNode: TreeNode<T> = {
      data: item,
    };
    return treeNode;
  });
}
