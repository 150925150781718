import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Cliente } from '../../@core/data/cotizacion-data';
import { ClienteService } from '../../@core/services/cliente.service';
import { NbWindowRef, NbWindowService } from '@nebular/theme';

@Component({
  selector: 'ngx-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent {
  data: Cliente[] = []
  dataSource: Cliente[] = []
  @ViewChild('nuevoClienteFormView',{ read: TemplateRef }) nuevoClienteFormView: TemplateRef<HTMLElement>;
  clienteWindowRef: NbWindowRef;
  constructor(private clientesService:ClienteService, private windowService: NbWindowService) {
    this.clientesService.getAll().subscribe(data => {
      this.data = data;
      this.dataSource = data;
    })
  }

  filter(value: string) {
    const filterValue = value.toLowerCase();
    this.dataSource = this.data.filter(option => option.nombre.toLowerCase().includes(filterValue)
    ||option.direccion.toLowerCase().includes(filterValue));
  }

  onNuevoClienteClick() {
    this.clienteWindowRef = this.windowService.open(
      this.nuevoClienteFormView,
      { title: 'Nuevo cliente', hasBackdrop: true, closeOnEsc: true },
    );
  }

  onClienteCreated(){
    this.clienteWindowRef.close();
    this.clientesService.getAll().subscribe(data => {
      this.data = data;
      this.dataSource = data;
    })
  }

}
