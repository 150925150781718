<nb-list class="small-text">
  <nb-list-item *ngFor="let pdf of pdfFiles; let i = index">
    <div class="row align-items-center">
      <div  class="col-md-3">
        <small><strong>{{ pdf.name }}</strong></small>
      </div>
      <div class="col-md-5">
        <input type="file" size="tiny" (change)="onFileSelected($event, i)"  accept="application/pdf" nbInput fullWidth class="form-control-sm">
      </div>
      <div class="col-md-2">
        <button nbButton size="tiny" status="primary" (click)="uploadFile(i)" [disabled]="!pdf.file">
          Subir
        </button>
      </div>
      <div class="col-md-2">
        <button nbButton size="tiny" status="info" (click)="downloadFile(i)" [disabled]="!contratoHasFile(pdf.path)">
          Descargar
        </button>
      </div>
    </div>
  </nb-list-item>
</nb-list>
