<table [nbTreeGrid]="datasource">

  <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="columns"></tr>
  <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: columns"></tr>


  <ng-container nbTreeGridColumnDef="usuario">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
      Usuario
    </th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <button (click)="openUserPerfil(row.data)" nbButton>{{row.data.usuario}}</button></td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="nombre">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
      Nombre
    </th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data.nombre}}</td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="apellidos">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
      Apellido
    </th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data.apellidos}}</td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="email">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
      Email
    </th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data.email}}</td>
  </ng-container>

  <ng-template #userPerfil let-data>
    {{data.usuario.nombre}}
  </ng-template>
</table>
