import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiniestrosComponent } from './siniestros.component';
import { NbButtonModule, NbCardModule, NbDatepickerModule, NbIconModule, NbInputModule, NbOptionModule, NbSelectModule, NbTableModule, NbTabsetModule, NbTreeGridModule, NbTooltipModule, NbFormFieldModule, NbAutocompleteModule, NbSpinnerModule } from '@nebular/theme';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { DetalleIncidenciaComponent } from './detalle-incidencia/detalle-incidencia.component';
import { SiniestroFormComponent } from './siniestro-form/siniestro-form.component';
import { CarpetaService, IncidenciasService } from '../../@core/services/incidencias-service';
import { EditIncidenciaFormComponent } from './edit-incidencia-form/edit-incidencia-form.component';
import { BitacoraTableComponent } from './bitacora-table/bitacora-table.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NumberFormatPipe } from '../../@core/pipes/number-format.pipe';



@NgModule({
  declarations: [
    SiniestrosComponent,
    DetalleIncidenciaComponent,
    SiniestroFormComponent,
    EditIncidenciaFormComponent,
    BitacoraTableComponent,
    DocumentosComponent,
    NumberFormatPipe
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbTableModule,
    NbTreeGridModule,
    NbButtonModule,
    FormBuilderModule,
    ReactiveFormsModule,
    MatTableBuilderModule,
    NbTabsetModule,
    NbButtonModule,
    NbOptionModule,
    NbSelectModule,
    NbDatepickerModule,
    NbInputModule,
    NbTooltipModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    NbInputModule,
    NbFormFieldModule,
    FormsModule,
    NbAutocompleteModule,
    NbSpinnerModule,
  ],
  providers:[
    IncidenciasService,
    CarpetaService,
  ]
})
export class SiniestrosModule { }
