import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntrevistasComponent } from './entrevistas.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { GCMARQFormsModule } from '../../forms/gcmarqforms.module';
import { GCMarqTablesModule } from '../../tables/gcmarq-tables.module';
import { EntrevistasService } from '../../@core/services/entrevistas.service';
import { FormBuilderModule } from '../../form-builder/form-builder.module';



@NgModule({
  declarations: [
    EntrevistasComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    GCMARQFormsModule,
    GCMarqTablesModule,
    FormBuilderModule,
    
  ],
  providers: [
    EntrevistasService
  ],

})
export class EntrevistasModule { }
