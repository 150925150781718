import { TipoDocumentoExpediente } from './../data/tipo-documento-expediente';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class TipoDocumentoExpedienteService extends BaseService<TipoDocumentoExpediente>{

  protected getPath(): string {
    return 'tipos-documentos-expediente';
  }
}
