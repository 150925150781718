import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { EntradaNoFiscal } from '../data/gastos-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntradasService extends BaseService<EntradaNoFiscal> {
  uploadComprobante(id: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('comprobante', file);
    return this.http.post(`${this.baseUrl}${this.getPath()}/${id}?upload-comprobante`, formData);

  }
  getAllByAttributes(attributes: ( { [key:string]: any; })[]): Observable<EntradaNoFiscal[]> {
    return this.http.get<EntradaNoFiscal[]>(`${this.baseUrl}${this.getPath()}?${attributes.map(attr => Object.keys(attr).map(key => `${key}=${attr[key]}`).join('&')).join('&')}`);
  }
  getAllByFechaEntrada(fecha: Date): Observable<EntradaNoFiscal[]> {
    return this.http.get<EntradaNoFiscal[]>(`${this.baseUrl}${this.getPath()}?fecha_entrada=${fecha.getFullYear()}-${(fecha.getMonth() + 1).toString().padStart(2, '0')}-${fecha.getDate().toString().padStart(2, '0')}`);
  }

  protected getPath(): string {
    return 'entradas';
  }
}
