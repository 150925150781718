import { FormGroup } from "@angular/forms";

export function removeEmptyControls(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.controls[key].value === null) {
        console.log('removing', key);
        form.controls[key].setValue('');
      }
    });
  }
