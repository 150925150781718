import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseService } from '../../@core/services/base-service';
import { NbWindowModule, NbWindowRef, NbWindowService } from '@nebular/theme';

@Component({
  selector: 'ngx-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() show: boolean = false;
  @ViewChild('openDelete', { read: TemplateRef }) openDelete: TemplateRef<HTMLElement>;
  deleteWindows: NbWindowRef;
  @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private windowsService:NbWindowService) { }

  onClickOpenDelete() {
    this.deleteWindows = this.windowsService.open(
      this.openDelete,
      { title: this.title!=undefined?this.title:'Eliminar', hasBackdrop: true, closeOnEsc: true },
    );
  }

  onConfirmDelete() {
    this.deleteWindows.close();
    this.delete.emit(true);
  }

  onCancelar(){
    this.deleteWindows.close();
  }

}
