<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Expedientes únicos
      <button nbButton status="success" nbTooltip="Agregar expediente"
              (click)="onClickOpenNuevoExpediente()" class="btn btn-success btn-sm p-lg-1">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
    </div>
    <div>
      <input nbInput class="search-input" placeholder="Contrato, cargo, nombre"
      [(ngModel)]="searchText" (input)="onExpedientesSearch()">
    </div>
  </nb-card-header>
  <nb-card-body>
    <ngx-expedientes-unicos-table [expedientesUnicos]="filteredData"></ngx-expedientes-unicos-table>
  </nb-card-body>
</nb-card>

<ng-template (onClose)="refreshData()"  #nuevoExpediente>
  <div class="responsive-div-md">
    <ngx-nuevo-expediente-stepper></ngx-nuevo-expediente-stepper>
  </div>
</ng-template>
