import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Proveedor } from '../data/gastos-data';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService extends BaseService<Proveedor> {



  getPath(): string {
    return 'proveedores';
  }
}
