<div class="row">
  <div class="col-xxxl-9 col-xl-12">
    <nb-card>
      <nb-card-header class="d-flex justify-content-between">
        <div>
          Usuarios
          <button nbButton status="success" nbTooltip="Agregar usuario" size="small"
            [nbSpinner]="loading" [disabled]="loading"
                  (click)="onClickOpenNuevaMaquinaria()" class="btn btn-success btn-sm p-lg-1">
            <nb-icon icon="plus-outline"></nb-icon>
          </button>
        </div>

        <div>
          <input nbInput class="search-input" placeholder="Nombre, numero economico,serie o modelo"
          [(ngModel)]="searchText" (input)="onUsuarioSearch()">
        <button nbButton status="info" nbTooltip="Generar reporte CSV" size="small" (click)="generarReporteCSV()" class="ml-2">
          <nb-icon icon="file-text-outline"></nb-icon>
        </button>
        </div>
      </nb-card-header>
      <nb-card-body>
        <ngx-mat-table-builder [columnsDef]="colums" [data]="filteredData" [actions]="actions"></ngx-mat-table-builder>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<ng-template #newDataFormView let-data>
  <div class="responsive-div-md" >
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onFormOutput($event)"></ngx-form-builder>
  </div>
</ng-template>
