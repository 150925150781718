<nb-card>
  <nb-card-body class="no-padding">
    <nb-tabset class="no-padding">
      <nb-tab tabTitle="Empleados">
        <nb-tabset class="no-padding">
          <nb-tab tabTitle="Pocisiones">
            <nb-card>
              <nb-card-header>
                <button
                  nbButton
                  status="primary"
                  (click)="openDialog(empleadocategoriasConfig)"
                >
                  Agregar
                </button>
              </nb-card-header>
              <nb-card-body class="no-padding">
                <ngx-mat-table-builder
                  [columnsDef]="empleadocategoriasConfig.tbDef"
                  [data]="empleadocategoriasConfig.data"
                  [actions]="empleadocategoriasConfig.tbActions"
                ></ngx-mat-table-builder>
              </nb-card-body>
            </nb-card>
          </nb-tab>
          <nb-tab tabTitle="Empresas Finiquito">
            <nb-card>
              <nb-card-header>
                <button
                  nbButton
                  status="primary"
                  (click)="openDialog(empresasFiniquitoConfig)"
                >
                  Agregar
                </button>
              </nb-card-header>
                <ngx-mat-table-builder
                  [columnsDef]="empresasFiniquitoConfig.tbDef"
                  [data]="empresasFiniquitoConfig.data"
                  [actions]="empresasFiniquitoConfig.tbActions"
                ></ngx-mat-table-builder>
            </nb-card>
          </nb-tab>
        </nb-tabset>
      </nb-tab>

      <nb-tab tabTitle="Vehiculos">
        <nb-tabset>
          <nb-tab tabTitle="Marcas">
            <nb-card>
              <nb-card-header>
                <button
                  nbButton
                  status="primary"
                  (click)="openDialog(marcasConfig)"
                >
                  Agregar
                </button>
              </nb-card-header>
              <ngx-mat-table-builder
                [columnsDef]="marcasConfig.tbDef"
                [data]="marcasConfig.data"
                [actions]="marcasConfig.tbActions"
              ></ngx-mat-table-builder>
            </nb-card>
          </nb-tab>
          <nb-tab tabTitle="Versiones">
            <nb-card>
              <nb-card-header>
                <button
                  nbButton
                  status="primary"
                  (click)="openDialog(versionesConfig)"
                >
                  Agregar
                </button>
              </nb-card-header>
              <ngx-mat-table-builder
                [columnsDef]="versionesConfig.tbDef"
                [data]="versionesConfig.data"
                [actions]="versionesConfig.tbActions"
              ></ngx-mat-table-builder>
            </nb-card>
          </nb-tab>
          <nb-tab tabTitle="Seguros">
            <nb-card >
              <nb-card-header>
                <button
                  nbButton
                  status="primary"
                  (click)="openDialog(segurosConfig)"
                >
                  Agregar
                </button>
              </nb-card-header>
              <ngx-mat-table-builder
                [columnsDef]="segurosConfig.tbDef"
                [data]="segurosConfig.data"
                [actions]="segurosConfig.tbActions"
              ></ngx-mat-table-builder>
            </nb-card>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab tabTitle="Ubicaciones">
        <nb-card>
          <nb-card-header>
            <button
              nbButton
              status="primary"
              (click)="openDialog(ubicacionesConfig)"
            >
              Agregar
            </button>
          </nb-card-header>
          <ngx-mat-table-builder
            [columnsDef]="ubicacionesConfig.tbDef"
            [data]="ubicacionesConfig.data"
            [actions]="ubicacionesConfig.tbActions"
          ></ngx-mat-table-builder>
        </nb-card>
      </nb-tab>
      <nb-tab tabTitle="Vehiculo/Maquinaria">
        <nb-tabset>
          <nb-tab tabTitle="Categorias">
            <nb-card>
              <nb-card-header>
                <button
                  nbButton
                  status="primary"
                  (click)="openDialog(maquinariaCategoriasConfig)"
                >
                  Agregar
                </button>
              </nb-card-header>
              <ngx-mat-table-builder
                [columnsDef]="maquinariaCategoriasConfig.tbDef"
                [data]="maquinariaCategoriasConfig.data"
                [actions]="maquinariaCategoriasConfig.tbActions"
              ></ngx-mat-table-builder>
            </nb-card>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab tabTitle="Proveedores">
        <nb-card >
          <nb-card-header>
            <button
              nbButton
              status="primary"
              (click)="openDialog(proveedoresConfig)"
            >
              Agregar
            </button>
          </nb-card-header>
          <ngx-mat-table-builder
            [columnsDef]="proveedoresConfig.tbDef"
            [data]="proveedoresConfig.data"
            [actions]="proveedoresConfig.tbActions"
          ></ngx-mat-table-builder>
        </nb-card>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>

<ng-template let-data #createForm>
  <ngx-form-builder
    [config]="data.config.newForm.formDef"
    (formOuput)="onSubmit($event, data.config)"
  ></ngx-form-builder>
</ng-template>
