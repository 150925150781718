import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NominasService } from '../../@core/services/nominas.service';
import { Nomina } from '../../@core/data/nomina-data';
import { ConfigCrudDef } from '../configuraciones/configuraciones.component';
import { FormFieldType } from '../../form-builder/form-builder.component';
import { FormGroup } from '@angular/forms';
import { NbDialogRef, NbWindowRef, NbWindowService } from '@nebular/theme';

@Component({
  selector: 'ngx-nominas',
  templateUrl: './nominas.component.html',
  styleUrls: ['./nominas.component.scss']
})
export class NominasComponent {
  nominaConfig: ConfigCrudDef = {
    data: [] as Nomina[],
    tbDef: [
      { name: 'id', label: 'ID' },
      { name: 'obra_id', label: 'Obra' },
      { name: 'fecha_inicial', label: 'Fecha Inicial' },
      { name: 'fecha_final', label: 'Fecha Final' },
      { name: 'empleado_revisa_id', label: 'Empleado Revisa' },
      { name: 'empleado_aprueba_id', label: 'Empleado Aprueba' },
      { name: 'empleado_autoriza_id', label: 'Empleado Autoriza' },
      { name: 'created_at', label: 'Fecha Creacion' },
    ],
    tbActions: [
      {
        name: 'ver',
        label: 'Ver',
        icon: 'eye',
        action: (row: Nomina) => console.log(row),
      },
    ],
    newForm: {
      formDef: [
       {
        type: FormFieldType.Row,
        fields: {
          fecha_inicial:{
            type: FormFieldType.Date,
            formControlName: 'fecha_inicial',
            label: 'Fecha Inicial',
            value: null,
          },
          fecha_final:{
            type: FormFieldType.Date,
            formControlName: 'fecha_final',
            label: 'Fecha Final',
            value: null,
            disable: true,
          },
        },
       }
      ],
      onSubmit: (form: FormGroup<any>) => {
        this.nominaService.create(form).subscribe((nomina) => {
          this.nominaConfig.data = [...this.nominaConfig.data, nomina];
        });
      },
    },
    loadData: () => {
      this.nominaService.getAll().subscribe((nominas) => {
        this.nominaConfig.data = nominas;
    });
   }
  }
  @ViewChild('nuevaNominaWindow') nuevaNominaWindow: TemplateRef<any>;
  nuevaNominaRef: NbWindowRef;
  constructor(private nominaService: NominasService, private windowService: NbWindowService) {
      this.nominaService.getAll().subscribe((nominas) => {
        this.nominaConfig.data = nominas;
    });
   }

   generarNomina() {
    this.nuevaNominaRef = this.windowService.open(this.nuevaNominaWindow, { title: 'Generar Nomina', context: {
      formConfig: this.nominaConfig.newForm,
    } });
  }
}
