import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { UserService } from '../../../@core/services/users.service';
import { User } from '../../../@core/data/usuarios-data';
import { OptionType } from '../../../form-builder/form-builder.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent{
  userForm: FormGroup;
  profileImage: string | ArrayBuffer | null = null;
  user: User;
  userFormConfig = [];
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: NbToastrService,
    private userService: UserService,
    private router: Router,

  ) {
    const userJson = localStorage.getItem('userLogged');
    this.user = userJson ? JSON.parse(userJson) : null;
    userService.getById(this.user.id).subscribe((user) => {
      this.userFormConfig =[
        {
          type: 'row',
          fields: {
            email: {
              type: 'input',
              label: 'Correo Electrónico',
              placeholder: 'Ejemplo',
              formControlName: 'email',
              validations: [Validators.email],
              col: 6,
              value: user.email,
            },
            photo:{
              type: 'file',
              label: 'Foto de perfil',
              formControlName: 'photo',
              options: [
                {key:OptionType.fileSelectTypes, value: 'image/*'},
              ],
              col: 6,
            }
          }
        },
        {
          type: 'row',
          fields: {
            password: {
              type: 'input',
              label: 'Contraseña',
              placeholder: 'Ejemplo',
              formControlName: 'password',
              col: 6,

            },
            confirmPassword: {
              type: 'input',
              label: 'Confirmar Contraseña',
              placeholder: 'Ejemplo',
              formControlName: 'confirmPassword',
              col: 6,
            },
          }
        }
      ]
    })
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('password')?.value;
    const confirmPass = group.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.userForm.patchValue({ photo: file });
  }

  controls(){
    return this.userForm.controls;
  }

  onSubmit(form:FormGroup) {
    if (form) {
      if (form.get('password')?.value === ''||form.get('password')?.value === null) {
        form.removeControl('password');
      }else{
        const pass = form.get('password')?.value;
        const confirmPass = form.get('confirmPassword')?.value;
        if(pass!==confirmPass){
          this.toastrService.danger('Las contraseñas no coinciden', 'Error');
          return;
        }
      }
      this.userService.update(form,this.user.id).subscribe((user)=>{
        this.toastrService.success('Perfil actualizado con éxito', 'Éxito');
        if(form.get('photo')?.value!=null){
          this.userService.uploadPhoto(form.get('photo')?.value,this.user.id).subscribe(()=>{
            this.toastrService.success('Foto de perfil actualizada con éxito', 'Éxito');
            this.router.navigate(['/pages/dashboard']);
          },error=>{
            this.toastrService.danger('Error al subir la foto de perfil', 'Error');
            this.router.navigate(['/pages/dashboard']);
          });
        }else{
          this.router.navigate(['/pages/dashboard']);
        }
      })

    } else {
      this.toastrService.danger('Por favor, corrija los errores en el formulario', 'Error');
    }
  }
}
