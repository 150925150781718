import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Seguro } from '../data/vehiculo-data';

@Injectable()
export class SeguroService extends BaseService<Seguro> {
  protected getPath(): string {
    return 'seguros';
  }
}
