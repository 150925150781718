<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <label class="text-danger">* Campos requeridos</label>
  <ng-container *ngFor="let row of config">
    <div class="row" *ngIf="row.type === 'row'">
      <ng-container *ngFor="let key of getKeys(row.fields)">
        <div [ngClass]="'col-lg-' + (row.fields[key].col || 12) + ' col-md-' + (row.fields[key].col || 12) + ' col-sm-12 col-xs-12'">
          <div [ngSwitch]="row.fields[key].type" class="form-group">
            <div class="form-group-label">
              <label [for]="key">
                {{ getLabel(row,key) }}
                {{ row.fields[key].validations?.includes('required') ? '*' : '' }}
              </label>
            </div>

              <ng-container [ngSwitch]="row.fields[key].type">
                <input *ngSwitchCase="'input'" nbInput
                  [id]="key"
                  [formControlName]="row.fields[key].formControlName"
                  [attr.placeholder]="row.fields[key].placeholder || key"
                  fieldSize="small"
                  [name]="row.fields[key].formControlName"
                  fullWidth
                  status="control">
              </ng-container>
              <ng-container *ngSwitchCase="'select'">
                <nb-select [id]="key"
                  [formControlName]="row.fields[key].formControlName"
                  [placeholder]="row.fields[key].placeholder || key"
                  status="control"
                  fieldSize="small"
                  fullWidth
                  [multiple]="row.fields[key].multiple || false"
                  [name]="row.fields[key].formControlName">
                  <nb-option *ngFor="let option of getOption('select-options', row.fields[key])"
                    [value]="option.key">{{ option.value }}</nb-option>
                </nb-select>
              </ng-container>

              <ng-container *ngSwitchCase="'select-async'">
                <nb-select [id]="key"
                  [formControlName]="row.fields[key].formControlName"
                  [placeholder]="row.fields[key].placeholder || key"
                  status="control"
                  fieldSize="small"
                  fullWidth
                  [name]="row.fields[key].formControlName">
                  <nb-option *ngFor="let option of getAsyncData('select-options', row.fields[key]) | async"
                    [value]="option.key">{{ option.value }}</nb-option>
                </nb-select>
              </ng-container>

              <textarea *ngSwitchCase="'textarea'" nbInput
                [id]="key"
                [name]="row.fields[key].formControlName"
                [formControlName]="row.fields[key].formControlName"
                [attr.placeholder]="row.fields[key].placeholder || key"
                fieldSize="small"
                fullWidth
                status="control"></textarea>

              <nb-checkbox *ngSwitchCase="'checkbox'"
                [id]="key"
                [formControlName]="row.fields[key].formControlName"
                [name]="row.fields[key].formControlName"
                status="control">
                {{ form.get(row.fields[key].formControlName).value
                   ? getOption(optionTypes.labelSi, row.fields[key])
                   : getOption(optionTypes.labelNo, row.fields[key]) }}
              </nb-checkbox>

              <ng-container *ngSwitchCase="'multiple-checkbox'">
                <div [formGroupName]="key">
                  <div *ngFor="let option of getOption(optionTypes.CheckboxOptions, row.fields[key])">
                    <nb-checkbox
                      [id]="option.key"
                      [formControlName]="option.key"
                      [name]="option.key"
                      status="control">
                      {{ option.value }}
                    </nb-checkbox>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'date'">
                <input nbInput
                  [id]="key"
                  [formControlName]="row.fields[key].formControlName"
                  [attr.placeholder]="row.fields[key].placeholder || key"
                  fieldSize="small"
                  status="control"
                  [name]="row.fields[key].formControlName"
                  [nbDatepicker]="datepicker"
                  fullWidth>
                <nb-datepicker #datepicker [adjustment]="'clockwise'"></nb-datepicker>
              </ng-container>

              <ng-container *ngSwitchCase="fieldType.File">
                <input nbInput
                  [id]="key"
                  [formControlName]="row.fields[key].formControlName"
                  [attr.placeholder]="row.fields[key].placeholder || key"
                  status="control"
                  [name]="row.fields[key].formControlName"
                  [accept]="getOption(optionTypes.fileSelectTypes, row.fields[key])"
                  [multiple]="row.fields[key].multiple || false"
                  type="file"
                  fieldSize="small"
                  (change)="onFileSelected($event, key)"
                  fullWidth>
              </ng-container>

              <ng-container *ngSwitchCase="fieldType.Radio">
                <nb-radio-group
                  [id]="row.fields[key].formControlName"
                  [formControlName]="row.fields[key].formControlName"
                  status="control"
                  [name]="row.fields[key].formControlName"
                  fullWidth>
                  <nb-radio *ngFor="let option of getOption(optionTypes.RadioOptions, row.fields[key])"
                    [value]="option.value">{{ option.label }}</nb-radio>
                </nb-radio-group>
              </ng-container>

              <input *ngSwitchCase="fieldType.Number" nbInput
                [name]="row.fields[key].formControlName"
                [id]="key"
                [formControlName]="row.fields[key].formControlName"
                [attr.placeholder]="row.fields[key].placeholder || key"
                status="control"
                type="number"
                ngxNoScroll
                fieldSize="small"
                (input)="onNumberChange($event, key)"
                fullWidth>

              <ng-container *ngSwitchCase="fieldType.DateTime">
                <input nbInput
                  [name]="row.fields[key].formControlName"
                  [id]="key"
                  [formControlName]="row.fields[key].formControlName"
                  [attr.placeholder]="row.fields[key].placeholder || key"
                  status="control"
                  [nbDatepicker]="dateTimePicker"
                  [step]="10"
                  fieldSize="small"
                  fullWidth>
                <nb-date-timepicker [adjustment]="'clockwise'" singleColumn #dateTimePicker></nb-date-timepicker>
              </ng-container>

            <div *ngIf="form.get(row.fields[key].formControlName).invalid && form.get(row.fields[key].formControlName).touched" class="error-container mt-2">
              <nb-alert status="danger" outline="danger" class="p-2">
                <p class="mb-1">Este campo no es válido. Por favor, revíselo.</p>
                <ul class="pl-3 mb-0">
                  <li *ngFor="let error of form.get(row.fields[key].formControlName).errors | keyvalue">
                    <ng-container [ngSwitch]="error.key">
                      <ng-container *ngSwitchCase="'required'">Este campo es obligatorio.</ng-container>
                      <ng-container *ngSwitchCase="'email'">Ingrese un correo electrónico válido.</ng-container>
                      <ng-container *ngSwitchCase="'minlength'">Debe contener al menos {{error.value.requiredLength}} caracteres.</ng-container>
                      <ng-container *ngSwitchCase="'maxlength'">No debe exceder {{error.value.requiredLength}} caracteres.</ng-container>
                      <ng-container *ngSwitchCase="'min'">El valor mínimo permitido es {{error.value.min}}.</ng-container>
                      <ng-container *ngSwitchCase="'max'">El valor máximo permitido es {{error.value.max}}.</ng-container>
                      <ng-container *ngSwitchDefault>Error de validación: {{error.key}}</ng-container>
                    </ng-container>
                  </li>
                </ul>
              </nb-alert>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <button type="submit" class="btn btn-primary">Guardar</button>
</form>
