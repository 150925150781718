<form [formGroup]="nuevaCotizacionForm" (ngSubmit)="onNuevaCotizacionSubmit()">
  <nb-card>
    <nb-card-header>
      <h2>Crear Nueva Cotización</h2>
      <p>Complete los campos a continuación para crear una nueva cotización.</p>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="folio">Folio</label>
            <input nbInput fullWidth id="folio" formControlName="folio" placeholder="Folio" required>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="cliente">Cliente</label>
            <nb-select fullWidth id="cliente" required formControlName="cliente_id" placeholder="Selecciona un cliente" [nbSpinner]="clienteLoading">
              <nb-option *ngFor="let cliente of clientes" [value]="cliente.id">{{cliente.nombre}}</nb-option>
            </nb-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="empleado">Empleado que atendió</label>
            <nb-select required fullWidth id="empleado" formControlName="empleado_id" placeholder="Selecciona un empleado" [nbSpinner]="empleadoLoading">
              <nb-option *ngFor="let empleado of empleados" [value]="empleado.id">{{empleado.nombre}} {{empleado.apellido_paterno}} {{empleado.apellido_materno}}</nb-option>
            </nb-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="de">De</label>
            <nb-select required fullWidth id="de" formControlName="de" placeholder="Selecciona una opción">
              <nb-option value="FISICA">Física</nb-option>
              <nb-option value="MORAL">Moral</nb-option>
            </nb-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="a">A</label>
            <nb-select  required fullWidth id="a" formControlName="a" placeholder="Selecciona una opción">
              <nb-option value="FISICA">Física</nb-option>
              <nb-option value="MORAL">Moral</nb-option>
            </nb-select>
          </div>
        </div>
        <div class="col-md-4">
          <label for="factura">¿Requiere factura?</label>
          <div class="form-group">
            <nb-checkbox formControlName="factura">
              {{nuevaCotizacionForm.get('factura').value ? 'Sí' : 'No'}}
            </nb-checkbox>
          </div>
        </div>
      </div>
    <div class="row">
      
    </div>
    </nb-card-body>
  </nb-card>
</form>
<nb-card>
  <nb-card-body>
    <ngx-cotizacion-maquinarias-table [conceptos]="maquinaria"></ngx-cotizacion-maquinarias-table>
  </nb-card-body>
</nb-card>
<button  nbButton nbTooltip="Agregar concepto" size="small" status="primary" (click)="addRow()">
  <nb-icon icon="plus-outline"></nb-icon> Agregar concepto
</button>
<button nbButton nbTooltip="Agregar maquinaria" size="small" status="primary" (click)="onNuevaCotizacionSubmit()">
  <nb-icon icon="plus-outline"></nb-icon> Guardar cotizacion
</button>


