import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiculosTableComponent } from './vehiculos-table/vehiculos-table.component';
import { NbAccordionModule, NbButton, NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbSpinnerModule, NbTooltipModule, NbTreeGridModule } from '@nebular/theme';
import { VehiculosComponent } from './vehiculos.component';
import { VehiculosFormsModule } from './vehiculos-forms/vehiculos-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FormBuilderModule } from '../../form-builder/form-builder.module';
import { MatTableBuilderModule } from '../../mat-table-builder/mat-table-builder.module';
import { VehiculosMantenimientoTableComponent } from './vehiculos-mantenimiento-table/vehiculos-mantenimiento-table.component';
import { FilterValueDateModule } from '../../forms/filter-value-date/filter-value-date.module';

@NgModule({
  declarations: [
    VehiculosTableComponent,
    VehiculosComponent,
    VehiculosMantenimientoTableComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbAccordionModule,
    NbIconModule,
    VehiculosFormsModule,
    NbTreeGridModule,
    ReactiveFormsModule,
    FormsModule,
    NbInputModule,
    NbTooltipModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    NbButtonModule,
    MatSortModule,
    FormBuilderModule,
    MatTableBuilderModule,
    NbSpinnerModule,
    FilterValueDateModule
  ]
})
export class VehiculosModule { }
