<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>Finiquitos <button nbButton [disabled]="disabledNewButton" nbTooltip="Nuevo finiquito" [nbSpinner]="disabledNewButton" (click)="onNuevoClick()">
      <nb-icon  icon="plus"></nb-icon>
    </button></div>
    <div><input nbInput type="text" (keyup)="filter($event.target.value)" placeholder="Buscar finiquito"></div>
  </nb-card-header>
  <nb-card-body>
    <ngx-finiquitos-table [data]="dataFiltered"></ngx-finiquitos-table>
  </nb-card-body>
</nb-card>

<ng-template #newWindow let-data>
  <div class="responsive-div">
    <ngx-form-builder [config]="data.formConfig" (formOuput)="onCreated($event)"></ngx-form-builder>
  </div>
</ng-template>

<ng-template #editWindow let-data>
  <div class="responsive-div">
    <ngx-manage-documents [contrato]="data.contrato"></ngx-manage-documents>
  </div>
</ng-template>
