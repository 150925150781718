import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Entrevista } from '../../@core/data/rh-data';
import { EntrevistasService } from '../../@core/services/entrevistas.service';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { FormBuilderConfig, FormFieldType, OptionType } from '../../form-builder/form-builder.component';
import { FormGroup } from '@angular/forms';
import { removeEmptyControls } from '../../@core/utils/forms-util';
import * as moment from 'moment-timezone';
@Component({
  selector: 'ngx-entrevistas',
  templateUrl: './entrevistas.component.html',
  styleUrls: ['./entrevistas.component.scss']
})
export class EntrevistasComponent implements OnInit {
  entrevistas: Entrevista[] = [];
  entrevistasFiltradas: Entrevista[] = [];
  @ViewChild('nuevaEntrevistaWindow',{ read: TemplateRef }) nuevaEntrevistaWindow: TemplateRef<HTMLElement>;
  clienteWindowRef: NbWindowRef;
  formConfig: FormBuilderConfig = [
    {
      type: FormFieldType.Row,
      fields: {
        nombre: {
          type: FormFieldType.Input,
          validations: [ 'required' ],
          col: 4,
          label: 'Nombre',
          placeholder: 'Ejemplo: Juan',
          formControlName: 'nombre'
        },
        apellido_paterno: {
          type: FormFieldType.Input,
          validations: [ 'required' ],
          col: 4,
          label: 'Apellido Paterno',
          placeholder: 'Ejemplo: Perez',
          formControlName: 'apellido_paterno'
        },apellido_materno: {
          type: FormFieldType.Input,
          validations: [ 'required' ],
          col: 4,
          label: 'Apellido Materno',
          placeholder: 'Ejemplo: Lopez',
          formControlName: 'apellido_materno'
        },
      }
    },
    {
      type: FormFieldType.Row,
      fields: {
        fecha_nacimiento: {
          type: FormFieldType.Date,
          validations: [ 'required' ],
          col: 6,
          label: 'Fecha de Nacimiento',
          formControlName: 'fecha_nacimiento'
        },
        telefono: {
          type: FormFieldType.Number,
          validations: [ 'required', 'minlength:10', 'maxlength:10' ],
          col: 6,
          label: 'Telefono',
          placeholder: 'Telefono del candidato',
          formControlName: 'telefono'
        },
      }
    },
    {
      type: FormFieldType.Row,
      fields: {
        puesto: {
          type: FormFieldType.Input,
          validations: [ 'required' ],
          col: 6,
          label: 'Puesto',
          placeholder: 'Ejemplo: Gerente',
          formControlName: 'puesto'
        },
        sueldo_solicitado: {
          type: FormFieldType.Number,
          validations: [ 'required' ],
          col: 6,
          label: 'Sueldo Solicitado',
          placeholder: 'Sueldo que solicita el candidato',
          formControlName: 'sueldo_solicitado'
        }
      }
    },
    {
      type: FormFieldType.Row,
      fields:{
        grado_estudios: {
          type: FormFieldType.Select,
          validations: [ 'required' ],
          col: 6,
          label: 'Grado de Estudios',
          placeholder: 'Ejemplo: Licenciatura',
          formControlName: 'grado_estudios',
          options: [
            {key:OptionType.SelectOptions,value:[
              {value:'Prepa',key:'PREPA'},
              {value:'Tecnica',key:'TECNICA'},
              {value:'Licenciatura',key:'LICENCIATURA'},
              {value:'Maestria',key:'MAESTRIA'},
            ]}
          ]
        },
        documento_estudios: {
          type: FormFieldType.File,
          validations: [ 'required' ],
          col: 6,
          label: 'Documento',
          formControlName: 'documento_estudios',
          options: [
            {key:OptionType.fileSelectTypes,value:'application/pdf' }
          ]
        },
      }
    },
    {
      type: FormFieldType.Row,
      fields: {
        referencia_interna: {
          type: FormFieldType.Input,
          validations: [ 'required' ],
          col: 6,
          label: 'Referencia Interna',
          placeholder: 'Referencia interna del candidato',
          formControlName: 'referencia_interna'
        },
        contacto_referencia: {
          type: FormFieldType.Input,
          validations: [ 'required' ],
          col: 6,
          label: 'Contacto de Referencia',
          placeholder: 'Contacto',
          formControlName: 'contacto_referencia'
        }
      }
    },
    {
      type: FormFieldType.Row,
      fields: {
        fecha_entrevista: {
          type: FormFieldType.DateTime,
          validations: [ 'required' ],
          col: 12,
          label: 'Fecha de Entrevista',
          placeholder: 'Fecha de Entrevista',
          formControlName: 'fecha_entrevista'
        },
      }
    },
    {
      type: FormFieldType.Row,
      fields: {
        apariencia:{
          type: FormFieldType.Radio,
          validations: [ 'required' ],
          col: 4,
          label: 'Apariencia',
          formControlName: 'apariencia',
          options: [
            {key:OptionType.RadioOptions,value:[
              {label:'Excelente',value:'EXCELENTE'},
              {label:'Buena',value:'BUENA'},
              {label:'Regular',value:'REGULAR'},
              {label:'Mala',value:'MALA'},
            ]}
          ]
        },
        facilidad_palabra:{
          type: FormFieldType.Radio,
          validations: [ 'required' ],
          col: 4,
          label: 'Facilidad de Palabra',
          formControlName: 'facilidad_palabra',
          options: [
            {key:OptionType.RadioOptions,value:[
              {label:'Excelente',value:'EXCELENTE'},
              {label:'Buena',value:'BUENA'},
              {label:'Regular',value:'REGULAR'},
              {label:'Mala',value:'MALA'},
            ]}
          ]
        },
        contratable: {
          type: FormFieldType.Radio,
          validations: [ 'required' ],
          col: 4,
          label: 'Contratable',
          formControlName: 'contratable',
          options: [
            {key:OptionType.RadioOptions,value:[
              {label:'Si',value:1},
              {label:'No',value:0}
            ]}
          ]
        },
      }
    },
    {
      type: FormFieldType.Row,
      fields:{
        fecha_inicio_lic:{
          type: FormFieldType.Date,
          col: 4,
          label: 'Inicio de licencia',
          placeholder: 'Vigencia',
          formControlName: 'fecha_inicio_lic',
        },
        fecha_fin_lic:{
          type: FormFieldType.Date,
          col: 4,
          label: 'Fin de licencia',
          placeholder: 'Vigencia',
          formControlName: 'fecha_fin_lic',
        },
        tipo_licencia: {
          type: FormFieldType.Select,
          col: 4,
          label: 'Tipo de Licencia',
          placeholder: 'Tipo de Licencia',
          formControlName: 'tipo_licencia',
          value: null,
          options: [
            {key:OptionType.SelectOptions,value:[
              {key:'A',value:'A'},
              {key:'B',value:'B'},
              {key:'C',value:'C'},
              {key:'D',value:'D'},
              {key:'E',value:'E'},
              {key:'AUTOMOVILISTA',value:'Automovilista'},
              {key:'CHOFER',value:'Chofer'},
              {key:null,value:'Ninguna'}
            ]}
          ]
        }
      }
    },
    {
      type: FormFieldType.Row,
      fields: {
        direccion: {
          type: FormFieldType.TextArea,
          validations: [ 'required' ],
          col: 12,
          label: 'Direccion',
          placeholder: 'Direccion del entrevistado',
          formControlName: 'direccion'
        },
      }
    },
    {
      type: FormFieldType.Row,
      fields: {
        comentarios: {
          type: FormFieldType.TextArea,
          validations: [ 'required' ],
          col: 12,
          label: 'Comentarios',
          placeholder: 'Comentarios de la entrevista',
          formControlName: 'comentarios'
        },
      }
    }
  ]
  columns: string[] = ['fecha_entrevista', 'id', 'empleado_id', 'nombre', 'apellido_paterno', 'apellido_materno', 'telefono', 'puesto', 'contratable', 'comentarios', 'created_at', 'fecha_nacimiento', 'direccion', 'sueldo_solicitado', 'grado_estudios', 'referencia_interna', 'contacto_referencia', 'apariencia', 'facilidad_palabra', 'tipo_licencia', 'fecha_inicio_lic', 'fecha_fin_lic', 'acciones'];
  constructor(private entrevistasService:EntrevistasService,
    private windowsService: NbWindowService, private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.entrevistasService.getAll().subscribe(entrevistas => {
    this.entrevistas = entrevistas;
    this.entrevistasFiltradas = entrevistas;
    });
  }

  filter(value: any) {
    return this.entrevistasFiltradas = this.entrevistas.filter((entrevista) => {
      return entrevista.nombre.toLowerCase().includes(value.toLowerCase())
      || entrevista.telefono.toLowerCase().includes(value.toLowerCase())
      || entrevista.nombre.toLowerCase().includes(value.toLowerCase())
      || entrevista.apellido_materno.toLowerCase().includes(value.toLowerCase())
      || entrevista.apellido_paterno.toLowerCase().includes(value.toLowerCase())
      || entrevista.puesto.toLowerCase().includes(value.toLowerCase());

    });
  }
  onNuevoClick() {
    this.clienteWindowRef = this.windowsService.open(
      this.nuevaEntrevistaWindow, { title: 'Nueva Entrevista'});
  }

  onEntrevistaCreated(form:FormGroup) {
    removeEmptyControls(form);
    this.entrevistasService.create(form).subscribe(() => {
      this.clienteWindowRef.close();
      this.toastrService.show('Entrevista creada', 'Nueva Entrevista', { status: 'success' });
      this.entrevistasService.getAll().subscribe(entrevistas => {
        this.entrevistas = entrevistas;
        this.entrevistasFiltradas = entrevistas;
      });
    });


  }


}
