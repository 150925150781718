<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <div>
      Empleados
      <button nbButton status="success" nbTooltip="Nuevo Empleado" size="small" (click)="onNewDataClick()">
        <nb-icon  icon="plus"></nb-icon>
      </button>
      <button nbButton status="info" nbTooltip="Descargar Reporte" size="small" (click)="onDownloadReportClick()">
        <nb-icon icon="download"></nb-icon>
      </button>
    </div>
    <div><input nbInput type="text" (keyup)="filter($event.target.value)" placeholder="Buscar empleado"></div>
  </nb-card-header>
  <nb-card-body>
    <ngx-mat-table-builder [columnsDef]="columns" [data]="dataSource" [actions]="acciones"></ngx-mat-table-builder>
  </nb-card-body>
</nb-card>

<ng-template let-data #newDataFormView>
  <div class="responsive-div" >
    <ngx-form-builder [config]="data.config" (formOuput)="onFormOutput($event)"></ngx-form-builder>
  </div>
</ng-template>
