import { ExpedienteUnicoService } from "./../../../../@core/services/expediente-unico.service";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TipoDocumentoExpediente } from "../../../../@core/data/tipo-documento-expediente";
import { TipoDocumentoExpedienteService } from "../../../../@core/services/tipo-documento-expediente-service.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ExpedienteUnico } from "../../../../@core/data/expediente-unico-data";
import { NbStepperComponent, NbWindowRef, NbWindowService } from "@nebular/theme";
import { ExpedienteUnicoDoc } from "../../../../@core/data/expediente-unico-doc-data";
import { ExpedienteUnicoDocService } from "../../../../@core/services/expediente-unico-doc.service";

@Component({
  selector: "ngx-nuevo-expediente-stepper",
  templateUrl: "./nuevo-expediente-stepper.component.html",
  styleUrls: ["./nuevo-expediente-stepper.component.scss"],
})
export class NuevoExpedienteStepperComponent implements OnInit {
  docsExpediente: ExpedienteUnicoDoc[] = [];
  nuevoExpedienteForm: FormGroup;
  uploadFilesForm: FormGroup;
  nuevoExpediente: ExpedienteUnico;
  @ViewChild("stepper") stepper: NbStepperComponent;

  constructor(
    private expedienteDocsService: ExpedienteUnicoDocService,
    private fb: FormBuilder,
    private expedienteUnicoService: ExpedienteUnicoService,
  ) {}

  ngOnInit(): void {
    this.nuevoExpedienteForm = this.fb.group({
      contrato: ["", Validators.required],
      descripcion: ["", Validators.required],
      entrega: ["", Validators.required],
      cargo_entrega: ["", Validators.required],
      recibe: ["", Validators.required],
      cargo_recibe: ["", Validators.required],
    });
    this.uploadFilesForm = this.fb.group({
      files: ["", Validators.required],
    });
  }

  onNuevoExpedienteSubmit() {
    this.expedienteUnicoService.create(this.nuevoExpedienteForm).subscribe(expediente => {
      this.nuevoExpediente = expediente;
      this.expedienteDocsService.filterByIdExpediente(this.nuevoExpediente.id).subscribe((docs) => {
        this.docsExpediente = docs;
        this.stepper.next();
      });
    });

  }

}
