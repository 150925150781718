import { MatTableActionConfig, MatTableColumnConfig } from './../../mat-table-builder/mat-table-builder.component';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Licencia } from '../../@core/data/rh-data';
import { FormBuilderConfig, FormFieldType, FormRowConfig, OptionType } from '../../form-builder/form-builder.component';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Empleado } from '../../@core/data/cotizacion-data';
import { LicenciasService } from '../../@core/services/licencias.service';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { FormGroup } from '@angular/forms';
import { CSVGenerator } from '../../@core/utils/csv-generator';

@Component({
  selector: 'ngx-licencias',
  templateUrl: './licencias.component.html',
  styleUrls: ['./licencias.component.scss']
})
export class LicenciasComponent implements OnInit {
  data:Licencia[] = [];
  filteredData:Licencia[] = [];
  empleados: Empleado[] = [];
  @ViewChild("newDataFormView", { read: TemplateRef })
  newDataFormView: TemplateRef<HTMLElement>;
  newDataWindowRef: NbWindowRef;
  columns:MatTableColumnConfig[] = [
    {name:'id',label:'ID',type:'number'},
    {name:'empleado_nombre',label:'Nombre',type:'string',value:(row:Licencia)=>{
      return `${row.empleado_nombre} ${row.empleado_apellido_paterno} ${row.empleado_apellido_materno}`;
      }
    },
    {name:'lugar_expedicion',label:'Lugar Expedición',type:'string'},
    {name:'fecha_inicio',label:'Fecha Inicio vigencia',type:'date'},
    {name:'fecha_fin',label:'Fecha Fin vigencia',type:'date'},
    {name:'estatus',label:'Estatus',type:'boolean',
      value: (row:Licencia)=>{
        return row.fecha_fin > new Date().toISOString();
      }
    },
    {name:'tipo',label:'Tipo',type:'string'},
  ];
  formConfig:FormBuilderConfig =[]
  acciones: MatTableActionConfig[] = [
    {
      name: "Descargar",
      icon: "download-outline",
      label: "Descargar licencia",
      action: (row) => {
        this.licenciaService.downloadDoc(row.id).subscribe((data) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      },
    },
    {
      name: "edit",
      label: "Editar",
      icon: "edit-2-outline",
      action: (form:FormGroup,row:Licencia,ref:NbWindowRef) => {
        this.licenciaService.update(form,row.id).subscribe(data=>{
          this.toastsService.success("Licencia actualizada","Licencia actualizada correctamente");
          if(form.get("file").value!=null){
            this.licenciaService.uploadDoc(row.id,form.get("file").value).subscribe(data=>{
              this.toastsService.success("Archivo subido","Archivo subido correctamente");
            },(error)=>{
              this.toastsService.danger(error.message,"Error al subir archivo");
            });
          }

          this.licenciaService.getAll().subscribe(data=>{
            this.data = data;
            this.filteredData = data;
          });
        });
        ref.close();
      },
      formConfig: (l: Licencia): FormRowConfig[] => {
        return [
          {
            type: FormFieldType.Row,
            fields:{
              empleado_id: {
                type: FormFieldType.Select,
                col: 6,
                options: [
                  {key: OptionType.SelectOptions, value: this.empleados.map((empleado) => ({ key: empleado.id, value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}` }))},
                ],
                formControlName: "empleado_id",
                label: "Empleado",
                placeholder: "Seleccione un empleado",
                value: l.empleado_id,
              },
              tipo:{
                type: FormFieldType.Select,
                col: 6,
                options: [
                  {key: OptionType.SelectOptions, value: [{key:"A",value:"A"},{key:"B",value:"B"},{key:"C",value:"C"},{key:"D",value:"E"},{key:"CHOFER",value:"CHOFER"},{key:"AUTOMOVILISTA",value:"AUTOMOVILISTA"}]},
                ],
                formControlName: "tipo",
                label: "Tipo",
                placeholder: "Seleccione un tipo de licencia",
                value: l.tipo
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields:{
              fecha_inicio: {
                type: FormFieldType.Date,
                col: 6,
                formControlName: "fecha_inicio",
                label: "Fecha Inicio",
                placeholder: "Fecha de inicio",
                value: l.fecha_inicio,
              },
              fecha_fin: {
                type: FormFieldType.Date,
                col: 6,
                validations: ["required"],
                formControlName: "fecha_fin",
                label: "Fecha Fin",
                placeholder: "Fecha fin vigencia",
                value: l.fecha_fin,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields:{
              lugar_expedicion: {
                type: FormFieldType.Input,
                col: 12,
                formControlName: "lugar_expedicion",
                label: "Lugar de expedición",
                placeholder: "Lugar de expedición",
                value: l.lugar_expedicion,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields:{
              file: {
                type: FormFieldType.File,
                col: 12,
                formControlName: "file",
                label: "Licencia",
                placeholder: "Seleccione un archivo",
                options:[{key:OptionType.fileSelectTypes,value:"application/pdf"}]
              },
            }
          }
        ]
      },

    },
    {
      name: "delete",
      label: "Eliminar",
      icon: "trash-2-outline",
      message: "¿Estás seguro de que deseas eliminar la licencia?",
      title: "Eliminar licencia",
      action: (row) => {
        this.licenciaService.delete(row.id).subscribe(data=>{
          this.toastsService.success("Licencia eliminada","Licencia eliminada correctamente");
          this.licenciaService.getAll().subscribe(data=>{
            this.data = data;
            this.filteredData = data;
          });
        });
      },
    }
  ];
  constructor(private empleadosService:EmpleadoService,
    private licenciaService:LicenciasService,private windowService:NbWindowService,
    private toastsService: NbToastrService
  ){}

  ngOnInit(): void {
    this.licenciaService.getAll().subscribe((data)=>{
      this.data = data;
      this.filteredData = data;
    });
    this.empleadosService.getAll().subscribe((data)=>{
      this.empleados = data;
      this.formConfig=[
        {
          type: FormFieldType.Row,
          fields:{
            empleado_id: {
              type: FormFieldType.Select,
              col: 6,
              // options: [
              //   {key: OptionType.SelectOptions, value:
              //     this.empleadosService.getAll(),map: (data) => {
              //       return data.map((empleado) => ({ key: empleado.id, value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}` }));
              //     }
              //   },
              // ],
              options: [
                  {key: OptionType.SelectOptions, value: this.empleados.map((empleado) => ({ key: empleado.id, value: `${empleado.nombre} ${empleado.apellido_paterno} ${empleado.apellido_materno}` }))
                  },
                ],
              validations: ["required"],
              formControlName: "empleado_id",
              label: "Empleado",
              placeholder: "Seleccione un empleado",
            },
            tipo:{
              type: FormFieldType.Select,
              col: 6,
              options: [
                {key: OptionType.SelectOptions, value: [{key:"A",value:"A"},{key:"B",value:"B"},{key:"C",value:"C"},{key:"D",value:"D"},
                  {key:'E',value:'E'},{key:'CHOFER',value:'CHOFER'},{key:'AUTOMOVILISTA',value:'AUTOMOVILISTA'}
                ]},
              ],
              validations: ["required"],
              formControlName: "tipo",
              label: "Tipo",
              placeholder: "Seleccione un tipo de licencia",
            },
          }
        },
        {
          type: FormFieldType.Row,
          fields:{
            fecha_inicio: {
              type: FormFieldType.Date,
              col: 6,
              validations: ["required"],
              formControlName: "fecha_inicio",
              label: "Fecha Inicio de vigencia",
              placeholder: "Fecha de inicio",
            },
            fecha_fin: {
              type: FormFieldType.Date,
              col: 6,
              validations: ["required"],
              formControlName: "fecha_fin",
              label: "Fecha Fin de vigencia",
              placeholder: "Fecha de fin",
            },
          }
        },
        {
          type: FormFieldType.Row,
          fields:{
            lugar_expedicion: {
              type: FormFieldType.Input,
              col: 12,
              validations: ["required"],
              formControlName: "lugar_expedicion",
              label: "Lugar de expedición",
              placeholder: "Lugar de expedición",
            },
          }
        },
        {
          type: FormFieldType.Row,
          fields:{

            file: {
              type: FormFieldType.File,
              col: 12,
              validations: ["required"],
              formControlName: "file",
              label: "Licencia",
              placeholder: "Seleccione un archivo",
              options:[{key:OptionType.fileSelectTypes,value:"application/pdf"}]
            },
          }
        }
      ]
    })
  }
  onNewDataClick() {
    this.newDataWindowRef= this.windowService.open(this.newDataFormView, { title: 'Nueva Licencia' });
  }

  filter(search: string) {
    this.filteredData = this.data.filter(row => {
      return row.empleado_nombre.toLowerCase().includes(search.toLowerCase()) ||
        row.empleado_apellido_paterno.toLowerCase().includes(search.toLowerCase()) ||
        row.empleado_apellido_materno.toLowerCase().includes(search.toLowerCase());
    });
  }
  onFormOutput(form:FormGroup){
    this.licenciaService.create(form).subscribe(data=>{
      this.toastsService.success("Licencia creada","Licencia creada correctamente");
      this.newDataWindowRef.close();
      this.licenciaService.getAll().subscribe(data=>{
        this.data = data;
        this.filteredData = data;
      });
    });
  }

  onClickGenerateReport(){
    CSVGenerator.generateExcel(this.columns,this.filteredData,"licencias");
  }
}
