import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpedientesUnicosComponent } from './expedientes-unicos.component';
import { ExpedientesUnicosFormsModule } from "./expedientes-unicos-coponents/expedientes-unicos-forms.module";
import { ExpedienteUnicoService } from '../../@core/services/expediente-unico.service';
import { NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ExpedientesUnicosComponent
  ],
  imports: [
    CommonModule,
    ExpedientesUnicosFormsModule,
    NbCardModule,
    NbIconModule,
    ReactiveFormsModule,
    FormsModule,
    NbInputModule,
  ],
  providers: [
    ExpedienteUnicoService,
  ],
})
export class ExpedientesUnicosModule { }
