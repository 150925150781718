import { FormBuilderConfig } from './../../form-builder/form-builder.component';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NbToastrService, NbTreeGridDataSourceBuilder, NbWindowRef, NbWindowService } from '@nebular/theme';
import { Equipo } from '../../@core/data/equipo-data';
import { EquiposService } from '../../@core/services/equipos.service';
import { MatTableActionConfig, MatTableColumnConfig } from '../../mat-table-builder/mat-table-builder.component';
import { FormGroup } from '@angular/forms';
import { FormFieldType, OptionType } from '../../form-builder/form-builder.component';
import { Obra } from '../../@core/data/maquinaria-data';
import { ObraService } from '../../@core/services/obra.service';
import { EmpleadoService } from '../../@core/services/empleado.service';
import { Empleado } from '../../@core/data/cotizacion-data';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ngx-equipos',
  templateUrl: './equipos.component.html',
  styleUrls: ['./equipos.component.scss']
})
export class EquiposComponent {
  equipos: Equipo[];
  dataFiltered: Equipo[];
  nuevoEquipoWindows: NbWindowRef;
  @ViewChild('nuevoEquipo', { read: TemplateRef }) equipoTemplate: TemplateRef<HTMLElement>;
  @ViewChild('mantenimientos', { read: TemplateRef }) mantenimientosTemplate: TemplateRef<HTMLElement>;
  mantenimientosRef: NbWindowRef;
  searchText: string = '';
  obras: Obra[];
  responsables: Empleado[];
  loadin: boolean = true;
  columnsDef: MatTableColumnConfig[] = [
    { name: 'id', label: 'ID', type: 'text' },
    { name: 'fecha_adquisicion', label: 'Fecha de adquisición', type: 'date' },
    { name: 'serie', label: 'Serie', type: 'text' },
    { name: 'modelo', label: 'Modelo', type: 'text' },
    { name: 'marca', label: 'Marca', type: 'text' },
    { name: 'obra', label: 'Obra', type: 'text' },
    { name: 'responsable', label: 'Responsable del equipo', type: 'text' },
    { name: 'tipo', label: 'Tipo de equipo', type: 'text' }
  ];

  actions: MatTableActionConfig[] = [
    {
      name: "evidencias",
      label: "Ver evidencias",
      icon: "eye",
      action: (e: Equipo) => {
        this.equiposService.downloadDocumento(e.id).subscribe((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'evidencia';
            a.click();
            window.URL.revokeObjectURL(url);

        }, (error) => {
          this.toastrService.danger('Error: ' + error.error.message, 'Error al descargar la evidencia');
        });
      }
    },
    {
      name: 'edit', icon: 'edit', label: 'Editar',
      formSize: 'medium',
      formConfig: (row: Equipo) => {
        return [
          {
            type: FormFieldType.Row,
            fields: {
              fecha_adquisicion: {
                formControlName: 'fecha_adquisicion',
                label: 'Fecha de adquisición',
                placeholder: 'Fecha de adquisición',
                value: row.fecha_adquisicion,
                col: 6,
                type: FormFieldType.Date,
              },
              serie: {
                formControlName: 'serie',
                label: 'Serie',
                placeholder: 'Serie',
                value: row.serie,
                col: 6,
                type: FormFieldType.Input,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              modelo: {
                formControlName: 'modelo',
                label: 'Modelo',
                placeholder: 'Modelo',
                value: row.modelo,
                col: 6,
                type: FormFieldType.Input,
              },
              marca: {
                formControlName: 'marca',
                label: 'Marca',
                placeholder: 'Marca',
                value: row.marca,
                col: 6,
                type: FormFieldType.Input,
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              obra_id: {
                formControlName: 'obra_id',
                label: 'Obra',
                placeholder: 'Obra',
                value: row.obra_id,
                col: 6,
                type: FormFieldType.Select,
                options: [
                  { key: OptionType.SelectOptions, value: this.obras?.map(o => ({ key: o.id, value: o.nombre })) || [] },
                ]
              },
              id_responsable: {
                formControlName: 'id_responsable',
                label: 'Responsable del equipo',
                placeholder: 'Responsable',
                value: row.id_responsable,
                col: 6,
                type: FormFieldType.Select,
                options: [
                  { key: OptionType.SelectOptions, value: this.responsables.map(r => ({ key: r.id, value: `${r.nombre} ${r.apellido_paterno} ${r.apellido_materno}` })) },
                ]
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              tipo: {
                formControlName: 'tipo',
                label: 'Tipo de equipo',
                placeholder: 'Tipo de equipo',
                value: row.tipo,
                col: 4,
                type: FormFieldType.Radio,
                options: [
                  { key: OptionType.RadioOptions, value: [
                    {value: 'COMPUTO', label: 'Computo'},
                    {value: 'MULTI', label: 'Multifuncional'},
                    {value: 'TOPOGRAFICO', label: 'Topografico'},
                  ]}
                ]
              },
              evidencia: {
                formControlName: 'evidencia',
                label: 'Evidencia',
                placeholder: 'Evidencia',
                col: 8,
                type: FormFieldType.File,
                options: [
                  { key: OptionType.fileSelectTypes, value: ['image/*'] },
                ]
              },
            }
          },
        ]
      },
      action: (form: FormGroup, equipo: Equipo, windowRef) => {
        this.equiposService.update(form, equipo.id).subscribe((data) => {
          this.toastrService.show('Equipo actualizado con éxito',
            'Equipo Actualizado', { status: 'success' });
          windowRef.close();

            if(form.get('evidencia').value!=null){
              this.equiposService.updateEvidencia(equipo.id, form.get('evidencia').value).subscribe(() => {
                this.toastrService.show('Evidencia actualizada con éxito',
                  'Evidencia Actualizada', { status: 'success' });
              }, (error) => {
                this.toastrService.danger('Error: ' + error.error.message, 'Error al actualizar la evidencia');
              });
            }

          this.equiposService.getAll().subscribe((data) => {
            this.equipos = data;
            this.dataFiltered = data;
          });
        }, (error) => {
          this.toastrService.danger('Error: ' + error.error.message, 'Error al actualizar el equipo');
        });
      }
    },
    {
      name: 'delete', icon: 'trash', label: 'Eliminar',
      message: '¿Está seguro que desea eliminar el equipo?',
      title: 'Eliminar equipo',
      action: (equipo: Equipo) => {
        this.equiposService.delete(equipo.id).subscribe(() => {
          this.equiposService.getAll().subscribe((data) => {
            this.equipos = data;
            this.dataFiltered = data;
          });
          this.toastrService.show('Equipo eliminado con éxito',
            'Equipo Eliminado', { status: 'success' });
        });
      }
    },
  ];

  constructor(
    private titleService: Title,
    private windowService: NbWindowService,
    private toastrService: NbToastrService,
    private equiposService: EquiposService,
    private obrasService: ObraService,
    private empleadosService: EmpleadoService,
    private windowsService: NbWindowService
  ) {
    this.titleService.setTitle('Equipos');
    this.equiposService.getAll().subscribe((data) => {
      this.equipos = data;
      this.dataFiltered = data;
    });

    forkJoin({
      obras: this.obrasService.getAll(),
      responsables: this.empleadosService.getAll()
    }).subscribe(({ obras, responsables }) => {
      this.obras = obras;
      this.responsables = responsables;
      this.loadin = false;
    });
  }

  onClickOpenNuevoEquipoForm() {
    let formConfig: FormBuilderConfig = [
      {
        type: FormFieldType.Row,
        fields: {
          fecha_adquisicion: {
            formControlName: 'fecha_adquisicion',
            label: 'Fecha de adquisición',
            placeholder: 'Fecha de adquisición',
            col: 6,
            type: FormFieldType.Date,
          },
          serie: {
            formControlName: 'serie',
            label: 'Serie',
            placeholder: 'Serie',
            col: 6,
            type: FormFieldType.Input,
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          modelo: {
            formControlName: 'modelo',
            label: 'Modelo',
            placeholder: 'Modelo',
            col: 6,
            type: FormFieldType.Input,
          },
          marca: {
            formControlName: 'marca',
            label: 'Marca',
            placeholder: 'Marca',
            col: 6,
            type: FormFieldType.Input,
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          obra_id: {
            formControlName: 'obra_id',
            label: 'Obra',
            placeholder: 'Obra',
            col: 6,
            type: FormFieldType.Select,
            options: [
              { key: OptionType.SelectOptions,
                value: this.obras.map(o => ({ key: o.id, value: o.nombre }))},
            ]
          },
          id_responsable: {
            formControlName: 'id_responsable',
            label: 'Responsable del equipo',
            placeholder: 'Responsable',
            col: 6,
            type: FormFieldType.Select,
            options: [
              { key: OptionType.SelectOptions, value: this.responsables.map(r => ({ key: r.id, value: `${r.nombre} ${r.apellido_paterno} ${r.apellido_materno}` })) },
            ]
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          tipo: {
            formControlName: 'tipo',
            label: 'Tipo de equipo',
            placeholder: 'Tipo de equipo',
            col: 4,
            type: FormFieldType.Radio,
            value: 'COMPUTO',
            options: [
              { key: OptionType.RadioOptions, value: [
                {value: 'COMPUTO', label: 'Computo'},
                {value: 'MULTI', label: 'Multifuncional'},
                {value: 'TOPOGRAFICO', label: 'Topografico'},
              ]}
            ]
          },
          evidencia: {
            formControlName: 'evidencia',
            label: 'Evidencia',
            placeholder: 'Evidencia',
            col: 8,
            type: FormFieldType.File,
            options: [
              { key: OptionType.fileSelectTypes, value: ['image/*'] },
            ]
          },
        }
      }
    ]
    this.nuevoEquipoWindows = this.windowService.open(
      this.equipoTemplate,
      { title: 'Nuevo equipo', context: { formConfig: formConfig } },
    );
  }

  onSavedEquipo(form: FormGroup) {
    console.log(form.value);
    this.equiposService.create(form).subscribe(() => {
      this.nuevoEquipoWindows.close();
      this.equiposService.getAll().subscribe((data) => {
        this.equipos = data;
        this.dataFiltered = data;
      });
      this.toastrService.success('Equipo creado con éxito',
        'Equipo Creado');
    }, (error) => {
      this.toastrService.danger('Error: ' + error.error.message,
        'Error al crear el equipo');
    });
  }

  onEquipoSearch() {
    const searchTextLower = this.searchText.toLowerCase();
    this.dataFiltered = this.equipos.filter((equipo) => {
      return (
        equipo.serie?.toLowerCase().includes(searchTextLower) ||
        equipo.modelo?.toLowerCase().includes(searchTextLower) ||
        equipo.marca?.toLowerCase().includes(searchTextLower) ||
        equipo.obra?.toLowerCase().includes(searchTextLower) ||
        equipo.responsable?.toLowerCase().includes(searchTextLower) ||
        equipo.tipo?.toLowerCase().includes(searchTextLower)
      );
    });
  }
}
