<table mat-table #table [dataSource]="dataSource">
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Mail </th>
    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
  </ng-container>
  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef> Nombre </th>
    <td mat-cell *matCellDef="let row"> {{row.titulo}} {{row.nombre}} {{row.apellido_paterno}} {{row.apellido_materno}} </td>
  </ng-container>
  <ng-container matColumnDef="cargo">
    <th mat-header-cell *matHeaderCellDef> Cargo </th>
    <td mat-cell *matCellDef="let row"> {{row.cargo}} </td>
  </ng-container>
  <ng-container matColumnDef="telefono">
    <th mat-header-cell *matHeaderCellDef> Teléfono </th>
    <td mat-cell *matCellDef="let row"> {{row.telefono}} </td>
  </ng-container>
  <ng-container matColumnDef="firma">
    <th mat-header-cell *matHeaderCellDef> ¿Firma Contrato? </th>
    <td mat-cell *matCellDef="let row">
      <nb-icon *ngIf="row.firma" icon="checkmark"></nb-icon>
      <nb-icon *ngIf="!row.firma" icon="close"></nb-icon>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>