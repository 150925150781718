<form [formGroup]="clienteForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="form-group col-sm-6">
      <label for="nombre">Nombre*</label>
      <input class="form-control" type="text" id="nombre" name="nombre" required formControlName="nombre" nbInput
        fullWidth status="basic" placeholder="Nombre">
    </div>
    <div class="form-group col-sm-6">
      <label for="numero">Número*</label>
      <input class="form-control" type="text" id="numero" name="numero" required formControlName="num_tel" nbInput
        fullWidth status="basic" placeholder="Número">
    </div>
  </div>
<div class="row">
  <div class="form-group col-sm-6">
    <label for="direccion">Dirección*</label>
    <input class="form-control" type="text" id="direccion" name="direccion" required formControlName="direccion" nbInput
      fullWidth status="basic" placeholder="Dirección">
  </div>
  <div class="form-group col-sm-6">
    <label for="correo">Correo*</label>
    <input class="form-control" type="email" id="correo" name="correo" formControlName="correo" nbInput
      fullWidth status="basic" placeholder="Correo">
  </div>
</div>
<div class="row">
  <div class="form-group col-sm-6">
    <label for="testigo">Testigo</label>
    <input class="form-control" type="text" id="testigo" name="testigo" formControlName="testigo" nbInput
      fullWidth status="basic" placeholder="Testigo">
  </div>
  <div class="form-group col-sm-6">
    <label for="representante">Representante</label>
    <input class="form-control" type="text" id="representante" name="representante" formControlName="representante" nbInput
      fullWidth status="basic" placeholder="Representante">
  </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-6">
      <label for="lugar_uso_maq">Lugar de uso de la maquinaria</label>
      <input class="form-control" type="text" id="lugar_uso_maq" name="lugar_uso_maq" formControlName="lugar_uso_maq" nbInput
        fullWidth status="basic" placeholder="Lugar de uso de la maquinaria">
    </div>
    <div class="form-group col-sm-6">
      <label for="rep_clave_elect">Clave electorale del representante</label>
      <input class="form-control" type="text" id="rep_clave_elect" name="rep_clave_elect" formControlName="rep_clave_elect" nbInput
        fullWidth status="basic" placeholder="Representante clave electrónica">
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-12">
      <label for="rep_direccion">Dirección del representante</label>
      <input class="form-control" type="text" id="rep_direccion" name="rep_direccion" formControlName="rep_direccion" nbInput
        fullWidth status="basic" placeholder="Dirección del representante">
    </div>
  </div>
  <button type="submit" nbButton status="primary">Guardar</button>
</form>

