import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { ExpedienteUnico } from '../../@core/data/expediente-unico-data';
import { ExpedienteUnicoService } from './../../@core/services/expediente-unico.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ngx-expedientes-unicos',
  templateUrl: './expedientes-unicos.component.html',
  styleUrls: ['./expedientes-unicos.component.scss']
})
export class ExpedientesUnicosComponent implements OnInit {

  searchText: string='';
  expedientesUnicos: ExpedienteUnico[];
  filteredData: ExpedienteUnico[];
  nuevoVehiculoWindows: NbWindowRef;
  @ViewChild('nuevoExpediente', { read: TemplateRef }) vehiculoTemplate: TemplateRef<HTMLElement>;

  constructor(private expedientesUnicosService: ExpedienteUnicoService,private titleService: Title,
    private windowService: NbWindowService,private toastrService: NbToastrService,) {
    this.titleService.setTitle('Expedientes Únicos');
  }

  ngOnInit() {
    this.expedientesUnicosService.getAll().subscribe(expedientesUnicos => {
      this.expedientesUnicos = expedientesUnicos;
      this.filteredData = expedientesUnicos;
    });
  }
  onExpedienteSaved(){

  }

  onExpedientesSearch() {
    this.filteredData = this.expedientesUnicos.filter((expediente) => {
      return expediente.contrato.toLowerCase().includes(this.searchText.toLowerCase()) ||
      expediente.cargo_entrega.toLowerCase().includes(this.searchText.toLowerCase()) ||
      expediente.recibe.toLowerCase().includes(this.searchText.toLowerCase()) ||
      expediente.cargo_recibe.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  onClickOpenNuevoExpediente() {
    this.nuevoVehiculoWindows = this.windowService.open(
      this.vehiculoTemplate,
      { title: 'Nuevo expediente', hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: true},
    );
    this.nuevoVehiculoWindows.onClose.subscribe(() => {
      this.refreshData();
    });
  }

  refreshData(){
    this.expedientesUnicosService.getAll().subscribe(expedientesUnicos => {
      this.expedientesUnicos = expedientesUnicos;
      this.filteredData = expedientesUnicos;
    });
  }
}
