import * as moment from 'moment';

export function filterItems(items: any[],searchFields: string[], searchValue: any,startDate?: Date, endDate?: Date, dateField?: string ): any[] {
    let filteredItems = items.filter(item => {
        return searchFields.some(field => {
            const value = item[field];
            const search = typeof value === 'string' ? value.toLowerCase() : value?.toString()?.toLowerCase();
            return search?.includes(searchValue?.toString()?.toLowerCase());
        });

    });
    if (startDate && endDate && dateField) {
      filteredItems = filteredItems.filter((item) => {
        const fechaBusqueda = moment(item[dateField]).add(6, 'hours').toDate();
        console.log(fechaBusqueda);
        console.log(startDate);

        console.log(startDate);

        const startDateOnly = moment(startDate).startOf('day').toDate();
        const endDateOnly = moment(endDate).endOf('day').toDate();
        const fechaBusquedaOnly = moment(fechaBusqueda).startOf('day').toDate();
        return (fechaBusquedaOnly >= startDateOnly) && (fechaBusquedaOnly <= endDateOnly);
      });
    }
    return filteredItems;
}
function adjustDate(date) {
  let adjustedDate = moment(date).utcOffset(-6).toDate();
  return adjustedDate;
}

