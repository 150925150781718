import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CotizacionesComponent } from './cotizaciones.component';
import { CotizacionesTablesModule } from "./cotizaciones-tables/cotizaciones-tables.module";
import { NbCardModule, NbIconModule, NbInputModule, NbTooltipModule } from '@nebular/theme';
import { CotizacionService } from '../../@core/services/cotizacion.service';
import { FormsModule } from '@angular/forms';
import { GCMARQFormsModule } from '../../forms/gcmarqforms.module';
import { GCMarqTablesModule } from '../../tables/gcmarq-tables.module';



@NgModule({
  declarations: [
    CotizacionesComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbInputModule,
    NbIconModule,
    FormsModule,
    CotizacionesTablesModule,
    NbTooltipModule,
    GCMARQFormsModule,
    GCMarqTablesModule
],
providers: [
  CotizacionService
]
})
export class CotizacionesModule { }
